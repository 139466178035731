import React from "react"
import "./StartBuildingCard.styles.scss"
import { Checkbox, Select } from "antd"
import * as _ from "lodash"
import { useDispatch } from "react-redux"
import { createInitialSpheres } from "../../../redux/Collections/Collections.actions"

const rulesOptions = [
  {
    value: "never",
    label: "Never",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "bi-monthly",
    label: "Bi-monthly",
  },
  {
    value: "quarterly",
    label: "Quarterly",
  },
  {
    value: "semi-annually",
    label: "Semi-annually",
  },
  {
    value: "annually",
    label: "Annually",
  },
]

const StartBuildingCard = () => {
  let spheresGroups = require("./spheres_groups.json")
  const [selectedSpheres, setSelectedSpheres] = React.useState(null)

  const dispatch = useDispatch()

  const onChangeSelectedSpheres = (idx) => {
    const newSelectedSpheres = [...selectedSpheres]
    newSelectedSpheres[idx].checked = !newSelectedSpheres[idx].checked
    setSelectedSpheres(newSelectedSpheres)
  }

  const handleChangeRule = (rule, idx) => {
    const newSelectedSpheres = [...selectedSpheres]
    newSelectedSpheres[idx].stay_in_touch_frequency = rule
    setSelectedSpheres(newSelectedSpheres)
  }

  const handleSubmit = () => {
    const spheres = _.filter(selectedSpheres, "checked")
    dispatch(createInitialSpheres(spheres))
  }

  return (
    <div className="StartBuildingCard">
      <div>
        <h1>Start building your database by choosing some initial spheres</h1>
        <h4>
          After selecting an initial set of spheres, you can then add, remove, and
          change to customize how you work!
        </h4>
      </div>
      {!selectedSpheres && (
        <div className="StartBuildingCard_SpheresGroups">
          {spheresGroups.map(({ title, description }, idx) => (
            <div
              key={idx}
              className={`SpheresGroup SpheresGroup--variant${idx % 4}`}
            >
              <div className="SpheresGroup_Body">
                <h2>{title}</h2>
                <span>{description}</span>
              </div>
              <div className="SpheresGroup_ButtonWrapper">
                <button
                  onClick={() =>
                    setSelectedSpheres(_.sortBy(spheresGroups[idx].spheres, "order"))
                  }
                >
                  Use This
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedSpheres && (
        <div className="StartBuildingCard_SpheresGroupDetails">
          <h2>Select the spheres you want to start with:</h2>
          {selectedSpheres.map(({ name, stay_in_touch_frequency }, idx) => (
            <div
              key={idx}
              className={`SphereDetails SphereDetails--variant${idx % 4}`}
            >
              <Checkbox
                checked={selectedSpheres[idx].checked}
                onChange={() => onChangeSelectedSpheres(idx)}
              />
              <span className="SphereDetails_Name">{name}</span>
              <span className="SphereDetails_Name">Stay in touch:</span>
              <span className="SphereDetails_Frequency">
                <Select
                  className="RuleCreator_Select"
                  dropdownClassName="RuleCreator_Select_Dropdown"
                  disabled={!selectedSpheres[idx].checked}
                  defaultValue={
                    rulesOptions
                      .map(({ value }) => value)
                      .includes(stay_in_touch_frequency)
                      ? stay_in_touch_frequency
                      : "never"
                  }
                  onSelect={(value) => handleChangeRule(value, idx)}
                >
                  {rulesOptions.map(({ value, label }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </span>
            </div>
          ))}
          <div className="StartBuildingCard_SpheresGroupDetails_Buttons">
            <button onClick={() => setSelectedSpheres(null)}>Back</button>
            <button onClick={handleSubmit}>Create</button>
          </div>
        </div>
      )}
    </div>
  )
}

StartBuildingCard.propTypes = {}

StartBuildingCard.defaultProps = {}

export default StartBuildingCard
