import React, { useEffect, useState } from "react"
import "./Forms.styles.scss"
import { Form, Input } from "antd"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { savePassword } from "../../../redux/User/User.actions"
import { useParams } from "react-router"
import * as yup from "yup"

const ResetPasswordForm = () => {
  const params = useParams()

  const [token, setToken] = useState(null)

  const saveNewPassword = async (values) => {
    dispatch(savePassword(values.password, token))
  }

  useEffect(() => {
    setToken(params.token)
    // eslint-disable-next-line
  }, [])

  const dispatch = useDispatch()

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(12, "Password must have at least 12 characters")
      .matches(/.*[A-Z].*/, "Password must contain at least one UPPERCASE letter")
      .matches(/.*[0-9].*/, "Password must contain at least one number"),
    password_confirmation: yup
      .string()
      .required("Password confirmation is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: schema,
    onSubmit: (values) => saveNewPassword(values),
  })

  return (
    <form className="ResetPasswordForm" onSubmit={formik.handleSubmit}>
      <p className="ResetPasswordForm_InputLabel">New password*</p>
      <Form.Item
        validateStatus={
          formik.touched.password && formik.errors.password ? "error" : "success"
        }
        help={formik.touched.password && formik.errors.password}
        extra={
          "Passwords should be at least 12 characters and at least one uppercase letter and number."
        }
      >
        <Input
          className="ResetPasswordForm_Input"
          name="password"
          placeholder="Enter your new password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <p className="ResetPasswordForm_InputLabel">Repeat new password*</p>
      <Form.Item
        validateStatus={
          formik.touched.password_confirmation && formik.errors.password_confirmation
            ? "error"
            : "success"
        }
        help={
          formik.touched.password_confirmation && formik.errors.password_confirmation
        }
      >
        <Input
          className="ResetPasswordForm_Input"
          name="password_confirmation"
          placeholder="Repeat your new password"
          type="password"
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <button
        type={"button"}
        className="ResetPasswordForm_Button"
        onClick={() => formik.submitForm()}
      >
        Submit
      </button>
    </form>
  )
}

export default ResetPasswordForm
