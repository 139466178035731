import React from "react"
import moment from "moment"
import "../InteractionModal.styles.scss"
import { useSelector } from "react-redux"

const DOMPurify = require("dompurify")(window)

const EventContent = ({ eventDetails }) => {
  const user = useSelector((state) => state.UserState)

  return (
    <>
      <div className={"InteractionModal__Info"}>
        <span>
          Owner:{" "}
          {eventDetails && eventDetails.owner ? `${eventDetails.owner}` : "---"}
        </span>
      </div>
      {eventDetails.location && (
        <div className={"InteractionModal__Info"}>
          <span>Location: {eventDetails.location}</span>
        </div>
      )}
      {eventDetails.when && (
        <div className={"InteractionModal__Info"}>
          <span>
            When:{" "}
            {moment
              .unix(eventDetails.when.start_time)
              .tz(user.time_zone)
              .format("MM/DD hh:mm A")}{" "}
            -{" "}
            {moment
              .unix(eventDetails.when.end_time)
              .tz(user.time_zone)
              .format("MM/DD hh:mm A")}
          </span>
        </div>
      )}
      {eventDetails && eventDetails.participants.length > 0 && (
        <div className={"InteractionModal__Info"}>
          <span>Participants:</span>{" "}
          <div>
            {eventDetails && eventDetails.participants.length > 0
              ? eventDetails.participants.map((participant, i) => {
                  if (participant.name) {
                    return (
                      <span
                        className={`InteractionModal__Value ${participant.status}`}
                      >
                        {participant.email ? `(${participant.email})` : ""}
                        {i !== eventDetails.participants.length - 1 && ", "}
                      </span>
                    )
                  } else {
                    return (
                      <span
                        className={`InteractionModal__Value ${participant.status}`}
                      >
                        {participant.email}
                        {i !== eventDetails.participants.length - 1 && ", "}
                      </span>
                    )
                  }
                })
              : "---"}
          </div>
        </div>
      )}
      {eventDetails?.body && (
        <div
          className={`InteractionModal__Body`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              eventDetails.description.split("\n").join("<br/>")
            ),
          }}
        />
      )}
    </>
  )
}

export default EventContent
