import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { notification } from "antd"
import RegistrationForm from "../../components/Login/Forms/RegistrationForm.component"
import SignUpPageBackground from "../../images/SignUpPageBackground.svg"
import PageTemplate from "../../components/Login/LoginPageTemplate/LoginPageTemplate.component"
import NylasAuthProvider from "../../components/Login/Forms/NylasAuthProvider.component"
import { Divider } from "antd/es"

const SignUpPage = (props) => {
  const history = useHistory()

  useEffect(() => {
    if (history.location.state && history.location.state.expired) {
      notification.error({
        message: "Login",
        description: "Your session has expired. Please login again.",
        className: "Notification-error",
      })
      history.location.state.expired = false
    }

    if (props.isAuthorized) {
      history.push("/dashboard")
    }
    // eslint-disable-next-line
  }, [history])

  return (
    <PageTemplate backgroundImage={SignUpPageBackground}>
      <NylasAuthProvider action={"Sign up"} />
      <Divider>OR</Divider>
      <RegistrationForm />
    </PageTemplate>
  )
}

export default SignUpPage
