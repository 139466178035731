import React, { useEffect, useState } from "react"
import "./BasicSettingsSection.styles.scss"
import { useFormik } from "formik"
import * as yup from "yup"
import { Button, Checkbox, Form, Input, Select, Spin } from "antd"
import Icon, { LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import {
  logout,
  logoutAllDevices,
  updateProfile,
} from "../../../redux/User/User.actions"
import "antd/dist/antd.css"
import "antd-country-phone-input/dist/index.css"
import moment from "moment"
import ConfirmModal from "../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal.component"
import useDebounce from "../../../hooks/useDebounce"
import { checkPublicUsername } from "../Account.actions"
import CheckIcon from "../../Icons/Check.icons"
import XIcon from "../../Icons/X.icons"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const { Option, OptGroup } = Select

const BasicSettingsSection = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserState)

  const [changPasswordVisible, setChangPasswordVisible] = useState(false)
  const [showLeaderboardNameInput, setShowLeaderboardNameInput] = useState(false)
  const [logoutAllVisible, setLogoutAllVisible] = useState(false)
  const [checkingPublicName, setCheckingPublicName] = useState(false)
  const [publicNameAvailable, setPublicNameAvailable] = useState(false)
  const [publicName, setPublicName] = useState("")
  const debouncedQuery = useDebounce(publicName, 200)

  const schema = yup.object().shape({
    time_zone: yup.string(),
    public_username: yup
      .string()
      .required("Public username is required")
      .min(3, "Public username must have at least 3 characters")
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "Public username is invalid. Only letters, numbers and underscores are allowed"
      )
      .nullable(),
    public_username_available: yup
      .boolean()
      .required()
      .oneOf([true], "Not available"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      time_zone: "",
      hide_leaderboard: false,
      daily_recommendations: true,
      track_opened: false,
      termsOfService: false,
      leaderboard_name: null,
      public_username: null,
      public_username_available: false,
    },
    validationSchema: schema,
    onSubmit: (values) => dispatch(updateProfile(values)),
  })

  useEffect(() => {
    formik.setValues({
      time_zone: user.time_zone,
      hide_leaderboard: user.hide_leaderboard,
      daily_recommendations: user.daily_recommendations,
      track_opened: user.track_opened,
      leaderboard_name: user.leaderboard_name,
      public_username: user.public_username,
    })
    setPublicName(user.public_username)
    setShowLeaderboardNameInput(!!user.leaderboard_name)

    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (publicNameAvailable && !checkingPublicName) {
      formik.setFieldValue("public_username_available", true)
    }
    // eslint-disable-next-line
  }, [publicNameAvailable, checkingPublicName, formik.values])

  const renderOptions = () => {
    const timezones = moment.tz.names()
    let mappedValues = {}
    let regions = []

    timezones.forEach((timezone) => {
      const splitTimezone = timezone.split("/")
      const region = splitTimezone[0]
      if (!mappedValues[region]) {
        mappedValues[region] = []
        regions.push(region)
      }
      mappedValues[region].push(timezone)
    })
    return regions.map((region) => {
      const options = mappedValues[region].map((timezone) => {
        return <Option key={timezone}>{timezone}</Option>
      })
      return (
        <OptGroup key={region} title={<div style={{ fontSize: 30 }}>{region}</div>}>
          {options}
        </OptGroup>
      )
    })
  }

  useEffect(() => {
    if (publicName && publicName.length > 2) {
      setCheckingPublicName(true)
    }
  }, [publicName])

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 2) {
      checkPublicUsername(debouncedQuery, (data) => {
        setPublicNameAvailable(data.available)
        setCheckingPublicName(false)
      })
    }
  }, [debouncedQuery])

  const publicUsernameErrorInfo = () => {
    if (formik.touched.public_username && formik.errors.public_username) {
      return formik.errors.public_username
    } else {
      return publicNameAvailable ? "Available" : "Not available"
    }
  }

  return (
    <>
      <div className="BasicSettings Wrapper">
        <h2>Basic Settings</h2>
        <ConfirmModal
          title="Are you sure you want to log out from all devices?"
          description="Confirming will log you out from every device you are currently logged in to (including the one you are using now)."
          visible={logoutAllVisible}
          onCancel={() => setLogoutAllVisible(false)}
          onConfirm={() => {
            dispatch(logoutAllDevices())
          }}
        />
        <ChangePasswordModal
          visible={changPasswordVisible}
          onCancel={() => setChangPasswordVisible(false)}
        />
        <Form className="BasicSettings_Form">
          <Form.Item name="hide_leaderboard">
            <Checkbox
              checked={formik.values.hide_leaderboard}
              onChange={(e) => {
                formik.setFieldValue("hide_leaderboard", e.target.checked, false)
              }}
            >
              Hide the leaderboard entirely
            </Checkbox>
          </Form.Item>
          <Form.Item name="daily_recommendations">
            <Checkbox
              checked={formik.values.daily_recommendations}
              onChange={(e) => {
                if (formik.values.email?.length === 0 || formik.errors.email) {
                  formik.setFieldTouched("daily_recommendations", true, false)
                  formik.setFieldError(
                    "daily_recommendations",
                    "Please add a valid email address so we can send you reminders"
                  )
                } else {
                  formik.setFieldValue(
                    "daily_recommendations",
                    e.target.checked,
                    false
                  )
                }
              }}
            >
              Send me a daily email with my reminders and recent activity
            </Checkbox>
          </Form.Item>
          <Form.Item name="track_opened">
            <Checkbox
              checked={formik.values.track_opened}
              onChange={(e) => {
                formik.setFieldValue("track_opened", e.target.checked, false)
              }}
            >
              Track when people open messages I send from Relatable
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="leaderboard_name_checkbox"
            style={{ marginBottom: "10px" }}
          >
            <Checkbox
              checked={!!formik.values.leaderboard_name}
              onChange={(e) => {
                if (e.target.checked) {
                  setShowLeaderboardNameInput(true)
                  formik.setFieldValue(
                    "leaderboard_name",
                    `${user.first_name} ${user.last_name}`
                  )
                } else {
                  setShowLeaderboardNameInput(false)
                  formik.setFieldValue("leaderboard_name", null)
                }
              }}
            >
              Show me on the leaderboard{" "}
            </Checkbox>
          </Form.Item>
          {(showLeaderboardNameInput || formik.values.leaderboard_name) && (
            <Form.Item
              label="Show my name as"
              colon={false}
              className="BasicSettings_Form_LeaderboardInput"
            >
              <Input
                key="leaderboard_name"
                name="leaderboard_name"
                value={formik.values.leaderboard_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Time zone"
            colon={false}
            className="BasicSettings_Form_TimeZoneInput"
          >
            <Select
              key="time_zone"
              name="time_zone"
              showSearch
              value={formik.values.time_zone}
              defaultValue={formik.values.time_zone}
              onSelect={(e) => formik.setFieldValue("time_zone", e.toString())}
              onBlur={formik.handleBlur}
            >
              {renderOptions()}
            </Select>
          </Form.Item>
          <div className="BasicSettings_Form_PublicName">
            <Form.Item
              label="My public username"
              colon={false}
              className="BasicSettings_Form_PublicUsernameInput"
            >
              <Input
                key="public_username"
                name="public_username"
                value={formik.values.public_username}
                onChange={(e) => {
                  setPublicName(e.target.value)
                  formik.setFieldValue("public_username", e.target.value)
                }}
                onBlur={formik.handleBlur}
                status={"error"}
              />
              {checkingPublicName && <RelatableLoader />}
            </Form.Item>
            <span
              className={
                publicUsernameErrorInfo() === "Available"
                  ? "BasicSettings_Form_PublicName_Available"
                  : "BasicSettings_Form_PublicName_NotAvailable"
              }
            >
              {publicUsernameErrorInfo() === "Available" ? (
                <Icon component={CheckIcon} />
              ) : (
                <Icon component={XIcon} />
              )}
              {publicUsernameErrorInfo()}
            </span>
          </div>
          <Button
            disabled={!publicNameAvailable}
            className={`SubmitButton ${
              !publicNameAvailable ? "SubmitButton-disabled" : ""
            }`}
            onClick={() => formik.submitForm()}
          >
            Save Changes
          </Button>
        </Form>
      </div>
      <div className="ButtonsSection Wrapper">
        <button
          className="ButtonsSection_Button ButtonsSection_Button-White"
          onClick={() => setChangPasswordVisible(true)}
        >
          Change Password
        </button>
        <button
          className="ButtonsSection_Button ButtonsSection_Button-Red"
          onClick={() => dispatch(logout())}
        >
          Logout
        </button>
        <button
          className="ButtonsSection_Button ButtonsSection_Button-Red"
          onClick={() => setLogoutAllVisible(true)}
        >
          Log me out from all devices
        </button>
      </div>
    </>
  )
}

export default BasicSettingsSection
