export const apiURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return process.env.REACT_APP_DEV_API_URL
    case "staging":
      return process.env.REACT_APP_STAGING_API_URL
    case "production":
      return process.env.REACT_APP_PROD_API_URL
    default:
      return null
  }
}

export const apiHost = () => {
  return apiURL().replace("/api/", "")
}
