import React from "react"
import { useReward } from "react-rewards"
import { approveTrigger } from "../../../../redux/Triggers/Triggers.actions"
import { useDispatch } from "react-redux"

const RemindMeButton = ({ id }) => {
  const dispatch = useDispatch()

  const { reward, isAnimating } = useReward(`rewardId_remindMe_${id}`, "confetti", {
    position: "absolute",
    lifetime: 40,
  })

  const handleApproveTrigger = () => {
    dispatch(approveTrigger(id))
  }
  return (
    <button
      disabled={isAnimating}
      className="EventCard_ApproveButton"
      onClick={() => {
        reward()
        setTimeout(() => {
          handleApproveTrigger()
        }, 800)
      }}
    >
      <div id={`rewardId_remindMe_${id}`} />
      Remind Me
    </button>
  )
}
export default RemindMeButton
