import React from "react"
import "./InfoBar.styles.scss"
import { Link } from "react-router-dom"

const InfoBar = ({ trialing, card_declined, children }) => {
  return (
    <div className={"InfoBar"}>
      <div className={"InfoBar_WelcomeText"}>
        {trialing && <span>Welcome to your trial of Relatable!</span>}
        {card_declined && (
          <span>
            Hey! We’re having trouble with your credit card.{" "}
            <Link to={"/account/settings?section=Billing"}>Mind fixing?</Link>
          </span>
        )}
      </div>
      {children}
    </div>
  )
}

export default InfoBar
