import React from "react"
import "./CallContactCard.styles.scss"
import Avatar from "../../../../UI/Avatar/Avatar.component"
import PropTypes from "prop-types"
import { ReactComponent as ArrowInCircleRight } from "../../../../Icons/ArrowInCircleRight.svg"
import Icon from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../../redux/App/App.actions"

const CallContactCard = ({ contact, onRecordInteraction }) => {
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )

  const dispatch = useDispatch()

  const handleShowPerson = (id, slug) => {
    if (contactDrawerVisible && id === visibleContactID) {
      dispatch(setVisibleContactID(null))
      dispatch(setContactDrawerVisible(false))
    } else {
      dispatch(setVisibleContactID(id, slug))
      dispatch(setContactDrawerVisible(true))
      dispatch(setContactDrawerMode("view"))
    }
  }
  const handleRecordInteraction = () => {
    onRecordInteraction()
  }

  return (
    <div
      className="CallContactCard"
      onClick={() => handleShowPerson(contact.id, contact.slug)}
    >
      <div className="CallContactCard_AvatarBox">
        <Avatar
          avatar={
            contact.profile_image_url !== "" ? contact.profile_image_url : null
          }
          firstName={contact.first_name}
          lastName={contact.last_name}
          colorNumber={contact.id}
          size={50}
        />
      </div>
      <div>
        <h4>{contact.full_name}</h4>
        <div className="CallContactCard_Spheres">
          {contact.spheres?.slice(0, 2)?.map((sphere) => (
            <span key={sphere.id}>{sphere.title}</span>
          ))}
          {contact.spheres?.length > 2 && <span>+{contact.spheres.length - 2}</span>}
        </div>
      </div>
      <button
        className="primary CallContactCard_Button"
        onClick={handleRecordInteraction}
      >
        Get Back In Touch <Icon component={ArrowInCircleRight} />
      </button>
    </div>
  )
}

CallContactCard.propTypes = {
  contact: PropTypes.object,
  onRecordInteraction: PropTypes.func,
}

CallContactCard.defaultProps = {
  contact: {},
}

export default CallContactCard
