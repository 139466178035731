import React from "react"

const XCircleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4967 6.27711 13.8108 4.62573 12.5925 3.40746C11.3743 2.18918 9.72289 1.5033 8 1.5ZM10.3563 9.64375C10.4501 9.73855 10.5028 9.86658 10.5028 10C10.5028 10.1334 10.4501 10.2615 10.3563 10.3562C10.2607 10.4487 10.1329 10.5003 10 10.5003C9.86706 10.5003 9.73932 10.4487 9.64375 10.3562L8 8.70625L6.35625 10.3562C6.26068 10.4487 6.13294 10.5003 6 10.5003C5.86706 10.5003 5.73932 10.4487 5.64375 10.3562C5.54986 10.2615 5.49719 10.1334 5.49719 10C5.49719 9.86658 5.54986 9.73855 5.64375 9.64375L7.29375 8L5.64375 6.35625C5.56402 6.2591 5.52328 6.13577 5.52944 6.01024C5.53561 5.88472 5.58824 5.76597 5.67711 5.67711C5.76598 5.58824 5.88472 5.5356 6.01025 5.52944C6.13577 5.52327 6.2591 5.56402 6.35625 5.64375L8 7.29375L9.64375 5.64375C9.7409 5.56402 9.86423 5.52327 9.98976 5.52944C10.1153 5.5356 10.234 5.58824 10.3229 5.67711C10.4118 5.76597 10.4644 5.88472 10.4706 6.01024C10.4767 6.13577 10.436 6.2591 10.3563 6.35625L8.70625 8L10.3563 9.64375Z"
      fill="currentColor"
    />
  </svg>
)

export default XCircleIcon
