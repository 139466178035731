import { call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import {
  FETCH_ACTIVE_CHALLENGES,
  FETCH_ACTIVITIES,
  FETCH_CHALLENGE,
  FETCH_POINTS,
  JOIN_TO_CHALLENGE,
} from "./Activities.types"
import {
  fetchActiveChallengesFail,
  fetchActiveChallengesSuccess,
  fetchActivitiesFail,
  fetchActivitiesSuccess,
  fetchChallengeFail,
  fetchChallengeSuccess,
  fetchPointsFail,
  fetchPointsSuccess,
  joinToChallengeFail,
  joinToChallengeSuccess,
} from "./Activities.actions"
import moment from "moment"

export function* watchActivitiesSaga() {
  yield takeLatest(FETCH_ACTIVITIES, fetchActivities)
  yield takeLatest(FETCH_POINTS, fetchPoints)
  yield takeLatest(FETCH_ACTIVE_CHALLENGES, fetchActiveChallenges)
  yield takeLatest(FETCH_CHALLENGE, fetchChallenge)
  yield takeLatest(JOIN_TO_CHALLENGE, joinToChallenge)
}

function* fetchActivities(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/activities",
        params: {
          page: action.page,
          per: action.per_page,
        },
        method: "GET",
      })
    )
    yield put(fetchActivitiesSuccess(data.activities, data.total_entries))
  } catch (e) {
    yield put(fetchActivitiesFail(e))
  }
}

function* fetchPoints(action) {
  try {
    const defaultDateFilter = moment().subtract(1, "months").format()
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/activities/points",
        params: {
          date_filter: action.date_filter || defaultDateFilter,
          option: action.option,
        },
        method: "GET",
      })
    )
    yield put(fetchPointsSuccess(data.points, data.leaderboard))
  } catch (e) {
    yield put(fetchPointsFail(e))
  }
}

function* fetchActiveChallenges(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/challenges/active",
        method: "GET",
      })
    )
    yield put(fetchActiveChallengesSuccess(data.challenges))
  } catch (e) {
    yield put(fetchActiveChallengesFail(e))
  }
}

function* fetchChallenge(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/challenges/${action.id}`,
        method: "GET",
      })
    )
    yield put(fetchChallengeSuccess(data.challenge))
  } catch (e) {
    yield put(fetchChallengeFail(e))
  }
}

function* joinToChallenge(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/challenges/join/${action.id}`,
        method: "PUT",
      })
    )
    yield put(joinToChallengeSuccess(data.challenge))
  } catch (e) {
    yield put(joinToChallengeFail(e))
  }
}
