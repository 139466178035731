import React from "react"
import "./PersonAvatarAndName.styles.scss"
import Avatar from "../Avatar/Avatar.component"
import PropTypes from "prop-types"

const PersonAvatarAndName = ({ person, showPerson }) => {
  const handleShowPerson = () => {
    if (showPerson && person?.status === "active") {
      showPerson(person.id, person.slug)
    }
  }

  return person ? (
    <span className="PersonAvatarAndName" onClick={handleShowPerson}>
      <Avatar
        className="PersonAvatarAndName_Avatar"
        avatar={person.profile_picture_url}
        firstName={person.first_name}
        lastName={person.last_name}
        email={person.email}
        colorNumber={person.id}
        size={24}
      />
      &nbsp;
      <span
        className={
          person?.status === "active"
            ? "PersonAvatarAndName_Name"
            : "PersonAvatarAndName_Name--disabled"
        }
      >
        {person.full_name?.trim()?.length ? person.full_name : person.email}
      </span>
    </span>
  ) : (
    <span className="PersonAvatarAndName">
      <span className="PersonAvatarAndName_Name">?</span>
    </span>
  )
}

PersonAvatarAndName.propTypes = {
  person: PropTypes.object,
  showPerson: PropTypes.func,
}

export default PersonAvatarAndName
