import React from "react"
import "../TeamsSection.styles.scss"
import { useFormik } from "formik"
import * as yup from "yup"
import { Form, Input } from "antd"
import { useDispatch } from "react-redux"
import "antd/dist/antd.css"
import "antd-country-phone-input/dist/index.css"
import { createTeam } from "../../../../redux/User/User.actions"

const CreateTeam = () => {
  const dispatch = useDispatch()

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Team name is required")
      .min(3, "Team name is too short")
      .max(40, "Team name is too long"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(createTeam(values.name))
      formik.resetForm()
    },
  })

  return (
    <div className="Subsection CreateTeam">
      <h3>Create a new team</h3>
      <Form className="CreateTeam_Form">
        <Form.Item
          colon={false}
          validateStatus={
            formik.touched.name && formik.errors.name ? "error" : "success"
          }
          help={formik.touched.name && formik.errors.name ? formik.errors.name : []}
        >
          <Input
            key="name"
            name="name"
            placeholder="Enter your team name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <button
          className="CreateTeam_Form_SubmitButton"
          onClick={() => formik.submitForm()}
        >
          Create
        </button>
      </Form>
    </div>
  )
}

export default CreateTeam
