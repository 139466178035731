import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import { Button, Card, Carousel, Col, Row, Spin, Tag, Typography } from "antd"
import { MailOutlined } from "@ant-design/icons"
import "./OrganizeContacts.styles.scss"
import EmptyState from "../../UI/EmptyState/EmptyState.ui"
import * as _ from "lodash"
import moment from "moment"
import { useSelector } from "react-redux"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const { Title, Text } = Typography
const { CheckableTag } = Tag

const OrganizeContacts = (props) => {
  const [spheres, setSpheres] = useState([])
  const [participants, setParticipants] = useState([])
  const [currentParticipant, setCurrentParticipant] = useState(0)
  const [selectedSpheres, setSelectedSpheres] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = useState(1)
  const [lastSlide, setLastSlide] = useState(false)

  const carouselRef = useRef(null)
  const user = useSelector((state) => state.UserState)

  const loadParticipants = async (user_id, page = 1, callback = null) => {
    const { data } = await axios.request({
      url: `/v1/daily_reminders/contacts`,
      params: {
        user_id: user_id,
        page: page,
      },
    })
    const newParticipants = _.uniqBy(participants.concat(data.contacts), "id")
    setParticipants(newParticipants)
    setSpheres(data.spheres)
    const isLastSlide = data.contacts.length === 0
    if (isLastSlide) {
      setCurrentParticipant(participants.length)
    }
    setLastSlide(isLastSlide)
    callback && callback(isLastSlide)
  }

  const handleChange = (id, checked) => {
    const nextSelectedSpheres = checked
      ? [...selectedSpheres, id]
      : selectedSpheres.filter((s) => s !== id)
    setSelectedSpheres(nextSelectedSpheres)
  }

  const handleDismiss = () => {
    setLoaded(false)
    const newParticipants = [...participants]
    newParticipants.splice(currentParticipant, 1)
    setParticipants(newParticipants)
    setLoaded(true)
  }

  const handleSkip = (participant_id) => {
    setCurrentParticipant(currentParticipant + 1)
  }

  const handleAddSphere = async (participant_index) => {
    setLoaded(false)
    const newParticipants = [...participants]
    newParticipants.splice(participant_index, 1)
    setParticipants(newParticipants)
    const id = participants[participant_index].id
    await axios.request({
      url: `v1/daily_reminders/add_to_sphere`,
      data: { user_id: props.user.id, participant_id: id, spheres: selectedSpheres },
      method: "POST",
    })
    setLoaded(true)
  }

  useEffect(() => {
    loadParticipants(props.user.id, null).then((r) => {
      setLoaded(true)
    })
    // eslint-disable-next-line
  }, [props.user])

  useEffect(() => {
    setSelectedSpheres([])
    loaded && carouselRef.current.goTo(currentParticipant)
    // eslint-disable-next-line
  }, [currentParticipant, carouselRef.current])

  const renderParticipantCard = (participant) => {
    const displayName =
      participant.first_name && participant.first_name.length > 0
        ? `${participant.first_name} ${participant.last_name}`
        : participant.email
    return (
      <div key={participant.id} className={"OrganizeContacts_ParticipantCard"}>
        <Title level={5}>{displayName}</Title>
        {participant.body || participant.subject ? (
          <Card className={"OrganizeContacts_Card"}>
            <Row style={{ flexFlow: "row" }}>
              <MailOutlined style={{ fontSize: "20px", marginRight: "6px" }} />
              <Text className="OrganizeContacts_ParticipantCard_Text" strong>
                {participant.subject}
              </Text>
            </Row>
            <Row>
              <Text className="OrganizeContacts_ParticipantCard_Text">
                {participant.body}
              </Text>
            </Row>
            <Row>
              {participant.last_message && (
                <Text className={"OrganizeContacts_ParticipantCard_Date"}>
                  {moment(participant.last_message)
                    .tz(user.time_zone)
                    .format("MMM Do, YYYY")}
                </Text>
              )}
            </Row>
          </Card>
        ) : (
          <EmptyState size="medium" instructions="No messages found" />
        )}
        <Row className={"O"}>
          <Button
            type="link"
            className={"OrganizeContacts_Button_Dismiss"}
            onClick={() => {
              handleDismiss()
            }}
          >
            Dismiss
          </Button>
          <Button
            className={"OrganizeContacts_Button_Skip"}
            onClick={() => {
              handleSkip(participant.id)
            }}
          >
            Skip
          </Button>
        </Row>

        <div className={"OrganizeContacts_Spheres"}>
          {spheres.map((sphere) => (
            <CheckableTag
              className={"OrganizeContacts_Button"}
              key={sphere.id}
              checked={selectedSpheres.indexOf(sphere.id) > -1}
              onChange={(checked) => handleChange(sphere.id, checked)}
            >
              {sphere.title}
            </CheckableTag>
          ))}
        </div>
      </div>
    )
  }

  const NextButton = (props) => {
    const { className, style } = props
    return (
      <Button
        className={`${className} ${
          selectedSpheres.length === 0 && "OrganizeContacts_Disabled"
        }`}
        disabled={selectedSpheres.length === 0}
        style={{
          ...style,
        }}
        onClick={() => {
          handleAddSphere(currentParticipant).then((r) => {
            carouselRef.current.goTo(currentParticipant)
            setSelectedSpheres([])
          })
        }}
      >
        Next Contact
      </Button>
    )
  }

  const PrevButton = (props) => {
    const { className, style, onClick } = props
    return (
      <Button
        disabled={currentParticipant === 0}
        type="link"
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        Previous Contact
      </Button>
    )
  }

  return (
    <div className={"OrganizeContacts_Container"}>
      <Row className={"OrganizeContacts_Container_Header"}>
        <Col>
          <Row>
            <Title level={2}>Hello {props.user.first_name}</Title>
          </Row>
          <Row>
            <Text>Why not organize some contacts?</Text>
          </Row>
        </Col>
      </Row>

      <RelatableLoader loading={!loaded} style={{ margin: "50% auto" }} size="large">
        <Carousel
          draggable={false}
          swipe={false}
          ref={carouselRef}
          arrows={false}
          easing={"ease-in-out"}
          dots={false}
          infinite={false}
          initialSlide={currentParticipant}
          beforeChange={(oldIndex, newIndex) => {
            if (currentParticipant === participants.length) {
              setLoaded(false)
              loadParticipants(props.user.id, page + 1, (lastSlide) => {
                setLoaded(true)
                if (!lastSlide) {
                  carouselRef.current.goTo(currentParticipant + 1)
                  setPage(page + 1)
                }
              })
            }
          }}
        >
          {participants.map((p) => renderParticipantCard(p))}
          <div className={"OrganizeContacts_LastSlide"} key={participants.length}>
            {lastSlide && (
              <>
                <Title level={2}>
                  Looks like you've processed everyone for today!
                  <br />
                  Check back soon!
                </Title>
              </>
            )}
          </div>
        </Carousel>
        <Row className={"OrganizeContacts_ButtonContainer"}>
          <PrevButton
            onClick={() => {
              if (currentParticipant > 0) {
                setCurrentParticipant(currentParticipant - 1)
                setLastSlide(false)
              }
            }}
          />
          <NextButton className={"OrganizeContacts_ButtonContainer_Next"} />
        </Row>
      </RelatableLoader>
    </div>
  )
}

export default OrganizeContacts
