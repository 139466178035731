import React, { useEffect } from "react"
import "./Forms.styles.scss"
import { Form, Input } from "antd"
import { useFormik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { registerUser } from "../../../redux/User/User.actions"
import { useHistory } from "react-router-dom"
import { StringParam, useQueryParams } from "use-query-params"

const RegistrationForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [queryParams] = useQueryParams({
    email: StringParam,
    name: StringParam,
    via: StringParam,
  })

  const signingUp = useSelector((state) => state.UserState.signingUp)

  useEffect(() => {
    if (window.Rewardful && window.Rewardful.referral && queryParams.via) {
      formik.setValues({
        referral: window.Rewardful.referral,
        referral_string: queryParams.via,
      })
    } else {
      console.log("No referral present.")
    }
    // eslint-disable-next-line
  }, [window.Rewardful])

  const schema = yup.object().shape({
    referral: yup.string(),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Please provide valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(12, "Password must have at least 12 characters")
      .matches(/.*[A-Z].*/, "Password must contain at least one UPPERCASE letter")
      .matches(/.*[0-9].*/, "Password must contain at least one number"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })

  const formik = useFormik({
    initialValues: {
      first_name: queryParams.name ? queryParams.name.split(" ")[0] : "",
      last_name: queryParams.name ? queryParams.name.split(" ")[1] : "",
      email: queryParams.email || "",
      password: "",
      password_confirmation: "",
      referral: "",
      referral_string: queryParams.via || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      let my_values = { ...values }
      if (window.Rewardful) {
        my_values.referral = window.Rewardful.referral
      }
      dispatch(registerUser(my_values))
    },
  })

  return (
    <form className="RegistrationForm" data-rewardful>
      <p className="RegistrationForm_InputLabel">First Name*</p>
      <Form.Item
        validateStatus={
          formik.touched.first_name && formik.errors.first_name ? "error" : "success"
        }
        help={formik.touched.first_name && formik.errors.first_name}
      >
        <Input
          className="RegistrationForm_Input"
          name="first_name"
          placeholder="Enter your first name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <p className="RegistrationForm_InputLabel">Last Name*</p>
      <Form.Item
        validateStatus={
          formik.touched.last_name && formik.errors.last_name ? "error" : "success"
        }
        help={formik.touched.last_name && formik.errors.last_name}
      >
        <Input
          className="RegistrationForm_Input"
          name="last_name"
          placeholder="Enter your last name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <p className="RegistrationForm_InputLabel">Email*</p>
      <Form.Item
        validateStatus={
          formik.touched.email && formik.errors.email ? "error" : "success"
        }
        help={formik.touched.email && formik.errors.email}
      >
        <Input
          className="RegistrationForm_Input"
          name="email"
          placeholder="Enter your email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <p className="RegistrationForm_InputLabel">Password*</p>
      <Form.Item
        validateStatus={
          formik.touched.password && formik.errors.password ? "error" : "success"
        }
        help={formik.touched.password && formik.errors.password}
        extra={
          "Must be at least 12 characters and at least one uppercase letter and number."
        }
      >
        <Input
          className="RegistrationForm_Input"
          name="password"
          placeholder="Create a password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <p className="RegistrationForm_InputLabel">Repeat Password</p>
      <Form.Item
        validateStatus={
          formik.touched.password_confirmation && formik.errors.password_confirmation
            ? "error"
            : "success"
        }
        help={
          formik.touched.password_confirmation && formik.errors.password_confirmation
        }
      >
        <Input
          className="RegistrationForm_Input"
          name="password_confirmation"
          placeholder="Create a password"
          type="password"
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
        />
      </Form.Item>
      {!queryParams.via && (
        <>
          <p className="RegistrationForm_InputLabel">
            Referral
            <span className={"RegistrationForm_InputLabel_Addon"}> (Optional)</span>
          </p>
          <Form.Item
            validateStatus={
              formik.touched.referral && formik.errors.referral ? "error" : "success"
            }
            help={formik.touched.referral && formik.errors.referral}
          >
            <Input
              className="RegistrationForm_Input"
              name="referral_string"
              placeholder="Enter the referral"
              value={formik.values.referral_string}
              onChange={(e) => {
                if (window.Rewardful) {
                  window.Rewardful.source(e.target.value)
                }
                formik.handleChange(e)
              }}
            />
          </Form.Item>
        </>
      )}
      <button
        disabled={signingUp}
        className={
          signingUp ? "RegistrationForm_Button--Disabled" : "RegistrationForm_Button"
        }
        onClick={(e) => {
          e.preventDefault()
          formik.submitForm()
        }}
      >
        {signingUp ? "Signing you up, reticulating splines" : "Create account"}
      </button>
      <div className="RegistrationForm_LogIn">
        Already have an account?
        <div
          className="RegistrationForm_Link"
          onClick={() => history.push("/login")}
        >
          Log in
        </div>
      </div>
    </form>
  )
}

export default RegistrationForm
