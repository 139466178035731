import React from "react"

const PlusCircleIcon = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.71429 2.4375C2.34107 2.4375 2.03571 2.74688 2.03571 3.125V16.875C2.03571 17.2531 2.34107 17.5625 2.71429 17.5625H16.2857C16.6589 17.5625 16.9643 17.2531 16.9643 16.875V3.125C16.9643 2.74688 16.6589 2.4375 16.2857 2.4375H2.71429ZM0 3.125C0 1.6082 1.21719 0.375 2.71429 0.375H16.2857C17.7828 0.375 19 1.6082 19 3.125V16.875C19 18.3918 17.7828 19.625 16.2857 19.625H2.71429C1.21719 19.625 0 18.3918 0 16.875V3.125ZM8.48214 13.7812V11.0312H5.76786C5.20379 11.0312 4.75 10.5715 4.75 10C4.75 9.42852 5.20379 8.96875 5.76786 8.96875H8.48214V6.21875C8.48214 5.64727 8.93594 5.1875 9.5 5.1875C10.0641 5.1875 10.5179 5.64727 10.5179 6.21875V8.96875H13.2321C13.7962 8.96875 14.25 9.42852 14.25 10C14.25 10.5715 13.7962 11.0312 13.2321 11.0312H10.5179V13.7812C10.5179 14.3527 10.0641 14.8125 9.5 14.8125C8.93594 14.8125 8.48214 14.3527 8.48214 13.7812Z"
      fill="currentColor"
    />
  </svg>
)

export default PlusCircleIcon
