export const ADD_NOTE = "ADD_NOTE"
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS"
export const ADD_NOTE_FAIL = "ADD_NOTE_FAIL"

export const DELETE_NOTE = "DELETE_NOTE"
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS"
export const DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL"

export const EDIT_NOTE = "EDIT_NOTE"
export const EDIT_NOTE_CLEAR = "EDIT_NOTE_CLEAR"

export const UPDATE_NOTE = "UPDATE_NOTE"
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS"
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL"

export const ADD_INTERACTION = "ADD_INTERACTION"
export const ADD_INTERACTION_SUCCESS = "ADD_INTERACTION_SUCCESS"
export const ADD_INTERACTION_FAIL = "ADD_INTERACTION_FAIL"

export const DELETE_INTERACTION = "DELETE_INTERACTION"
export const DELETE_INTERACTION_SUCCESS = "DELETE_INTERACTION_SUCCESS"
export const DELETE_INTERACTION_FAIL = "DELETE_INTERACTION_FAIL"

export const DELETE_EVENT = "DELETE_EVENT"
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS"
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL"

export const CLEAR_REDUX_INTERACTIONS_STATE = "CLEAR_REDUX_INTERACTIONS_STATE"
export const SET_INITIAL_REDUX_INTERACTIONS_STATE =
  "SET_INITIAL_REDUX_INTERACTIONS_STATE"
