import React, { useEffect, useState } from "react"
import { setupAxios } from "./config/axios"
import { Route, Switch } from "react-router-dom"
import Dashboard from "./pages/Dashboard/Dashboard.page"
import MainLayout from "./layout/MainLayout/MainLayout.layout"
import DatabasePage from "./pages/Database/Database.page"
import SpheralizerPage from "./pages/Game/Spheralizer/Spheralizer.page"
import AccountPage from "./pages/Account/Account.page"
import LoginPage from "./pages/Login/Login.page"
import { store } from "./redux/store"
import { QueryParamProvider } from "use-query-params"
import ImportPage from "./pages/Account/Import/Import.page"
import TermsPage from "./pages/Terms.page"
import PrivacyPage from "./pages/Privacy.page"
import UncategorizedPage from "./pages/Uncategorized/Uncategorized.page"
import { useDispatch, useSelector } from "react-redux"
import SignUpPage from "./pages/SignUp/SignUp.page"
import AdminPage from "./pages/Admin/Admin.page"
import ProtectedRoute from "./ProtectedRoute"
import ProcessContacts from "./pages/DailyReminders/DailyReminders.page"
import ResetPasswordPage from "./pages/ResetPassword/ResetPassword.page"
import DuplicatesAndEnrichmentsPage from "./pages/DuplicatesAndEnrichments/DuplicatesAndEnrichments.page"
import EmailComposerModal from "./components/UI/EmailComposerModal/EmailComposerModal.component"
import SphereModal from "./components/Database/SphereModal/SphereModal.component"
import SpecificallySharedCollection from "./pages/SpecificallySharedCollection/SpecificallySharedCollection.page"
import SharedCollectionPage from "./pages/SharedCollection/SharedCollection.page"
import { resetLoggingOutState } from "./redux/User/User.actions"
import SuccessPaymentPage from "./pages/SuccessPayment/SuccessPayment.page"
import FreeAccountPage from "./pages/FreeAccount/FreeAccount.page"
import FlowSphere from "./pages/FlowSphere/FlowSphere"
import StageSphere from "./pages/StageSphere/StageSphere"
import UserPanel from "./pages/ProfileFiller/UserPanel/UserPanel.page"
import PublicView from "./pages/ProfileFiller/PublicView/PublicView.page"
import MessageCreator from "./components/UI/EmailComposerModal/MessageCreator/MessageCreator.component"
import RelatableLoader from "./components/UI/RelatableLoader/RelatableLoader.component"
import Confetti from "react-confetti"
import PartnerPage from "./pages/Partner/Partner.page"

function App() {
  const [showConfetti, setShowConfetti] = useState(false)

  const loggingOut = useSelector((state) => state.UserState.loggingOut)
  const redirecting = useSelector((state) => state.UserState.redirecting)
  const role = useSelector((state) => state.UserState.role)
  const partner = useSelector((state) => state.UserState.partner)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetLoggingOutState())
    // eslint-disable-next-line
  }, [])

  const splitMessageSenderVisible = useSelector(
    (state) => state.AppState.splitMessageSenderVisible
  )
  const fetchingSelectedPeople = useSelector(
    (state) => state.PeopleState.fetching_selected
  )

  const isAuthorized = store.getState().UserState.access_token
  setupAxios()
  return (
    <div className="App">
      {splitMessageSenderVisible && !fetchingSelectedPeople && (
        <EmailComposerModal visible={splitMessageSenderVisible}>
          <MessageCreator setShowConfetti={setShowConfetti} />
        </EmailComposerModal>
      )}
      {showConfetti && (
        <Confetti
          style={{ zIndex: 1000 }}
          numberOfPieces={200}
          recycle={false}
          gravity={0.2}
          tweenDuration={2000}
          zIndex={1000}
        />
      )}
      <UserPanel />
      <SphereModal />
      <div className={`LogoutOverlay ${loggingOut ? "active" : ""}`}>
        <RelatableLoader
          quote={true}
          customQuote={"Logging Out"}
          loading={loggingOut}
          size={"large"}
        />
      </div>
      <div className={`LogoutOverlay ${redirecting ? "active" : ""}`}>
        <RelatableLoader loading={redirecting} quote={true} size={"large"} />
      </div>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Route exact path={"/login"}>
            <LoginPage loggingOut={loggingOut} isAuthorized={isAuthorized} />
          </Route>
          <Route exact path={"/redirect"}>
            <span>Redirecting...</span>
          </Route>
          <Route exact path={"/i/:token"}>
            <ProcessContacts />
          </Route>
          <Route exact path={"/new_password/:token"}>
            <ResetPasswordPage />
          </Route>
          <Route exact path={"/shared/:token"}>
            <SharedCollectionPage isAuthorized={isAuthorized} />
          </Route>
          <Route exact path={"/signup"}>
            <SignUpPage isAuthorized={isAuthorized} />
          </Route>
          <Route exact path={"/terms"}>
            <TermsPage />
          </Route>
          <Route exact path={"/privacy"}>
            <PrivacyPage />
          </Route>
          <ProtectedRoute
            isAuthorized={isAuthorized}
            exact
            path={"/database/spheralizer"}
          >
            <SpheralizerPage />
          </ProtectedRoute>
          <ProtectedRoute
            isAuthorized={isAuthorized}
            exact
            path={[
              "/",
              "/dashboard",
              "/dashboard/person/:slug",
              "/dashboard/trigger/:id",
              "/database",
              "/database/duplicates",
              "/database/person/:slug",
              "/database/duplicates/person/:slug",
              "/database/shared/:token",

              "/flow-sphere",
              "/flow-sphere/:friendly_id",
              "/flow-sphere/:friendly_id/person/:slug",

              "/stage-sphere",
              "/stage-sphere/:friendly_id",
              "/stage-sphere/:friendly_id/person/:slug",

              "/uncategorized",
              "/uncategorized/person/:slug",
              "/account/settings",
              "/account/settings/person/:slug",
              "/account/success_payment",
              "/account/free_account",

              "/partner",

              "/admin",
              "/admin/person/:slug",

              "/account/settings/csv/:id",
            ]}
          >
            <MainLayout>
              <Switch>
                <ProtectedRoute isAuthorized={isAuthorized} exact path={"/"}>
                  {store.getState().UserState.access_token && <Dashboard />}
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/dashboard"}
                >
                  <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/dashboard/person/:slug"}
                >
                  {/*update the link in mailer*/}
                  <Dashboard show_person={true} />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/dashboard/trigger/:id"}
                >
                  <Dashboard approve_trigger={true} />
                </ProtectedRoute>
                <ProtectedRoute isAuthorized={isAuthorized} exact path={"/database"}>
                  <DatabasePage />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/database/person/:slug"}
                >
                  {/*update the link in mailer*/}
                  <DatabasePage show_person={true} />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={[
                    "/database/duplicates",
                    "/database/duplicates/person/:slug",
                  ]}
                >
                  <DuplicatesAndEnrichmentsPage />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={[
                    "/flow-sphere",
                    "/flow-sphere/:friendly_id",
                    "/flow-sphere/:friendly_id/person/:slug",
                  ]}
                >
                  <FlowSphere />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={[
                    "/stage-sphere",
                    "/stage-sphere/:friendly_id",
                    "/stage-sphere/:friendly_id/person/:slug",
                  ]}
                >
                  <StageSphere />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/database/shared/:token"}
                >
                  <SpecificallySharedCollection />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/uncategorized"}
                >
                  <UncategorizedPage />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/uncategorized/person/:slug"}
                >
                  <UncategorizedPage />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/account/settings"}
                >
                  <AccountPage />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/account/settings/person/:slug"}
                >
                  <AccountPage showPerson={true} />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/account/success_payment"}
                >
                  <SuccessPaymentPage />
                </ProtectedRoute>
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/account/free_account"}
                >
                  <FreeAccountPage />
                </ProtectedRoute>
                {partner && (
                  <ProtectedRoute
                    isAuthorized={isAuthorized}
                    exact
                    path={"/partner"}
                  >
                    <PartnerPage />
                  </ProtectedRoute>
                )}
                {role && role === "admin" && (
                  <ProtectedRoute
                    isAuthorized={isAuthorized}
                    exact
                    path={["/admin", "/admin/person/:slug"]}
                  >
                    <AdminPage />
                  </ProtectedRoute>
                )}
                <ProtectedRoute
                  isAuthorized={isAuthorized}
                  exact
                  path={"/account/settings/csv/:id"}
                >
                  <ImportPage />
                </ProtectedRoute>
              </Switch>
            </MainLayout>
          </ProtectedRoute>
          <Route exact path={"/:public_username"}>
            <PublicView />
          </Route>
        </Switch>
      </QueryParamProvider>
    </div>
  )
}

export default App
