import React from "react"
import PropTypes from "prop-types"
import "./HeaderTitlesContactDrawer.styles.scss"
import { Form, Input, Tooltip } from "antd"
import Icon from "@ant-design/icons"
import { ReactComponent as UserIcon } from "../../../../Icons/UserIcon.svg"

const HeaderTitlesContactDrawer = ({ isEditing, formik, contact }) => {
  const teamMembers = () => {
    if (!contact.canonical_participants) {
      return null
    }
    let members = []
    Object.keys(contact.canonical_participants).length > 0 &&
      Object.keys(contact.canonical_participants).forEach((key) => {
        members = [...members, ...contact.canonical_participants[key]]
      })

    if (members.length === 0) {
      return null
    }

    members = members.map((m) => m.user_name)

    // remove duplicates
    members = [...new Set(members)]

    return (
      <Tooltip
        title={
          members.slice(0, 2).join(" and ") +
          " also have added " +
          (contact.first_name || contact.full_name) +
          " to them database"
        }
      >
        <div className="HeaderTitlesContactDrawer_TitleWrapper_Icon">
          <Icon component={UserIcon} />
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="HeaderTitlesContactDrawer">
      {isEditing ? (
        <>
          <div className="HeaderTitlesContactDrawer_FormItems">
            {[
              { label: "First Name", key: "first_name" },
              {
                label: "Last Name",
                key: "last_name",
              },
              { label: "One Line Bio", key: "one_liner" },
            ].map((field) => (
              <Form.Item
                key={field.key}
                className={`FormItem--${field.key}`}
                validateStatus={
                  formik.touched[field.key] && formik.errors[field.key]
                    ? "error"
                    : "success"
                }
                help={formik.touched[field.key] && formik.errors[field.key]}
              >
                <Input
                  placeholder={field.label}
                  key={field.key}
                  name={field.key}
                  value={formik.values[field.key]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            ))}
          </div>
        </>
      ) : (
        <div className="HeaderTitlesContactDrawer_TitleWrapper">
          <div>
            <h2>{contact.full_name}</h2>
            {contact.one_liner && <p>{contact.one_liner.replace(" at ", " @ ")}</p>}
          </div>
          {teamMembers()}
        </div>
      )}
    </div>
  )
}

HeaderTitlesContactDrawer.propTypes = {
  isEditing: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  contact: PropTypes.object,
}

HeaderTitlesContactDrawer.defaultProps = {
  isEditing: false,
}

export default HeaderTitlesContactDrawer
