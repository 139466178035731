import React, { useEffect, useState } from "react"
import { Select } from "antd"

const WaitStep = ({ step, onUpdate }) => {
  const [interval, setInterval] = useState("days")
  const [number, setNumber] = useState(1)

  useEffect(() => {
    if (step?.configuration) {
      setNumber(step.configuration.number)
      setInterval(step.configuration.interval)
    }
  }, [step])

  useEffect(() => {
    onUpdate({ number, interval })
    // eslint-disable-next-line
  }, [number, interval])
  const onChangeNumber = (val) => {
    setNumber(val)
  }

  const onChangeInterval = (val) => {
    setInterval(val)
  }

  const intervals = [
    {
      value: "hours",
      label: "Hours",
    },
    {
      value: "days",
      label: "Days",
    },

    {
      value: "weeks",
      label: "Weeks",
    },
  ]

  return (
    <div className={"FlowSphere_StepContent"}>
      <span>Wait</span>
      <Select size={"large"} value={number} onChange={(v) => onChangeNumber(v)}>
        {Array.from({ length: 24 }, (_, i) => i + 1).map((num, idx) => {
          return (
            <Select.Option key={idx} value={num}>
              {num}
            </Select.Option>
          )
        })}
      </Select>
      <Select size={"large"} value={interval} onChange={(v) => onChangeInterval(v)}>
        {intervals.map(({ label, value }, idx) => {
          return (
            <Select.Option key={idx} value={value}>
              {label}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export default WaitStep
