import React from "react"
import moment from "moment"
import "./WelcomeBar.styles.scss"

const WelcomeBar = () => {
  let messages = require("./welcome_bar_messages.json")
  let today = moment()
  let i = today.dayOfYear() % messages.length

  let message = messages[i]

  return (
    <div className="WelcomeBar">
      <span>{message.content}</span>
    </div>
  )
}

export default WelcomeBar
