import {
  ADD_INTRODUCTION,
  ADD_INTRODUCTION_FAIL,
  ADD_INTRODUCTION_SUCCESS,
  APPROVE_INTRODUCTION,
  APPROVE_INTRODUCTION_FAIL,
  APPROVE_INTRODUCTION_SUCCESS,
  CLEAR_REDUX_INTRODUCTIONS_STATE,
  DELETE_INTRODUCTION,
  DELETE_INTRODUCTION_FAIL,
  DELETE_INTRODUCTION_SUCCESS,
  FETCH_INTRODUCTIONS,
  FETCH_INTRODUCTIONS_FAIL,
  FETCH_INTRODUCTIONS_SUCCESS,
  SET_INITIAL_REDUX_INTRODUCTIONS_STATE,
} from "./Introductions.types"

export const fetchIntroductions = (category, page) => {
  return {
    type: FETCH_INTRODUCTIONS,
    category: category,
    page: page,
  }
}

export const fetchIntroductionsSuccess = (
  awaiting,
  awaiting_total_entries,
  approved,
  approved_total_entries
) => {
  return {
    type: FETCH_INTRODUCTIONS_SUCCESS,
    awaiting: awaiting,
    awaiting_total_entries: awaiting_total_entries,
    approved: approved,
    approved_total_entries: approved_total_entries,
  }
}

export const fetchIntroductionsFail = (error) => {
  return {
    type: FETCH_INTRODUCTIONS_FAIL,
    error: error,
  }
}

export const addIntroduction = (introduction) => {
  return {
    type: ADD_INTRODUCTION,
    introduction: introduction,
  }
}

export const addIntroductionSuccess = (introduction) => {
  return {
    type: ADD_INTRODUCTION_SUCCESS,
    introduction: introduction,
  }
}

export const addIntroductionFail = (error) => {
  return {
    type: ADD_INTRODUCTION_FAIL,
    error: error,
  }
}

export const approveIntroduction = (id, participant_id, config = null) => {
  return {
    type: APPROVE_INTRODUCTION,
    id: id,
    participant_id: participant_id,
    config: config,
  }
}

export const approveIntroductionSuccess = (introduction) => {
  return {
    type: APPROVE_INTRODUCTION_SUCCESS,
    introduction: introduction,
  }
}

export const approveIntroductionFail = (error) => {
  return {
    type: APPROVE_INTRODUCTION_FAIL,
    error: error,
  }
}

export const deleteIntroduction = (id) => {
  return {
    type: DELETE_INTRODUCTION,
    id: id,
  }
}

export const deleteIntroductionSuccess = (introduction) => {
  return {
    type: DELETE_INTRODUCTION_SUCCESS,
    introduction: introduction,
  }
}

export const deleteIntroductionFail = (error) => {
  return {
    type: DELETE_INTRODUCTION_FAIL,
    error: error,
  }
}

export const clearReduxIntroductionsState = () => {
  return {
    type: CLEAR_REDUX_INTRODUCTIONS_STATE,
  }
}

export const setInitialReduxIntroductionsState = () => {
  return {
    type: SET_INITIAL_REDUX_INTRODUCTIONS_STATE,
  }
}
