import React from "react"

const PhoneCall2Icon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8828 1.92364C12.2812 0.470512 10.6953 -0.302926 9.17969 0.111137L2.30469 1.98614C0.945312 2.36114 0 3.59551 0 5.00176C0 24.3299 15.6719 40.0018 35 40.0018C36.4062 40.0018 37.6406 39.0564 38.0156 37.6971L39.8906 30.8221C40.3047 29.3064 39.5312 27.7205 38.0781 27.1189L30.5781 23.9939C29.3047 23.4627 27.8281 23.8299 26.9609 24.9002L23.8047 28.7518C18.3047 26.1502 13.8516 21.6971 11.25 16.1971L15.1016 13.0486C16.1719 12.1736 16.5391 10.7049 16.0078 9.43145L12.8828 1.93145V1.92364Z"
      fill="currentColor"
    />
  </svg>
)

export default PhoneCall2Icon
