import React, { useEffect, useState } from "react"
import Table from "antd/lib/table"
import TweenOneGroup from "rc-tween-one/es/TweenOneGroup"
import "antd/dist/antd.css"
import { useDispatch, useSelector } from "react-redux"
import {
  addToSpheres,
  archiveParticipants,
  fetchParticipants,
} from "../../../redux/Participants/Participants.actions"
import { Checkbox, Dropdown, Empty, Popover, Radio, Spin, Tooltip } from "antd"
import SpheresSelector from "../../Database/SpheresSelector/SpheresSelector.component"
import MdClose from "react-ionicons/lib/MdClose"
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params"
import "./ParticipantsTable.styles.scss"
import {
  deselectParticipant,
  deselectParticipants,
  selectParticipant,
  selectParticipants,
} from "../../../redux/App/App.actions"
import Icon from "@ant-design/icons"
import ArrowUpIcon from "../../Icons/ArrowUp.icons"
import ArrowDownIcon from "../../Icons/ArrowDown.icons"
import CustomPagination from "../../Database/CustomPagination/CustomPagination.component"
import MessageCell from "./MessageCell.component"
import SlidersHorizontalIcon from "../../Icons/SlidersHorizontal.icons"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const TableContext = React.createContext({ enter: null, leave: null })

const animTag = ($props) => {
  return (
    <TableContext.Consumer key="1">
      {({ enter, leave }) => {
        const children = React.Children.toArray($props.children)

        return (
          <TweenOneGroup
            component={React.Fragment}
            enter={enter}
            leave={leave}
            appear={false}
            exclusive
            key="twnnone"
          >
            {children.map((item) => (
              <tbody key={item.key} {...$props}>
                {item}
              </tbody>
            ))}
          </TweenOneGroup>
        )
      }}
    </TableContext.Consumer>
  )
}

const ParticipantsTable = ({
  uncategorized,
  setParticipantsTableIsEmpty,
  perPage = 15,
}) => {
  const [isPageTween, setPageTween] = useState(false)
  const [page, setPage] = useQueryParam("participants_page", NumberParam)
  const [sortField, setSortField] = useQueryParam("participants_by", StringParam)
  const [sortOrder, setSortOrder] = useQueryParam("participants_order", StringParam)
  const [searchValue] = useQueryParam("q", StringParam)
  const [filters, setFilters] = useQueryParam("participants_filters", ArrayParam)
  const participants = useSelector((state) => state.ParticipantsState.participants)
  const participantsTotalEntries = useSelector(
    (state) => state.ParticipantsState.total_entries
  )
  const user = useSelector((state) => state.UserState)
  const fetching = useSelector((state) => state.ParticipantsState.fetching)
  const fetching_reserve = useSelector(
    (state) => state.ParticipantsState.fetching_reserve
  )
  const [tableData, setTableData] = useState(participants)
  const [uncategorizedFilters, setUncategorizedFilters] = useState(["threads"])
  const selectedParticipantsIds = useSelector(
    (state) => state.AppState.selected_participants_ids
  )
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const generateFullName = (first_name, last_name) => {
    if (first_name && last_name) {
      return first_name + " " + last_name
    } else if (first_name) {
      return first_name
    } else if (last_name) {
      return last_name
    } else {
      return ""
    }
  }

  const onEnd = () => {
    setPageTween(false)
  }
  const dispatch = useDispatch()

  const rmStyle = (e) => {
    const { targets } = e
    // callback in render before
    setTimeout(() => {
      targets.style = ""
    })
  }

  const onDelete = (id, e) => {
    e && e.preventDefault()
    const newData = tableData.filter((item) => item.id !== id)
    setTableData(newData)
    if (setParticipantsTableIsEmpty) {
      setParticipantsTableIsEmpty(newData?.length === 0)
    }
    setPageTween(false)
    dispatch(archiveParticipants([id], page))
    if (tableData.length === perPage + 1) {
      dispatch(
        fetchParticipants(page, perPage, filters, searchValue, sortField, sortOrder)
      )
    }
  }

  const onAddToSphere = (existing, created, id) => {
    const newData = tableData.filter((item) => item.id !== id)
    setTableData(newData)
    if (setParticipantsTableIsEmpty) {
      setParticipantsTableIsEmpty(newData?.length === 0)
    }
    setPageTween(false)
    dispatch(addToSpheres(id, existing, created))
    if (tableData.length === perPage + 1) {
      dispatch(
        fetchParticipants(page, perPage, filters, searchValue, sortField, sortOrder)
      )
    }
  }

  const renderSorterIcon = (sortColumns, columnKey) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === columnKey)

    if (sortedColumn?.order === "ascend") {
      return (
        <Icon className="ParticipantsTable_SorterIcon" component={ArrowUpIcon} />
      )
    } else if (sortedColumn?.order === "descend") {
      return (
        <Icon className="ParticipantsTable_SorterIcon" component={ArrowDownIcon} />
      )
    } else {
      return null
    }
  }

  const options = [
    {
      label: "Emails",
      value: "threads",
    },
    {
      label: "Imported Contacts",
      value: "contacts",
    },
    {
      label: "Phone Book",
      value: "phone book",
    },
    {
      label: "LinkedIn",
      value: "linkedin",
    },
    {
      label: "Unknown",
      value: "unknown",
    },
  ]

  const updateUncategorizedFilters = (newFilter) => {
    const tempFilters = filters ? [...filters] : []

    if (uncategorizedFilters.includes(newFilter)) {
      tempFilters.splice(tempFilters.indexOf(`source: ${newFilter}`), 1)
      setUncategorizedFilters(uncategorizedFilters.filter((f) => f !== newFilter))
    } else {
      setUncategorizedFilters([...uncategorizedFilters, newFilter])
      tempFilters.push(`source: ${newFilter}`)
    }

    setFilters(tempFilters)
  }

  const filterMenu = (
    <div className="UncategorizedFilter" onMouseLeave={() => setDropdownOpen(false)}>
      <div className="UncategorizedFilter_Checkboxes">
        {options.map((option) => (
          <Checkbox
            key={option.value}
            checked={uncategorizedFilters.includes(option.value)}
            onChange={() => updateUncategorizedFilters(option.value)}
            className="UncategorizedFilter_Checkboxes_Item"
          >
            {option.label}
          </Checkbox>
        ))}
      </div>
    </div>
  )

  const columns = [
    {
      title: ({ sortColumns }) => {
        return (
          <div>
            Name
            {renderSorterIcon(sortColumns, "full_name")}
          </div>
        )
      },
      className: "name",
      key: "full_name",
      width: "25%",
      sorter: true,
      render: (record) =>
        record.first_name || record.last_name ? (
          <div className="ParticipantsTable_Name">
            <span className="ParticipantsTable_Name_FullName">
              {record.is_phone_number ? (
                <a href={`sms:${record.email}`}>
                  {record.full_name || record.email}
                </a>
              ) : (
                generateFullName(record.first_name, record.last_name)
              )}
            </span>
            <span className="ParticipantsTable_Name_Email">{record.email}</span>
          </div>
        ) : (
          <div className="ParticipantsTable_Name">
            <span className="ParticipantsTable_Name_FullName">{record.email}</span>
          </div>
        ),
    },
    {
      title: "Message",
      className: "message",
      key: "message",
      width: "45%",
      render: (record) =>
        record.body || record.subject ? (
          <MessageCell
            participantId={record.id}
            details={record}
            time_zone={user.time_zone}
          />
        ) : (
          <Empty
            description={"Message has no subject or body."}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 30 }}
          />
        ),
    },
    {
      title: (
        <div className="ActionsColumnTitle">
          Actions
          <Tooltip
            overlayClassName="ActionsColumnTitle_FilterButton_Tooltip"
            placement="topRight"
            title="Click here to filter uncategorized contacts."
          >
            <Dropdown
              overlay={filterMenu}
              placement="bottomRight"
              trigger={["click"]}
              visible={dropdownOpen}
            >
              <button
                className="ActionsColumnTitle_FilterButton"
                onClick={() => setDropdownOpen(true)}
              >
                <Icon component={SlidersHorizontalIcon} />
              </button>
            </Dropdown>
          </Tooltip>
        </div>
      ),
      className: "spheres",
      width: "280px",
      key: "action",
      render: (record) => (
        <div className="ParticipantsTable_ActionsColumn">
          <SpheresSelector
            collapsible
            clearAfterSubmit
            alreadySelected={[]}
            drawerVisible={true}
            handleSubmit={(existingSpheres, newSpheres) => {
              const existing = existingSpheres.filter((el) => el.id !== null)
              const created = newSpheres.filter((el) => el.title !== null)
              if (existing.length || created.length) {
                onAddToSphere(existing, created, record.id)
              } else {
                onDelete(record.id, null)
              }
            }}
          />
          <div className="ParticipantsTable_ActionsColumn_Separator">|</div>
          {record.status === "active" ? (
            <Popover
              title={
                <div className="DismissPopover_Title">
                  <span>What does Dismiss do?</span>
                </div>
              }
              overlayClassName="DismissPopover"
              content={
                <div className="DismissPopover_Content">
                  <span>
                    We&apos;ll hide this unknown person from your list. Don&apos;t
                    worry, their information doesn&apos;t go away - if you want to
                    add this person to your database, just create a contact with
                    their email address and we&apos;ll bring this back.
                  </span>
                </div>
              }
            >
              <div
                className="ParticipantsTable_ArchvieButton"
                onClick={(e) => onDelete(record.id, e)}
              >
                <MdClose fontSize="19px" color="#A1AAC8" />
                <span>Archive</span>
              </div>
            </Popover>
          ) : (
            <span className="ParticipantsTable_ArchiveText">Archived.</span>
          )}
        </div>
      ),
    },
  ]

  const enterAnim = [
    {
      display: "none",
      opacity: 0,
      x: 30,
      duration: 0,
    },
    {
      display: "table-row-group",
      opacity: 0,
      height: 0,
      delay: 250,
      x: 30,
      duration: 0,
      onComplete: onEnd,
    },
    {
      opacity: 1,
      x: 0,
      duration: 250,
      ease: "easeOutQuad",
      onComplete: rmStyle,
    },
  ]
  const pageEnterAnim = [
    {
      opacity: 0,
      duration: 0,
    },
    {
      height: 0,
      duration: 150,
      type: "from",
      delay: 150,
      ease: "easeOutQuad",
      onComplete: onEnd,
    },
    {
      opacity: 1,
      duration: 150,
      ease: "easeOutQuad",
      onComplete: rmStyle,
    },
  ]
  const leaveAnim = [{ duration: 250, opacity: 0 }]
  const pageLeaveAnim = [
    { duration: 250, opacity: 0 },
    { height: 0, duration: 200, ease: "easeOutQuad" },
  ]

  useEffect(() => {
    setUncategorizedFilters(["threads"])
    setFilters(["source: threads"])
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSortField("last_sent")
    setSortOrder("desc")
    setPage(1)
    // eslint-disable-next-line
  }, [uncategorized])

  useEffect(() => {
    setTableData(participants)
    if (setParticipantsTableIsEmpty) {
      setParticipantsTableIsEmpty(participants?.length === 0)
    }
    // eslint-disable-next-line
  }, [participants])

  useEffect(() => {
    setPage(1)
    dispatch(
      fetchParticipants(1, perPage, filters, searchValue, sortField, sortOrder)
    )
    // eslint-disable-next-line
  }, [searchValue, filters])

  const handleTableChange = (pagination, tableFilters, sorter) => {
    let order = sortOrder
    let field = sorter?.column?.key || "last_sent"

    if (sorter?.order) {
      order = sorter.order === "ascend" ? "asc" : "desc"
      setSortOrder(order)
    }

    if (Number.isInteger(pagination)) {
      setPage(pagination)
    }

    setSortField(sorter?.column?.key || "last_sent")

    dispatch(
      fetchParticipants(
        Number.isInteger(pagination) ? pagination : 1,
        perPage,
        filters,
        searchValue,
        field,
        order
      )
    )
  }
  return fetching && tableData?.length === 0 ? (
    <div className="ParticipantsTable_LoadingBox">
      <RelatableLoader />
    </div>
  ) : (
    <>
      <h4 className="ParticipantsTableHeader">
        You've also been talking to these people. Add any of them to a sphere or two
        to stay in touch.
      </h4>
      <div className="ParticipantsTableWrapper">
        <TableContext.Provider
          value={{
            enter: !isPageTween ? enterAnim : pageEnterAnim,
            leave: !isPageTween ? leaveAnim : pageLeaveAnim,
          }}
        >
          <Table
            rowKey="id"
            tableLayout="fixed"
            className="ParticipantsTable"
            loading={{
              spinning: fetching || (fetching_reserve && tableData.length < 15),
              indicator: (
                <RelatableLoader
                  quote={true}
                  loading={fetching || (fetching_reserve && tableData.length < 15)}
                />
              ),
            }}
            columns={columns}
            dataSource={tableData?.slice(0, perPage).map((p) => ({
              ...p,
              expandable: p.body?.length > 70,
              expanded: false,
            }))}
            components={{ body: { wrapper: animTag } }}
            onChange={handleTableChange}
            scroll={{
              x: true,
            }}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                if (record.expandable) {
                  const newTableData = tableData.map((p) => ({
                    ...p,
                    expanded: p.id === record.id ? !p.expanded : p.expanded,
                  }))
                  setTableData(newTableData)
                }
              },
            })}
            rowSelection={{
              selectedRowKeys: selectedParticipantsIds,
              onSelect: (record, selected) => {
                if (selected) {
                  dispatch(selectParticipant(record.id))
                } else {
                  dispatch(deselectParticipant(record.id))
                }
              },
              onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected) {
                  dispatch(
                    selectParticipants(
                      selectedRows.filter((c) => c !== undefined).map((c) => c.id)
                    )
                  )
                } else {
                  dispatch(deselectParticipants())
                }
              },
            }}
            rowClassName={(record) =>
              `ParticipantsTable_Row ${record.status !== "active" && "archived"}`
            }
          />
        </TableContext.Provider>
        <CustomPagination
          total={participantsTotalEntries > 10000 ? 10000 : participantsTotalEntries}
          currentPage={page}
          onPageChange={(newPage) => {
            handleTableChange(newPage)
          }}
          perPage={perPage}
        />
      </div>
    </>
  )
}

export default ParticipantsTable
