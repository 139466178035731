import React, { useEffect, useState } from "react"
import "./TrialExtensionsModal.styles.scss"
import { Modal, Spin } from "antd"
import PropTypes from "prop-types"
import PadlockAndKey from "../../../images/PadlockAndKey.svg"
import CurvedBackground from "../../../images/CurvedBackground.svg"
import axios from "axios"
import { createStripeSession } from "../../../redux/Accounts/Accounts.actions"
import { useDispatch } from "react-redux"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const TrialExtensionsModal = ({ visible, onClose, trial_days_remaining }) => {
  const dispatch = useDispatch()
  const handleCreateStripeSession = () => {
    dispatch(createStripeSession())
  }

  const getTrialInfo = async (callback) => {
    const { data } = await axios.request({
      url: "/v1/users/trial_extensions",
      method: "GET",
    })
    callback(data)
  }

  const [loaded, setLoaded] = useState(false)

  const availableTrialExtensions = [
    {
      days: 1,
      title: "Connect your e-mail account",
      category: "email_connected",
      completed_at: null,
    },
    {
      days: 1,
      title: "Add 10 people to spheres",
      category: "people_added",
      completed_at: null,
    },
    {
      days: 3,
      title: "Send your first split message",
      category: "message_sent",
      completed_at: null,
    },
    {
      days: 4,
      title:
        "Make a public post about how you’re loving Relatable so far, and send us the link to the post",
      category: "post_published",
      completed_at: null,
    },
    {
      days: 5,
      category: "card_added",
      completed_at: null,
    },

    {
      days: 4,
      title: "Explain the ending of LOST or Sopranos to us",
      category: "ending_explained",
      completed_at: null,
    },
    {
      days: 30,
      title:
        "Write a country music song about Relatable, get it trending on the Billboard 100",
      category: "song_written",
      completed_at: null,
    },
  ]

  const renderDays = (amount) => {
    return `${amount} ${amount > 1 ? "days" : "day"}`
  }

  const [trialExtensions, setTrialExtensions] = useState(availableTrialExtensions)

  useEffect(() => {
    getTrialInfo((extensions) => {
      if (extensions.length) {
        const newExtensions = trialExtensions.map((e) => ({
          ...e,
          completed_at:
            extensions.find((te) => e.category === te.category)?.completed_at ||
            null,
        }))
        setTrialExtensions(newExtensions)
      }
      setLoaded(true)
    })
    // eslint-disable-next-line
  }, [])
  return (
    <Modal
      visible={visible}
      width={"800px"}
      onCancel={onClose}
      className="TrialExtensions"
      footer={null}
    >
      <div className="TrialExtensions_Content">
        <div className="TrialExtensions_Header">
          <img src={PadlockAndKey} alt="Padlock and key" />

          <div className={"TrialExtensions_Title"}>
            We'd love to <span>help</span> you unlock the amazing potential of your
            relationships!
          </div>
          <div className={"TrialExtensions_TrialDaysLeft"}>
            {trial_days_remaining > 0 ? (
              <div>
                You have <span> {renderDays(trial_days_remaining)}</span> left in
                your trial
              </div>
            ) : (
              <div>Your trial ended {renderDays(trial_days_remaining * -1)} ago</div>
            )}
          </div>
          <div className={"TrialExtensions_Title"}>
            Click{" "}
            <button
              className="TrialExtensions_CreateSessionButton"
              onClick={handleCreateStripeSession}
            >
              here
            </button>{" "}
            to upgrade
          </div>
        </div>
        <img
          className={"TrialExtensions_CurvedBackground"}
          src={CurvedBackground}
          alt="Background waves"
        />
        <div className="TrialExtensions_Body">
          <div className={"TrialExtensions_Text TrialExtensions_Bold"}>
            Want more time?
          </div>
          <RelatableLoader loading={!loaded}>
            <div className={"TrialExtensions_Extensions"}>
              {trialExtensions.map((t) => {
                return (
                  <div className={"TrialExtensions_ExtensionBlock"}>
                    <div
                      className={`TrialExtensions_Days ${
                        t.completed_at ? "TrialExtensions_Completed" : ""
                      }`}
                    >
                      +{renderDays(t.days)}
                    </div>
                    <div className={"TrialExtensions_Text"}>
                      {t.category === "card_added" ? (
                        <span>
                          Add your credit card{" "}
                          <button
                            className="TrialExtensions_CreateSessionButton"
                            onClick={handleCreateStripeSession}
                          >
                            here
                          </button>
                        </span>
                      ) : (
                        t.title
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </RelatableLoader>
        </div>
      </div>
    </Modal>
  )
}

TrialExtensionsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

TrialExtensionsModal.defaultProps = {}

export default TrialExtensionsModal
