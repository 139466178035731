import React, { useEffect, useState } from "react"
import "./SearchPeople.styles.scss"
import * as _ from "lodash"
import { useHistory } from "react-router-dom"
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params"

const SearchPeople = () => {
  const [searchInput, setSearchInput] = useState("")
  const [query, setQuery] = useQueryParams({
    res: StringParam,
    q: StringParam,
    people_page: NumberParam,
    people_by: StringParam,
    people_order: StringParam,
    participants_page: NumberParam,
    participants_by: StringParam,
    participants_order: StringParam,
    participants_filters: ArrayParam,
  })

  const history = useHistory()

  const searchPeopleAndParticipants = (q) => {
    if (history.location.pathname !== "/database") {
      history.push("/database")
    }

    const new_filters =
      query.participants_filters?.filter((filter) => {
        return !filter.includes("source")
      }) || []

    setQuery({
      res: "people",
      q: q,
      people_page: 1,
      people_by: "last_sent",
      people_order: "desc",
      participants_page: 1,
      participants_by: "last_sent",
      participants_order: "desc",
      participants_filters: new_filters,
    })
  }

  const [debouncedSearch] = useState(() =>
    _.debounce(searchPeopleAndParticipants, 500)
  )

  useEffect(() => {
    if (!!query.q) {
      setSearchInput(query.q)
    }
  }, [query.q])

  return (
    <div className="SearchPeople">
      <input
        value={searchInput}
        placeholder="Start typing to find a relationship..."
        onChange={(e) => {
          setSearchInput(e.target.value)
          if (e.target.value.length > 2 || e.target.value === "") {
            debouncedSearch(e.target.value)
          }
        }}
      />
    </div>
  )
}

export default SearchPeople
