import React from "react"
import "./DashboardForm.style.scss"

const DashboardFormTab = ({
  title,
  activeTab,
  setActiveTab,
  setCollapsed,
  children,
}) => {
  const collapseDashboardForm = () => {
    if (activeTab === title) {
      setCollapsed(true)
      setTimeout(() => setActiveTab(null), 500)
    }
  }
  return (
    <div
      className={
        title === "grow_relationships"
          ? "DashboardForm_TabTitle DashboardForm_TabTitle--growRelationships"
          : "DashboardForm_TabTitle"
      }
      onClick={collapseDashboardForm}
    >
      {children}
    </div>
  )
}

export default DashboardFormTab
