import React, { useState } from "react"
import { Checkbox } from "antd"
import { FlagOutlined, SettingOutlined } from "@ant-design/icons/lib"
import PropTypes from "prop-types"
import "./ListWithCheckbos.styles.scss"
import TriggerCreator from "../../../RuleCreator/RuleCreator.component"

const ListWithCheckbox = ({
  onApplyFilter,
  filters,
  options,
  popular_options,
  title,
  reverse_checked,
}) => {
  const [settingTrigger, setSettingTrigger] = useState(false)
  const [currentSphere, setCurrentSphere] = useState(null)
  const [showSettingsIcon, setShowSettingsIcon] = useState(null)

  const handleChangeShowSettingsIcon = (id) => {
    if (!settingTrigger) {
      setShowSettingsIcon(id)
    }
  }

  return (
    <div
      className={`ListWithCheckbox ${
        options && options[0] && "level" in options[0]
          ? `ListWithCheckbox--noScrolling`
          : null
      }`}
    >
      {["topics.id", "companies.id", "location.id"].includes(title) && (
        <div className="ListWithCheckbox_Item">
          <Checkbox
            checked={filters.find(
              (f) => f.field === title && f.values.includes("null")
            )}
            onChange={(e) =>
              onApplyFilter(e.target.checked, {
                field: title,
                value: "null",
              })
            }
          >
            None
          </Checkbox>
        </div>
      )}
      {(popular_options || options).map((option, index) => (
        <div
          onMouseEnter={() => handleChangeShowSettingsIcon(option.id)}
          onMouseLeave={() => handleChangeShowSettingsIcon(null)}
          key={index}
          className={`ListWithCheckbox_Item ${
            "level" in option && `ListWithCheckbox_Item--${option?.level}`
          }`}
        >
          <Checkbox
            checked={
              reverse_checked
                ? !filters.find(
                    (f) =>
                      f.field === (title || option.field) &&
                      f.values.includes(option.id.toString())
                  )
                : filters.find(
                    (f) =>
                      f.field === (title || option.field) &&
                      f.values.includes(option.id.toString())
                  )
            }
            onChange={(e) =>
              onApplyFilter(reverse_checked ? !e.target.checked : e.target.checked, {
                field: title || option.field,
                value: option.id.toString(),
              })
            }
          >
            {option.title}
          </Checkbox>
          {title === "spheres.id" && (
            <>
              {showSettingsIcon === option.id ? (
                <TriggerCreator
                  id={option.id}
                  opened={settingTrigger && currentSphere === option.id}
                  handleClose={() => {
                    setSettingTrigger(false)
                    setShowSettingsIcon(null)
                    setCurrentSphere(null)
                  }}
                  onDelete={() => {
                    setSettingTrigger(false)
                    setShowSettingsIcon(null)
                    setCurrentSphere(null)
                  }}
                >
                  <SettingOutlined
                    onClick={() => {
                      setCurrentSphere(option.id)
                      setSettingTrigger(true)
                    }}
                    className="ListWithCheckbox_Item_SettingsIcon"
                  />
                </TriggerCreator>
              ) : (
                option.rules.filter((rule) => rule.enabled).length > 0 && (
                  <FlagOutlined className="ListWithCheckbox_Item_FlagIcon" />
                )
              )}
            </>
          )}
        </div>
      ))}
    </div>
  )
}

ListWithCheckbox.propTypes = {
  onApplyFilter: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  options: PropTypes.array,
  popular_options: PropTypes.array,
  title: PropTypes.oneOf([
    "spheres.id",
    "topics.id",
    "companies.id",
    "location.id",
    "importance",
    "strength",
  ]),
  reverse_checked: PropTypes.bool,
}

export default ListWithCheckbox
