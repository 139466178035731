import { Button, Table } from "antd"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { fetchChallenges } from "../../../redux/Admin/Admin.actions"
import moment from "moment"
import "./ChallengesTable.styles.scss"
import { generateGoalName } from "../../../layout/MainLayout/RightPanel/ActivitiesTab/helpers"
import ChallengeDetailsModal from "../ChallengeDetailsModal/ChallengeDetailsModal.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const ChallengesTable = () => {
  const [challengeDetailModalVisible, setChallengeDetailModalVisible] =
    useState(false)
  const [selectedChallenge, setSelectedChallenge] = useState(null)

  const challenges = useSelector((state) => state.AdminState.challenges)
  const challenges_loading = useSelector(
    (state) => state.AdminState.challenges_loading
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchChallenges())
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      title: "Name",
      className: "name hoverable",
      width: "13%",
      key: "name",
      ellipsis: true,
      render: (record) => <span className="ChallengesTable_Row">{record.name}</span>,
    },
    {
      title: "One liner",
      className: "name hoverable",
      key: "one_liner",
      width: "10%",
      ellipsis: true,
      render: (record) => (
        <span className="ChallengesTable_Row">{record.one_liner}</span>
      ),
    },
    {
      title: "Description",
      className: "name hoverable",
      key: "description",
      ellipsis: true,
      render: (record) => (
        <span className="ChallengesTable_Row">{record.description}</span>
      ),
    },
    {
      title: "Start Date",
      className: "name hoverable",
      key: "start_date",
      width: "14%",
      render: (record) => (
        <span className="ChallengesTable_Row">
          {moment(record.start_date).format("MMM Do, YYYY")}
        </span>
      ),
    },
    {
      title: "End Date",
      className: "name hoverable",
      width: "14%",
      key: "end_date",
      render: (record) => (
        <span className="ChallengesTable_Row">
          {moment(record.end_date).format("MMM Do, YYYY")}
        </span>
      ),
    },
    {
      title: "Status",
      className: "name hoverable",
      width: "8%",
      key: "status",
      render: (record) => (
        <span className="ChallengesTable_Row">{record.status}</span>
      ),
    },
    {
      title: "Goals",
      className: "name hoverable",
      width: "18%",
      key: "goals",
      render: (record) => (
        <div>
          {record.goals.map((goal, i) => (
            <div key={i}>
              <span className="ChallengesTable_Row">
                {generateGoalName(goal.type, goal.number_of_todo)}
              </span>
              <br />
            </div>
          ))}
        </div>
      ),
    },
    {
      className: "name hoverable",
      key: "actions",
      width: "8%",
      render: (record) => (
        <Button
          onClick={() => {
            setChallengeDetailModalVisible(true)
            setSelectedChallenge(record)
          }}
        >
          Edit
        </Button>
      ),
    },
  ]

  return (
    <div className="ChallengeTable">
      {challengeDetailModalVisible && selectedChallenge && (
        <ChallengeDetailsModal
          challenge={selectedChallenge}
          visible={challengeDetailModalVisible}
          onCancel={() => {
            setChallengeDetailModalVisible(false)
            setSelectedChallenge(null)
          }}
        />
      )}
      <Button
        className="ChallengeTable_NewChallengeButton"
        type="primary"
        onClick={() => {
          setChallengeDetailModalVisible(true)
          setSelectedChallenge({})
        }}
      >
        New Challenge
      </Button>
      <Table
        rowKey="id"
        tableLayout="fixed"
        className="ChallengesTable"
        style={{ height: "auto", maxHeight: "80%" }}
        loading={{
          spinning: challenges_loading,
          indicator: <RelatableLoader quote={true} loading={challenges_loading} />,
        }}
        columns={columns}
        dataSource={challenges}
        pagination={false}
      />
    </div>
  )
}

export default ChallengesTable
