import { call, put, takeLatest } from "redux-saga/effects"
import {
  ADD_RULE,
  ADD_SHARED_COLLECTION,
  CREATE_INITIAL_SPHERES,
  CREATE_SPHERE,
  DELETE_COLLECTION_SHARING,
  DELETE_SPHERE,
  FETCH_COLLECTION,
  FETCH_COMPANIES,
  FETCH_CONNEXIONS,
  FETCH_EXPERTISES,
  FETCH_FLOW_SPHERE,
  FETCH_LOCATIONS,
  FETCH_POPULAR_COMPANIES,
  FETCH_POPULAR_EXPERTISES,
  FETCH_POPULAR_LOCATIONS,
  FETCH_POPULAR_SOURCES,
  FETCH_POPULAR_TOPICS,
  FETCH_SOURCES,
  FETCH_SPHERES,
  FETCH_STAGE_SPHERE,
  FETCH_TOPICS,
  MANAGE_GLOBAL_SHARING,
  MANAGE_PERSONAL_SHARING,
  REMOVE_SHARED_COLLECTION,
  RENAME_SPHERE,
  REORDER_SPHERES,
  SAVE_FLOW_SPHERE,
  SAVE_STAGE_SPHERE,
  UPDATE_RULE,
} from "./Collections.types"
import {
  addRuleFail,
  addRuleSuccess,
  addSharedCollectionFail,
  addSharedCollectionSuccess,
  createInitialSpheresFail,
  createInitialSpheresSuccess,
  createSphereFail,
  createSphereSuccess,
  deleteCollectionSharingFail,
  deleteCollectionSharingSuccess,
  deleteSphereFail,
  deleteSphereSuccess,
  fetchCollection as fetchCollectionAction,
  fetchCollectionFail,
  fetchCollectionSuccess,
  fetchCompaniesFail,
  fetchCompaniesSuccess,
  fetchConnexionsFail,
  fetchConnexionsSuccess,
  fetchExpertisesFail,
  fetchExpertisesSuccess,
  fetchFlowSphere,
  fetchFlowSphereFail,
  fetchFlowSphereSuccess,
  fetchLocationsFail,
  fetchLocationsSuccess,
  fetchPopularCompaniesSuccess,
  fetchPopularExpertisesSuccess,
  fetchPopularLocationsSuccess,
  fetchPopularSourcesSuccess,
  fetchPopularTopicsSuccess,
  fetchSourcesFail,
  fetchSourcesSuccess,
  fetchSpheres as fetchSpheresAction,
  fetchSpheresFail,
  fetchSpheresSuccess,
  fetchStageSphereFail,
  fetchStageSphereSuccess,
  fetchTopicsFail,
  fetchTopicsSuccess,
  manageGlobalSharingFail,
  manageGlobalSharingSuccess,
  managePersonalSharingFail,
  managePersonalSharingSuccess,
  removeSharedCollectionFail,
  removeSharedCollectionSuccess,
  renameSphereFail,
  renameSphereSuccess,
  reorderSpheresFail,
  reorderSpheresSuccess,
  saveFlowSphereFail,
  saveFlowSphereSuccess,
  saveStageSphereFail,
  saveStageSphereSuccess,
  updateRuleFail,
  updateRuleSuccess,
} from "./Collections.actions"
import axios from "axios"
import { fetchPeople } from "../People/People.actions"
import { setSphereModalVisible } from "../App/App.actions"
import history from "../../history"

export function* watchSpheresSaga() {
  yield takeLatest(FETCH_SPHERES, fetchSpheres)
  yield takeLatest(DELETE_SPHERE, deleteSphere)
  yield takeLatest(REORDER_SPHERES, reorderSpheres)
  yield takeLatest(RENAME_SPHERE, renameSphere)
  yield takeLatest(FETCH_TOPICS, fetchTopics)
  yield takeLatest(FETCH_EXPERTISES, fetchExpertises)
  yield takeLatest(FETCH_POPULAR_EXPERTISES, fetchPopularExpertises)

  yield takeLatest(FETCH_CONNEXIONS, fetchConnexions)

  yield takeLatest(FETCH_SOURCES, fetchSources)
  yield takeLatest(FETCH_POPULAR_SOURCES, fetchPopularSources)

  yield takeLatest(FETCH_POPULAR_TOPICS, fetchPopularTopics)
  yield takeLatest(FETCH_COMPANIES, fetchCompanies)
  yield takeLatest(FETCH_POPULAR_COMPANIES, fetchPopularCompanies)
  yield takeLatest(FETCH_LOCATIONS, fetchLocations)
  yield takeLatest(FETCH_POPULAR_LOCATIONS, fetchPopularLocations)
  yield takeLatest(ADD_RULE, addRule)
  yield takeLatest(UPDATE_RULE, updateRule)
  yield takeLatest(FETCH_COLLECTION, fetchCollection)
  yield takeLatest(MANAGE_PERSONAL_SHARING, managePersonalSharing)
  yield takeLatest(MANAGE_GLOBAL_SHARING, manageGlobalSharing)
  yield takeLatest(DELETE_COLLECTION_SHARING, deleteCollectionSharing)
  yield takeLatest(ADD_SHARED_COLLECTION, addSharedCollection)
  yield takeLatest(REMOVE_SHARED_COLLECTION, removeSharedCollection)

  yield takeLatest(CREATE_SPHERE, createSphere)
  yield takeLatest(SAVE_FLOW_SPHERE, saveFlowSphere)
  yield takeLatest(FETCH_FLOW_SPHERE, getFlowSphere)

  yield takeLatest(SAVE_STAGE_SPHERE, saveStageSphere)
  yield takeLatest(FETCH_STAGE_SPHERE, getStageSphere)
  yield takeLatest(CREATE_INITIAL_SPHERES, createInitialSpheres)
}

function* fetchSpheres() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/sphere",
      })
    )
    yield put(
      fetchSpheresSuccess(data.spheres, data.spheres_order, data.shared_spheres)
    )
  } catch (error) {
    yield put(fetchSpheresFail(error))
  }
}

function* deleteSphere(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/collections/sphere/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(deleteSphereSuccess(data.sphere))
    yield put(setSphereModalVisible(false, null))
  } catch (error) {
    yield put(deleteSphereFail(error))
  }
}

function* reorderSpheres(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/collections/reorder_spheres`,
        data: {
          reordered_spheres_ids: action.reordered_spheres_ids,
        },
        method: "PUT",
      })
    )
    yield put(reorderSpheresSuccess(data.spheres_order))
  } catch (error) {
    yield put(reorderSpheresFail(error))
  }
}

function* renameSphere(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/collections/rename_sphere/${action.id}`,
        data: {
          new_title: action.new_title,
        },
        method: "PUT",
      })
    )
    yield put(renameSphereSuccess())
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const page = urlParams.get("people_page") || null
    const query = urlParams.get("q") || null
    const filters = urlParams.get("people_filters") || null
    const sortField = urlParams.get("people_by") || "last_sent"
    const sortOrder = urlParams.get("people_order") || "desc"
    yield put(
      fetchPeople(parseInt(page), 20, query, [filters], sortField, sortOrder)
    )
    yield put(fetchFlowSphere(action.id))
    yield put(fetchSpheresAction())
  } catch (error) {
    yield put(renameSphereFail(error))
  }
}

function* fetchTopics() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/topics",
      })
    )
    yield put(fetchTopicsSuccess(data.data))
  } catch (error) {
    yield put(fetchTopicsFail(error))
  }
}

function* fetchPopularTopics(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/topics/popular",
        params: {
          status: action.status,
        },
      })
    )
    yield put(fetchPopularTopicsSuccess(data.data))
  } catch (error) {
    yield put(fetchTopicsFail(error))
  }
}

function* fetchExpertises() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/expertises",
      })
    )
    yield put(fetchExpertisesSuccess(data.data))
  } catch (error) {
    yield put(fetchExpertisesFail(error))
  }
}

function* fetchPopularExpertises(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/expertises/popular",
        params: {
          status: action.status,
        },
      })
    )
    yield put(fetchPopularExpertisesSuccess(data.data))
  } catch (error) {
    yield put(fetchExpertisesFail(error))
  }
}

function* fetchConnexions() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/connexions",
      })
    )
    yield put(fetchConnexionsSuccess(data.data))
  } catch (error) {
    yield put(fetchConnexionsFail(error))
  }
}

function* fetchSources() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/sources",
      })
    )
    yield put(fetchSourcesSuccess(data.data))
  } catch (error) {
    yield put(fetchSourcesFail(error))
  }
}

function* fetchPopularSources(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/sources/popular",
        params: {
          status: action.status,
        },
      })
    )
    yield put(fetchPopularSourcesSuccess(data.data))
  } catch (error) {
    yield put(fetchSourcesFail(error))
  }
}

function* fetchCompanies() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/companies",
      })
    )
    yield put(fetchCompaniesSuccess(data.data))
  } catch (error) {
    yield put(fetchCompaniesFail(error))
  }
}

function* fetchPopularCompanies(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/companies/popular",
        params: {
          status: action.status,
        },
      })
    )
    yield put(fetchPopularCompaniesSuccess(data.data))
  } catch (error) {
    yield put(fetchCompaniesFail(error))
  }
}

function* fetchLocations() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/locations",
      })
    )
    yield put(fetchLocationsSuccess(data.data))
  } catch (error) {
    yield put(fetchLocationsFail(error))
  }
}

function* fetchPopularLocations(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/locations/popular",
        params: {
          status: action.status,
        },
      })
    )
    yield put(fetchPopularLocationsSuccess(data.data))
  } catch (error) {
    yield put(fetchLocationsFail(error))
  }
}

function* addRule(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/collections/sphere/${action.sphere_id}/add_rule`,
        data: {
          enabled: true,
          type: action.rule_type,
          settings: action.settings,
        },
        method: "POST",
      })
    )
    yield put(addRuleSuccess(data.rule))
    yield put(fetchCollectionAction("sphere", action.sphere_id))
  } catch (error) {
    yield put(addRuleFail(error))
  }
}

function* updateRule(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/collections/sphere/${action.sphere_id}/update_rule`,
        data: {
          id: action.rule_id,
          enabled: action.enabled,
          type: action.rule_type,
          settings: action.settings,
        },
        method: "PATCH",
      })
    )
    yield put(updateRuleSuccess(data.rule))
    yield put(fetchCollectionAction("sphere", action.sphere_id))
  } catch (error) {
    yield put(updateRuleFail(error))
  }
}

function* fetchCollection(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/collection_details",
        params: {
          id: action.collection_id,
          type: action.collection_type,
        },
      })
    )
    yield put(fetchCollectionSuccess(data.collection, data.collection_sharings))
  } catch (error) {
    yield put(fetchCollectionFail(error))
  }
}

function* managePersonalSharing(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collection_sharings/manage_personal_sharing",
        method: "PATCH",
        params: {
          id: action.collection_id,
          email: action.email,
          access_level: action.access_level,
          type: action.collection_type,
        },
      })
    )
    yield put(managePersonalSharingSuccess(data.collection_sharing))
  } catch (error) {
    yield put(managePersonalSharingFail(error))
  }
}

function* manageGlobalSharing(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collection_sharings/manage_global_sharing",
        method: "PATCH",
        params: {
          id: action.collection_id,
          access_level: action.access_level,
          type: action.collection_type,
        },
      })
    )
    yield put(manageGlobalSharingSuccess(data.collection_sharing))
  } catch (error) {
    yield put(manageGlobalSharingFail(error))
  }
}

function* deleteCollectionSharing(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/collection_sharings/delete_collection_sharing",
        method: "DELETE",
        params: {
          collection_sharing_id: action.collection_sharing_id,
        },
      })
    )
    yield put(deleteCollectionSharingSuccess(action.collection_sharing_id))
  } catch (error) {
    yield put(deleteCollectionSharingFail(error))
  }
}

function* addSharedCollection(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/collection_sharings/add_shared_collection",
        method: "POST",
        params: {
          token: action.sharing_token,
        },
      })
    )
    yield put(addSharedCollectionSuccess())
    yield put(fetchSpheresAction())
  } catch (error) {
    yield put(addSharedCollectionFail(error))
  }
}

function* removeSharedCollection(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/collection_sharings/remove_shared_collection`,
        method: "POST",
        params: {
          collection_id: action.collection_id,
        },
      })
    )
    yield put(removeSharedCollectionSuccess())
    yield put(fetchSpheresAction())
  } catch (error) {
    yield put(removeSharedCollectionFail(error))
  }
}

function* createSphere(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/collections/sphere",
        method: "POST",
        data: {
          name: action.sphere_name,
          sphere_type: action.sphere_type,
        },
      })
    )
    yield put(setSphereModalVisible(false, null))
    if (action.sphere_type) {
      yield call(() =>
        history.push(`/${action.sphere_type}-sphere/${data.friendly_id}`)
      )
    }

    yield put(createSphereSuccess())
    yield put(fetchSpheresAction())
  } catch (error) {
    yield put(createSphereFail(error))
  }
}

function* saveFlowSphere(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/flow_sphere/${action.sphere_id}`,
        method: "PUT",
        data: {
          sphere_steps: action.sphere_steps,
          sphere_stages_to_remove: action.steps_to_remove,
        },
      })
    )
    yield put(saveFlowSphereSuccess(data.flow_sphere))
  } catch (error) {
    yield put(saveFlowSphereFail(error))
  }
}

function* getFlowSphere(action) {
  if (!action.sphere_id) {
    yield put(fetchFlowSphereSuccess(null))
    return
  }
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/flow_sphere/${action.sphere_id}`,
        method: "GET",
      })
    )
    yield put(fetchFlowSphereSuccess(data.flow_sphere))
  } catch (error) {
    yield put(fetchFlowSphereFail(error))
  }
}

function* getStageSphere(action) {
  if (!action.sphere_id) {
    yield put(fetchStageSphereSuccess(null))
    return
  }
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/stage_sphere/${action.sphere_id}`,
        method: "GET",
      })
    )
    yield put(fetchStageSphereSuccess(data.stage_sphere))
  } catch (error) {
    yield put(fetchStageSphereFail(error))
  }
}

function* saveStageSphere(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/stage_sphere/${action.sphere_id}`,
        method: "PUT",
        data: {
          sphere_steps: action.sphere_steps,
        },
      })
    )
    yield put(saveStageSphereSuccess(data.stage_sphere))
  } catch (error) {
    yield put(saveStageSphereFail(error))
  }
}

function* createInitialSpheres(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/collections/sphere/create_initial_spheres",
        method: "POST",
        data: {
          spheres: action.spheres,
        },
      })
    )
    yield put(createInitialSpheresSuccess())
    yield put(fetchSpheresAction())
  } catch (error) {
    yield put(createInitialSpheresFail(error))
  }
}
