import React from "react"

const InfoIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.76197 1.57031C7.44544 1.57031 6.15847 1.96071 5.06382 2.69213C3.96916 3.42356 3.11598 4.46316 2.61217 5.67948C2.10835 6.89579 1.97653 8.23419 2.23337 9.52543C2.49022 10.8167 3.12419 12.0027 4.05511 12.9337C4.98604 13.8646 6.17212 14.4986 7.46335 14.7554C8.75459 15.0122 10.093 14.8804 11.3093 14.3766C12.5256 13.8728 13.5652 13.0196 14.2966 11.925C15.0281 10.8303 15.4185 9.54334 15.4185 8.22681C15.4151 6.46244 14.7127 4.7713 13.4651 3.52369C12.2175 2.27609 10.5263 1.5737 8.76197 1.57031ZM8.63396 4.64254C8.78587 4.64254 8.93436 4.68759 9.06067 4.77198C9.18698 4.85638 9.28542 4.97633 9.34355 5.11668C9.40168 5.25702 9.41689 5.41145 9.38726 5.56044C9.35762 5.70943 9.28447 5.84628 9.17706 5.9537C9.06964 6.06111 8.93279 6.13426 8.7838 6.1639C8.63481 6.19353 8.48038 6.17832 8.34004 6.12019C8.19969 6.06206 8.07974 5.96362 7.99534 5.83731C7.91095 5.711 7.8659 5.56251 7.8659 5.4106C7.8659 5.2069 7.94682 5.01154 8.09086 4.8675C8.2349 4.72346 8.43026 4.64254 8.63396 4.64254ZM9.27401 11.8111H8.76197C8.62617 11.8111 8.49593 11.7571 8.3999 11.6611C8.30388 11.5651 8.24993 11.4348 8.24993 11.299V8.22681C8.11413 8.22681 7.98389 8.17286 7.88787 8.07684C7.79184 7.98081 7.73789 7.85057 7.73789 7.71477C7.73789 7.57897 7.79184 7.44873 7.88787 7.35271C7.98389 7.25668 8.11413 7.20273 8.24993 7.20273H8.76197C8.89777 7.20273 9.02801 7.25668 9.12404 7.35271C9.22006 7.44873 9.27401 7.57897 9.27401 7.71477V10.787C9.40981 10.787 9.54005 10.8409 9.63607 10.937C9.7321 11.033 9.78605 11.1632 9.78605 11.299C9.78605 11.4348 9.7321 11.5651 9.63607 11.6611C9.54005 11.7571 9.40981 11.8111 9.27401 11.8111Z"
      fill="currentColor"
    />
  </svg>
)

export default InfoIcon
