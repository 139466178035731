export const APPROVE_TRIGGER = "APPROVE_TRIGGER"
export const APPROVE_TRIGGER_SUCCESS = "APPROVE_TRIGGER_SUCCESS"
export const APPROVE_TRIGGER_FAIL = "APPROVE_TRIGGER_FAIL"

export const IGNORE_TRIGGER = "IGNORE_TRIGGER"
export const IGNORE_TRIGGER_SUCCESS = "IGNORE_TRIGGER_SUCCESS"
export const IGNORE_TRIGGER_FAIL = "IGNORE_TRIGGER_FAIL"

export const UPDATE_TRIGGER = "UPDATE_TRIGGER"
export const UPDATE_TRIGGER_SUCCESS = "UPDATE_TRIGGER_SUCCESS"
export const UPDATE_TRIGGER_FAIL = "UPDATE_TRIGGER_FAIL"

export const CLEAR_REDUX_TRIGGERS_STATE = "CLEAR_REDUX_TRIGGERS_STATE"
export const SET_INITIAL_REDUX_TRIGGERS_STATE = "SET_INITIAL_REDUX_TRIGGERS_STATE"
