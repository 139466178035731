import React, { useState } from "react"
import "./SyncingContactsModal.styles.scss"
import { Checkbox, Modal } from "antd"
import PropTypes from "prop-types"
import { ReactComponent as PeopleInCircles2 } from "../../../images/PeopleInCircles2.svg"

const CheckboxGroup = Checkbox.Group

const plainOptions = [
  "Inbox/Calendar Tracking",
  "Organizing Game",
  "Follow Up Reminders",
  "Location Reminder",
  "Email Templates",
  "Birthday/Anniversary Reminders",
  "Spheralizer",
  "Split Message",
  "Introduction Reminders",
]

const SyncingContactsModal = ({ visible, onClose }) => {
  const [checkedList, setCheckedList] = useState(plainOptions)
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(true)

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }
  return (
    <Modal
      title={
        <>
          <PeopleInCircles2 />
          <h1>We're syncing your contacts now...</h1>
          <p>While we're waiting... what features do you want to turn on?</p>
        </>
      }
      visible={visible}
      width="400px"
      onCancel={onClose}
      onOk={onClose}
      closable={false}
      okText="Jump in!"
      className="SyncingContactsModal"
      wrapClassName="WrapSyncingContactsModal"
    >
      <div className="SyncingContactsModal_Body">
        <CheckboxGroup
          className="SyncingContactsModal_Body_CheckboxGroup"
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
        />
        <Checkbox
          className="SyncingContactsModal_Body_CheckAll"
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          All of them!
        </Checkbox>
      </div>
    </Modal>
  )
}

SyncingContactsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

SyncingContactsModal.defaultProps = {}

export default SyncingContactsModal
