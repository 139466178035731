import React from "react"
import ContactCard from "../Components/ContactCard/ContactCard.component"
import {
  addToSpheres,
  archiveParticipants,
} from "../../../../redux/Participants/Participants.actions"
import { useDispatch } from "react-redux"
import SpheresSelector from "../../../Database/SpheresSelector/SpheresSelector.component"

const AddToDatabaseScene = ({ participant, goNext }) => {
  const dispatch = useDispatch()

  const handleAddToSphere = (existing, created) => {
    dispatch(addToSpheres(participant.id, existing, created, true))
    goNext()
  }

  const handleDelete = () => {
    dispatch(archiveParticipants([participant.id], 1))
    goNext()
  }

  return (
    <div className="Scene AddToDatabaseScene">
      <ContactCard contact={participant} type="participant" />
      <h2 style={{ marginTop: "40px" }}>
        You’ve been
        {participant.full_name
          ? ` talking to ${participant.full_name} recently.`
          : " them."}
      </h2>
      <h3 style={{ marginBottom: "40px" }}>
        Do you want us to keep track of them for you?
      </h3>
      <SpheresSelector
        collapsible
        clearAfterSubmit
        alreadySelected={[]}
        drawerVisible={true}
        handleSubmit={(existingSpheres, newSpheres) => {
          const existing = existingSpheres.filter((el) => el.id !== null)
          const created = newSpheres.filter((el) => el.title !== null)
          if (existing.length || created.length) {
            handleAddToSphere(existing, created)
          }
        }}
      >
        <button className="primary">
          🧠 Yes, add {participant.full_name || participant.email} to my database
        </button>
      </SpheresSelector>
      <button onClick={handleDelete}>👎🏼 No thanks, archive them</button>
    </div>
  )
}

export default AddToDatabaseScene
