import React, { useEffect, useState } from "react"
import "./StageSphere.scss"
import { Dropdown, Input, Menu, Select, Spin } from "antd"
import Icon, { MoreOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { Draggable, Droppable } from "react-beautiful-dnd"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../redux/App/App.actions"
import TrashIcon from "../../components/Icons/Trash.icons"
import moment from "moment/moment"
import changePersonStep from "./api/changePersonStep.api"
import PersonAvatarAndName from "../../components/UI/PersonAvatarAndName/PersonAvatarAndName.component"
import * as _ from "lodash"
import getSearchedPeople from "./api/getSearchedPeople"
import RelatableLoader from "../../components/UI/RelatableLoader/RelatableLoader.component"

const StepColumn = ({
  handleSaveStageSphere,
  step,
  index,
  sphere,
  deleteStep,
  existingSteps,
}) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.UserState)
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )
  const [stepName, setStepName] = useState(step.name)
  const [debouncedSearch] = useState(() => _.debounce(getSearchedPeople, 500))
  const [searchInput, setSearchInput] = useState(null)
  const [searchedPeople, setSearchedPeople] = useState([])
  const [loading, setLoading] = useState(false)

  const handleRenameStep = () => {
    if (stepName !== step.name) {
      const newSteps = [...existingSteps]
      newSteps[index].name = stepName
      handleSaveStageSphere(newSteps)
    }
  }

  const searchPeople = (value) => {
    setLoading(true)
    debouncedSearch(value, (data) => {
      setSearchedPeople(data)
      setLoading(false)
    })
  }

  const handleShowPerson = (id, slug) => {
    if (contactDrawerVisible && id === visibleContactID) {
      dispatch(setVisibleContactID(null))
      dispatch(setContactDrawerVisible(false))
    } else {
      dispatch(setVisibleContactID(id, slug))
      dispatch(setContactDrawerVisible(true))
      dispatch(setContactDrawerMode("view"))
    }
  }

  useEffect(() => {
    setStepName(step.name || "")
  }, [step.name])

  const moreMenu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          deleteStep(step)
        }}
      >
        <div className="StageSphere_Step_Remove">
          <Icon component={TrashIcon} />
          <span>Delete step</span>
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className={"StageSphere_Step"}>
      <div className={"StageSphere_Step_TitleContainer"}>
        <div className={"StageSphere_EditTitle"}>
          <Input
            autoFocus={stepName === ""}
            className={"StageSphere_Step_Title"}
            value={stepName}
            onChange={(e) => setStepName(e.target.value)}
            onBlur={() => {
              handleRenameStep()
            }}
            onPressEnter={(event) => {
              event.target.blur()
              handleRenameStep()
            }}
          />
          <Dropdown.Button
            type={"link"}
            icon={
              <Icon className="StageSphere_Step_EditIcon" component={MoreOutlined} />
            }
            overlay={moreMenu}
            placement="topRight"
            trigger={["click"]}
          />
        </div>
        {sphere?.id ? (
          <div className={"StageSphere_Step_AddPerson"}>
            <Select
              className={"StageSphere_Step_AddPerson_Select"}
              showSearch
              value={searchInput}
              placeholder={"Add someone..."}
              onSearch={(value) => {
                setSearchInput(value)
                if (value.length > 2) {
                  searchPeople(value)
                }
              }}
              filterOption={false}
              notFoundContent={loading ? <RelatableLoader size="small" /> : null}
              options={searchedPeople.map((person) => {
                return {
                  label: (
                    <div
                      className={"StageSphere_Step_AddPerson_Option"}
                      onClick={() => {
                        changePersonStep(
                          {
                            person_id: person.id,
                            step_id: step.id,
                            collection_id: sphere.id,
                          },
                          (data) => {
                            setSearchInput(null)
                            setSearchedPeople([])
                            const newSteps = [...existingSteps]
                            newSteps[index].people.push(data.person)
                            handleSaveStageSphere(newSteps)
                          }
                        )
                      }}
                    >
                      <span>{person.name}</span>
                    </div>
                  ),
                  value: person.id,
                }
              })}
            />
          </div>
        ) : null}
      </div>
      <Droppable droppableId={`${index}`}>
        {(provided, snapshot) => (
          <div
            className={`StageSphere_Step_Content ${
              snapshot.isDraggingOver ? "draggingOver" : "no-draggingOver"
            }`}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {step.people.length ? (
              step.people.map((person, index) => {
                return (
                  <Draggable
                    key={`${person.id}`}
                    draggableId={`${person.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`StageSphere_PersonWrapper no-select ${
                          snapshot.isDragging ? "dragging" : "no-dragging"
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <PersonAvatarAndName
                          key={index}
                          showPerson={() => {
                            handleShowPerson(person.id, person.slug)
                          }}
                          person={person}
                        />
                        <span className={"StageSphere_PersonWrapper_LastContacted"}>
                          Last contacted:{" "}
                          {person.last_contacted
                            ? moment(person.last_contacted)
                                .tz(user.time_zone)
                                .format("MMM Do")
                            : "Never"}
                        </span>
                      </div>
                    )}
                  </Draggable>
                )
              })
            ) : (
              <div className={"StageSphere_Step_NoPeople"}></div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default StepColumn
