import React from "react"

const SixGearIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z"
      stroke="#697586"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5071 7.56523C18.7328 7.88836 18.9304 8.23024 19.0978 8.58711L21.5259 9.93711C21.8291 11.2951 21.8323 12.7028 21.5353 14.0621L19.0978 15.4121C18.9304 15.769 18.7328 16.1109 18.5071 16.434L18.554 19.2184C17.525 20.1561 16.307 20.8625 14.9821 21.2902L12.5915 19.8559C12.1983 19.884 11.8035 19.884 11.4103 19.8559L9.02901 21.2809C7.69992 20.861 6.47797 20.1572 5.44776 19.2184L5.49463 16.4434C5.27082 16.1158 5.07333 15.7709 4.90401 15.4121L2.47588 14.0621C2.17269 12.7042 2.16949 11.2964 2.46651 9.93711L4.90401 8.58711C5.07133 8.23024 5.26893 7.88836 5.49463 7.56523L5.44776 4.78086C6.4768 3.84314 7.69474 3.13667 9.01963 2.70898L11.4103 4.14336C11.8035 4.11523 12.1983 4.11523 12.5915 4.14336L14.9728 2.71836C16.3018 3.13826 17.5238 3.842 18.554 4.78086L18.5071 7.56523Z"
      stroke="#697586"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default SixGearIcon
