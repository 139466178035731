import { applyMiddleware, createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import logger from "redux-logger"
import createSagaMiddleware from "redux-saga"
import storage from "redux-persist/lib/storage"
import { routerMiddleware } from "connected-react-router"

import rootReducer from "./rootReducer"
import { watchUserSaga } from "./User/User.sagas"
import { watchPeopleSaga } from "./People/People.sagas"
import { watchParticipantsSaga } from "./Participants/Participants.sagas"
import { watchSpheresSaga } from "./Collections/Collections.sagas"
import { watchAccountsSaga } from "./Accounts/Accounts.sagas"
import { watchIntroductionsSaga } from "./Introductions/Introductions.sagas"
import { watchInteractionsSaga } from "./Interactions/Interactions.sagas"
import { watchRemindersSaga } from "./Reminders/Reminders.sagas"
import { watchTriggersSaga } from "./Triggers/Triggers.sagas"
import { watchActivitiesSaga } from "./Activities/Activities.sagas"
import history from "../history"
import { watchMessagesSaga } from "./Messages/Messages.sagas"
import { watchAdminSaga } from "./Admin/Admin.sagas"
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"

const sagaMiddleware = createSagaMiddleware()
const connectedRouterMiddleware = routerMiddleware(history)

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history))

let middleware = []
if (process.env.NODE_ENV === "development") {
  middleware = [connectedRouterMiddleware, sagaMiddleware, logger]
} else {
  middleware = [connectedRouterMiddleware, sagaMiddleware]
}

export const store = createStore(persistedReducer, applyMiddleware(...middleware))
export const persistor = persistStore(store)

sagaMiddleware.run(watchUserSaga)
sagaMiddleware.run(watchPeopleSaga)
sagaMiddleware.run(watchParticipantsSaga)
sagaMiddleware.run(watchSpheresSaga)
sagaMiddleware.run(watchAccountsSaga)
sagaMiddleware.run(watchIntroductionsSaga)
sagaMiddleware.run(watchInteractionsSaga)
sagaMiddleware.run(watchRemindersSaga)
sagaMiddleware.run(watchTriggersSaga)
sagaMiddleware.run(watchActivitiesSaga)
sagaMiddleware.run(watchMessagesSaga)
sagaMiddleware.run(watchAdminSaga)
