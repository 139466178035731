import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { Select } from "antd"
import {
  addRule,
  updateRule,
} from "../../../../redux/Collections/Collections.actions"

const SphereReminders = () => {
  const sphere = useSelector((state) => state.CollectionsState.collection)
  const loading = useSelector((state) => state.CollectionsState.loading)
  const [rules, setRules] = useState(sphere.rules)

  const dispatch = useDispatch()

  useEffect(() => {
    setRules(sphere.rules)
  }, [sphere.rules])

  const ruleDescription = () => {
    return "We’ll remind you if we haven’t seen any interaction between you two."
  }

  const hasCadenceRule = () => {
    return rules.find((rule) => rule.type === "Rule::Cadence")
  }

  return (
    <div className={"RuleCreator_Block"}>
      <div>
        <span className={"RuleCreator_Title"}>Stay in touch</span>
      </div>
      <div className={"RuleCreator_Block_Content"}>
        <Select
          loading={loading}
          disabled={loading}
          className={"RuleCreator_Select"}
          dropdownClassName={"RuleCreator_Select_Dropdown"}
          value={
            rules.length > 0 && hasCadenceRule() && hasCadenceRule().enabled
              ? rules.find((rule) => rule.type === "Rule::Cadence").settings.interval
              : null
          }
          onSelect={(value) => {
            if (hasCadenceRule()) {
              dispatch(
                updateRule(sphere.id, value !== "never", hasCadenceRule().id, {
                  interval: value,
                })
              )
            } else {
              dispatch(
                addRule(sphere.id, "Rule::Cadence", {
                  interval: value,
                })
              )
            }
          }}
        >
          <Select.Option value={"never"}>Never</Select.Option>
          <Select.Option value={"weekly"}>Weekly</Select.Option>
          <Select.Option value={"monthly"}>Monthly</Select.Option>
          <Select.Option value={"bi-monthly"}>Bi-monthly</Select.Option>
          <Select.Option value={"quarterly"}>Quarterly</Select.Option>
          <Select.Option value={"semi-annually"}>Semi-annually</Select.Option>
          <Select.Option value={"annually"}>Annually</Select.Option>
        </Select>
      </div>
      <div className={"RuleCreator_Block_Content"}>{ruleDescription()}</div>
    </div>
  )
}

export default SphereReminders
