import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Icon from "@ant-design/icons"
import MessageIcon from "../../../Icons/Message.icons"
import PhoneCall2Icon from "../../../Icons/PhoneCall2.icons"
import Envelope2Icon from "../../../Icons/Envelope2.icons"
import InteractionTextarea from "../Components/InteractionTextArea/InteractionTextarea.component"
import { useDispatch, useSelector } from "react-redux"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setMessageSent,
  setSplitMessageSenderVisible,
  setVisibleContactID,
  showNoAccountNotification,
} from "../../../../redux/App/App.actions"

const RecordingInteractionScene = ({ selectedPerson, goNext, goBack }) => {
  const [method, setMethod] = useState(null)
  const userAccounts = useSelector((state) => state.AccountsState.accounts)
  const messageSent = useSelector((state) => state.AppState.message_sent)

  const dispatch = useDispatch()

  const onShowPerson = (id, slug) => {
    dispatch(setVisibleContactID(id, slug))
    dispatch(setContactDrawerVisible(true))
    dispatch(setContactDrawerMode("view"))
  }

  const onHidePerson = () => {
    dispatch(setVisibleContactID(null))
    dispatch(setContactDrawerVisible(false))
  }

  const handleGoNext = () => {
    onHidePerson()
    goNext()
  }

  const handleGoBack = () => {
    onHidePerson()
    goBack()
  }

  useEffect(() => {
    if (messageSent) {
      dispatch(setMessageSent(false))
      handleGoNext()
    }
  }, [messageSent])

  useEffect(() => {
    if (selectedPerson) {
      onShowPerson(selectedPerson.id, selectedPerson.slug)
      if (selectedPerson?.phone_numbers?.length > 0) {
        setMethod("call")
        window.open(
          `tel:${selectedPerson.phone_numbers[0].value?.replaceAll(" ", "")}`,
          "_self"
        )
      }
    }
  }, [selectedPerson])

  const handleEmail = () => {
    setMethod("email")
    const runningAccounts = userAccounts.filter((a) => a.state === "running")
    if (runningAccounts.length > 0) {
      dispatch(setSplitMessageSenderVisible(true))
      dispatch(setVisibleContactID(selectedPerson.id))
    } else {
      dispatch(showNoAccountNotification())
    }
  }

  return (
    <div className="Scene RecordingInteractionScene">
      <h2>Re-engage with {selectedPerson.full_name}</h2>
      <div className="RecordingInteractionScene_Methods">
        {selectedPerson?.phone_numbers?.length > 0 ? (
          <>
            <a
              className={method === "call" ? "active" : ""}
              href={`tel:${selectedPerson.phone_numbers[0].value?.replaceAll(
                " ",
                ""
              )}`}
            >
              <Icon component={PhoneCall2Icon} onClick={() => setMethod("call")} />
            </a>
            <a
              className={method === "sms" ? "active" : ""}
              href={`sms:${selectedPerson.phone_numbers[0].value?.replaceAll(
                " ",
                ""
              )}`}
            >
              <Icon component={MessageIcon} onClick={() => setMethod("sms")} />
            </a>
          </>
        ) : (
          <>
            <Icon className="disabled" component={PhoneCall2Icon} />
            <Icon className="disabled" component={MessageIcon} />
          </>
        )}
        {selectedPerson?.email ? (
          <Icon
            className={method === "email" ? "active" : ""}
            component={Envelope2Icon}
            onClick={handleEmail}
          />
        ) : (
          <Icon className="disabled" component={Envelope2Icon} />
        )}
      </div>
      <p>Able to connect? Add an interaction to their profile.</p>
      {["call", "sms"].includes(method) && (
        <InteractionTextarea
          initialParticipants={selectedPerson}
          method={method}
          person={selectedPerson}
          afterSubmit={handleGoNext}
        />
      )}

      <button onClick={handleGoBack}>⏰ No, I’ll try another time</button>
    </div>
  )
}

RecordingInteractionScene.propTypes = {
  selectedPerson: PropTypes.object,
  goNext: PropTypes.func,
}

export default RecordingInteractionScene
