import React, { useEffect, useRef, useState } from "react"
import "./StageSphere.scss"
import { Dropdown, Input, Menu, Tooltip } from "antd"
import Icon, { EditOutlined, MoreOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import {
  deleteSphere,
  renameSphere,
} from "../../redux/Collections/Collections.actions"
import ConfirmModal from "../../components/Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { uuid } from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils"
import { useHistory, useParams } from "react-router"
import { PlusCircleFilled } from "@ant-design/icons/lib"
import getStageSphere from "./api/getStageSphere.api"
import changePersonStep from "./api/changePersonStep.api"
import removeSphereStep from "./api/removeSphereStep.api"
import StepColumn from "./StepColumn"
import updateSphereSteps from "./api/updateSphereSteps"

const StageSphere = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const stageSphereTitleRef = useRef(null)

  const [stageSphereName, setStageSphereName] = useState(null)
  const [lastOrder, setLastOrder] = useState(0)
  const [steps, setSteps] = useState([])
  const [deleteSphereConfirmVisible, setDeleteSphereConfirmVisible] = useState(false)
  const [sphere, setStageSphere] = useState(null)
  const [stepToDelete, setStepToDelete] = useState(null)

  useEffect(() => {
    if (params.friendly_id) {
      getStageSphere(params.friendly_id, (data) => {
        setStageSphere(data.stage_sphere)
        setStageSphereName(data.stage_sphere.title)
        setSteps(data.stage_sphere.steps)
        setLastOrder(data.stage_sphere.steps.length)
      })
    }
  }, [params.friendly_id])

  const handleSaveStageSphere = (newSteps) => {
    updateSphereSteps(sphere?.id, newSteps, (data) => {
      setStageSphere(data.stage_sphere)
      setSteps(data.stage_sphere.steps)
      scrollingRight()
    })
  }

  const handleRenameSphere = () => {
    if (sphere?.id && stageSphereName !== sphere.title) {
      dispatch(renameSphere(sphere.id, stageSphereName))
    }
  }

  const targetElement = useRef()
  const scrollingRight = () => {
    const elmnt = targetElement.current
    elmnt.scrollLeft = elmnt.scrollWidth
  }

  const handleRemoveStep = (step_id) => {
    const newSteps = steps.filter((s) => s.id !== step_id)
    setLastOrder(newSteps.length)
    setSteps(newSteps)
    setStepToDelete(null)
    removeSphereStep(step_id, (data) => {
      setStageSphere(data.stage_sphere)
      setSteps(data.stage_sphere.steps)
    })
  }

  const handleRemoveStepFromDraft = (idx) => {
    const newSteps = [...steps]
    newSteps.splice(idx, 1)
    setLastOrder(newSteps.length)
    setSteps(newSteps)
  }

  const handleAddStep = (step) => {
    setLastOrder(step.order)
    const newSteps = [...steps, step]
    setSteps(newSteps)
    if (sphere?.id) {
      handleSaveStageSphere(newSteps)
    }
  }

  const AddOrderToSphere = (items_order = null, items) => {
    if (items_order) {
      return items.map((item) => {
        item.order = items_order.findIndex((o) => o === item.id)
        if (item.order === -1) {
          item.order = items_order.length + item.id
        }
        return item
      })
    } else {
      return items.map((item, index) => {
        item.order = index
        return item
      })
    }
  }

  const handleReorderSteps = (result) => {
    if (!result.destination) return

    if (
      result.destination.droppableId === "droppable-stages" ||
      result.source.droppableId === "droppable-stages"
    ) {
      const items = Array.from(steps, (s) => s.id)
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
      const newSteps = AddOrderToSphere(items, steps)
      setSteps(newSteps)
      handleSaveStageSphere(newSteps)
    }

    if (result.destination.droppableId === result.source.droppableId) {
      return
    }

    const newSteps = [...steps]
    const sourceIndex = result.source.droppableId
    const destinationIndex = result.destination.droppableId
    const personIndex = steps[sourceIndex].people.findIndex(
      (p) => p.id === +result.draggableId
    )
    newSteps[destinationIndex].people.push(steps[sourceIndex].people[personIndex])
    newSteps[sourceIndex].people.splice(personIndex, 1)

    setSteps(newSteps)
    changePersonStep({
      collection_id: sphere.id,
      person_id: +result.draggableId,
      step_id: newSteps[destinationIndex].id,
    })
  }

  const moreMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setDeleteSphereConfirmVisible(true)}>
        <span className={"StageSphere_Buttons_Red"}>Delete Sphere</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="StageSphere">
      <ConfirmModal
        title={`Are you sure you want to delete sphere?`}
        description={`Removing ${stageSphereName} will archive all the contacts that don't belong to any sphere.`}
        visible={deleteSphereConfirmVisible}
        onCancel={() => setDeleteSphereConfirmVisible(false)}
        onConfirm={() => {
          dispatch(deleteSphere(sphere.id))
          history.push("/dashboard")
        }}
      />
      <ConfirmModal
        title={`Are you sure you want to delete this step?`}
        description={`Deleting this step will cause anyone in this step to move to the next step instantly.`}
        visible={!!stepToDelete}
        onCancel={() => setStepToDelete(null)}
        onConfirm={() => handleRemoveStep(stepToDelete)}
      />
      <div className={"StageSphere_Header"}>
        <div className={"StageSphere_TitleContainer"}>
          <div className={"StageSphere_EditTitle"}>
            <Input
              ref={stageSphereTitleRef}
              onKeyPress={(e) => {
                stageSphereTitleRef.current.input.style.width = `${
                  stageSphereName.length + 1
                }ch`
              }}
              style={{ width: `${stageSphereName?.length}ch` }}
              className={"StageSphere_Title"}
              value={stageSphereName}
              onChange={(e) => setStageSphereName(e.target.value)}
              onBlur={handleRenameSphere}
            />
            <EditOutlined className={`StageSphere_EditIcon`} />
          </div>

          <div className={"StageSphere_Buttons"}>
            {sphere?.id && (
              <Dropdown.Button
                type={"link"}
                icon={
                  <Icon
                    className="StageSphere_Step_EditIcon"
                    component={MoreOutlined}
                  />
                }
                overlay={moreMenu}
                placement="topRight"
                trigger={["click"]}
              />
            )}
          </div>
        </div>
      </div>
      <div ref={targetElement} className={"StageSphere_DraggableStepsWrapper"}>
        {steps.length > 0 && (
          <DragDropContext onDragEnd={handleReorderSteps}>
            <Droppable
              direction={"horizontal"}
              type={"droppable-stages"}
              droppableId={`droppable-stages`}
            >
              {(provided, snapshot) => (
                <div
                  className={"StageSphere_StepsContainer"}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {steps
                    .sort((a, b) => a.order - b.order)
                    .map((step, idx) => {
                      return (
                        <Draggable
                          key={step.id}
                          draggableId={`${step.id}`}
                          index={idx}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`StageSphere_DraggableStep ${
                                snapshot.isDragging ? "isDragged" : "isNotDragged"
                              }`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <StepColumn
                                key={step.id}
                                step={step}
                                existingSteps={steps}
                                index={idx}
                                sphere={sphere}
                                handleSaveStageSphere={handleSaveStageSphere}
                                deleteStep={(step) =>
                                  sphere?.id
                                    ? setStepToDelete(step.id)
                                    : handleRemoveStepFromDraft(idx)
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div className={"StageSphere_AddPanel"}>
          <button
            className={"StageSphere_AddStep"}
            onClick={() => {
              handleAddStep({
                name: "",
                people: [],
                order_key: uuid("step"),
                order: lastOrder + 1,
                is_new: true,
              })
            }}
          >
            <Tooltip placement={"bottom"} title="Add next step">
              <PlusCircleFilled style={{ fontSize: "40px" }} />
            </Tooltip>
          </button>
        </div>
      </div>
    </div>
  )
}

export default StageSphere
