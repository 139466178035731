import React from "react"
import "../../RightPanel.styles.scss"
import { Tooltip } from "antd"

const Popup = ({ children, content, visible, handleVisibleChange }) => {
  return (
    <Tooltip
      visible={visible}
      onVisibleChange={handleVisibleChange}
      title={content}
      trigger="click"
      placement="bottomLeft"
      icon={null}
      overlayClassName="IntroductionsTab_Popup"
    >
      {children}
    </Tooltip>
  )
}

export default Popup
