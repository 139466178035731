export const SET_CONTACT_DRAWER_VISIBLE = "SET_CONTACT_DRAWER_VISIBLE"
export const SET_CONTACT_DRAWER_MODE = "SET_CONTACT_DRAWER_MODE"
export const SET_VISIBLE_CONTACT_ID = "SET_VISIBLE_CONTACT_ID"
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
export const SET_LEFT_RAIL_COLLAPSED = "SET_LEFT_RAIL_COLLAPSED"

export const SET_RIGHT_RAIL_COLLAPSED = "SET_RIGHT_RAIL_COLLAPSED"

export const SELECT_CONTACT = "SELECT_CONTACT"
export const DESELECT_CONTACT = "DESELECT_CONTACT"
export const FILL_DATA_IN_SELECTED_CONTACTS = "FILL_DATA_IN_SELECTED_CONTACTS"

export const SELECT_CONTACTS = "SELECT_CONTACTS"
export const DESELECT_CONTACTS = "DESELECT_CONTACTS"

export const RESET_SELECTED_CONTACTS = "RESET_SELECTED_CONTACTS"

export const SELECT_PARTICIPANT = "SELECT_PARTICIPANT"
export const DESELECT_PARTICIPANT = "DESELECT_PARTICIPANT"

export const SELECT_PARTICIPANTS = "SELECT_PARTICIPANTS"
export const DESELECT_PARTICIPANTS = "DESELECT_PARTICIPANTS"

export const SET_CURRENT_LOCATION_KEY = "SET_CURRENT_LOCATION_KEY"

export const SET_MESSAGE_SENDER_VISIBLE = "SET_MESSAGE_SENDER_VISIBLE"
export const SHOW_NO_ACCOUNT_NOTIFICATION = "SHOW_NO_ACCOUNT_NOTIFICATION"
export const SET_SPLIT_MESSAGE_SENDER_VISIBLE = "SET_SPLIT_MESSAGE_SENDER_VISIBLE"
export const SET_SCHEDULED_SPLIT_MESSAGE_SENDER_VISIBLE =
  "SET_SCHEDULED_SPLIT_MESSAGE_SENDER_VISIBLE"

export const SET_RIGHT_PANEL_TAB = "SET_RIGHT_PANEL_TAB"

export const CLEAR_REDUX_APP_STATE = "CLEAR_REDUX_APP_STATE"
export const SET_INITIAL_REDUX_APP_STATE = "SET_INITIAL_REDUX_APP_STATE"
export const SET_SPHERE_MODAL_VISIBLE = "SET_SPHERE_MODAL_VISIBLE"

export const VALIDATION_ERROR = "VALIDATION_ERROR"

export const SET_INIT_CC = "SET_INIT_CC"

export const OPEN_DRAWER_TAB = "OPEN_DRAWER_TAB"

export const SET_USER_PANEL_MODAL_VISIBLE = "SET_USER_PANEL_MODAL_VISIBLE"

export const SET_MESSAGE_SENT = "SET_MESSAGE_SENT"

export const SET_SHOW_START_BUILDING_CARD = "SET_SHOW_START_BUILDING_CARD"

export const TOGGLE_SUGGESTED_MESSAGES_PANEL = "TOGGLE_SUGGESTED_MESSAGES_PANEL"

export const SET_SELECTED_CONTACT_ID = "SET_SELECTED_CONTACT_ID"
