import React, { useCallback, useEffect } from "react"
import "./MyInfoSection.styles.scss"
import { useFormik } from "formik"
import * as yup from "yup"
import { Avatar, Form, Input, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { updateAvatar, updateProfile } from "../../../redux/User/User.actions"
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input"
import en from "world_countries_lists/data/en/world.json"
import "antd/dist/antd.css"
import "antd-country-phone-input/dist/index.css"
import { UserOutlined } from "@ant-design/icons"
import { apiHost } from "../../../config/serverConf"
import { useDropzone } from "react-dropzone"
import { setUserPanelModalVisible } from "../../../redux/App/App.actions"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const MyInfoSection = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserState)
  const updating = useSelector((state) => state.UserState.updating)

  const PHONE_NO_REGEX = /^[0-9\- ]{8,14}$/

  const onDrop = useCallback((files) => {
    if (files && files.length > 0) {
      dispatch(updateAvatar(...files))
    }
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  })

  const schema = yup.object().shape({
    passwordRequired: yup.boolean(),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Please provide valid email")
      .required("Email is required"),
    current_password: yup.string().when("passwordRequired", {
      is: true,
      then: yup.string().required("Must enter current password"),
    }),
    password: yup
      .string()
      .min(12, "Password must have at least 12 characters")
      .matches(/.*[A-Z].*/, "Password must contain at least one UPPERCASE letter")
      .matches(/.*[0-9].*/, "Password must contain at least one number")
      .nullable(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .nullable(),
    bio: yup.string().nullable(),
    number: yup.string().matches(PHONE_NO_REGEX, {
      message: "Please provide valid phone number",
      excludeEmptyString: true,
    }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      passwordRequired: false,
      email: "",
      first_name: "",
      last_name: "",
      password: null,
      password_confirmation: null,
      bio: "",
      short: "US",
      number: "",
      code: "",
      termsOfService: false,
    },
    validationSchema: schema,
    onSubmit: (values) => dispatch(updateProfile(values)),
  })

  useEffect(() => {
    formik.setValues({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      bio: user.bio,
      number: user.phone_number?.number,
      code: user.phone_number?.code,
      short: user.phone_number?.short,
      public_username: user.public_username,
      passwordRequired: false,
    })
    // eslint-disable-next-line
  }, [user])

  return (
    <div className="MyInfoSection Wrapper">
      <h2>My Contact Card</h2>
      <div className="MyInfoSection_Avatar" {...getRootProps()}>
        {updating && <RelatableLoader size={"large"} />}
        <input {...getInputProps()} />
        <Avatar
          style={updating ? { opacity: 0.2 } : {}}
          icon={<UserOutlined />}
          size={60}
          src={`${apiHost()}${user.avatar}`}
        />
      </div>
      <Form className="MyInfoSection_Form">
        <Form.Item
          className="MyInfoSection_Form_FirstName"
          label="First name"
          colon={false}
          validateStatus={
            formik.touched.first_name && formik.errors.first_name
              ? "error"
              : "success"
          }
          help={
            formik.touched.first_name && formik.errors.first_name
              ? formik.errors.first_name
              : []
          }
        >
          <Input
            key="first_name"
            name="first_name"
            placeholder="Enter your first name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          className="MyInfoSection_Form_LastName"
          label="Last name"
          colon={false}
          validateStatus={
            formik.touched.last_name && formik.errors.last_name ? "error" : "success"
          }
          help={
            formik.touched.last_name && formik.errors.last_name
              ? formik.errors.last_name
              : []
          }
        >
          <Input
            key="last_name"
            name="last_name"
            placeholder="Enter your last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          className="MyInfoSection_Form_Email"
          label="Email"
          colon={false}
          validateStatus={
            formik.touched.email && formik.errors.email ? "error" : "success"
          }
          help={
            formik.touched.email && formik.errors.email ? formik.errors.email : []
          }
        >
          <Input
            key="email"
            name="email"
            placeholder="Enter your email"
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue("passwordRequired", true)
              formik.handleChange(e)
            }}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          className="MyInfoSection_Form_PhoneNumber"
          name="number"
          label="Phone number"
          colon={false}
          validateStatus={
            formik.touched.number && formik.errors.number ? "error" : "success"
          }
          help={
            formik.touched.number && formik.errors.number ? formik.errors.number : []
          }
        >
          <ConfigProvider locale={en}>
            <CountryPhoneInput
              name="number"
              placeholder="Enter your phone number"
              inline
              initailValues={{ short: "US" }}
              value={{
                phone: formik.values.number,
                code: formik.values.code,
                short: formik.values.short,
              }}
              onChange={(e) => {
                formik.setFieldValue("number", e.phone)
                formik.setFieldValue("code", e.code)
                formik.setFieldValue("short", e.short)
              }}
              onBlur={formik.handleBlur}
            />
          </ConfigProvider>
        </Form.Item>
        <Form.Item
          className="MyInfoSection_Form_Bio"
          label="Bio"
          colon={false}
          validateStatus={
            formik.touched.bio && formik.errors.bio ? "error" : "success"
          }
          help={formik.touched.bio && formik.errors.bio ? formik.errors.bio : []}
        >
          <Input.TextArea
            rows={4}
            key="bio"
            name="bio"
            placeholder="Enter your bio"
            value={formik.values.bio}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <div className="MyInfoSection_Form_Footer">
          <button
            onClick={() => dispatch(setUserPanelModalVisible(true))}
            className="SubmitButton ButtonWhite"
          >
            Get my link
          </button>
          <div className="MyInfoSection_Form_Footer_SubmitWrapper">
            {formik.values.passwordRequired && (
              <Form.Item
                label="Current password"
                colon={false}
                validateStatus={formik.errors.current_password ? "error" : "success"}
                help={
                  formik.errors.current_password
                    ? formik.errors.current_password
                    : []
                }
              >
                <Input
                  type="password"
                  key="current_password"
                  name="current_password"
                  placeholder="Enter your current password"
                  value={formik.values.current_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            )}
            <button className="SubmitButton" onClick={() => formik.submitForm()}>
              Save changes
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default MyInfoSection
