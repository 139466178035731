import "../EmailComposerModal/MessageCreator/MessageCreator.styles.scss"
import React, { useEffect, useState } from "react"
import * as _ from "lodash"
import getSearchedPeople from "../../../pages/StageSphere/api/getSearchedPeople"
import { Select, Spin } from "antd"
import RelatableLoader from "../RelatableLoader/RelatableLoader.component"

const CCMultiSelect = ({ selectedPeople, setSelectedPeople }) => {
  const [debouncedSearch] = useState(() => _.debounce(getSearchedPeople, 500))
  const [searchedPeople, setSearchedPeople] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [filteredOptions, setFilteredOptions] = useState([])

  useEffect(() => {
    setSelectedOptions(
      selectedPeople.map((p) => ({
        label: p,
        value: p,
      }))
    )
  }, [selectedPeople])

  const searchPeople = (value) => {
    setLoading(true)
    debouncedSearch(
      value,
      (data) => {
        setSearchedPeople(data)
        setLoading(false)
      },
      "SORT"
    )
  }

  const handleAddPersonToCCList = (value, option) => {
    setSelectedOptions([
      ...selectedOptions,
      {
        label: option.label || value,
        value: option.value || value,
      },
    ])
    setSelectedPeople([...selectedPeople, option.value || value])
  }

  const handleRemovePersonFromCCList = (person) => {
    setSelectedOptions(selectedOptions.filter((p) => p.value !== person))
    setSelectedPeople(selectedPeople.filter((p) => p !== person))
  }

  useEffect(() => {
    const tempFilteredOptions = []
    const selectedPeopleValues = selectedOptions.map((s) => s.value)
    searchedPeople.forEach((p) => {
      p.emails.forEach((e) => {
        if (!selectedPeopleValues.includes(e)) {
          tempFilteredOptions.push({
            label: p.full_name ? `${p.full_name} : ${e}` : e,
            value: e,
          })
        }
      })
    })

    setFilteredOptions(tempFilteredOptions)
    // eslint-disable-next-line
  }, [searchedPeople])

  return (
    <Select
      bordered={false}
      mode="tags"
      allowClear={true}
      onClear={() => {
        setSelectedOptions([])
        setSelectedPeople([])
      }}
      value={selectedOptions.map((p) => p.label)}
      listHeight={200}
      placeholder="CC"
      onDeselect={handleRemovePersonFromCCList}
      onSelect={(value, option) => handleAddPersonToCCList(value, option)}
      onSearch={(value) => {
        if (value.length > 2) {
          searchPeople(value)
        }
      }}
      filterOption={false}
      notFoundContent={loading ? <RelatableLoader size="small" /> : null}
      options={filteredOptions.map((fp) => ({
        label: fp.label,
        value: fp.value,
      }))}
    />
  )
}

export default CCMultiSelect
