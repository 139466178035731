import React from "react"
import "./SingleSphereCard.styles.scss"
import { Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { addRule, updateRule } from "../../../redux/Collections/Collections.actions"
import Icon from "@ant-design/icons"
import SixGearIcon from "../../Icons/SixGear.icons"
import RepeatIcon from "../../Icons/Repeat.icons"
import ShareSphereIcon from "../../Icons/ShareSphere.icons"
import { setSphereModalVisible } from "../../../redux/App/App.actions"
import { useHistory } from "react-router-dom"

const rulesOptions = [
  {
    value: "never",
    label: "Never",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "bi-monthly",
    label: "Bi-monthly",
  },
  {
    value: "quarterly",
    label: "Quarterly",
  },
  {
    value: "semi-annually",
    label: "Semi-annually",
  },
  {
    value: "annually",
    label: "Annually",
  },
]

const SingleSphereCard = ({ sphere }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector((state) => state.CollectionsState.loading)

  const handleChangeRule = (value) => {
    if (hasCadenceRule()) {
      dispatch(
        updateRule(sphere.id, value !== "never", hasCadenceRule().id, {
          interval: value,
        })
      )
    } else {
      dispatch(
        addRule(sphere.id, "Rule::Cadence", {
          interval: value,
        })
      )
    }
  }

  const hasCadenceRule = () => {
    return sphere.rules.find((rule) => rule.type === "Rule::Cadence")
  }

  return (
    <div className="SingleSphereCard">
      <div className={"SingleSphereCard_Header"}>
        <h1>{sphere.title}</h1>
        <button
          onClick={() => {
            if (sphere.sphere_type) {
              history.push(`/${sphere.sphere_type}-sphere/${sphere.friendly_id}`)
            } else {
              dispatch(setSphereModalVisible(true, sphere.id))
            }
          }}
          className={"SingleSphereCard_Header_SettingsButton"}
        >
          <Icon component={SixGearIcon} />
        </button>
      </div>

      {!sphere.sphere_type && (
        <div className={`SingleSphereCard_Body`}>
          <div className={"SingleSphereCard_Body_Frequency"}>
            <Icon component={RepeatIcon} />
            <span className="SingleSphereCard_Body_Text">Stay in touch</span>
            <Select
              disabled={loading}
              loading={loading}
              className="RuleCreator_Select SingleSphereCard_Body_Frequency_Select"
              dropdownClassName="RuleCreator_Select_Dropdown"
              onSelect={(value) => handleChangeRule(value)}
              value={
                sphere.rules.length > 0 &&
                hasCadenceRule() &&
                hasCadenceRule().enabled
                  ? sphere.rules.find((rule) => rule.type === "Rule::Cadence")
                      .settings.interval
                  : "never"
              }
            >
              {rulesOptions.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <button
            onClick={() => {
              dispatch(setSphereModalVisible(true, sphere.id, "sharing"))
            }}
            className="SingleSphereCard_Body_ShareButton"
          >
            <Icon component={ShareSphereIcon} />
            <span>Share Sphere</span>
          </button>
        </div>
      )}
    </div>
  )
}

SingleSphereCard.propTypes = {}

SingleSphereCard.defaultProps = {}

export default SingleSphereCard
