import React from "react"

const GlobeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeMiterlimit="10"
    />
    <path
      d="M3.51562 9H20.4844"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.51562 15H20.4844"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 20.7566C14.0711 20.7566 15.75 16.8363 15.75 12.0004C15.75 7.16445 14.0711 3.24414 12 3.24414C9.92893 3.24414 8.25 7.16445 8.25 12.0004C8.25 16.8363 9.92893 20.7566 12 20.7566Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeMiterlimit="10"
    />
  </svg>
)

export default GlobeIcon
