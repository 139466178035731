import React, { useEffect, useState } from "react"
import "./DuplicatesSection.styles.scss"
import { Col, Table, Typography } from "antd"
import Avatar from "../../../components/UI/Avatar/Avatar.component"
import mergePeopleSVG from "../../../images/MergePeopleHeader.svg"

import { useDispatch, useSelector } from "react-redux"

import {
  approveDuplicate,
  fetchDuplicatesAction,
  ignoreDuplicate,
} from "../../../redux/People/People.actions"
import { PlusOutlined } from "@ant-design/icons"
import {
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../redux/App/App.actions"
import CustomPagination from "../../../components/Database/CustomPagination/CustomPagination.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const { Text, Paragraph } = Typography

const DuplicatesSection = () => {
  const dispatch = useDispatch()
  const suggested_duplicates = useSelector(
    (state) => state.PeopleState.suggested_duplicates
  )
  const loading = useSelector((state) => state.PeopleState.loading)
  const suggested_duplicates_loading = useSelector(
    (state) => state.PeopleState.suggested_duplicates_loading
  )

  const duplicatesTotalEntries = useSelector(
    (state) => state.PeopleState.duplicatesTotalEntries
  )
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )

  const [page, setPage] = useState(1)

  const handleClickViewPerson = (personId) => {
    if (contactDrawerVisible && +visibleContactID === +personId) {
      dispatch(setContactDrawerVisible(false))
      dispatch(setVisibleContactID(null))
      dispatch(setActiveTab(null))
    } else if (contactDrawerVisible && +visibleContactID !== +personId) {
      dispatch(setContactDrawerMode("view"))
      dispatch(setActiveTab(null))
      dispatch(setVisibleContactID(+personId))
    } else {
      dispatch(setActiveTab(null))
      dispatch(setContactDrawerVisible(true))
      dispatch(setVisibleContactID(personId))
    }
  }

  useEffect(() => {
    dispatch(fetchDuplicatesAction(1))
    // eslint-disable-next-line
  }, [])

  const renderPerson = (record) => {
    return (
      <div className="DuplicatesSection_Table_Row">
        <div className={"DuplicatesSection_Table_Avatar"}>
          <Avatar
            avatar={
              record?.profile_image_url !== "" ? record.profile_image_url : null
            }
            firstName={record?.first_name}
            lastName={record?.last_name}
            email={record?.email?.value}
            colorNumber={record?.id}
            size={40}
          />
        </div>
        {}
        {record.first_name && record.first_name.trim().length > 0 ? (
          <div className={"DuplicatesSection_Table_DetailsWrap"}>
            <div
              onClick={() => {
                if (!record.participant_id) {
                  handleClickViewPerson(record.id)
                }
              }}
              className={"DuplicatesSection_Table_FullName"}
            >
              {record.first_name} {record.last_name}
            </div>
            {record.email && record.email.value?.length > 0 && (
              <div className={"DuplicatesSection_Table_Email"}>
                {record.email.value}
              </div>
            )}
          </div>
        ) : (
          <div className={"DuplicatesSection_Table_DetailsWrap"}>
            <div
              onClick={() => {
                if (!record.participant_id) {
                  handleClickViewPerson(record.id)
                }
              }}
              className={"DuplicatesSection_Table_FullName"}
            >
              {record.email.value}
            </div>
          </div>
        )}
      </div>
    )
  }

  const columns = [
    {
      key: "person_main",
      dataIndex: "person_main",
      render: (record) => renderPerson(record),
    },
    {
      key: "default",
      render: (record) => (
        <span className={"DuplicatesSection_Table_PlusWrap"}>
          <PlusOutlined style={{ fontSize: "20px" }} />
        </span>
      ),
    },
    {
      key: "person_two",
      dataIndex: "person_two",
      render: (record) => renderPerson(record),
    },

    {
      key: "action",
      render: (record) => (
        <div className={"DuplicatesSection_Table_ButtonWrap"}>
          <div>
            <button
              onClick={() => {
                dispatch(
                  approveDuplicate(
                    record.person_main.id,
                    record.person_two.id,
                    page,
                    record.person_two.participant_id
                  )
                )
              }}
              className={"DuplicatesSection_Table_ButtonMerge"}
              style={{ marginRight: "10px" }}
            >
              Merge
            </button>
          </div>
          <Col>
            <button
              onClick={() => {
                dispatch(
                  ignoreDuplicate(
                    record.person_main.id,
                    record.person_two.id,
                    page,
                    record.person_two.participant_id
                  )
                )
              }}
              className={"DuplicatesSection_Table_ButtonNope"}
            >
              Nope
            </button>
          </Col>
        </div>
      ),
    },
  ]

  const handleTableChange = (page) => {
    setPage(page)

    dispatch(fetchDuplicatesAction(page))
  }

  return (
    <>
      {duplicatesTotalEntries > 0 ? (
        <div className="DuplicatesSection">
          <div className="DuplicatesSection_Header">
            <div>
              <Text className="DuplicatesSection_Header_Title">
                We found {duplicatesTotalEntries} potential duplicate contacts. What
                do you want to do?
              </Text>
              <Paragraph className="DuplicatesSection_Header_Text">
                Click on each contact to review, and then decide if you want to merge
                each pair. Remember, from the Database page, you can select two or
                more contacts to merge them there!
              </Paragraph>
            </div>
            <img src={mergePeopleSVG} width={150} alt="People in circles" />
          </div>
          <Table
            loading={{
              spinning: loading || suggested_duplicates_loading,
              indicator: (
                <RelatableLoader
                  quote={true}
                  loading={loading || suggested_duplicates_loading}
                />
              ),
            }}
            showHeader={false}
            pagination={false}
            rowKey={(record) => `${record.id}`}
            className="DuplicatesSection_Table"
            columns={columns}
            dataSource={suggested_duplicates}
            onChange={handleTableChange}
            rowClassName={(record, index) => {
              return `profile_variation${record?.id % 3}`
            }}
          />
          <CustomPagination
            total={duplicatesTotalEntries}
            currentPage={page}
            onPageChange={(page) => {
              handleTableChange(page)
            }}
          />
        </div>
      ) : null}
    </>
  )
}

export default DuplicatesSection
