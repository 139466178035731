import React, { useState } from "react"
import { Modal, Select } from "antd"
import "./ChooseMainContactModal.styles.scss"
import { useSelector } from "react-redux"

const ChooseMainContactModal = ({ visible, onCancel, onConfirm }) => {
  const selectedContacts = useSelector((state) => state.AppState.selected_contacts)

  const [main_contact_id, setMainContactId] = useState(null)

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onCancel}
      centered={true}
      width={400}
      className={"ChooseMainContactModal__modal"}
    >
      <div className="ChooseMainContactModal">
        <div className={"ChooseMainContactModal_Text"}>
          <span className={"ChooseMainContactModal_Text_Title"}>
            Are you sure you want to merge?
          </span>
          <span className={"ChooseMainContactModal_Text_Description"}>
            Choose main contact from selected contacts:
            <Select
              style={{ width: "100%", marginTop: "6px" }}
              placeholder="Select main contact"
              optionFilterProp="children"
              onChange={setMainContactId}
              options={selectedContacts?.map((c) => ({
                label: c.full_name,
                value: c.id,
              }))}
            />
          </span>
        </div>

        <div className={"ChooseMainContactModal_Buttons"}>
          <div
            className="ChooseMainContactModal_YesButton"
            onClick={() => {
              onConfirm(main_contact_id)
              onCancel()
            }}
          >
            Yes
          </div>
          <div className="ChooseMainContactModal_NoButton" onClick={onCancel}>
            Nevermind
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChooseMainContactModal
