import React, { useEffect } from "react"
import "./TeamsSection.styles.scss"
import "antd/dist/antd.css"
import "antd-country-phone-input/dist/index.css"
import CreateTeam from "./Subsection/CreateTeam.component"
import { useDispatch, useSelector } from "react-redux"
import { fetchTeam } from "../../../redux/User/User.actions"
import TeamDetails from "./Subsection/TeamDetails.component"
import TeamInvitations from "./Subsection/TeamInvitation.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const TeamsSection = () => {
  const dispatch = useDispatch()
  const fetching_team = useSelector((state) => state.UserState.fetching_team)
  const teams = useSelector((state) => state.UserState.teams)
  const team_invitations = useSelector((state) => state.UserState.team_invitations)

  useEffect(() => {
    dispatch(fetchTeam())
  }, [])

  return (
    <div className="TeamSection Wrapper">
      <h2>Teams</h2>
      {fetching_team ? (
        <RelatableLoader />
      ) : (
        <>
          <div className="TeamSection_ListOfTeams">
            {team_invitations
              ?.sort((a, b) => a.id - b.id)
              .map((invitation) => (
                <TeamInvitations
                  key={invitation.team_id}
                  team_invitation={invitation}
                />
              ))}
            {teams
              ?.sort((a, b) => a.id - b.id)
              .map((team) => (
                <TeamDetails key={team.id} team={team} />
              ))}
          </div>
          <CreateTeam />
        </>
      )}
    </div>
  )
}

export default TeamsSection
