import { Tooltip } from "antd"
import React from "react"
import "./StrengthSelector.styles.scss"
import StrengthIndicator from "../StrengthIndicator/StrengthIndicator.component"
import { useDispatch } from "react-redux"
import { editContact } from "../../../redux/People/People.actions"

const StrengthSelectorText = ({ id }) => {
  const dispatch = useDispatch()

  return (
    <div>
      <div
        className="StrengthSelector_Option"
        onClick={() => {
          dispatch(editContact(id, { strength: "na" }))
        }}
      >
        <StrengthIndicator level={"na"} />
      </div>
      <div
        className={"StrengthSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { strength: "weak" }))
        }}
      >
        <StrengthIndicator level={"weak"} />
      </div>
      <div
        className={"StrengthSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { strength: "average" }))
        }}
      >
        <StrengthIndicator level={"average"} />
      </div>
      <div
        className={"StrengthSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { strength: "strong" }))
        }}
      >
        <StrengthIndicator level={"strong"} />
      </div>
      <div
        className={"StrengthSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { strength: "very_strong" }))
        }}
      >
        <StrengthIndicator level={"very_strong"} />
      </div>
    </div>
  )
}

const StrengthSelector = ({ children, id, opened }) => {
  return (
    <Tooltip
      title={<StrengthSelectorText id={id} />}
      visible={opened}
      placement={"bottom"}
      icon={null}
      overlayClassName={"StrengthSelector"}
    >
      {children}
    </Tooltip>
  )
}

export default StrengthSelector
