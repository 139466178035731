import React from "react"
import "./StrengthIndicator.styles.scss"

const StrengthIndicator = ({ level, disabled = false }) => {
  const labels = {
    na: "N/A",
    weak: "Weak",
    average: "Avg",
    strong: "Strong",
    very_strong: "Very",
  }
  return (
    <div className={disabled ? "StrengthIndicator-disabled" : "StrengthIndicator"}>
      <p>{labels[level]}</p>
      <div className={`StrengthIndicator_Bar StrengthIndicator_Bar-${level}`} />
    </div>
  )
}

export default StrengthIndicator
