import React from "react"

const ArrowLeftIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8346 7.00033H1.16797M1.16797 7.00033L7.0013 12.8337M1.16797 7.00033L7.0013 1.16699"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeftIcon
