import React from "react"

const Envelope2Icon = () => (
  <svg
    width="40"
    height="30"
    viewBox="0 0 40 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3.75195C4.3125 3.75195 3.75 4.31445 3.75 5.00195V6.72852L17.2266 17.791C18.8438 19.1191 21.1641 19.1191 22.7812 17.791L36.25 6.72852V5.00195C36.25 4.31445 35.6875 3.75195 35 3.75195H5ZM3.75 11.5801V25.002C3.75 25.6895 4.3125 26.252 5 26.252H35C35.6875 26.252 36.25 25.6895 36.25 25.002V11.5801L25.1562 20.6895C22.1562 23.1504 17.8359 23.1504 14.8438 20.6895L3.75 11.5801ZM0 5.00195C0 2.24414 2.24219 0.00195312 5 0.00195312H35C37.7578 0.00195312 40 2.24414 40 5.00195V25.002C40 27.7598 37.7578 30.002 35 30.002H5C2.24219 30.002 0 27.7598 0 25.002V5.00195Z"
      fill="currentColor"
    />
  </svg>
)

export default Envelope2Icon
