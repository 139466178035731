import React from "react"
import "./../SuccessPayment/SuccessPayment.styles.scss"

const FreeAccountPage = () => {
  return (
    <div className="FreeAccount">
      <h1>YOUR ACCOUNT IS FREE!</h1>
      <span>Enjoy your time in Relatable!</span>
      <img
        alt="Watch Video"
        className={"video_placeholder"}
        src={`${process.env.PUBLIC_URL}/success_payment_gifs/office_party_1.gif`}
      />
    </div>
  )
}

export default FreeAccountPage
