import React from "react"
import "./BulkActionsDrawer.styles.scss"
import { Drawer } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  addParticipantsToSpheres,
  archiveParticipants,
} from "../../../redux/Participants/Participants.actions"
import DrawerOverlay from "../../UI/DrawerOverlay/DrawerOverlay.component"
import NumberOfSelectedRecords from "../../Database/BulkActionsDrawer/components/NumberOfSelectedRecords/NumberOfSelectedRecords.component"
import UpdateCollection from "../../Database/BulkActionsDrawer/components/UpdateCollection/UpdateCollection.component"
import SplitButton from "../../Database/BulkActionsDrawer/components/SplitButton/SplitButton.component"
import TrashIcon from "../../Icons/Trash.icons"
import Icon from "@ant-design/icons"
import { ArrayParam, NumberParam, useQueryParam } from "use-query-params"

const BulkActionsDrawer = ({ visible }) => {
  const selectedParticipantsIds = useSelector(
    (state) => state.AppState.selected_participants_ids
  )
  const [page] = useQueryParam("participants_page", NumberParam)
  const [filters] = useQueryParam("participants_filters", ArrayParam)

  const dispatch = useDispatch()

  const handleDismissAllSelectedContacts = () => {
    const currentPage = page ? page : 1
    const currentFilters = filters ? filters : null

    dispatch(
      archiveParticipants(selectedParticipantsIds, currentPage, currentFilters)
    )
  }

  const bulkUpdateSpheres = (existingSpheres, newSpheres) => {
    const currentPage = page ? page : 1
    const currentFilters = filters ? filters : null

    return selectedParticipantsIds.length > 0
      ? dispatch(
          addParticipantsToSpheres(
            selectedParticipantsIds,
            existingSpheres,
            newSpheres,
            currentPage,
            currentFilters
          )
        )
      : null
  }

  return (
    <Drawer
      title="Bulk Actions"
      placement="right"
      visible={visible}
      mask={false}
      className="BulkActionsDrawer"
      closable={false}
      destroyOnClose={true}
      getContainer={true}
      width={"22.5vw"}
    >
      <>
        <DrawerOverlay name="people" />
        <div className="BulkActionsDrawer_Content">
          <NumberOfSelectedRecords
            numberOfRecords={selectedParticipantsIds.length}
          />
          <div className="BulkActionsButtonsGroup">
            <SplitButton
              disabled={selectedParticipantsIds.length < 1}
              onClick={handleDismissAllSelectedContacts}
              fillColor="blue"
            >
              <Icon component={TrashIcon} className="SplitButton_Icon DismissIcon" />
              Dismiss all selected people
            </SplitButton>
          </div>
          <div className="BulkActionsDrawer_Content_Filter">
            <UpdateCollection
              title="Spheres"
              fillColor="lightGrey"
              collectionType="spheres"
              showRemove={false}
              onSubmit={bulkUpdateSpheres}
              numberOfRecords={selectedParticipantsIds.length}
            />
          </div>
        </div>
      </>
    </Drawer>
  )
}

export default BulkActionsDrawer
