import React from "react"
import "./AttachmentsUpload.scss"
import { Button, notification, Upload } from "antd"
import Icon, { FileFilled } from "@ant-design/icons"
import XCircleIcon from "../../Icons/XCircle.icons"

const AttachmentsUpload = ({ fileList, handleFileList, disabled = false }) => {
  const uploadProps = {
    disabled: disabled,
    listType: "picture",
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      handleFileList(newFileList)
    },
    onChange: (event) => {
      setTimeout(() => {
        if (fileList.some((f) => f.status === "uploading")) {
          const updatedFiles = fileList.map((f) => {
            if (f.status === "uploading") {
              f.status = "done"
            }
            return f
          })
          handleFileList([...updatedFiles])
        }
      }, 1000)
    },
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (isLt2M) {
        file.status = "uploading"
        handleFileList([...fileList, file])
      } else {
        notification.error({
          message: "Attachment",
          description: "Attachment must be smaller than 2MB!",
          className: "Notification-error",
        })
      }

      return false
    },
    itemRender: (original, file, fileList, actions) => {
      return (
        <div className={"AttachmentsUpload_FileRender"}>
          <FileFilled />
          {file.name}{" "}
          <div
            className="AttachmentsUpload_FileRender_DeleteButton"
            onClick={() => {
              actions.remove(file)
            }}
          >
            <Icon component={XCircleIcon} />
          </div>
        </div>
      )
    },
    fileList,
  }

  return (
    <div className={"AttachmentsUpload_Wrapper"}>
      <Upload {...uploadProps}>
        <Button
          className={"AttachmentsUpload_UploadButton"}
          type={"link"}
          disabled={disabled}
        >
          Add attachment
        </Button>
      </Upload>
    </div>
  )
}
export default AttachmentsUpload
