import axios from "axios"

const getCustomLabels = async (field, callback) => {
  const { data } = await axios.request({
    url: `/v1/people/custom_labels?field=${field}`,
    method: "GET",
  })

  callback(data)
}

export default getCustomLabels
