import React, { useState } from "react"
import PropTypes from "prop-types"
import "./SectionNotes.styles.scss"
import Icon, { LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import NoteItem from "../../NoteItem/NoteItem.component"
import PlusInSquareIcons from "../../../../Icons/PlusInSquare.icons"
import { Pagination, Tooltip } from "antd"
import { openDrawerTab } from "../../../../../redux/App/App.actions"

const SectionNotes = ({
  contact,
  loadMore,
  editable = true,
  hideAddButton = false,
}) => {
  const [page, setPage] = useState(1)
  const loading = useSelector((state) => state.InteractionsState.fetching_notes)

  const dispatch = useDispatch()

  return (
    <section id="sectionNotes">
      <h3 className="SectionTitle">
        Notes on {contact.full_name}
        {!hideAddButton && (
          <Tooltip title="Add new note" placement="topRight">
            <Icon
              onClick={() => dispatch(openDrawerTab("note"))}
              component={PlusInSquareIcons}
            />
          </Tooltip>
        )}
      </h3>
      {loading ? (
        <div className="SectionNotes_Loading">
          <LoadingOutlined />
          <span>Loading...</span>
        </div>
      ) : contact.notes_total_entries > 0 ? (
        <div className="SectionNotes">
          {contact.notes.map((note) => (
            <NoteItem key={note.id} editable={editable} note={note} />
          ))}
          {contact.notes_total_entries > 3 && (
            <div className="Section_Pagination">
              <span>More Notes: </span>
              <Pagination
                showSizeChanger={false}
                current={page}
                onChange={(newPage) => {
                  loadMore(contact.id, newPage)
                  setPage(newPage)
                }}
                hideOnSinglePage={true}
                total={contact.notes_total_entries}
                defaultPageSize={3}
              />
            </div>
          )}
        </div>
      ) : (
        <span className="inactive">Notes have not been added</span>
      )}
    </section>
  )
}

SectionNotes.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    notes: PropTypes.array,
    notes_total_entries: PropTypes.number,
  }),
  loadMore: PropTypes.func,
  editable: PropTypes.bool,
  hideAddButton: PropTypes.bool,
}
export default SectionNotes
