import axios from "axios"
import * as _ from "lodash"

export const fetchForMentions = async (searchQuery, mode, resource = "people") => {
  if (resource === "people") {
    const { data } = await axios.request({
      url: "/v1/people/autocomplete",
      params: {
        q: searchQuery,
        mode: mode || "SEARCH",
      },
      method: "GET",
    })

    const participants = _.uniqBy(
      data.people.map((participant) => ({
        id: participant.value,
        name: participant.text,
      })),
      "id"
    )

    return participants
  } else if (resource === "topics") {
    const { data } = await axios.request({
      url:
        searchQuery && searchQuery.length > 0
          ? "/v1/collections/topics/autocomplete"
          : "v1/collections/topics/popular",
      params: {
        q: searchQuery,
      },
      method: "GET",
    })

    return data
  } else if (resource === "expertises") {
    const { data } = await axios.request({
      url:
        searchQuery && searchQuery.length > 0
          ? "/v1/collections/expertises/autocomplete"
          : "v1/collections/expertises/popular",
      params: {
        q: searchQuery,
      },
      method: "GET",
    })

    return data
  }
}
