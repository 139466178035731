import React from "react"
import { Link } from "react-router-dom"
import { Col, Row } from "antd"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"

const TermsPage = () => {
  const dispatch = useDispatch()

  return (
    <Row style={{ height: "100%", maxHeight: "100vh", overflow: "auto" }}>
      <Col span={12} offset={6} style={{ overflow: "auto" }}>
        <Link to="/">Back to Relatable</Link>
        <hr />
        <h1>Terms and Privacy</h1>
        <h5 className="c1" id="h.mqvhlmuu7yu6">
          <span className="c4">Terms of Service Last Revised: May 6th, 2020</span>
        </h5>
        <p className="c11">
          <span className="c5">
            These Terms of Service govern your use of the Relatable service (the
            Service) and you agree to these terms when you first create a user
            account with us.
          </span>
        </p>
        <h2 className="c12" id="h.kzvvrbf5z8rn">
          <span className="c6">Description of Services.</span>
        </h2>
        <p className="c20">
          <span className="c31">
            Relatable uses a variety of automated and machine-learning methods to
            analyze information you authorized the Service to access (Content) in
            your mobile device, email accounts, and in other online, cloud or mobile
            services (Authorized Sources). Thismeans that we will have access to, and
            will analyze, among other things, your full emails and other materials
            for purposes of helping identify additional contacts and optimizing your
            communications with those contacts. All analysis will be conducted
            consistent with our{" "}
          </span>
          <span className="c9">
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#1890ff",
                cursor: "pointer",
              }}
              className="c24"
              onClick={() => dispatch(push("/privacy"))}
            >
              Privacy Policy
            </button>
          </span>
          <span className="c5">
            , which you should review carefully. Further, until you instruct the
            Service otherwise, the Service will continuously update your Content from
            the Authorized Sources. We will process your Content and post the
            information in your designated customer relationship management (CRM)
            application.
          </span>
        </p>
        <h2 className="c12" id="h.rawkkbq92evu">
          <span className="c6">
            Your Responsibility for Authorized Sources and Your CRM.
          </span>
        </h2>
        <p className="c11">
          <span className="c5">
            You are responsible for obtaining and maintaining access to the
            Authorized Sources and your CRM. This means that you must ensure that
            your agreements with Authorized Sources and your CRM are up-to-date,
            maintain and update any authentication credentials or security patches,
            and pay any and all fees imposed by each Authorized Source (including
            Internet service provider or airtime charges, as well as any fees imposed
            by your carrier for data usage). In addition, you must provide and are
            responsible for maintaining and updating all equipment necessary to
            access the Service.
          </span>
        </p>
        <h2 className="c12" id="h.kqw5ah6oej87">
          <span className="c6">License and Service Access.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Relatable grants you a limited license to access and use the Service.
            This license does not permit you to, and you agree that you will not: (i)
            upload to or transmit through the Service any information that you do not
            have a right to make available (such as the intellectual property of
            another party or information that you are not legally permitted to use);
            (ii) upload to or transmit through the Service any material that contains
            a software virus or any other computer code, file or program designed to
            interrupt, destroy or limit the functionality of any computer software or
            hardware or telecommunications equipment; (iii) take any action that
            imposes or may impose (in Relatable&#39;s sole discretion) an
            unreasonable or disproportionately large load on Relatable&#39;s
            infrastructure; (iv) enter information or download information from the
            Service through any means other than direct user interaction with the
            Service; or (v) make any use of data mining, robots, or similar data
            gathering and extraction tools to submit Content to the Service.
            Relatable, in its sole discretion, may revoke all permissions and
            licenses to your use of the Service, at any time.
          </span>
        </p>
        <h2 className="c12" id="h.yg8jg88dl25g">
          <span className="c6">Your Representations and Warranties.</span>
        </h2>
        <p className="c20">
          <span className="c31">
            In consideration of your use of the Service, you represent that you are
            of legal age to form a binding contract and are not a person barred from
            receiving services under the laws of the United States or other
            applicable jurisdiction. You also agree to provide true, accurate,
            current and complete information about yourself as prompted if you use
            the Service&#39;s registration form (the Registration Data). If you
            provide any information that is untrue, inaccurate, not current or
            incomplete (or becomes untrue, inaccurate, not current or incomplete), or
            Relatable has reasonable grounds to suspect that such information is
            untrue, inaccurate, not current or incomplete, Relatable has the right to
            suspend or terminate your account and refuse any and all current or
            future use of the Service (or any portion thereof). If you use the
            Service, you are responsible for maintaining the confidentiality of your
            account and password, and for restricting access to the Authorized
            Sources, your computer(s) or mobile device(s), and your CRM. You
            acknowledge that you have responsibility for all activities that occur
            under your account or password. You agree to notify Relatable immediately
            of any unauthorized use of your account, Authorized Sources or any other
            breach of security, by emailing{" "}
          </span>
          <span className="c9">support@Relatable.one</span>
          <span className="c5">.</span>
        </p>
        <p className="c11">
          <span className="c5">
            You agree that your use of the Service, Content, Authorized Sources and
            CRM will comply with all applicable state, local, federal and
            international laws including, for instance, privacy laws and anti-spam
            laws in the United States, Canada and elsewhere.
          </span>
        </p>
        <h2 className="c12" id="h.122faegqto5s">
          <span className="c6">Terms Specifically Applicable to Content.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            The following terms apply specifically to Content:
          </span>
        </p>
        <h3 className="c18" id="h.4gti7ueew2cr">
          <span className="c15">License to Content:</span>
        </h3>
        <p className="c11">
          <span className="c5">
            With respect to your Content, including, but not limited to, data, text,
            messages, files, documents, images, photographs, videos, audiovisual
            works, musical compositions, sound recordings, postings, your and/or
            other persons&#39; names, likenesses, voices, screen names, profiles,
            actions, appearances, performances and/or other biographical information
            or material, and any other materials, you grant Relatable a perpetual,
            irrevocable, royalty-free, transferable right and license to use, copy,
            modify, delete in its entirety, adapt, publish, translate, create
            derivative works from and/or distribute such Content and/or incorporate
            such content into any form, medium or technology throughout the world,
            for purposes related to the Service and for Relatable&#39;s internal
            purposes, without the requirement of any permission from or compensation
            to you.
          </span>
        </p>
        <p className="c20">
          <span className="c31">
            None of the Content that you submit to the Service shall be subject to
            any obligation of confidence on the part of Relatable, its agents,
            subsidiaries, affiliates, partners, or third party service providers and
            their respective directors, officers, and employees. Relatable shall not
            be liable for any use or disclosure of any Content. Without limiting the
            foregoing, you hereby grant Relatable, without the requirement of any
            permission from or payment to you or to any other person or entity, a
            perpetual, irrevocable, transferable, fully-paid, royalty-free,
            non-exclusive, and sub-licensable worldwide license to use, re-use,
            reproduce, print, transmit, publish, exhibit, display, distribute,
            re-distribute, copy, host, cache, archive, store, index, categorize,
            stream, comment on, edit, alter, modify, adapt, translate, create
            derivative works based upon such Content, in whole or in part, but at all
            times consistent with our{" "}
          </span>
          <span className="c9">
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#1890ff",
                cursor: "pointer",
              }}
              className="c24"
              onClick={() => dispatch(push("/privacy"))}
            >
              Privacy Policy
            </button>
          </span>
          <span className="c5">
            , all without further notice to you and with or without attribution.
          </span>
        </p>
        <h3 className="c18" id="h.988krk1nd6uw">
          <span className="c15">Representations Regarding Content.</span>
        </h3>
        <p className="c11">
          <span className="c5">
            You agree that you are solely responsible for the Content you provide and
            for any use you make of such Content after it has been processed by
            Relatable. You expressly agree that Relatable has no obligation to review
            any Content you submit. Relatable repudiates any and all legal liability
            for opinions or other information included in any Content. Nevertheless,
            we reserve the right to reject any Content that is related to any of the
            following:
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            1. Illegal or fraudulent activity or any harm to others
          </span>
        </p>
        <ul className="c14 lst-kix_r1iegr53eyij-0 start">
          <li className="c26">
            <span className="c4">
              Content that is related to engaging in, promoting, or advocating any
              illegal activity or fraudulent schemes of any kind - including, but not
              limited to, money laundering, tax evasion, securities fraud, and
              pyramid or multi-level marketing schemes.
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              Content that you know is false, inaccurate, libelous, or otherwise
              misleading in any way.
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              Content you do not have the right to use or transmit under law (such as
              copyright, trade secret or securities) or due to your personal
              contractual or fiduciary relationships.
            </span>
          </li>
          <li className="c28">
            <span className="c4">
              Content that encourages conduct that would constitute a criminal
              offense, give rise to civil liability or otherwise violate any
              applicable local, state, national or international law.
            </span>
          </li>
        </ul>
        <p className="c11">
          <span className="c5">
            2. Profane, obscene, inappropriate, disruptive, and unrelated Content
          </span>
        </p>
        <ul className="c14 lst-kix_rgibqxh7pe4-0 start">
          <li className="c26">
            <span className="c4">
              Content that is related to profanity, threats, personal attacks,
              abusive, defamatory, derogatory, or inflammatory language, or stalking
              or harassment of any individual, entity or organization.
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              Content that is related to discriminatory or hateful speech of any kind
              regarding age, gender, race, religion, nationality, sexual orientation,
              or disability.
            </span>
          </li>
          <li className="c28">
            <span className="c4">
              Content that impersonates others or provides any kind of false
              information.
            </span>
          </li>
        </ul>
        <p className="c11">
          <span className="c5">
            3. Copyright and intellectual property violations
          </span>
        </p>
        <ul className="c14 lst-kix_3pqci39lp1rn-0 start">
          <li className="c26">
            <span className="c4">
              Content that is related to infringement of any party&#39;s copyright,
              patent, trademark, trade secret, intellectual property or other
              proprietary rights, or right of publicity or privacy.
            </span>
          </li>
          <li className="c28">
            <span className="c4">
              By providing the Service access to your Content, you warrant and
              represent that you are the copyright owner of the content or that the
              copyright owner of the content has granted you permission to use such
              content consistent with the manner and purpose of your use.
            </span>
          </li>
        </ul>
        <p className="c11">
          <span className="c5">4. Content Related to Children</span>
        </p>
        <p className="c11">
          <span className="c5">
            You expressly agree not to include in the Content any personally
            identifiable information about children under the age of 13. We reserve
            the right to reject and not process any personally identifiable
            information about children under the age of 13.
          </span>
        </p>
        <h3 className="c18" id="h.hwlal6el5ur1">
          <span className="c15">
            Acknowledgment of Where Content May Be Processed.
          </span>
        </h3>
        <p className="c11">
          <span className="c5">
            As part of the Service, the Content may be processed in a country other
            than the one in which you (or any data subject associated with such
            Content) reside. For example, if you are in Canada, your Content may be
            processed in the United States of America. You hereby grant consent for
            such processing and you represent and warrant that you are authorized to
            grant such consent on the part of any data subject associated with such
            Content.
          </span>
        </p>
        <h2 className="c12" id="h.b6xxul84vz6p">
          <span className="c6">Disclaimer of Warranty.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            EXCEPT AS OTHERWISE SPECIFIED IN A SEPARATE AGREEMENT BETWEEN YOU AND
            Relatable, Relatable MAKES NO WARRANTIES WITH RESPECT TO ANY PRODUCTS,
            CONTENT OR SERVICES PROVIDED. AS BETWEEN YOU AND Relatable, ALL PRODUCTS,
            CONTENT AND SERVICES ARE PROVIDED TO YOU AS IS WITHOUT WARRANTY OF ANY
            KIND EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, NON-INFRINGEMENT, ANTI-VIRUS, SECURITY OR ACCURACY.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            OTHER THAN AS REQUIRED UNDER APPLICABLE CONSUMER PROTECTION LAW, UNDER NO
            CIRCUMSTANCE WILL Relatable BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
            USER&#39;S RELIANCE ON INFORMATION OBTAINED THROUGH THE SITE, LINKED
            SITE, OR USER&#39;S RELIANCE ON ANY PRODUCT OR SERVICE. IT IS THE
            RESPONSIBILITY OF THE USER TO EVALUATE THE ACCURACY, COMPLETENESS OR
            USEFULNESS OF ANY OPINION, ADVICE OR OTHER INFORMATION OBTAINED FROM A
            LINKED SITE. PLEASE SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE,
            REGARDING THE EVALUATION OF ANY SPECIFIC OPINION, ADVICE, PRODUCT,
            SERVICE, OR OTHER INFORMATION.
          </span>
        </p>
        <h2 className="c12" id="h.eyswmc82pv9r">
          <span className="c6">Limitation of Liability.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT Relatable AND ITS SUBSIDIARIES,
            AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT
            BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
            DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
            LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
            RESULTING FROM USE OF THE SERVICE OR ANY RELATED SERVICES.
          </span>
        </p>
        <h2 className="c12" id="h.3wtze0xsfwa9">
          <span className="c6">Indemnity.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            You agree to indemnify and hold Relatable (and its officers, directors,
            agents, subsidiaries, joint ventures, and employees) harmless from any
            claim or demand, including reasonable attorneys&#39; fees, or arising out
            of or related to your breach of this Terms of Service, your violation of
            any law or the rights of a third party (including any data protection or
            anti-spam rights) or any claim or liability associated with your use of
            any Content we have processed as part of the Services.
          </span>
        </p>
        <h2 className="c12" id="h.tk1w67evzzc8">
          <span className="c6">Electronic Communication.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            When you use the Service or send emails to Relatable, you are
            communicating with Relatable electronically. You consent to receive
            communications from Relatable electronically. Relatable will communicate
            with you by email and by text message, when you have provide consent to
            receive such texts. You agree that all agreements, notices, disclosures
            and other communications that we provide to you electronically will
            satisfy any legal requirement that such communications be in writing.
          </span>
        </p>
        <h2 className="c12" id="h.105w2k8q3zcq">
          <span className="c6">Links.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            The Service may provide links to other World Wide Web sites or online
            resources. Because Relatable has no control over such sites and
            resources, you acknowledge and agree that Relatable is not responsible
            for the availability of such external sites or resources, and does not
            endorse and is not responsible or liable for any information,
            advertising, products or other materials on or available from such sites
            or resources. You further acknowledge and agree that Relatable shall not
            be responsible or liable, directly or indirectly, for any damage or loss
            caused or alleged to be caused by or in connection with use of or
            reliance on any such content, goods or services available on or through
            any such site or resource.
          </span>
        </p>
        <h2 className="c12" id="h.ukeg8w3sv7yo">
          <span className="c6">Access To Password Protected/Secure Areas.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Access to and use of password protected and/or secure areas of the
            Service is restricted to authorized users only. Unauthorized individuals
            attempting to access these areas of the Service may be subject to
            prosecution.
          </span>
        </p>
        <h2 className="c12" id="h.v1ukalhu7gkv">
          <span className="c6">
            Modification of Service, Terms of Service and Privacy Policy.
          </span>
        </h2>
        <p className="c11">
          <span className="c5">
            Relatable reserves the right to make changes to the Service, related
            policies and agreements, this Terms of Service and the Privacy Policy, at
            any time. When the changes are material, we will notify you by sending
            you an email with a link to the updated document. Using the Service
            following notification of the changes to the applicable document
            signifies your acceptance of those changes.
          </span>
        </p>
        <h2 className="c12" id="h.4re89kc4qwwu">
          <span className="c6">Termination of the Service.</span>
        </h2>
        <p className="c20">
          <span className="c31">
            If at any time, you wish to terminate your access to the Service, you may
            do so at any time by sending an email with the subject line Termination
            to the following email address:{" "}
          </span>
          <span className="c9">support@Relatable.one</span>
          <span className="c5">.</span>
        </p>
        <h2 className="c12" id="h.tkj91hrk71d5">
          <span className="c6">Trademarks.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Relatable, and other Relatable service names are trademarks, registered
            trademarks or trade dress of Relatable or its affiliates in the U.S.
            and/or other countries. Relatable&#39;s trademarks and trade dress may
            not be used in connection with any product or service other than those
            products or services offered by Relatable, in any manner that is likely
            to cause confusion among customers, or in any manner that disparages or
            discredits Relatable.
          </span>
        </p>
        <h2 className="c12" id="h.m3zctlvb6edy">
          <span className="c6">
            Procedure for Claims of Intellectual Property Infringement.
          </span>
        </h2>
        <p className="c11">
          <span className="c5">
            Relatable respects the intellectual property of others, and we ask our
            Users to do the same. Relatable may, in appropriate circumstances and at
            its discretion, disable and/or terminate the accounts of users who may be
            infringing the intellectual property of a third party. If you believe
            that your work has been copied in a way that constitutes copyright
            infringement, or your intellectual property rights have been otherwise
            violated, please provide Relatable&#39;s Copyright Agent the following
            information:
          </span>
        </p>
        <ul className="c14 lst-kix_fhk0lxejln8f-0 start">
          <li className="c26">
            <span className="c4">
              An electronic or physical signature of the person authorized to act on
              behalf of the owner of the copyright or other intellectual property
              interest;
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              A description of the copyrighted work or other intellectual property
              that you claim has been infringed;
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              A description of where the material that you claim is infringing is
              located on the Service; your address, telephone number, and email
              address;
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              A statement by you that you have a good faith belief that the disputed
              use is not authorized by the copyright owner, its agent, or the law;
            </span>
          </li>
          <li className="c28">
            <span className="c4">
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the copyright
              or intellectual property owner or authorized to act on the copyright or
              intellectual property owner&#39;s behalf.
            </span>
          </li>
        </ul>
        <p className="c11">
          <span className="c5">
            Relatable&#39;s agent for notice of claims of copyright or other
            intellectual property infringement can be reached as follows:
          </span>
        </p>
        <h5 className="c1" id="h.bspqk3oz6l6q">
          <span className="c4">By mail:</span>
        </h5>
        <p className="c11">
          <span className="c5">Copyright Agent</span>
        </p>
        <p className="c11">
          <span className="c5">RogerTen, LLC</span>
        </p>
        <p className="c11">
          <span className="c5">
            11820 Goya Drive
            <br />
            Potomac, MD 20854
          </span>
        </p>
        <h5 className="c1" id="h.8eb45mbyacm">
          <span className="c4">By phone:</span>
        </h5>
        <p className="c11">
          <span className="c5">202 683 7803</span>
        </p>
        <p className="c11">
          <span className="c5">
            Relatable may update this mailing address from time to time. You agree
            that changes to this mailing address shall not constitute a modification
            to this Terms of Service.
          </span>
        </p>
        <h2 className="c12" id="h.8ikgxlt1481k">
          <span className="c6">Survival of Terms After Agreement Ends.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Notwithstanding any other provisions of this Terms of Service, or any
            general legal principles to the contrary, any provision of this Terms of
            Service that imposes or contemplates continuing obligations on a party
            will survive the expiration or termination of this Terms of Service.
          </span>
        </p>
        <h2 className="c12" id="h.7u619to57xvt">
          <span className="c6">General.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            If any of these conditions are deemed invalid, void, or for any reason
            unenforceable, the parties agree that the court should endeavor to give
            effect to the parties&#39; intentions as reflected in the provision, and
            the unenforceable condition shall be deemed severable and shall not
            affect the validity and enforceability of any remaining condition.
            Headings are for reference purposes only and do not limit the scope or
            extent of such section. This Terms of Service and the relationship
            between you and Relatable will be governed by the laws of the State of
            Delaware without regard to its conflict of law provisions. You and
            Relatable agree to submit to the personal jurisdiction of the federal and
            state courts located in the State of Delaware with respect to any legal
            proceedings that may arise in connection with this Terms of Service. The
            failure of Relatable to act with respect to a breach by you or others
            does not waive its right to act with respect to subsequent or similar
            breaches. Relatable does not guarantee it will take action against all
            breaches of this Terms of Service. Except as otherwise expressly provided
            in this Terms of Service, there shall be no third-party beneficiaries to
            this Terms of Service. This Terms of Service constitutes the entire
            agreement between you and Relatable and governs your use of the Service,
            superseding any prior agreements between you and Relatable with respect
            to the Service.
          </span>
        </p>
        <h2 className="c12" id="h.pj81sb9t1x7j">
          <span className="c6">Security.</span>
        </h2>
        <p className="c20">
          <span className="c31">
            To secure information, Relatable follows ISO 27001 and uses NIST
            frameworks via{" "}
          </span>
          <span className="c9">
            <a
              className="c24"
              href="https://www.google.com/url?q=https://aws.amazon.com/compliance/&amp;sa=D&amp;ust=1588779521179000"
            >
              Amazon Web Service (AWS) cloud computing compliance
            </a>
          </span>
          <span className="c5">
            &nbsp;Relatable uses AWS for data storage, which is SSAE 16-compliant.
            When developing and maintaining the Relatable services, Relatable uses
            partners like Heroku, AWS, Elastic.co, all of which also use the AWS
            infrastructure. All of these providers use security best practices to
            ensure we have a Secure System Development Life Cycle process.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            Relatable transmits all data over industry-best-practice, secured TLS
            connections. We leverage AWS&#39;s scale for DDOS mitigation and monitor
            for intrusions and other security vulnerabilities with CloudWatch,
            CloudTrail and database errors via NewRelic. Relatable uses
            state-of-the-art encryption, SSL and HTTPS for all transmission of data
            to end-user services. No data is sent to third parties via our API or
            otherwise in unencrypted forms.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            Relatable is PCI compliant. Credit card numbers may be captured within
            the Relatable product, but are never sent to, or stored on,
            Relatable&#39;s servers.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            At any time, Relatable may replace any of these named services in this
            document with functionally-equivalent services.
          </span>
        </p>
        <h2 className="c12" id="h.rtl0ybwgqir4">
          <span className="c6">European Data Processing Addendum</span>
        </h2>
        <p className="c11">
          <span className="c5">
            This European Data Processing Addendum (&quot;Addendum&quot;) forms part
            of the Terms of Service (the &quot;Agreement&quot;) between Relatable,
            Inc. (&quot;Relatable&quot;) and reflects the parties&#39; agreement with
            regard to the processing of Personal Data in accordance with the
            requirements of the applicable Data Protection Legislation. This Addendum
            will be effective and will replace any previously applicable data
            processing and security terms relating to processing of Personal Data
            under the Agreement as of the Addendum Effective Date.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            The terms used in this Addendum shall have the meanings set forth in this
            Addendum. Capitalized terms not otherwise defined herein shall have the
            meaning given to them in the Agreement. Except as modified below, the
            terms of the Agreement shall remain in full force and effect.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            In consideration of the mutual obligations set out herein, the parties
            hereby agree that the terms and conditions set out below shall be added
            as an Addendum to the Agreement. Except where the context requires
            otherwise, references in this Addendum to the Agreement are to the
            Agreement as amended by, and including, this Addendum.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            This Addendum is dated the later of (i) the service activation date, or
            (ii) 25 May 2018 (&quot;Addendum Effective Date&quot;).
          </span>
        </p>
        <h2 className="c12" id="h.32datto03xhg">
          <span className="c6">I. DEFINITIONS AND INTERPRETATION</span>
        </h2>
        <ol className="c14 lst-kix_ecacedrqaxcm-0 start" start="1">
          <li className="c26">
            <span className="c4">
              The following terms shall have the following meanings:
            </span>
          </li>
        </ol>
        <ol className="c14 lst-kix_ecacedrqaxcm-1 start" start="1">
          <li className="c27">
            <span className="c4">
              &quot;Data Protection Legislation&quot; means all laws and regulations,
              including laws and regulations of the European Union, the European
              Economic Area, their member states and the United Kingdom which are
              applicable to the processing of Personal Data under this Agreement
              including but not limited to the EU General Data Protection Regulation
              (2016/679);
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              &quot;Data Controller&quot;, &quot;Data Processor&quot;, &quot;Data
              Subject&quot;, &quot;Personal Data&quot; and &quot;Subprocessor&quot;
              each have the meanings given to them in the Data Protection
              Legislation;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              &quot;Processing&quot; has the meaning set out in the Data Protection
              Legislation and &quot;process&quot; and &quot;processed&quot; shall be
              construed accordingly;
            </span>
          </li>
          <li className="c17">
            <span className="c4">
              &quot;Services&quot; means those services and other activities to be
              provided to or carried out by on behalf of Relatable for Customer by
              Relatable pursuant to the Agreement.
            </span>
          </li>
        </ol>
        <ol className="c14 lst-kix_ecacedrqaxcm-0" start="2">
          <li className="c8">
            <span className="c4">
              For the purpose of this Addendum, references to clauses shall be deemed
              to be references to the terms of this Addendum, unless otherwise stated
              or if the context otherwise requires.
            </span>
          </li>
          <li className="c28">
            <span className="c36">Applicability</span>
            <span className="c4">
              . This Addendum shall apply only to the extent Customer is established
              within the European Union, European Economic Area or the United Kingdom
              or Switzerland and/or Relatable processes Personal Data of Data
              Subjects located in the European Union, European Economic Area or the
              United Kingdom or Switzerland on behalf of Customer.
            </span>
          </li>
        </ol>
        <h2 className="c12" id="h.4shpcf4kkn2t">
          <span className="c6">II. DATA PROTECTION</span>
        </h2>
        <ol className="c14 lst-kix_gpwu0t83rod7-0 start" start="1">
          <li className="c26">
            <span className="c4">
              Both parties will comply with all applicable requirements of the Data
              Protection Legislation. This clause 2.1 is in addition to, and does not
              relieve, remove or replace, a party&#39;s obligations under the Data
              Protection Legislation.
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              The parties acknowledge that for the purposes of the Data Protection
              Legislation, Relatable is the Data Processor, and Customer is the Data
              Controller.
            </span>
          </li>
          <li className="c28">
            <span className="c4">
              The Customer agrees that Relatable, its affiliates and agents, may
              process Personal Data on behalf of Customer to perform its obligations
              for the term of this Agreement. The type of Personal Data and
              categories of Data Subjects are described below.
            </span>
          </li>
        </ol>
        <h3 className="c18" id="h.5hq1302mwutg">
          <span className="c15">2.3.1 Nature and Purpose of Processing</span>
        </h3>
        <p className="c11">
          <span className="c5">
            Relatable will process Personal Data as necessary to perform the Services
            pursuant to the Agreement and as further instructed by Customer in its
            use of the Services.
          </span>
        </p>
        <h3 className="c18" id="h.n0rgl0mpq5mx">
          <span className="c15">2.3.2 Duration of Processing</span>
        </h3>
        <p className="c11">
          <span className="c5">
            Subject to clause 2.4.6 of the Addendum, Relatable will process Personal
            Data for the duration of the Agreement, unless otherwise agreed upon in
            writing.
          </span>
        </p>
        <h3 className="c18" id="h.7pufisub1wlu">
          <span className="c15">2.3.3 Categories of Data Subjects</span>
        </h3>
        <h3 className="c2" id="h.sn1o1y5qu71c">
          <span className="c4">
            Customer may submit Personal Data to the Services, the extent of which is
            determined and controlled by Customer in its sole discretion, and which
            may include, but is not limited to Personal Data relating to the
            following categories of data subjects:
          </span>
        </h3>
        <ul className="c14 lst-kix_y26qaajjjr30-0 start">
          <li className="c10">
            <span className="c4">
              Prospects, customers, business partners and vendors of Customer (who
              are natural persons)
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              Employees or contact persons of Customer&#39;s prospects, customers,
              business partners and vendors
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              Employees, agents, advisors, freelancers of Customer (who are natural
              persons)
            </span>
          </li>
          <li className="c10">
            <span className="c4">Friends, family and relatives</span>
          </li>
          <li className="c10">
            <span className="c4">
              Customer&#39;s Users authorized by Customer to use the Services
            </span>
          </li>
        </ul>
        <h3 className="c18" id="h.fmmbiswbit7z">
          <span className="c15">2.3.4 Type of Personal Data</span>
        </h3>
        <h3 className="c2" id="h.kjj2nd3xh7y7">
          <span className="c4">
            Customer may submit Personal Data to the Services, the extent of which is
            determined and controlled by Customer in its sole discretion, and which
            may include, but is not limited to the following categories of Personal
            Data:
          </span>
        </h3>
        <ul className="c14 lst-kix_odi9dfe4h4h2-0 start">
          <li className="c10">
            <span className="c4">name</span>
          </li>
          <li className="c10">
            <span className="c4">employer</span>
          </li>
          <li className="c10">
            <span className="c4">birthdate</span>
          </li>
          <li className="c10">
            <span className="c4">business role</span>
          </li>
          <li className="c10">
            <span className="c4">professional title</span>
          </li>
          <li className="c10">
            <span className="c4">
              contact information (company, industry, email address, telephone
              number, and physical business address, website)
            </span>
          </li>
          <li className="c10">
            <span className="c4">localization data</span>
          </li>
          <li className="c10">
            <span className="c4">device identification data</span>
          </li>
          <li className="c10">
            <span className="c4">social media profiles</span>
          </li>
        </ul>
        <ol className="c14 lst-kix_k8u0bdhtx9c-0 start" start="1">
          <li className="c26">
            <span className="c4">
              Without prejudice to the generality of clause 2.1, Relatable shall, in
              relation to any Personal Data processed in connection with the
              performance by Relatable of its obligations under this Agreement:
            </span>
          </li>
        </ol>
        <ol className="c14 lst-kix_k8u0bdhtx9c-1 start" start="1">
          <li className="c27">
            <span className="c4">
              process that Personal Data only on the written instructions of Customer
              unless Relatable is required by the laws of any member state of the
              European Union or by the laws of the European Union applicable to
              Relatable to process Personal Data (&quot;Applicable Laws&quot;). Where
              Relatable is relying on laws of a member state of the European Union or
              European Union law as the basis for processing Personal Data, Relatable
              shall notify Customer of this before performing the processing required
              by the Applicable Laws unless those Applicable Laws prohibit Relatable
              from so notifying Customer;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              ensure that it has in place appropriate technical and organisational
              measures to protect against unauthorised or unlawful processing of
              Personal Data and against accidental loss or destruction of, or damage
              to, Personal Data (&quot;Personal Data Breach&quot;), appropriate to
              the harm that might result from the unauthorised or unlawful processing
              or accidental loss, destruction or damage and the nature of the data to
              be protected, having regard to the state of technological development
              and the cost of implementing any measures;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              ensure that all personnel who have access to and/or process Personal
              Data are obliged to keep the Personal Data confidential;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              assist Customer in responding to any request from a Data Subject and in
              ensuring compliance with its obligations under the Data Protection
              Legislation with respect to security, breach notifications, impact
              assessments and consultations with supervisory authorities or
              regulators provided that Relatable may charge Customer on a time and
              materials basis in the event that Relatable considers, in its
              reasonable discretion, that such assistance is onerous, complex,
              frequent, or time consuming;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              notify Customer without delay on becoming aware of a Personal Data
              Breach and shall provide further information about the Personal Data
              Breach to Relatable in phases as such information becomes available;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              at the written direction of Customer, delete or return Personal Data
              and copies thereof to Customer on termination of the Agreement unless
              required by Applicable Law to store the Personal Data;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              maintain records and information to demonstrate its compliance with
              this clause 2.4 and, at Customer&#39;s expense and subject to clause
              2.5, shall permit Customer, or its appointed third-party auditors
              (collectively, &quot;Auditor&quot;), to audit the architecture, systems
              and procedures relevant to Relatable&#39;s compliance with this
              Addendum and shall make available to the Auditor all information,
              systems and staff necessary for the Auditor to conduct such audit. To
              the extent any such audit incurs in excess of 20 hours of Relatable
              personnel time, Relatable may charge Customer on a time and materials
              basis for any such excess hours; and
            </span>
          </li>
          <li className="c17">
            <span className="c4">
              inform Customer immediately if it considers in its opinion that any of
              Customer&#39;s instructions infringe Data Protection Laws.
            </span>
          </li>
        </ol>
        <ol className="c14 lst-kix_k8u0bdhtx9c-0" start="2">
          <li className="c8">
            <span className="c4">
              Before the commencement of an audit described in clause 2.4, Relatable
              and Customer will mutually agree upon the reasonable scope, start date,
              duration of and security and confidentiality controls applicable to the
              audit. Customer agrees that:
            </span>
          </li>
        </ol>
        <ol className="c14 lst-kix_k8u0bdhtx9c-1 start" start="1">
          <li className="c27">
            <span className="c4">
              audits will be conducted during Relatable&#39;s normal business hours;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              it will not exercise its on-site audit rights more than once in any
              twelve (12) calendar months period, unless it has reasonable concerns
              as to Relatable&#39;s compliance with this Addendum or where it is
              required or requested to carry out an audit under Data Protection
              Legislation, or by a Supervisory Authority or any similar regulatory
              authority responsible for the enforcement of Data Protection
              Legislation in any country or territory;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              it will be responsible for any fees charged by any third party auditor
              appointed by Customer to execute any such audit;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              Relatable may object to any third-party auditor appointed by Customer
              to conduct an audit if the auditor is, in Relatable&#39;s opinion, not
              suitably qualified or independent, a competitor of Relatable or
              otherwise manifestly unsuitable. Any such objection by Relatable will
              require Customer to appoint another auditor or conduct the audit
              itself;
            </span>
          </li>
          <li className="c0">
            <span className="c4">
              nothing in this clause 2.5 will require Relatable either to disclose to
              the Auditor, or to allow the Auditor access to (a) any data processed
              by Relatable on behalf of any other organization, (b) any Relatable
              internal accounting or financial information, (c) any trade secret of
              Relatable, (d) any information that, in Relatable&#39;s opinion, could
              (i) compromise the security of any Relatable systems or premises, or
              (ii) cause Relatable to breach its obligations to Customer or any third
              party, or (e) any information that Customer seeks to access for any
              reason other than the good faith fulfilment of Customer&#39;s
              obligations under the Applicable Data Protection Law; and
            </span>
          </li>
          <li className="c17">
            <span className="c4">
              shall provide Relatable with copies of any audit reports completed by
              Customer&#39;s independent third-party auditors, which reports shall be
              subject to the confidentiality provisions of this Agreement.
            </span>
          </li>
        </ol>
        <ol className="c14 lst-kix_k8u0bdhtx9c-0" start="3">
          <li className="c8">
            <span className="c4">
              The Customer consents to the use of third-party processors by Relatable
              to process Personal Data on behalf of Customer in the performance of
              its obligations under this Agreement, and to provide certain services
              on behalf of Relatable, such as support services. Relatable confirms
              that it has entered or (as the case may be) will enter with the
              third-party processors into written agreements incorporating terms
              which are substantially similar to, and no less onerous than, those set
              out in this Addendum. Relatable shall inform Customer of any intended
              changes concerning the appointment or replacement of further
              third-party processors. The Customer may object to any new third-party
              processor by terminating the applicable subscription with respect only
              to those services which cannot be provided by Relatable without the use
              of the objected-to new third-party processor. Such termination will be
              made by providing written notice to Relatable, on the condition that
              Customer provides such notice within 14 days of being informed of the
              engagement of the new third-party processor as described in this clause
              2.6. This termination right is Customer&#39;s sole and exclusive remedy
              if Customer objects to any new third-party processor. As between
              Customer and Relatable, Relatable shall remain liable for the
              performance of obligations by any third-party processors appointed by
              it pursuant to this clause 2.6.
            </span>
          </li>
          <li className="c28">
            <span className="c4">
              The Customer acknowledges and agrees that Personal Data will be
              processed by Relatable outside of the European Union, European Economic
              Area or the United Kingdom or Switzerland including in the United
              States of America. Where Personal Data is transferred from the European
              Union, European Economic Area or the United Kingdom or Switzerland to a
              jurisdiction outside of the European Union, European Economic Area or
              the United Kingdom or Switzerland, Relatable will execute appropriate
              safeguards in relation to the transfer (unless appropriate safeguards
              have already been provided by Customer).
            </span>
          </li>
        </ol>
        <h2 className="c12" id="h.u2m6dkm9o6d">
          <span className="c6">III. General Terms</span>
        </h2>
        <ol className="c14 lst-kix_9u9s06e99hpc-0 start" start="1">
          <li className="c8">
            <span className="c22">Termination</span>
            <span className="c4">
              Subject to clause 3.2, the parties agree that this Addendum shall
              terminate automatically upon termination of the Agreement.
            </span>
          </li>
          <li className="c8">
            <span className="c4">
              Any obligation imposed on Relatable under this Addendum in relation to
              the processing of Personal Data shall survive any termination or
              expiration of this Addendum.
            </span>
          </li>
          <li className="c8">
            <span className="c22">Governing law of this Addendum</span>
            <span className="c4">
              This Addendum shall be governed by the governing law of the Agreement.
            </span>
          </li>
          <li className="c8">
            <span className="c22">Choice of jurisdiction</span>
            <span className="c4">
              The parties to this Addendum hereby submit to the choice of
              jurisdiction stipulated in the Agreement with respect to any disputes
              or claims howsoever arising under this Addendum.
            </span>
          </li>
          <li className="c8">
            <span className="c22">Order of precedence</span>
            <span className="c4">
              Nothing in this Addendum reduces Relatable&#39;s obligations under the
              Agreement in relation to the protection of Personal Data or permits
              Relatable to process (or permit the processing of) Personal Data in a
              manner which is prohibited by the Agreement. In the event of any
              inconsistency between this Addendum and any other agreements between
              the parties, including but not limited to the Agreement, the Addendum
              shall prevail.
            </span>
          </li>
          <li className="c28">
            <span className="c22">Severance</span>
            <span className="c4">
              Should any provision of this Addendum be invalid or unenforceable, then
              the remainder of this Addendum shall remain valid and in force. The
              invalid or unenforceable provision shall be either (i) amended as
              necessary to ensure its validity and enforceability, while preserving
              the parties&#39; intentions as closely as possible or, if this is not
              possible, (ii) construed in a manner as if the invalid or unenforceable
              part had never been contained therein.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c21" />
        </p>
      </Col>
    </Row>
  )
}

export default TermsPage
