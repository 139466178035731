import {
  CLEAR_REDUX_TRIGGERS_STATE,
  SET_INITIAL_REDUX_TRIGGERS_STATE,
} from "./Triggers.types"
import { LOGOUT_SUCCESS } from "../User/User.types"

const INITIAL_STATE = {}

export const triggersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_TRIGGERS_STATE:
      return {}
    case SET_INITIAL_REDUX_TRIGGERS_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
