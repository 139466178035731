import {
  ADD_INTRODUCTION,
  ADD_INTRODUCTION_FAIL,
  ADD_INTRODUCTION_SUCCESS,
  APPROVE_INTRODUCTION_SUCCESS,
  CLEAR_REDUX_INTRODUCTIONS_STATE,
  DELETE_INTRODUCTION_SUCCESS,
  FETCH_INTRODUCTIONS,
  FETCH_INTRODUCTIONS_FAIL,
  FETCH_INTRODUCTIONS_SUCCESS,
  SET_INITIAL_REDUX_INTRODUCTIONS_STATE,
} from "./Introductions.types"
import { DELETE_CONTACT_SUCCESS } from "../People/People.types"
import { LOGOUT_SUCCESS } from "../User/User.types"

const INITIAL_STATE = {
  awaiting: {
    introductions: [],
    total_entries: 0,
  },
  completed: {
    introductions: [],
    total_entries: 0,
  },
  adding: false,
  loading: false,
}

const approveIntroduction = (state, intro) => {
  if (intro.status === "COMPLETED") {
    const awaiting = state.awaiting.introductions.filter((i) => i.id !== intro.id)
    const completed = state.completed.introductions.filter((i) => i.id !== intro.id)
    return {
      ...state,
      awaiting: {
        ...state.awaiting,
        introductions: [...awaiting],
      },
      completed: {
        ...state.completed,
        introductions: [intro, ...completed],
      },
    }
  } else {
    const awaiting = state.awaiting.introductions.filter((i) => i.id !== intro.id)
    return {
      ...state,
      awaiting: {
        ...state.awaiting,
        introductions: [intro, ...awaiting],
      },
    }
  }
}

const addIntroduction = (state, intro) => {
  if (intro.status === "COMPLETED") {
    return {
      ...state,
      completed: {
        ...state.completed,
        introductions: [intro, ...state.completed.introductions],
      },
      adding: false,
    }
  } else {
    return {
      ...state,
      awaiting: {
        ...state.awaiting,
        introductions: [intro, ...state.awaiting.introductions],
      },
      adding: false,
    }
  }
}

const deleteIntroduction = (state, id) => {
  const awaiting = state.awaiting.introductions.filter((intro) => intro.id !== id)
  const completed = state.completed.introductions.filter((intro) => intro.id !== id)

  return {
    ...state,
    awaiting: {
      introductions: [...awaiting],
      total_entries: state.awaiting.total_entries - 1,
    },
    completed: {
      introductions: [...completed],
      total_entries: state.completed.total_entries - 1,
    },
  }
}

export const introductionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INTRODUCTIONS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_INTRODUCTIONS_SUCCESS:
      return {
        ...state,
        awaiting: {
          introductions: action.awaiting,
          total_entries: action.awaiting_total_entries,
        },
        completed: {
          introductions: action.approved,
          total_entries: action.approved_total_entries,
        },
        loading: false,
      }
    case FETCH_INTRODUCTIONS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case ADD_INTRODUCTION:
      return {
        ...state,
        adding: true,
      }
    case ADD_INTRODUCTION_SUCCESS:
      return addIntroduction(state, action.introduction)
    case ADD_INTRODUCTION_FAIL:
      return {
        ...state,
        adding: false,
      }
    case APPROVE_INTRODUCTION_SUCCESS:
      return approveIntroduction(state, action.introduction)
    case DELETE_INTRODUCTION_SUCCESS:
      return deleteIntroduction(state, action.introduction.id)
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        awaiting: {
          introductions: [
            ...state.awaiting.introductions.filter((intro) => {
              return !intro.participants.some(
                (participant) => participant.person_id === action.personId
              )
            }),
          ],
        },
        completed: {
          introductions: [
            ...state.completed.introductions.filter((intro) => {
              return !intro.participants.some(
                (participant) => participant.person_id === action.personId
              )
            }),
          ],
        },
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_INTRODUCTIONS_STATE:
      return {}
    case SET_INITIAL_REDUX_INTRODUCTIONS_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
