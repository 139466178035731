import React, { useEffect, useState } from "react"
import "./LocationFinder.styles.scss"
import axios from "axios"
import { AutoComplete, Input } from "antd"
import useDebounce from "../../../hooks/useDebounce"

const { Search } = Input

const LocationFinder = ({ value, onSelect, disabled = false }) => {
  const [query, setQuery] = useState("")
  const [initialValue, setInitialValue] = useState(value)

  const debouncedQuery = useDebounce(query, 200)
  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchCities = async (q) => {
    const { data } = await axios.request({
      url: "/v1/collections/locations/autocomplete.json",
      params: { q: q },
      method: "GET",
    })
    setCities(data.cities)
  }

  useEffect(() => {
    if (query && query.length > 2) {
      setLoading(true)
    }
  }, [query])

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 2) {
      fetchCities(debouncedQuery).then((r) => setLoading(false))
    }
  }, [debouncedQuery])

  return (
    <AutoComplete
      disabled={disabled}
      placeholder="Location"
      className="LocationFinder"
      value={initialValue || query}
      dropdownClassName="LocationFinder_Dropdown"
      dataSource={cities}
      onChange={(newValue) => {
        setInitialValue(null)
        setQuery(newValue)
      }}
      onSelect={onSelect}
    >
      <Search loading={loading} />
    </AutoComplete>
  )
}

export default LocationFinder
