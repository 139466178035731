import React, { useEffect } from "react"
import "./StayInTouchReminder.styles.scss"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchParticipants } from "../../../redux/Participants/Participants.actions"
import { ReactComponent as GuyWithPuzzles } from "../../../images/GuyWithPuzzles .svg"

const StayInTouchReminder = ({ showStayInTouchReminder = false }) => {
  const participants = useSelector((state) => state.ParticipantsState.participants)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchParticipants())
    // eslint-disable-next-line
  }, [])

  const numberOfParticipants = () => {
    if (participants && participants.length < 11) {
      return participants.length
    } else if (participants && participants.length > 10) {
      return "10+"
    } else {
      return 0
    }
  }

  return (
    <>
      {(showStayInTouchReminder || (participants && participants.length > 0)) && (
        <div className="StayInTouchReminder">
          <GuyWithPuzzles />
          <div className="StayInTouchReminder_Wrapper">
            <h2>
              We’re tracking {numberOfParticipants()} recent connections you’ve made
            </h2>
            <span>
              Putting them into spheres will help you remember and stay in touch with
              the right ones. Our Spheralizer makes it really simple to get
              organized.
            </span>
          </div>
          <Link to="/database/spheralizer" className="StayInTouchReminder_Button">
            Get Organized
          </Link>
        </div>
      )}
    </>
  )
}

export default StayInTouchReminder
