import axios from "axios"

const checkUniqEmailAndPhoneNumber = async (person_id, values, callback) => {
  const { data } = await axios.request({
    url: "/v1/people/check_uniq_email_and_phone_number",
    params: {
      email_addresses: values.email_addresses.map((email) => email.value),
      phone_numbers: values.phone_numbers.map((phone) => phone.value),
      person_id: person_id,
    },
    method: "GET",
  })

  callback(data)
}

export default checkUniqEmailAndPhoneNumber
