import React from "react"

const KeyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    width="24px"
    height="24px"
  >
    <path
      fill="#f5ce85"
      d="M2.566,71.726l2.941-9.535L28.89,38.809l-0.829-0.83c-0.932-0.931-1.444-2.168-1.444-3.485 s0.513-2.554,1.443-3.485l1.754-1.754l-0.025-0.238c-0.098-0.907-0.146-1.754-0.146-2.588C29.643,13.234,40.377,2.5,53.571,2.5 c0.225,0,0.452,0.003,0.679,0.01c12.47,0.344,22.896,10.77,23.24,23.239c0.181,6.525-2.222,12.691-6.765,17.362 c-4.545,4.673-10.637,7.246-17.154,7.246c-0.838,0-1.684-0.047-2.588-0.145l-0.238-0.026l-1.754,1.754 c-0.931,0.931-2.168,1.444-3.484,1.444s-2.555-0.513-3.485-1.443l-0.83-0.83l-4.39,4.39H29.5v7.301L26.802,65.5H19.5v7.301 l-1.71,1.71l-9.514,2.922L2.566,71.726z M53.941,11.5c-3.89,0-7.545,1.514-10.295,4.264l-0.354,0.354l20.589,20.589l0.354-0.354 c5.677-5.676,5.677-14.913,0-20.589C61.485,13.014,57.83,11.5,53.941,11.5z"
    />
    <path
      fill="#967a44"
      d="M53.571,3c0.22,0,0.443,0.003,0.664,0.009c12.209,0.337,22.417,10.545,22.755,22.754 c0.177,6.389-2.175,12.426-6.624,17c-4.45,4.575-10.415,7.095-16.796,7.095c-0.816,0-1.645-0.047-2.535-0.142l-0.476-0.051 l-0.338,0.338l-1.584,1.584c-0.836,0.836-1.949,1.297-3.131,1.297c-1.183,0-2.295-0.461-3.131-1.297l-0.477-0.477l-0.707-0.707 l-0.707,0.707L36.594,55H30h-1v1v6.594L26.594,65H20h-1v1v6.594l-1.476,1.476l-9.107,2.797l-5.284-5.282l2.815-9.129L28.89,39.516 l0.707-0.707l-0.707-0.707l-0.476-0.476c-0.837-0.836-1.297-1.949-1.297-3.132c0-1.183,0.461-2.295,1.297-3.132l1.585-1.585 l0.338-0.338l-0.051-0.476c-0.096-0.89-0.142-1.72-0.142-2.535C30.143,13.51,40.653,3,53.571,3 M63.882,37.414l0.707-0.707 C67.434,33.863,69,30.081,69,26.059s-1.566-7.804-4.411-10.648C61.745,12.566,57.963,11,53.941,11s-7.804,1.566-10.648,4.411 l-0.707,0.707l0.707,0.707l19.882,19.882L63.882,37.414 M53.571,2C40.079,2,29.143,12.937,29.143,26.429 c0,0.893,0.055,1.773,0.148,2.642l-1.585,1.585c-2.12,2.12-2.12,5.558,0,7.677l0.476,0.476L5.066,61.925L2,71.867L8.136,78 l9.92-3.047L20,73.008V66h7.008L30,63.008V56h7.008l4.183-4.183l0.477,0.477c1.06,1.06,2.449,1.59,3.838,1.59 c1.389,0,2.779-0.53,3.839-1.59l1.584-1.584c0.869,0.094,1.749,0.148,2.642,0.148c13.722,0,24.801-11.314,24.419-25.122 C77.637,12.988,67.011,2.362,54.263,2.01C54.033,2.003,53.801,2,53.571,2L53.571,2z M63.882,36L44,16.118 C46.745,13.373,50.343,12,53.941,12s7.196,1.373,9.941,4.118C69.373,21.608,69.373,30.51,63.882,36L63.882,36z"
    />
    <path
      fill="#dbb065"
      d="M6.613 54.398H38.162V55.398H6.613z"
      transform="rotate(-45.001 22.387 54.899)"
    />
    <path
      fill="#967a44"
      d="M34.553 35.769H35.553V54.143H34.553z"
      transform="rotate(-45.001 35.053 44.956)"
    />
  </svg>
)

export default KeyIcon
