import axios from "axios"
import * as _ from "lodash"

export const fetchForMentions = async (searchQuery, mode) => {
  const { data } = await axios.request({
    url: "/v1/people/autocomplete",
    params: {
      q: searchQuery,
      mode: mode || "SEARCH",
    },
    method: "GET",
  })

  const participants = _.uniqBy(
    data.people.map((participant) => ({
      id: participant.value,
      name: participant.text,
      first_name: participant.first_name,
      last_name: participant.last_name,
      description: participant.description,
      emails: participant.emails,
      last_used_email: participant.last_used_email,
    })),
    "id"
  )

  return participants
}
