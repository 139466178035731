import {
  ADD_ACCOUNT,
  ADD_ACCOUNT_FAIL,
  ADD_ACCOUNT_SUCCESS,
  CLEAR_REDUX_ACCOUNTS_STATE,
  CREATE_STRIPE_SESSION,
  CREATE_STRIPE_SESSION_FAIL,
  CREATE_STRIPE_SESSION_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_FAIL,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_CALENDARS,
  FETCH_CALENDARS_FAIL,
  FETCH_CALENDARS_SUCCESS,
  MAKE_DEFAULT,
  MAKE_DEFAULT_FAIL,
  MAKE_DEFAULT_SUCCESS,
  RESYNC_INTERACTIONS,
  RESYNC_INTERACTIONS_FAIL,
  RESYNC_INTERACTIONS_START,
  RESYNC_INTERACTIONS_SUCCESS,
  SET_INITIAL_REDUX_ACCOUNTS_STATE,
  SHOW_COPIED_API_KEY_NOTIFICATION,
  SHOW_UNSUBSCRIBE_NOTIFICATION,
  UPDATE_CRITERIA,
  UPDATE_CRITERIA_FAIL,
  UPDATE_CRITERIA_SUCCESS,
  UPDATE_SIGNATURE,
  UPDATE_SIGNATURE_FAIL,
  UPDATE_SIGNATURE_SUCCESS,
} from "./Accounts.types"

export const fetchAccounts = () => {
  return {
    type: FETCH_ACCOUNTS,
  }
}

export const fetchAccountsSuccess = (
  accounts,
  services,
  split_messages_left_today,
  is_user_active
) => {
  return {
    type: FETCH_ACCOUNTS_SUCCESS,
    accounts: accounts,
    services: services,
    split_messages_left_today: split_messages_left_today,
    is_user_active: is_user_active,
  }
}

export const fetchAccountsFail = (error) => {
  return {
    type: FETCH_ACCOUNTS_FAIL,
    error: error,
  }
}

export const addAccount = (nylas_code, nylas_state) => {
  return {
    type: ADD_ACCOUNT,
    code: nylas_code,
    state: nylas_state,
  }
}

export const addAccountSuccess = (account, code) => {
  return {
    type: ADD_ACCOUNT_SUCCESS,
    account: account,
    code: code,
  }
}

export const addAccountFail = (error) => {
  return {
    type: ADD_ACCOUNT_FAIL,
    error: error,
  }
}

export const makeDefault = (id) => {
  return {
    type: MAKE_DEFAULT,
    id: id,
  }
}

export const makeDefaultSuccess = (account) => {
  return {
    type: MAKE_DEFAULT_SUCCESS,
    account: account,
  }
}

export const makeDefaultFail = (error) => {
  return {
    type: MAKE_DEFAULT_FAIL,
    error: error,
  }
}

export const deleteAccount = (account_id) => {
  return {
    type: DELETE_ACCOUNT,
    id: account_id,
  }
}

export const deleteAccountSuccess = (account_id) => {
  return {
    type: DELETE_ACCOUNT_SUCCESS,
    id: account_id,
  }
}

export const deleteAccountFail = (error) => {
  return {
    type: DELETE_ACCOUNT_FAIL,
    error: error,
  }
}

export const resyncInteractions = (grant_id, initialSync = false) => {
  return {
    type: RESYNC_INTERACTIONS,
    id: grant_id,
    initialSync: initialSync,
  }
}

export const resyncInteractionsStart = () => {
  return {
    type: RESYNC_INTERACTIONS_START,
  }
}

export const resyncInteractionsSuccess = () => {
  return {
    type: RESYNC_INTERACTIONS_SUCCESS,
  }
}

export const resyncInteractionsFail = (error) => {
  return {
    type: RESYNC_INTERACTIONS_FAIL,
    error: error,
  }
}

export const updateSignature = (account_id, signature) => {
  return {
    type: UPDATE_SIGNATURE,
    account_id: account_id,
    signature: signature,
  }
}

export const updateSignatureSuccess = (account_id, signature) => {
  return {
    type: UPDATE_SIGNATURE_SUCCESS,
    account_id: account_id,
    signature: signature,
  }
}

export const updateSignatureFail = (error) => {
  return {
    type: UPDATE_SIGNATURE_FAIL,
    error: error,
  }
}

export const updateCriteria = (account_id, criteria) => {
  return {
    type: UPDATE_CRITERIA,
    account_id: account_id,
    criteria: criteria,
  }
}

export const updateCriteriaSuccess = (account_id, criteria) => {
  return {
    type: UPDATE_CRITERIA_SUCCESS,
    account_id: account_id,
    criteria: criteria,
  }
}

export const updateCriteriaFail = (error) => {
  return {
    type: UPDATE_CRITERIA_FAIL,
    error: error,
  }
}

export const clearReduxAccountsState = () => {
  return {
    type: CLEAR_REDUX_ACCOUNTS_STATE,
  }
}

export const setInitialReduxAccountsState = () => {
  return {
    type: SET_INITIAL_REDUX_ACCOUNTS_STATE,
  }
}

export const fetchCalendars = (accountId) => {
  return {
    type: FETCH_CALENDARS,
    account_id: accountId,
  }
}

export const fetchCalendarsSuccess = (accountId, calendars) => {
  return {
    type: FETCH_CALENDARS_SUCCESS,
    account_id: accountId,
    calendars: calendars,
  }
}

export const fetchCalendarsFail = (error) => {
  return {
    type: FETCH_CALENDARS_FAIL,
    error: error,
  }
}

export const createStripeSession = () => {
  return {
    type: CREATE_STRIPE_SESSION,
  }
}

export const createStripeSessionSuccess = (session_url) => {
  return {
    type: CREATE_STRIPE_SESSION_SUCCESS,
    session_url: session_url,
  }
}

export const createStripeSessionFail = (error) => {
  return {
    type: CREATE_STRIPE_SESSION_FAIL,
    error: error,
  }
}

export const showUnsubscribeNotification = () => {
  return {
    type: SHOW_UNSUBSCRIBE_NOTIFICATION,
  }
}

export const showCopiedAPIKeyNotification = () => {
  return {
    type: SHOW_COPIED_API_KEY_NOTIFICATION,
  }
}
