import React, { useEffect, useRef, useState } from "react"
import * as _ from "lodash"
import "./CollectionSelector.styles.scss"
import { Select, Spin } from "antd"
import { useSelector } from "react-redux"
import ConfirmPopup from "./ConfirmPopup.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const { Option } = Select

const CollectionSelector = ({
  submitMode,
  collectionType,
  collectionTitle,
  currentElements = [],
  handleSubmit,
  disabled = false,
}) => {
  const select = useRef(null)

  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const topics = useSelector((state) => state.CollectionsState.topics)
  const companies = useSelector((state) => state.CollectionsState.companies)
  const expertises = useSelector((state) => state.CollectionsState.expertises)

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false)
  const [collection, setCollection] = useState(null)
  const [options, setOptions] = useState(null)
  const [onBlurDisable, setOnBlurDisable] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [existingElements, setExistingElements] = useState([])

  const [selected, setSelected] = useState([])
  const [newElements, setNewElements] = useState([])

  useEffect(() => {
    switch (collectionType) {
      case "spheres":
        setCollection(spheres)
        break
      case "topics":
        setCollection(topics)
        break
      case "companies":
        setCollection(companies)
        break
      case "expertises":
        setCollection(expertises)
        break
      default:
        break
    }
  }, [collectionType, spheres, topics, companies, expertises])

  useEffect(() => {
    if (currentElements) {
      const selected = currentElements.map((element) => element.title)
      setSelected(selected)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (collection) {
      populateOptions()
    }
    // eslint-disable-next-line
  }, [collection])

  useEffect(() => {
    if (collection) {
      setExistingElements(
        collection.filter((element) => selected.includes(element.title))
      )
    }
    // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    setNewElements(
      selected.filter(
        (element) =>
          !existingElements.map((element) => element.title).includes(element)
      )
    )
    // eslint-disable-next-line
  }, [existingElements])

  useEffect(() => {
    if (submitMode !== "onClick") {
      handleSubmit(existingElements, newElements)
    }
    // eslint-disable-next-line
  }, [newElements])

  const populateOptions = () => {
    setOptions(
      collection.map((element) => (
        <Option key={element.id} value={element.title}>
          {element.title}
        </Option>
      ))
    )
  }

  return !collection ? (
    <RelatableLoader />
  ) : (
    <div>
      {submitMode === "onClick" && (
        <ConfirmPopup
          open={openConfirmPopup}
          goBack={() => setOpenConfirmPopup(false)}
          cancelChanges={() => {
            setOpenConfirmPopup(false)
            setDropdownOpen(false)
            setSelected([])
          }}
        />
      )}
      <Select
        open={dropdownOpen}
        onDropdownVisibleChange={(open) => {
          if (!onBlurDisable) {
            if (submitMode === "onClick") {
              if (selected.length > 0) {
                setOpenConfirmPopup(true)
              } else {
                setDropdownOpen(open)
              }
            } else {
              setDropdownOpen(open)
            }
          }
        }}
        disabled={disabled}
        bordered={false}
        mode={"tags"}
        ref={select}
        allowClear={true}
        value={selected}
        className="CollectionSelector"
        listHeight={200}
        placeholder={collectionTitle || _.capitalize(collectionType.toString())}
        onChange={(collection) => {
          setSelected(collection)
        }}
        dropdownClassName="CollectionSelector_Dropdown"
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) => (
          <div>
            <div>{menu}</div>
            {submitMode === "onClick" && (
              <div
                className={"CollectionSelector_Dropdown_SubmitButton"}
                onMouseEnter={() => setOnBlurDisable(true)}
                onMouseLeave={() => setOnBlurDisable(false)}
                onClick={() => {
                  handleSubmit(existingElements, newElements)
                  setSelected([])
                  setOnBlurDisable(false)
                  setDropdownOpen(false)
                }}
              >
                Done
              </div>
            )}
          </div>
        )}
      >
        {options}
      </Select>
    </div>
  )
}

export default CollectionSelector
