import {
  ADD_EXTRA_TRIAL_DAYS,
  ADD_EXTRA_TRIAL_DAYS_FAIL,
  ADD_EXTRA_TRIAL_DAYS_SUCCESS,
  BACK_TO_ADMIN,
  BACK_TO_ADMIN_FAIL,
  BACK_TO_ADMIN_SUCCESS,
  CLEAR_REDUX_ADMIN_STATE,
  CREATE_CHALLENGE,
  CREATE_CHALLENGE_FAIL,
  CREATE_CHALLENGE_SUCCESS,
  FETCH_CHALLENGES,
  FETCH_CHALLENGES_FAIL,
  FETCH_CHALLENGES_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_FAIL,
  FETCH_USERS_SUCCESS,
  SEND_WARNING_EMAIL,
  SEND_WARNING_EMAIL_SUCCESS,
  SET_AS_FREE_ACCOUNT,
  SET_AS_FREE_ACCOUNT_FAIL,
  SET_AS_FREE_ACCOUNT_SUCCESS,
  SET_INITIAL_REDUX_ADMIN_STATE,
  TAKE_SESSION,
  TAKE_SESSION_FAIL,
  TAKE_SESSION_SUCCESS,
  UPDATE_CHALLENGE,
  UPDATE_CHALLENGE_FAIL,
  UPDATE_CHALLENGE_SUCCESS,
  WIPE_ACCOUNTS,
  WIPE_ACCOUNTS_FAIL,
  WIPE_ACCOUNTS_SUCCESS,
} from "./Admin.types"

export const fetchUsers = (page, per_page, query, filters, order, field) => {
  return {
    type: FETCH_USERS,
    page: page,
    per_page: per_page,
    query: query,
    filters: filters,
    order: order,
    sort_field: field,
  }
}

export const fetchUsersSuccess = (users, total_entries) => {
  return {
    type: FETCH_USERS_SUCCESS,
    users: users,
    total_entries: total_entries,
  }
}

export const fetchUsersFail = (error) => {
  return {
    type: FETCH_USERS_FAIL,
    error: error,
  }
}

export const takeSession = (email, admin_refresh_token) => {
  return {
    type: TAKE_SESSION,
    email,
    admin_refresh_token,
  }
}

export const takeSessionSuccess = (admin_refresh_token, is_impersonating) => {
  return {
    type: TAKE_SESSION_SUCCESS,
    admin_refresh_token,
    is_impersonating,
  }
}

export const takeSessionFail = (error) => {
  return {
    type: TAKE_SESSION_FAIL,
    error: error,
  }
}

export const backToAdmin = (admin_refresh_token) => {
  return {
    type: BACK_TO_ADMIN,
    admin_refresh_token,
  }
}

export const backToAdminSuccess = () => {
  return {
    type: BACK_TO_ADMIN_SUCCESS,
  }
}

export const backToAdminFail = (error) => {
  return {
    type: BACK_TO_ADMIN_FAIL,
    error: error,
  }
}

export const clearReduxAdminState = () => {
  return {
    type: CLEAR_REDUX_ADMIN_STATE,
  }
}

export const setInitialReduxAdminState = () => {
  return {
    type: SET_INITIAL_REDUX_ADMIN_STATE,
  }
}

export const setAsFreeAccount = (id) => {
  return {
    type: SET_AS_FREE_ACCOUNT,
    id,
  }
}

export const setAsFreeAccountSuccess = () => {
  return {
    type: SET_AS_FREE_ACCOUNT_SUCCESS,
  }
}

export const setAsFreeAccountFail = (error) => {
  return {
    type: SET_AS_FREE_ACCOUNT_FAIL,
    error: error,
  }
}

export const addExtraTrialDays = (user_id, number_of_days) => {
  return {
    type: ADD_EXTRA_TRIAL_DAYS,
    user_id,
    number_of_days,
  }
}

export const addExtraTrialDaysSuccess = (
  user_id,
  trial_days_remaining,
  user_status
) => {
  return {
    type: ADD_EXTRA_TRIAL_DAYS_SUCCESS,
    user_id,
    trial_days_remaining,
    user_status,
  }
}

export const addExtraTrialDaysFail = (error) => {
  return {
    type: ADD_EXTRA_TRIAL_DAYS_FAIL,
    error: error,
  }
}

export const fetchChallenges = () => {
  return {
    type: FETCH_CHALLENGES,
  }
}

export const fetchChallengesSuccess = (challenges) => {
  return {
    type: FETCH_CHALLENGES_SUCCESS,
    challenges: challenges,
  }
}

export const fetchChallengesFail = (error) => {
  return {
    type: FETCH_CHALLENGES_FAIL,
    error: error,
  }
}

export const createChallenge = (challenge) => {
  return {
    type: CREATE_CHALLENGE,
    challenge: challenge,
  }
}

export const createChallengeSuccess = (challenge) => {
  return {
    type: CREATE_CHALLENGE_SUCCESS,
    challenge: challenge,
  }
}

export const createChallengeFail = (error) => {
  return {
    type: CREATE_CHALLENGE_FAIL,
    error: error,
  }
}

export const updateChallenge = (id, challenge) => {
  return {
    type: UPDATE_CHALLENGE,
    id: id,
    challenge: challenge,
  }
}

export const updateChallengeSuccess = (challenge) => {
  return {
    type: UPDATE_CHALLENGE_SUCCESS,
    challenge: challenge,
  }
}

export const updateChallengeFail = (error) => {
  return {
    type: UPDATE_CHALLENGE_FAIL,
    error: error,
  }
}

export const wipeAccounts = (ids) => {
  return {
    type: WIPE_ACCOUNTS,
    ids,
  }
}

export const wipeAccountsSuccess = () => {
  return {
    type: WIPE_ACCOUNTS_SUCCESS,
  }
}

export const wipeAccountsFail = (error) => {
  return {
    type: WIPE_ACCOUNTS_FAIL,
    error: error,
  }
}

export const sendWarningEmail = (ids) => {
  return {
    type: SEND_WARNING_EMAIL,
    ids,
  }
}

export const sendWarningEmailSuccess = () => {
  return {
    type: SEND_WARNING_EMAIL_SUCCESS,
  }
}

export const sendWarningEmailFail = (error) => {
  let SEND_WARNING_EMAIL_FAIL
  return {
    type: SEND_WARNING_EMAIL_FAIL,
    error: error,
  }
}
