import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./ContactImageInput.styles.scss"
import { useSelector } from "react-redux"
import { useDropzone } from "react-dropzone"
import { Spin } from "antd"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const ContactImageInput = ({
  image,
  setImage,
  contact,
  disabled,
  handleUpdateImage,
}) => {
  const updating = useSelector((state) => state.PeopleState.updatingImage)
  const [offset, setOffset] = useState(0)

  const onScroll = (e) => {
    setOffset(e.currentTarget.scrollTop)
  }

  useEffect(() => {
    const container = document.getElementById("ContactDrawerSections")

    // clean up code
    container.removeEventListener("scroll", onScroll)
    container.addEventListener("scroll", onScroll, { passive: true })
    return () => container.removeEventListener("scroll", onScroll)
  }, [])

  const getContactSourceMessage = () => {
    switch (contact.source) {
      case "csv":
        return "Imported from CSV file."
      case "manual":
        return "Manually added contact"
      default:
        return contact.source && contact.source !== ""
          ? `Contact from ${contact.source} account`
          : null
    }
  }

  const onDrop = (files) => {
    if (!contact) {
      setImage(
        ...files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    } else {
      handleUpdateImage(contact?.id, ...files)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const onImageLoad = (e) => {
    e.currentTarget.classList.add("animate-avatar")
  }

  return (
    <div
      className={`ContactImageInput ${
        disabled ? "" : "ContactImageInput--enabled"
      } ${contact?.profile_image_url || image ? "" : "ContactImageInput--blank"}`}
    >
      <div {...getRootProps()}>
        {updating && <RelatableLoader />}
        <input {...getInputProps()} disabled={disabled} />
        {image ? (
          <img onLoad={onImageLoad} src={image.preview} alt={"profile_image"} />
        ) : (
          <img
            style={{
              transform: "scale(1)",
              top: `${contact?.profile_image_url ? `-${offset * 0.1}px` : ""}`,
            }}
            onLoad={onImageLoad}
            className={
              contact?.profile_image_url ? "" : `profile_variation${contact?.id % 4}`
            }
            src={
              contact && contact.profile_image_url
                ? `${contact.profile_image_url}`
                : `/avatars/${contact?.id % 20}.png`
            }
            alt={"profile_image"}
          />
        )}
      </div>
      {contact && contact.source && <span>{getContactSourceMessage()}</span>}
    </div>
  )
}

ContactImageInput.propTypes = {
  image: PropTypes.any,
  setImage: PropTypes.func,
  contact: PropTypes.object,
  disabled: PropTypes.bool,
}

ContactImageInput.defaultProps = {
  disabled: false,
}

export default ContactImageInput
