import React, { useEffect, useState } from "react"
import { Collapse, Modal, Spin, Switch } from "antd"
import Icon from "@ant-design/icons"
import CancelIcon from "../../../Icons/Cancel.icons"
import "./LinkedinModal.styles.scss"
import PropTypes from "prop-types"
import {
  connectLinkedin,
  disconnectLinkedin,
  getLinkedinStatus,
  saveSynced,
  syncLinkedin,
} from "../../Account.actions"
import CollapsePanel from "antd/es/collapse/CollapsePanel"
import SettingsIcon from "../../../Icons/Settings.icons"
import RelatableLoader from "../../../UI/RelatableLoader/RelatableLoader.component"

const LinkedinModal = ({ visible, onClose, userId }) => {
  const [extensionDetected, setExtensionDetected] = useState(false)
  const [linkedinStatus, setLinkedinStatus] = useState("not_connected")
  const [linkedinSynced, setLinkedinSynced] = useState(false)
  const [loading, setLoading] = useState(true)
  const [runtimeId, setRuntimeId] = useState(null)

  const getCookie = (name) =>
    document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2)
  const setCookie = (c_name, value, exdays) => {
    const exdate = new Date()
    exdate.setDate(exdate.getDate() + exdays)
    const c_value = `${value}${
      exdays == null ? "" : "; expires=" + exdate.toUTCString() + "; path=/"
    }`
    document.cookie = c_name + "=" + c_value
  }

  useEffect(() => {
    async function checkExtension() {
      try {
        const runtimeId = getCookie(`${process.env.REACT_APP_ENV}_li_ext_id`)
        setRuntimeId(runtimeId)
        window.chrome.runtime.sendMessage(
          runtimeId,
          { message: "version" },
          function (reply) {
            if (reply) {
              if (reply.version) {
                if (reply.version >= 1) {
                  setExtensionDetected(true)
                }
              }
            } else {
              setExtensionDetected(false)
            }
          }
        )
        // Extension is installed
      } catch (error) {
        // Extension is not installed or not responding
        setExtensionDetected(false)
      }
    }

    checkExtension().then((r) => handleCheckStatus())
  }, [])

  const handleCheckStatus = () => {
    getLinkedinStatus((response) => {
      setLinkedinStatus(response.status)
      setLinkedinSynced(response.synced)
      setLoading(false)
    })
  }
  const handleSyncLinkedin = (token, status, cookie) => {
    syncLinkedin(token, status, cookie, (response) => {
      setLoading(false)
      setLinkedinStatus(status)
    })
  }

  const handleDisconnectLinkedin = () => {
    setLoading(true)
    disconnectLinkedin((response) => {
      setLoading(false)
      setLinkedinStatus(response.status)
      try {
        window.chrome.runtime.sendMessage(
          runtimeId,
          { message: "terminate_connection" },
          function (reply) {
            console.log(reply)
          }
        )
        // Extension is installed
      } catch (error) {
        // Extension is not installed or not responding
      }
    })
  }

  const handleConnectLinkedin = () => {
    setLoading(true)
    connectLinkedin((response) => {
      try {
        window.chrome.runtime.sendMessage(
          runtimeId,
          { message: "establish_connection", token: response.token },
          function (reply) {
            const status =
              reply.cookie && reply.cookie.some((c) => c.name === "li_at")
                ? "running"
                : "missing_token"
            handleSyncLinkedin(reply.token, status, reply.cookie)
          }
        )
        // Extension is installed
      } catch (error) {
        // Extension is not installed or not responding
      }
    })
  }

  const handleSaveSynced = (sync) => {
    setLinkedinSynced(sync)
    setLoading(true)
    saveSynced(sync, (response) => {
      setLoading(false)
      setLinkedinStatus(response.status)
    })
  }

  const buttonHandler = () => {
    const status = extensionDetected ? linkedinStatus : null

    if (loading)
      return (
        <div style={{ display: "contents" }}>
          <a>
            <RelatableLoader />
          </a>
        </div>
      )

    switch (status) {
      case null:
        return (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/relatable-crm/dbpiijgjfgnelhkmnklfebcnhdlecodc"
            >
              <span>
                Chrome Extension Not Found - Click to Install & Reload Page
              </span>
            </a>
          </div>
        )
      case "not_connected":
        return (
          <div>
            <a onClick={handleConnectLinkedin}>
              <span>Click to Connect w/ LinkedIn</span>
            </a>
          </div>
        )
      case "stopped":
        return (
          <a onClick={handleConnectLinkedin}>
            <span>Click to Connect w/ LinkedIn</span>
          </a>
        )
      case "error":
        return (
          <div>
            <a onClick={handleConnectLinkedin}>
              <span>Sorry, an error occurred. Click to Connect w/ LinkedIn</span>
            </a>
          </div>
        )
      case "missing_token":
        return (
          <a
            onClick={() => {
              setLinkedinStatus("not_connected")
              return true
            }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin"
          >
            <span>Can’t Connect w/ LinkedIn - Sign In to LinkedIn & Retry</span>
          </a>
        )
      case "running":
        return (
          <a
            onClick={handleDisconnectLinkedin}
            className={"LinkedinModal_Footer_Connected"}
          >
            <span>Connected to LinkedIn! Click here to disconnect. </span>
          </a>
        )
      default:
        return null
    }
  }

  return (
    <Modal
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onClose}
      width={623}
      closeIcon={<Icon component={CancelIcon} className="LinkedinModal_CloseIcon" />}
      wrapClassName="LinkedinModal"
    >
      <div>
        <div className="LinkedinModal_Header">
          <span>Sync your LinkedIn Account with Relatable</span>
        </div>
        <div className="LinkedinModal_Body">
          <p>
            Relatable can sync your LinkedIn into Relatable, update your database
            with peoples current job information on LinkedIn, and notify you of job
            changes and other key updates.
          </p>
          <p>
            We do not collect or store your LinkedIn password - instead, Relatable
            uses your web browser connection to access LinkedIn.
          </p>
          <p>
            In order to connect LinkedIn with Relatable, you’ll need to install our
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://chrome.google.com/webstore/detail/relatable-crm/dbpiijgjfgnelhkmnklfebcnhdlecodc"
              }
              className={"LinkedinModal_Body__underline"}
            >
              {" "}
              Chrome Extension (yes, sorry, Chrome only for now!).{" "}
            </a>
            Please ensure you are logged in to LinkedIn!
          </p>
        </div>

        <div className="LinkedinModal_Footer">{buttonHandler()}</div>
        {linkedinStatus === "running" && (
          <Collapse bordered={false} className={"LinkedinModal_Configure"}>
            <CollapsePanel
              key={1}
              extra={<Icon component={SettingsIcon} />}
              header={
                <span className="LinkedinModal_Configure_Header">
                  Configure your LinkedIn connection
                </span>
              }
            >
              <div className="LinkedinModal_Configure_Content">
                <Switch
                  onChange={(value) => {
                    handleSaveSynced(value)
                  }}
                  checked={linkedinSynced}
                />
                <span>Enable LinkedIn Contacts sync</span>
              </div>
            </CollapsePanel>
          </Collapse>
        )}
      </div>
    </Modal>
  )
}

LinkedinModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
export default LinkedinModal
