import { Button, Input, Modal, Select } from "antd"
import axios from "axios"
import React, { useEffect, useState } from "react"
import "./PresetTemplates.styles.scss"

const PresetTemplateModal = ({
  handleGetPresetTemplates,
  visible,
  presetTemplate,
  handleClickCancel,
}) => {
  const { TextArea } = Input
  const [subject, setSubject] = useState("")
  const [category, setCategory] = useState("")
  const [description, setDescription] = useState("")
  const [body, setBody] = useState("")
  const [id, setId] = useState("")

  const savePresetTemplate = async (data) => {
    if (presetTemplate.id) {
      await axios.request({
        url: `/v1/preset_templates/${presetTemplate.id}`,
        method: "PATCH",
        data,
      })
    } else {
      await axios.request({
        url: "/v1/preset_templates",
        method: "POST",
        data,
      })
    }
  }

  useEffect(() => {
    if (presetTemplate) {
      setSubject(presetTemplate.subject || "")
      setCategory(presetTemplate.category || null)
      setDescription(presetTemplate.description || "")
      setBody(presetTemplate.body || "")
      setId(presetTemplate.id || null)
    } else {
      setSubject("")
      setCategory(null)
      setDescription("")
      setBody("")
      setId(null)
    }
  }, [presetTemplate])

  return (
    <Modal
      closable={false}
      width="40vw"
      destroyOnClose
      footer={null}
      title={id ? "Update Preset Template" : "New Preset Template"}
      visible={visible}
      onCancel={handleClickCancel}
      className="ChallengeDetailsModal"
    >
      <div className={"PresetTemplateModal_Form"}>
        <div className={"PresetTemplateModal_Form_item"}>
          <label>Subject</label>
          <Input
            placeholder="Subject..."
            key="participant_2_subject"
            name="participant_2_subject"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
          />
        </div>
        <div className={"PresetTemplateModal_Form_item"}>
          <label>Description</label>

          <TextArea
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description..."
            value={description}
          />
        </div>
        <div className={"PresetTemplateModal_Form_item"}>
          <label>Category</label>
          <Select
            placeholder="Select a category"
            key={id}
            value={category}
            onChange={(e) => setCategory(e)}
          >
            <Select.Option value="sms">SMS</Select.Option>
            <Select.Option value="email">Email</Select.Option>
          </Select>
        </div>
        <div className={"PresetTemplateModal_Form_item"}>
          <label>Body</label>
          <TextArea
            onChange={(e) => setBody(e.target.value)}
            placeholder="Body..."
            value={body}
          />
        </div>
      </div>
      <div className={"PresetTemplateModal_SubmitWrapper"}>
        <Button
          type="primary"
          onClick={() => {
            if (!subject.length || !category || !body.length) {
              return
            }
            savePresetTemplate({
              subject,
              description,
              category,
              body,
              id,
            }).then((r) => {
              handleClickCancel()
              handleGetPresetTemplates()
            })
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default PresetTemplateModal
