import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  ADD_PARTICIPANTS_TO_SPHERES,
  ADD_TO_SPHERES,
  ARCHIVE_PARTICIPANTS,
  FETCH_PARTICIPANTS,
  FETCH_RESERVE_PARTICIPANTS,
} from "./Participants.types"
import {
  addParticipantsToSpheresFail,
  addParticipantsToSpheresSuccess,
  addToSpheresFail,
  addToSpheresSuccess,
  archiveParticipantsFail,
  archiveParticipantsSuccess,
  fetchParticipants,
  fetchParticipantsFail,
  fetchParticipantsSuccess,
  fetchReserveParticipantsFail,
  fetchReserveParticipantsSuccess,
} from "./Participants.actions"
import axios from "axios"
import { fetchSpheres } from "../Collections/Collections.actions"
import {
  deselectParticipants,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../App/App.actions"
import { fetchActivities, fetchPoints } from "../Activities/Activities.actions"

export function* watchParticipantsSaga() {
  yield takeLatest(FETCH_PARTICIPANTS, getParticipants)
  yield takeLatest(FETCH_RESERVE_PARTICIPANTS, getReserveParticipants)
  yield takeLatest(ADD_TO_SPHERES, addToSpheres)
  yield takeLatest(ADD_PARTICIPANTS_TO_SPHERES, addParticipantsToSpheres)
  yield takeLatest(ARCHIVE_PARTICIPANTS, archiveParticipants)
}

function* getParticipants(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/participants",
        params: {
          page: action.page,
          per_page: action.per_page,
          query: action.query,
          filters: action.filters,
          field: action.sortField,
          order: action.sortOrder,
        },
      })
    )

    yield put(fetchParticipantsSuccess(data.participants, data.total_entries))
  } catch (error) {
    yield put(fetchParticipantsFail(error))
  }
}

function* getReserveParticipants(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/participants",
        params: {
          page: action.page,
          per_page: action.per_page,
          query: action.query,
          filters: action.filters,
          field: action.sortField,
          order: action.sortOrder,
        },
      })
    )

    yield put(fetchReserveParticipantsSuccess(data.participants, data.total_entries))
  } catch (error) {
    yield put(fetchReserveParticipantsFail(error))
  }
}

function* addToSpheres(action) {
  try {
    const { participant, existingSpheres, newSpheres, quickAdd } = action

    const { data } = yield call(() =>
      axios.request({
        url: `v1/participants/add_participants_to_sphere`,
        data: {
          spheres: existingSpheres.concat(newSpheres),
          participants_ids: [participant],
        },
        method: "PUT",
      })
    )

    if (!quickAdd) {
      yield all([
        put(setContactDrawerMode("view")),
        put(setContactDrawerVisible(true)),
        put(setVisibleContactID(data.people[0].person_id, data.people[0].slug)),
      ])
    }

    const person = yield call(() =>
      axios.request({
        url: `v1/people/${data.people[0].person_id}`,
      })
    )
    yield all([
      put(addToSpheresSuccess(participant, person.data.person)),
      put(fetchSpheres()),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
      put(deselectParticipants()),
    ])
  } catch (error) {
    yield put(addToSpheresFail(error))
  }
}

function* addParticipantsToSpheres(action) {
  try {
    const { participantsIds, existingSpheres, newSpheres } = action
    const { data } = yield call(() =>
      axios.request({
        url: `v1/participants/add_participants_to_sphere`,
        data: {
          spheres: existingSpheres.concat(newSpheres),
          participants_ids: participantsIds,
        },
        method: "PUT",
      })
    )
    yield call(() =>
      axios.request({
        url: `v1/people/${data.people[0].person_id}`,
      })
    )
    yield all([
      put(addParticipantsToSpheresSuccess(participantsIds)),
      put(fetchSpheres()),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
      put(deselectParticipants()),
      put(fetchParticipants(action.page, 15, action.filters)),
    ])
  } catch (error) {
    yield put(addParticipantsToSpheresFail(error))
  }
}

function* archiveParticipants(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `v1/participants/archive`,
        data: { participants_ids: action.participants_ids },
        method: "PUT",
      })
    )
    yield put(archiveParticipantsSuccess(data.participants_ids))
    yield put(deselectParticipants())
    if (data.participants_ids?.length > 1) {
      yield put(fetchParticipants(action.page, 15, action.filters))
    }
  } catch (error) {
    yield put(archiveParticipantsFail(error))
  }
}
