import {
  CLEAR_REDUX_MESSAGES_STATE,
  DELETE_SCHEDULED_SPLIT_MESSAGE,
  DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL,
  DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_FAIL,
  DELETE_TEMPLATE_SUCCESS,
  FETCH_AI_DRAFT,
  FETCH_AI_DRAFT_FAIL,
  FETCH_AI_DRAFT_SUCCESS,
  FETCH_MESSAGES,
  FETCH_MESSAGES_FAIL,
  FETCH_MESSAGES_OPEN_RATE,
  FETCH_MESSAGES_OPEN_RATE_FAIL,
  FETCH_MESSAGES_OPEN_RATE_SUCCESS,
  FETCH_MESSAGES_SUCCESS,
  FETCH_SPLIT_MESSAGE,
  FETCH_SPLIT_MESSAGE_DETAILS,
  FETCH_SPLIT_MESSAGE_DETAILS_FAIL,
  FETCH_SPLIT_MESSAGE_DETAILS_SUCCESS,
  FETCH_SPLIT_MESSAGE_FAIL,
  FETCH_SPLIT_MESSAGE_SUCCESS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_FAIL,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAIL,
  FETCH_TEMPLATES_SUCCESS,
  NEW_TEMPLATE,
  NEW_TEMPLATE_FAIL,
  NEW_TEMPLATE_SUCCESS,
  RESEND_MESSAGE,
  RESEND_MESSAGE_FAIL,
  RESEND_MESSAGE_SUCCESS,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_FAIL,
  SAVE_TEMPLATE_SUCCESS,
  SCHEDULED_SPLIT_MESSAGE_FAIL,
  SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  SEND_SPLIT_MESSAGE,
  SEND_SPLIT_MESSAGE_FAIL,
  SEND_SPLIT_MESSAGE_SUCCESS,
  SET_INITIAL_REDUX_MESSAGES_STATE,
  SET_LOADING,
  SET_MESSAGE_DRAFT,
  UPDATE_SCHEDULED_SPLIT_MESSAGE,
  UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL,
  UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  UPLOAD_MESSAGE_IMAGE,
  UPLOAD_MESSAGE_IMAGE_FAIL,
  UPLOAD_MESSAGE_IMAGE_SUCCESS,
} from "./Messages.types"
import { LOGOUT_SUCCESS } from "../User/User.types"
import {
  SET_MESSAGE_SENDER_VISIBLE,
  SET_SPLIT_MESSAGE_SENDER_VISIBLE,
} from "../App/App.types"

const INITIAL_STATE = {
  messages: null,
  total_entries: 0,
  loading: false,
  loading_open_rate: false,
  templates: null,
  selected_template: null,
  generatingDraft: false,
  ai_draft: null,
}

export const messagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MESSAGES:
      return {
        ...state,
        loading: true,
      }
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        total_entries: action.total_entries,
        loading: false,
      }
    case FETCH_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_MESSAGES_OPEN_RATE:
      return {
        ...state,
        loading_open_rate: true,
      }
    case FETCH_MESSAGES_OPEN_RATE_SUCCESS:
      return {
        ...state,
        open_rate_30_days: action.open_rate_30_days,
        open_rate_all_time: action.open_rate_all_time,
        loading_open_rate: false,
      }
    case FETCH_MESSAGES_OPEN_RATE_FAIL:
      return {
        ...state,
        loading_open_rate: false,
      }
    case FETCH_SPLIT_MESSAGE:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SPLIT_MESSAGE_SUCCESS:
      const index = state.messages.findIndex((m) => m.id === action.split_message.id)
      const _messages = state.messages
      _messages[index] = action.split_message
      return {
        ...state,
        messages: _messages,
        loading: false,
      }
    case FETCH_SPLIT_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_SPLIT_MESSAGE_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SPLIT_MESSAGE_DETAILS_SUCCESS:
      const idx = state.messages.findIndex((m) => m.id === action.split_message.id)
      const temp_messages = state.messages
      temp_messages[idx] = action.split_message
      return {
        ...state,
        messages: temp_messages,
        loading: false,
      }
    case FETCH_SPLIT_MESSAGE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_TEMPLATES:
      return {
        ...state,
        loading: true,
      }
    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        loading: false,
      }
    case FETCH_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        selected_template: action.template,
        loading: false,
      }
    case FETCH_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case SAVE_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: state.templates.map((t) =>
          t.id !== action.template.id ? t : action.template
        ),
        loading: false,
      }
    case SAVE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case DELETE_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: state.templates.filter((a) => a.id !== action.template_id),
        loading: false,
      }
    case DELETE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case NEW_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case NEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: [...state.templates, action.template],
        loading: false,
      }
    case NEW_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPLOAD_MESSAGE_IMAGE:
      return {
        ...state,
        loading: true,
      }
    case UPLOAD_MESSAGE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPLOAD_MESSAGE_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case SEND_SPLIT_MESSAGE:
      return {
        ...state,
        loading: true,
      }
    case SEND_SPLIT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case SEND_SPLIT_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_SCHEDULED_SPLIT_MESSAGE:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case DELETE_SCHEDULED_SPLIT_MESSAGE:
      return {
        ...state,
        loading: true,
      }
    case DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case RESEND_MESSAGE:
      return {
        ...state,
        loading: true,
      }
    case RESEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case RESEND_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case SCHEDULED_SPLIT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case SCHEDULED_SPLIT_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.value,
      }
    case SET_MESSAGE_SENDER_VISIBLE:
      return {
        ...state,
        selected_template: null,
      }
    case SET_SPLIT_MESSAGE_SENDER_VISIBLE:
      return {
        ...state,
        selected_template: null,
      }
    case FETCH_AI_DRAFT:
      return {
        ...state,
        generatingDraft: true,
      }
    case FETCH_AI_DRAFT_SUCCESS:
      return {
        ...state,
        generatingDraft: false,
      }
    case FETCH_AI_DRAFT_FAIL:
      return {
        ...state,
        generatingDraft: false,
      }
    case SET_MESSAGE_DRAFT:
      return {
        ...state,
        ai_draft: action.aiDraft,
        generatingDraft: false,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_MESSAGES_STATE:
      return {}
    case SET_INITIAL_REDUX_MESSAGES_STATE:
      return { ...INITIAL_STATE }
    default:
      return {
        ...state,
      }
  }
}
