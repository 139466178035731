import React, { useState } from "react"
import { Button, Collapse, Modal } from "antd"

const MfaModal = ({ mfaToken, qrCode, visible, codeAuthentication }) => {
  const [mfaCode, setMfaCode] = useState(null)

  return (
    <Modal
      closable={false}
      width={"40vw"}
      destroyOnClose
      footer={null}
      title={<h3>Multi Factor Authentication</h3>}
      visible={visible}
      onCancel={() => {}}
    >
      <div
        style={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          textAlign: "center",
        }}
      >
        {qrCode && (
          <Collapse>
            <Collapse.Panel
              header={"I didn't set up MFA for Relatable yet"}
              key={"1"}
            >
              <span>
                Please scan this QR code with Google Authenticator app and provide
                code from the app
              </span>
              <br />
              <img src={qrCode} alt={"QR code"} />
            </Collapse.Panel>
          </Collapse>
        )}
        <span>Please provide code from Google Authenticator app:</span>
        <span>
          <input
            value={mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
            type={"number"}
          ></input>
        </span>

        <div>
          <Button
            onClick={() => codeAuthentication(mfaCode, mfaToken)}
            style={{ margin: "2rem 0" }}
            type={"primary"}
            block
            disabled={!mfaCode}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MfaModal
