import Nylas from "nylas"
import { generateNylasToken } from "../components/Account/Account.actions"
import axios from "axios"

Nylas.config({
  clientId: process.env.REACT_APP_NYLAS_CLIENT_ID,
  clientSecret: process.env.REACT_APP_NYLAS_CLIENT_SECRET,
})

export const nylasAuthenticationURI = async (email, callback) => {
  const { data } = await axios.request({
    url: "/v1/nylas/generate_url_and_token",
    params: { email },
    method: "GET",
  })
  callback(data.url)
}

export const nylasSignInURI = (provider, needs_reconnect, email_hint, callback) => {
  let url = ""

  const redirect_uri = process.env.REACT_APP_DEV_UI_URL + "/login"
  const microsoft_providers = [
    "microsoft",
    "outlook",
    "office365",
    "exchange",
    "eas",
  ]

  if (provider === "google") {
    const scopes = [
      "https%3A//www.googleapis.com/auth/gmail.readonly",
      "https%3A//www.googleapis.com/auth/calendar.readonly",
      "https%3A//www.googleapis.com/auth/gmail.send",
      "https%3A//www.googleapis.com/auth/contacts.readonly",
      "https%3A//www.googleapis.com/auth/userinfo.email",
      "https%3A//www.googleapis.com/auth/userinfo.profile",
    ]

    url =
      "https://accounts.google.com/o/oauth2/v2/auth?" +
      "response_type=code&" +
      `access_type=offline&` +
      `prompt=consent&` +
      "state=google&" +
      `redirect_uri=${redirect_uri}&` +
      `client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}` +
      "&scope=" +
      scopes.join("%20")
  } else if (microsoft_providers.includes(provider)) {
    const scopes = [
      "User.Read",
      "Calendars.Read.Shared",
      "Mail.Send",
      "Contacts.ReadWrite",
      "Mail.ReadWrite",
      "Calendars.ReadWrite",
      "Calendars.ReadWrite.Shared",
      "openid",
      "profile",
      "offline_access",
      "email",
      "EAS.AccessAsUser.All",
      "EWS.AccessAsUser.All",
    ]

    const nonce = Math.random().toString(36).substring(2, 15)
    url =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?" +
      "response_type=code%20id_token&" +
      "response_mode=fragment&" +
      "tenant=common&" +
      `prompt=select_account&` +
      `redirect_uri=${redirect_uri}&` +
      `client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID_V3}&` +
      `state=microsoft&` +
      `nonce=${nonce}&` +
      "scope=" +
      scopes.join("%20")
    // localStorage.setItem("needs_reconnect", needs_reconnect)
    localStorage.setItem("nonce", nonce)
    // localStorage.setItem("email_hint", email_hint)
  }
  callback(url)
}
