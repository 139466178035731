import React from "react"
import PropTypes from "prop-types"
import "./Avatar.styles.scss"

const Avatar = ({
  avatar,
  firstName,
  lastName,
  email,
  colorNumber,
  size,
  shape,
}) => {
  if (avatar) {
    return (
      <span
        className={"ant-avatar ant-avatar-circle ant-avatar-image"}
        style={{ minHeight: size, minWidth: size }}
      >
        <img alt={"avatar"} src={avatar} />
      </span>
    )
  } else {
    return (
      <span
        className={`ant-avatar ant-avatar-circle Avatar Avatar--color${
          colorNumber % 5
        } Avatar--shape_${shape}`}
        style={{
          minHeight: size,
          minWidth: size,
        }}
      >
        <img
          alt={"avatar"}
          className="Avatar--image"
          src={`/avatars/${colorNumber % 20}.png`}
        />
        <span className={"ant-avatar-string Avatar--string"}>
          {firstName || lastName
            ? `${firstName?.length > 0 ? firstName[0] : ""}${
                lastName?.length > 0 ? lastName[0] : ""
              }`
            : `${email?.length > 0 ? email[0] : ""}`}
        </span>
      </span>
    )
  }
}

Avatar.propTypes = {
  avatar: PropTypes.any,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  colorNumber: PropTypes.number,
  size: PropTypes.number,
  shape: PropTypes.oneOf(["circle", "square"]),
}

Avatar.defaultProps = {
  colorNumber: 0,
  size: 40,
  shape: "circle",
}

export default Avatar
