import React from "react"
import { Checkbox, Modal, Spin } from "antd"
import "./ChallengeModal.styles.scss"
import PropTypes from "prop-types"
import { ReactComponent as PeopleInCircles } from "../../../../../images/PeopleInCircles.svg"
import CurvedBackground from "../../../../../images/CurvedBackground.svg"
import CancelIcon from "../../../../../components/Icons/Cancel.icons"
import Icon from "@ant-design/icons"
import Avatar from "../../../../../components/UI/Avatar/Avatar.component"
import ProgressBar from "../../../../../components/UI/ProgressBar/ProgressBar.component"
import { useDispatch, useSelector } from "react-redux"
import { apiHost } from "../../../../../config/serverConf"
import { joinToChallenge } from "../../../../../redux/Activities/Activities.actions"
import { generateGoalName } from "../helpers"
import moment from "moment/moment"
import RelatableLoader from "../../../../../components/UI/RelatableLoader/RelatableLoader.component"

const ChallengeModal = ({ visible, onClose }) => {
  const challenge = useSelector((state) => state.Activities.challenge)
  const loading = useSelector((state) => state.Activities.loading_challenge)
  const dispatch = useDispatch()

  const handleJoinToChallenge = (id) => {
    dispatch(joinToChallenge(id))
  }

  return (
    <Modal
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onClose}
      width={623}
      closeIcon={
        <Icon component={CancelIcon} className="ChallengeModal_CloseIcon" />
      }
      wrapClassName="ChallengeModal"
    >
      {loading ? (
        <div className="ChallengeModal_LoadingWrapper">
          <RelatableLoader />
        </div>
      ) : !challenge ? (
        <div className="ChallengeModal_Header">
          <PeopleInCircles />
          <h1>There is no challenge to show</h1>
          <span>Try again later</span>
        </div>
      ) : (
        <>
          <div className="ChallengeModal_Header">
            <PeopleInCircles />
            <h1>{challenge.name}</h1>
            <span>
              {`${moment(challenge.start_date).format("MMM Do")} - ${moment(
                challenge.end_date
              ).format("MMM Do")}`}
            </span>
            <span>{challenge.description}</span>
          </div>
          <img
            className="ChallengeModal_CurvedBackground"
            src={CurvedBackground}
            alt="Bacground waves"
          />
          <div className="ChallengeModal_Body">
            <div>
              <h2>To Complete</h2>
              <ul>
                {challenge.goals.map((goal, index) => (
                  <li key={index}>
                    <Checkbox
                      checked={
                        challenge.my_progress >= 0
                          ? goal.number_of_todo === goal.finished
                          : true
                      }
                    />
                    <span>
                      {generateGoalName(goal.type, goal.number_of_todo)}
                      {challenge.my_progress >= 0 ? (
                        <span className="Progress">
                          ({goal.finished}/{goal.number_of_todo})
                        </span>
                      ) : null}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="ChallengeModal_Body_AreYouIn">
              {challenge.my_progress >= 0 ? (
                <>
                  <div className="ChallengeModal_Body_AreYouIn_Wrapper">
                    <h2>My progress</h2>
                  </div>
                  <ProgressBar progress={challenge.my_progress} />
                </>
              ) : (
                <div className="ChallengeModal_Body_AreYouIn_Wrapper">
                  <h2>Are you in?</h2>
                  <button onClick={() => handleJoinToChallenge(challenge.id)}>
                    I'm in!
                  </button>
                </div>
              )}
            </div>
            {challenge.participants.map((participant, index) => (
              <div key={index} className="ChallengeModal_Body_Participant">
                <Avatar
                  avatar={
                    participant?.avatar !== ""
                      ? apiHost() + participant?.avatar
                      : null
                  }
                  firstName={participant?.full_name.split(" ")[0]}
                  lastName={participant?.full_name.split(" ")[1]}
                  colorNumber={participant.id}
                />
                <div className="ChallengeModal_Body_Participant_Names">
                  <span className="ChallengeModal_Body_Participant_Names_FullName">
                    {participant.full_name}
                  </span>
                  <span className="ChallengeModal_Body_Participant_Names_Bio">
                    {participant.bio}
                  </span>
                </div>
                <ProgressBar progress={participant.progress} />
              </div>
            ))}
          </div>
        </>
      )}
    </Modal>
  )
}

ChallengeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
export default ChallengeModal
