import React from "react"
import "./DuplicatesAndEnrichments.styles.scss"
import { Button } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import history from "../../history"
import DuplicatesSection from "../../components/Database/DuplicatesTable/DuplicatesSection.component"
import EnrichmentsSection from "../../components/Database/EnrichmentsTable/EnrichmentsSection.component"

const DuplicatesAndEnrichmentsPage = () => {
  return (
    <div className="DuplicatesAndEnrichmentsPage">
      <Button
        className={"DuplicatesAndEnrichmentsPage_Back"}
        onClick={() => history.push("/database")}
        type={"link"}
        icon={<ArrowLeftOutlined />}
      >
        Back to Database page
      </Button>
      <EnrichmentsSection />
      <DuplicatesSection />
    </div>
  )
}

export default DuplicatesAndEnrichmentsPage
