import React from "react"

const TagIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.503 2.42804L3.93741 3.93741L2.42804 11.503C2.40439 11.6238 2.41083 11.7486 2.4468 11.8663C2.48277 11.984 2.54716 12.0911 2.63429 12.178L12.4218 21.9655C12.4908 22.0361 12.5732 22.0922 12.6641 22.1305C12.7551 22.1688 12.8528 22.1885 12.9515 22.1885C13.0502 22.1885 13.1479 22.1688 13.2388 22.1305C13.3298 22.0922 13.4122 22.0361 13.4812 21.9655L21.9655 13.4812C22.0361 13.4122 22.0922 13.3298 22.1305 13.2388C22.1688 13.1479 22.1885 13.0502 22.1885 12.9515C22.1885 12.8528 22.1688 12.7551 22.1305 12.6641C22.0922 12.5732 22.0361 12.4908 21.9655 12.4218L12.178 2.63429C12.0911 2.54716 11.984 2.48277 11.8663 2.4468C11.7486 2.41083 11.6238 2.40439 11.503 2.42804V2.42804Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 9C8.49632 9 9 8.49632 9 7.875C9 7.25368 8.49632 6.75 7.875 6.75C7.25368 6.75 6.75 7.25368 6.75 7.875C6.75 8.49632 7.25368 9 7.875 9Z"
      fill="currentColor"
    />
  </svg>
)

export default TagIcon
