import React from "react"
import PropTypes from "prop-types"
import "./DrawerOverlay.styles.scss"
import { Spin } from "antd"
import { useSelector } from "react-redux"
import RelatableLoader from "../RelatableLoader/RelatableLoader.component"

const DrawerOverlay = ({ name }) => {
  const merging = useSelector((state) => state.PeopleState.merging)
  const archiving = useSelector((state) => state.PeopleState.archiving)
  const editing = useSelector((state) => state.PeopleState.editing)

  return (
    <div
      className={`DrawerOverlay ${editing && "editing"} ${merging && "merging"} ${
        archiving && "archiving"
      }`}
    >
      <RelatableLoader size={"large"} />
      {merging && <p>{`Merging ${name}...`}</p>}
      {archiving && <p>{`Archiving ${name}...`}</p>}
      {editing && <p>{`Updating ${name}...`}</p>}
    </div>
  )
}

DrawerOverlay.propTypes = {
  name: PropTypes.oneOf(["contacts", "people"]),
}

DrawerOverlay.defaultProps = {
  name: "contacts",
}

export default DrawerOverlay
