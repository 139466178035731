import { Button, Col, Input, Popover, Row, Select } from "antd"
import React, { useState } from "react"
import "./RuleCreator.styles.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  addRule,
  deleteSphere,
  renameSphere,
  updateRule,
} from "../../../redux/Collections/Collections.actions"
import { EditOutlined } from "@ant-design/icons"

const RuleCreatorText = ({ id, handleClose, onDelete }) => {
  const dispatch = useDispatch()

  const sphere = useSelector((state) =>
    state.CollectionsState.spheres.find((sphere) => sphere.id === id)
  )
  const [sphereName, setSphereName] = useState(sphere.title)
  const loading = useSelector((state) => state.CollectionsState.loading)
  const rules = sphere.rules

  const hasCadenceRule = () => {
    return rules.find((rule) => rule.type === "Rule::Cadence")
  }

  const ruleDescription = () => {
    return "We’ll remind you if we haven’t seen any interaction between you two."
  }

  const handleRenameSphere = () => {
    dispatch(renameSphere(id, sphereName))
  }

  return (
    sphere && (
      <Row type={"flex"} style={{ width: "100%", padding: "0 1rem" }}>
        <Row className={"RuleCreator_Block"}>
          <Row type={"flex"} style={{ width: "100%" }}>
            <Col span={24} className={"RuleCreator_Sphere"}>
              <Input
                className={"RuleCreator_Sphere_Title"}
                value={sphereName}
                onChange={(e) => setSphereName(e.target.value)}
                onBlur={handleRenameSphere}
              />
              <EditOutlined className={"RuleCreator_Sphere_EditIcon"} />
            </Col>
          </Row>
        </Row>
        <Row className={"RuleCreator_Block"}>
          <Row>
            <Col span={24}>
              <span className={"RuleCreator_Title"}>Stay in touch</span>
            </Col>
          </Row>
          <Row className={"RuleCreator_Block_Content"}>
            <Col span={24}>
              <Select
                loading={loading}
                disabled={loading}
                className={"RuleCreator_Select"}
                dropdownClassName={"RuleCreator_Select_Dropdown"}
                defaultValue={
                  rules.length > 0 && hasCadenceRule() && hasCadenceRule().enabled
                    ? rules.find((rule) => rule.type === "Rule::Cadence").settings
                        .interval
                    : null
                }
                onSelect={(value) => {
                  if (hasCadenceRule()) {
                    dispatch(
                      updateRule(id, value !== "never", hasCadenceRule().id, {
                        interval: value,
                      })
                    )
                  } else {
                    dispatch(
                      addRule(id, "Rule::Cadence", {
                        interval: value,
                      })
                    )
                  }
                  handleClose()
                }}
              >
                <Select.Option value={"never"}>Never</Select.Option>
                <Select.Option value={"weekly"}>Weekly</Select.Option>
                <Select.Option value={"monthly"}>Monthly</Select.Option>
                <Select.Option value={"bi-monthly"}>Bi-monthly</Select.Option>
                <Select.Option value={"quarterly"}>Quarterly</Select.Option>
                <Select.Option value={"semi-annually"}>Semi-annually</Select.Option>
                <Select.Option value={"annually"}>Annually</Select.Option>
              </Select>
            </Col>
          </Row>
          <Row className={"RuleCreator_Block_Content"}>
            <Col span={24} className={"RuleCreator_Description"}>
              {ruleDescription()}
            </Col>
          </Row>
        </Row>
        <Row
          className={"RuleCreator_Block-actions"}
          type={"flex"}
          justify={"space-between"}
          align={"middle"}
        >
          <Col span={12}>
            <span
              className={"RuleCreator_DeleteButton"}
              onClick={() => {
                onDelete(id)
                dispatch(deleteSphere(id))
              }}
            >
              Delete Sphere
            </span>
          </Col>
          <Col span={12}>
            <Button
              className={"RuleCreator_SubmitButton"}
              onClick={() => {
                handleClose()
              }}
            >
              Done
            </Button>
          </Col>
        </Row>
      </Row>
    )
  )
}

const RuleCreator = ({ children, id, opened, handleClose, onDelete }) => {
  return (
    <Popover
      content={
        <RuleCreatorText id={id} handleClose={handleClose} onDelete={onDelete} />
      }
      autoAdjustOverflow={true}
      visible={opened}
      trigger={"click"}
      placement={"left"}
      onVisibleChange={(opened) => {
        if (!opened) handleClose()
      }}
      overlayClassName={"RuleCreator"}
    >
      {children}
    </Popover>
  )
}

export default RuleCreator
