import React from "react"

const GitForkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20.25C13.4497 20.25 14.625 19.0747 14.625 17.625C14.625 16.1753 13.4497 15 12 15C10.5503 15 9.375 16.1753 9.375 17.625C9.375 19.0747 10.5503 20.25 12 20.25Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.625 9C19.0747 9 20.25 7.82475 20.25 6.375C20.25 4.92525 19.0747 3.75 17.625 3.75C16.1753 3.75 15 4.92525 15 6.375C15 7.82475 16.1753 9 17.625 9Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 9C7.82475 9 9 7.82475 9 6.375C9 4.92525 7.82475 3.75 6.375 3.75C4.92525 3.75 3.75 4.92525 3.75 6.375C3.75 7.82475 4.92525 9 6.375 9Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.37502 9V9.75C6.37378 10.0458 6.43113 10.339 6.54376 10.6125C6.6564 10.886 6.82208 11.1346 7.03126 11.3438C7.24044 11.5529 7.48897 11.7186 7.76252 11.8313C8.03606 11.9439 8.3292 12.0012 8.62502 12H15.375C15.6708 12.0012 15.964 11.9439 16.2375 11.8313C16.5111 11.7186 16.7596 11.5529 16.9688 11.3438C17.178 11.1346 17.3436 10.886 17.4563 10.6125C17.5689 10.339 17.6263 10.0458 17.625 9.75V9"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12V15"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GitForkIcon
