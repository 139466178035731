import {
  CLEAR_REDUX_MESSAGES_STATE,
  DELETE_SCHEDULED_SPLIT_MESSAGE,
  DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL,
  DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_FAIL,
  DELETE_TEMPLATE_SUCCESS,
  FETCH_AI_DRAFT,
  FETCH_AI_DRAFT_FAIL,
  FETCH_AI_DRAFT_SUCCESS,
  FETCH_MESSAGES,
  FETCH_MESSAGES_FAIL,
  FETCH_MESSAGES_OPEN_RATE,
  FETCH_MESSAGES_OPEN_RATE_FAIL,
  FETCH_MESSAGES_OPEN_RATE_SUCCESS,
  FETCH_MESSAGES_SUCCESS,
  FETCH_SPLIT_MESSAGE,
  FETCH_SPLIT_MESSAGE_DETAILS,
  FETCH_SPLIT_MESSAGE_DETAILS_FAIL,
  FETCH_SPLIT_MESSAGE_DETAILS_SUCCESS,
  FETCH_SPLIT_MESSAGE_FAIL,
  FETCH_SPLIT_MESSAGE_SUCCESS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_FAIL,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAIL,
  FETCH_TEMPLATES_SUCCESS,
  NEW_TEMPLATE,
  NEW_TEMPLATE_FAIL,
  NEW_TEMPLATE_SUCCESS,
  RESEND_MESSAGE,
  RESEND_MESSAGE_FAIL,
  RESEND_MESSAGE_SUCCESS,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_FAIL,
  SAVE_TEMPLATE_SUCCESS,
  SCHEDULED_SPLIT_MESSAGE_FAIL,
  SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  SEND_SPLIT_MESSAGE,
  SEND_SPLIT_MESSAGE_FAIL,
  SEND_SPLIT_MESSAGE_SUCCESS,
  SET_INITIAL_REDUX_MESSAGES_STATE,
  SET_LOADING,
  SET_MESSAGE_DRAFT,
  UPDATE_SCHEDULED_SPLIT_MESSAGE,
  UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL,
  UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  UPLOAD_MESSAGE_IMAGE,
  UPLOAD_MESSAGE_IMAGE_FAIL,
  UPLOAD_MESSAGE_IMAGE_SUCCESS,
} from "./Messages.types"

export const fetchMessages = (page, per_page) => {
  return {
    type: FETCH_MESSAGES,
    page: page,
    per_page: per_page,
  }
}

export const fetchMessagesSuccess = (messages, total_entries) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    messages: messages,
    total_entries: total_entries,
  }
}

export const fetchMessagesFail = (error) => {
  return {
    type: FETCH_MESSAGES_FAIL,
    error: error,
  }
}

export const fetchMessagesOpenRate = () => {
  return {
    type: FETCH_MESSAGES_OPEN_RATE,
  }
}

export const fetchMessagesOpenRateSuccess = (
  open_rate_30_days,
  open_rate_all_time
) => {
  return {
    type: FETCH_MESSAGES_OPEN_RATE_SUCCESS,
    open_rate_30_days: open_rate_30_days,
    open_rate_all_time: open_rate_all_time,
  }
}

export const fetchMessagesOpenRateFail = (error) => {
  return {
    type: FETCH_MESSAGES_OPEN_RATE_FAIL,
    error: error,
  }
}

export const sendSplitMessage = (
  messages,
  attachments,
  scheduledDate = null,
  cc_receivers = [],
  ai_draft = null
) => {
  return {
    type: SEND_SPLIT_MESSAGE,
    messages,
    attachments,
    scheduledDate,
    cc_receivers,
    ai_draft,
  }
}

export const sendSplitMessageSuccess = (messages) => {
  return {
    type: SEND_SPLIT_MESSAGE_SUCCESS,
    messages,
  }
}

export const sendSplitMessageFail = (error) => {
  return {
    type: SEND_SPLIT_MESSAGE_FAIL,
    error: error,
  }
}

export const updateScheduledSplitMessage = (
  messages,
  attachments,
  scheduledDate = null
) => {
  return {
    type: UPDATE_SCHEDULED_SPLIT_MESSAGE,
    messages,
    attachments,
    scheduledDate,
  }
}

export const updateScheduledSplitMessageSuccess = () => {
  return {
    type: UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  }
}

export const updateScheduledSplitMessageFail = (error) => {
  return {
    type: UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL,
    error: error,
  }
}

export const deleteScheduledSplitMessage = (split_message_id, from) => {
  return {
    type: DELETE_SCHEDULED_SPLIT_MESSAGE,
    split_message_id: split_message_id,
    from: from,
  }
}

export const deleteScheduledSplitMessageSuccess = () => {
  return {
    type: DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS,
  }
}

export const deleteScheduledSplitMessageFail = (error) => {
  return {
    type: DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL,
    error: error,
  }
}

export const scheduledSplitMessageSuccess = (messages) => {
  return {
    type: SCHEDULED_SPLIT_MESSAGE_SUCCESS,
    messages,
  }
}

export const scheduledSplitMessageFail = (error) => {
  return {
    type: SCHEDULED_SPLIT_MESSAGE_FAIL,
    error: error,
  }
}

export const fetchTemplates = () => {
  return {
    type: FETCH_TEMPLATES,
  }
}
export const fetchTemplatesFail = (error) => {
  return {
    type: FETCH_TEMPLATES_FAIL,
    error,
  }
}
export const fetchTemplatesSuccess = (templates) => {
  return {
    type: FETCH_TEMPLATES_SUCCESS,
    templates,
  }
}

export const fetchTemplate = (id) => {
  return {
    type: FETCH_TEMPLATE,
    id,
  }
}
export const fetchTemplateFail = (error) => {
  return {
    type: FETCH_TEMPLATE_FAIL,
    error,
  }
}
export const fetchTemplateSuccess = (template) => {
  return {
    type: FETCH_TEMPLATE_SUCCESS,
    template,
  }
}

export const fetchSplitMessage = (id) => {
  return {
    type: FETCH_SPLIT_MESSAGE,
    id,
  }
}
export const fetchSplitMessageFail = (error) => {
  return {
    type: FETCH_SPLIT_MESSAGE_FAIL,
    error,
  }
}
export const fetchSplitMessageSuccess = (split_message) => {
  return {
    type: FETCH_SPLIT_MESSAGE_SUCCESS,
    split_message,
  }
}

export const fetchSplitMessageDetails = (id) => {
  return {
    type: FETCH_SPLIT_MESSAGE_DETAILS,
    id,
  }
}
export const fetchSplitMessageDetailsFail = (error) => {
  return {
    type: FETCH_SPLIT_MESSAGE_DETAILS_FAIL,
    error,
  }
}
export const fetchSplitMessageDetailsSuccess = (split_message) => {
  return {
    type: FETCH_SPLIT_MESSAGE_DETAILS_SUCCESS,
    split_message,
  }
}

export const saveTemplate = (template) => {
  return {
    type: SAVE_TEMPLATE,
    template,
  }
}
export const saveTemplateFail = (error) => {
  return {
    type: SAVE_TEMPLATE_FAIL,
    error,
  }
}
export const saveTemplateSuccess = (template) => {
  return {
    type: SAVE_TEMPLATE_SUCCESS,
    template,
  }
}

export const deleteTemplate = (template_id) => {
  return {
    type: DELETE_TEMPLATE,
    template_id,
  }
}
export const deleteTemplateFail = (error) => {
  return {
    type: DELETE_TEMPLATE_FAIL,
    error,
  }
}
export const deleteTemplateSuccess = (template_id) => {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    template_id,
  }
}

export const newTemplate = (template) => {
  return {
    type: NEW_TEMPLATE,
    template,
  }
}
export const newTemplateFail = (error) => {
  return {
    type: NEW_TEMPLATE_FAIL,
    error,
  }
}
export const newTemplateSuccess = (template) => {
  return {
    type: NEW_TEMPLATE_SUCCESS,
    template,
  }
}

export const uploadMessageImage = () => {
  return {
    type: UPLOAD_MESSAGE_IMAGE,
  }
}
export const uploadMessageImageFail = (error) => {
  return {
    type: UPLOAD_MESSAGE_IMAGE_FAIL,
    error,
  }
}
export const uploadMessageImageSuccess = () => {
  return {
    type: UPLOAD_MESSAGE_IMAGE_SUCCESS,
  }
}

export const clearReduxMessagesState = () => {
  return {
    type: CLEAR_REDUX_MESSAGES_STATE,
  }
}

export const setInitialReduxMessagesState = () => {
  return {
    type: SET_INITIAL_REDUX_MESSAGES_STATE,
  }
}

export const setLoading = (value) => {
  return {
    type: SET_LOADING,
    value: value,
  }
}
export const resendMessage = (type, id) => {
  return {
    type: RESEND_MESSAGE,
    messageType: type,
    id,
  }
}
export const resendMessageFail = (error) => {
  return {
    type: RESEND_MESSAGE_FAIL,
    error,
  }
}
export const resendMessageSuccess = () => {
  return {
    type: RESEND_MESSAGE_SUCCESS,
  }
}

export const fetchAiDraft = (person_id, tone, subject, preset_template_id) => {
  return {
    type: FETCH_AI_DRAFT,
    person_id,
    tone,
    subject,
    preset_template_id,
  }
}
export const fetchAiDraftFail = (error) => {
  return {
    type: FETCH_AI_DRAFT_FAIL,
    error,
  }
}

export const fetchAiDraftSuccess = (aiDraft, person_id, messageSenderVisible) => {
  return {
    type: FETCH_AI_DRAFT_SUCCESS,
    aiDraft,
    person_id,
    messageSenderVisible,
  }
}

export const setMessageDraft = (aiDraft) => {
  return {
    type: SET_MESSAGE_DRAFT,
    aiDraft,
  }
}
