import React, { useState } from "react"
import { Button, Col, Modal, Row } from "antd"
import { useDispatch } from "react-redux"
import { addExtraTrialDays } from "../../../redux/Admin/Admin.actions"

const ExtraTrialDaysModal = ({ user_id, visible, onCancel }) => {
  const [numberOfExtraDays, setNumberOfExtraDays] = useState(0)
  const dispatch = useDispatch()

  return (
    <Modal
      closable={false}
      width={"40vw"}
      destroyOnClose
      footer={null}
      title={<h3>Add extra trial days</h3>}
      visible={visible}
      onCancel={() => onCancel()}
    >
      <div style={{ padding: "2rem" }}>
        <span>How many extra days do you want to add?</span>
        <br />
        <span>
          I want to add{" "}
          <input
            value={numberOfExtraDays}
            onChange={(e) => setNumberOfExtraDays(e.target.value)}
            type={"number"}
          ></input>{" "}
          extra days.
        </span>
        <hr style={{ margin: "1rem" }} />
        <div>
          <Row>
            <Col offset={1} md={11}>
              <Button
                onClick={() => {
                  onCancel()
                }}
                style={{ margin: "1rem 0" }}
                type={"danger"}
                block
              >
                Cancel
              </Button>
            </Col>
            <Col offset={1} md={11}>
              <Button
                onClick={() => {
                  dispatch(addExtraTrialDays(user_id, numberOfExtraDays))
                  onCancel()
                }}
                style={{ margin: "1rem 0" }}
                type={"primary"}
                block
                disabled={numberOfExtraDays <= 0}
              >
                Add extra days
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

export default ExtraTrialDaysModal
