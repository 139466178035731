import React, { useEffect, useRef, useState } from "react"
import "./GrowRelationshipsTab.styles.scss"
import { Spin } from "antd"
import Avatar from "../../../../UI/Avatar/Avatar.component"
import Icon from "@ant-design/icons"
import GoToArrayIcon from "../../../../Icons/GoToArray.icons"
import {
  fetchCountedEngagementStatuses,
  fetchMeetings,
} from "./GrowRelationshipsTab.actions"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment/moment"
import {
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../../redux/App/App.actions"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const GrowRelationshipsTab = () => {
  const [loadingEngagementStatuses, setLoadingEngagementStatuses] = useState(true)
  const [loadingMeetings, setLoadingMeetings] = useState(true)
  const [engagements, setEngagements] = useState({})
  const [meetings, setMeetings] = useState([])
  const [leftBoxHeight, setLeftBoxHeight] = useState(120)
  const leftBoxRef = useRef(null)
  const user = useSelector((state) => state.UserState)

  const dispatch = useDispatch()

  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )

  useEffect(() => {
    fetchCountedEngagementStatuses().then((data) => {
      setEngagements(data)
      setLoadingEngagementStatuses(false)
    })
    fetchMeetings().then((data) => {
      setMeetings(data)
      setLoadingMeetings(false)
    })
  }, [])

  const isLeapYearAnd29Feb = (date) => {
    const currentYear = parseInt(moment().tz(user.time_zone).format("YYYY"))
    return (
      date &&
      currentYear % 4 !== 0 &&
      moment(date).tz(user.time_zone).format("MM-DD") === "02-29"
    )
  }

  const timeFromNow = (date) => {
    if (date) {
      const currentYear = parseInt(moment().tz(user.time_zone).format("YYYY"))
      const currentMonth = moment().tz(user.time_zone).format("M")
      const currentDate = moment().tz(user.time_zone).format("YYYY-MM-DD")
      const dateMonth = moment(date).tz(user.time_zone).format("M")
      let formattedDate

      if (currentMonth === "12" && dateMonth === "1") {
        formattedDate = moment(date)
          .tz(user.time_zone)
          .format(`${currentYear + 1}-MM-DD`)
      } else if (currentMonth === "1" && dateMonth === "12") {
        formattedDate = moment(date)
          .tz(user.time_zone)
          .format(`${currentYear - 1}-MM-DD`)
      } else if (isLeapYearAnd29Feb(date)) {
        formattedDate = moment(`${currentYear}-03-01`).tz(user.time_zone)
      } else {
        formattedDate = moment(date)
          .tz(user.time_zone)
          .format(`${currentYear}-MM-DD`)
      }

      if (formattedDate === currentDate) {
        return "today"
      } else {
        return moment(formattedDate).tz(user.time_zone).from(currentDate)
      }
    } else {
      return ""
    }
  }

  const handleClickViewPerson = (personId, personSlug) => {
    if (contactDrawerVisible && +visibleContactID === +personId) {
      dispatch(setContactDrawerVisible(false))
      dispatch(setVisibleContactID(null))
      dispatch(setActiveTab(null))
    } else if (contactDrawerVisible && +visibleContactID !== +personId) {
      dispatch(setContactDrawerMode("view"))
      dispatch(setActiveTab(null))
      dispatch(setVisibleContactID(+personId, personSlug))
    } else {
      dispatch(setActiveTab(null))
      dispatch(setContactDrawerVisible(true))
      dispatch(setVisibleContactID(personId, personSlug))
    }
  }

  useEffect(() => {
    if (leftBoxRef.current.offsetHeight > 0) {
      setLeftBoxHeight(leftBoxRef.current.offsetHeight)
    }
  }, [loadingEngagementStatuses])

  return (
    <div className="GrowRelationships">
      <div className="GrowRelationships_Body">
        <h1>
          Take any of these actions right now in order to be able to deepen
          relationships with your sphere.
        </h1>
        <div className="GrowRelationships_Body_Actions">
          <div className="StayInTouch">
            <h3>Stay in touch with your network</h3>
            <div className="StayInTouch_Body" ref={leftBoxRef}>
              <div>
                <span>
                  Choose how often you want to stay in touch with any of your
                  spheres, and we’ll keep track if it’s been too long.
                </span>
              </div>
              <div>
                <p>Click below to see who you might want to catch up with.</p>
                {loadingEngagementStatuses ? (
                  <div className="StayInTouch_Loading">
                    <RelatableLoader />
                  </div>
                ) : (
                  <>
                    <div className="StayInTouch_Bars">
                      {engagements.total_count > 0 ? (
                        <>
                          <a
                            href="database?people_filters=engagement_status%3A%20hide_none%2Chide_fade"
                            className="StayInTouch_Bars_GreenBar"
                            style={{
                              width: `${engagements.green_count_percentage}%`,
                            }}
                          >
                            {" "}
                          </a>
                          <a
                            href="database?people_filters=engagement_status%3A%20hide_none%2Chide_good"
                            className="StayInTouch_Bars_YellowBar"
                            style={{
                              width: `${engagements.yellow_count_percentage}%`,
                            }}
                          >
                            {" "}
                          </a>
                        </>
                      ) : (
                        <div className="StayInTouch_Bars_GreyBar" />
                      )}
                    </div>
                    <div className="StayInTouch_BarsTitle">
                      {engagements.total_count > 0 ? (
                        <>
                          <a href="database?people_filters=engagement_status%3A%20hide_none%2Chide_fade">
                            {engagements.green_count} OK
                          </a>
                          <a href="database?people_filters=engagement_status%3A%20hide_none%2Chide_good">
                            {engagements.yellow_count} Fading
                          </a>
                        </>
                      ) : (
                        <span>
                          Turn on Stay in Touch reminders to get suggestions
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="KeepOrganized">
            <h3>Keep organized</h3>
            <div className="KeepOrganized_Body">
              <span>
                Adding the people you care about to one or more spheres will ensure
                you never forget them. The Spheralizer is our handy tool to quickly
                put everyone in the right place.
              </span>
              <a href="database/spheralizer">Launch Spheralizer</a>
            </div>
          </div>
          <div className="RememberDetails">
            <h3>Prepare and remember the details</h3>
            {loadingMeetings ? (
              <div className="RememberDetails_Loading">
                <RelatableLoader />
              </div>
            ) : meetings.length > 0 ? (
              <div className="RememberDetails_Body">
                <span>
                  Here are the people on your <a href="account/settings">calendar</a>{" "}
                  today.
                </span>
                <div
                  className="RememberDetails_Events"
                  style={{ maxHeight: leftBoxHeight }}
                >
                  {meetings.map((m) => (
                    <div className="RememberDetails_Events_Event">
                      <Avatar
                        size={36}
                        avatar={m.person.avatar}
                        firstName={m.person.full_name?.split(" ")[0]}
                        lastName={m.person.full_name?.split(" ")[1]}
                        colorNumber={m.person.id}
                      />
                      <div>
                        <span className="FullName">
                          <b>{m.person.full_name}</b> @ {timeFromNow(m.date)}
                        </span>
                        <p className="Details">{m.name}</p>
                      </div>
                      <button
                        onClick={() =>
                          handleClickViewPerson(m.person.id, m.person.slug)
                        }
                      >
                        <Icon component={GoToArrayIcon} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <span>
                No meetings found. Have you connected your{" "}
                <a href="account/settings">calendar</a> accounts?
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

GrowRelationshipsTab.propTypes = {}

GrowRelationshipsTab.defaultProps = {}

export default GrowRelationshipsTab
