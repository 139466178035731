import React, { useEffect, useState } from "react"
import { Modal, Select } from "antd"
import "./DuplicatedEmailAndPhoneNumbersModal.styles.scss"
import history from "../../../../history"
import { setContactDrawerMode } from "../../../../redux/App/App.actions"
import { useDispatch } from "react-redux"
import { mergeContacts } from "../../../../redux/People/People.actions"

const DuplicatedEmailAndPhoneNumbersModal = ({
  visible,
  onCancel,
  duplicatedEmails,
  duplicatedPhoneNumbers,
  currentContact,
  mergeAvailable,
}) => {
  const dispatch = useDispatch()
  const [main_contact_id, setMainContactId] = useState(null)
  const [mergedContacts, setMergedContacts] = useState([])

  useEffect(() => {
    if (currentContact && (duplicatedEmails || duplicatedPhoneNumbers)) {
      const mergedContacts = [
        ...duplicatedEmails,
        ...duplicatedPhoneNumbers,
        currentContact,
      ].map((contact) => ({
        value: contact.person_id,
        label: contact.person_full_name,
      }))
      setMergedContacts(mergedContacts)
    }
  }, [duplicatedEmails, duplicatedPhoneNumbers])

  const handleClickMerge = () => {
    dispatch(
      mergeContacts(
        mergedContacts.map((c) => c.value),
        1,
        [],
        null,
        null,
        null,
        main_contact_id
      )
    )
    onCancel()
  }

  const generateDescription = () => {
    const isFewEmails = duplicatedEmails.length > 1
    const isOneEmail = duplicatedEmails.length === 1
    const isFewPhoneNumbers = duplicatedPhoneNumbers.length > 1
    const isOnePhoneNumber = duplicatedPhoneNumbers.length === 1
    let partOfDescription = ""

    if (isFewEmails && isFewPhoneNumbers) {
      partOfDescription =
        "these email addresses and these phone numbers from the existing contacts"
    } else if (isFewEmails && isOnePhoneNumber) {
      partOfDescription =
        "these email addresses and the phone number from the existing contacts"
    } else if (isOneEmail && isFewPhoneNumbers) {
      partOfDescription =
        "the email address and these phone numbers from the existing contacts"
    } else if (isOneEmail && isOnePhoneNumber) {
      partOfDescription =
        "the email address and the phone number from the existing contacts"
    } else if (isFewPhoneNumbers) {
      partOfDescription = "these phone numbers from the existing contacts"
    } else if (isOnePhoneNumber) {
      partOfDescription = "the phone number from the existing contact"
    } else if (isFewEmails) {
      partOfDescription = "these email addresses from the existing contacts"
    } else if (isOneEmail) {
      partOfDescription = "the email address from the existing contact"
    } else {
      partOfDescription =
        "these email addresses and these phone numbers from the existing contacts"
    }

    return (
      <span>
        Please {mergeAvailable ? "merge contacts or" : ""} remove {partOfDescription}{" "}
        before adding it here
      </span>
    )
  }

  const handleShowPerson = (slug) => {
    dispatch(setContactDrawerMode("view"))
    onCancel()
    history.push(`/database/person/${slug}`)
  }

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onCancel}
      centered={true}
      width={680}
      className={"DuplicatedEmailAndPhoneNumbersModal__modal"}
    >
      <div className="DuplicatedEmailAndPhoneNumbersModal">
        <div className={"DuplicatedEmailAndPhoneNumbersModal_Text"}>
          <span className={"DuplicatedEmailAndPhoneNumbersModal_Text_Title"}>
            Validation failed
          </span>
          <span className="DuplicatedEmailAndPhoneNumbersModal_Text_Description">
            <p>{generateDescription()}</p>
            {duplicatedEmails.length > 0 ? (
              <h4 style={{ marginBottom: "4px" }}>Duplicated emails:</h4>
            ) : null}
            {duplicatedEmails.map((d_e, index) => (
              <p
                key={index}
                className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem"
              >
                {d_e.email} -{" "}
                <a
                  href={`/database/person/${d_e.person_slug}`}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem_FullName"
                >
                  {d_e.person_full_name}
                </a>
              </p>
            ))}
            {duplicatedPhoneNumbers.length > 0 ? (
              <h4 style={{ margin: "12px 0 4px" }}>Duplicated phone numbers:</h4>
            ) : null}
            {duplicatedPhoneNumbers.map((d_p, index) => (
              <p
                key={index}
                className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem"
              >
                {d_p.phone_number} -{" "}
                <a
                  href={`/database/person/${d_p.person_slug}`}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem_FullName"
                >
                  {d_p.person_full_name}
                </a>
              </p>
            ))}
          </span>
        </div>

        <div className={"DuplicatedEmailAndPhoneNumbersModal_Options"}>
          {mergeAvailable ? (
            <div className="DuplicatedEmailAndPhoneNumbersModal_Buttons">
              <Select
                style={{ width: "100%", marginTop: "6px" }}
                placeholder="Select main contact"
                optionFilterProp="children"
                onChange={setMainContactId}
                options={mergedContacts}
              />
              <div
                role={"button"}
                className={"DuplicatedEmailAndPhoneNumbersModal_YesButton"}
                onClick={handleClickMerge}
              >
                Merge
              </div>
            </div>
          ) : null}
          <div className="DuplicatedEmailAndPhoneNumbersModal_Buttons">
            <div
              role={"button"}
              className="DuplicatedEmailAndPhoneNumbersModal_NoButton"
              onClick={onCancel}
            >
              Back to Editing
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DuplicatedEmailAndPhoneNumbersModal
