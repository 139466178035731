import React from "react"
import "./LeftRail.styles.scss"
import PropTypes from "prop-types"

const FeatureCard = ({ title, description, image }) => {
  return (
    <div className={"LeftRail_FeatureCard"}>
      <div className={"LeftRail_FeatureCard_Content"}>
        <span className={"LeftRail_FeatureCard_Title"}>{title}</span>
        <span className={"LeftRail_FeatureCard_Description"}>{description}</span>
      </div>
      <div className={"LeftRail_FeatureCard_Image"}>{image}</div>
    </div>
  )
}

FeatureCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  image: PropTypes.node,
}

export default FeatureCard
