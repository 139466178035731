import React from "react"
import { Button, Card } from "antd"

const ReduxStateCard = ({ title, clearState, setInitialState }) => {
  return (
    <Card style={{ width: "fit-content" }} title={title}>
      <Button style={{ marginRight: "10px" }} onClick={clearState}>
        Clear
      </Button>
      <Button onClick={setInitialState}>Set initial</Button>
    </Card>
  )
}

export default ReduxStateCard
