import React, { useEffect, useState } from "react"
import "./Reminders.styles.scss"
import { Skeleton } from "antd"
import DashboardReminder from "./DashboardReminder.component"

const Reminders = ({ reminders, remindersCount }) => {
  const [numberOfVisible, setNumberOfVisible] = useState(remindersCount)
  const [fakeLoading, setFakeLoading] = useState(false)

  useEffect(() => {
    setNumberOfVisible(remindersCount)
  }, [remindersCount])

  const handleShowMore = () => {
    setFakeLoading(true)
    setTimeout(() => {
      setNumberOfVisible(+numberOfVisible + 3)
      setFakeLoading(false)
    }, 200)
  }

  return (
    <div className="Reminders">
      {reminders?.slice(0, numberOfVisible).map((reminder, i) => (
        <DashboardReminder reminder={reminder} index={i} key={i} />
      ))}
      <Skeleton avatar title={false} loading={fakeLoading} active />
      <Skeleton avatar title={false} loading={fakeLoading} active />
      <Skeleton avatar title={false} loading={fakeLoading} active />
      {reminders?.length > numberOfVisible && (
        <div className="Reminders_LoadingMore">
          <button onClick={handleShowMore}>Load more...</button>
        </div>
      )}
    </div>
  )
}

export default Reminders
