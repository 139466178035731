import {
  ADD_PARTICIPANTS_TO_SPHERES,
  ADD_PARTICIPANTS_TO_SPHERES_FAIL,
  ADD_PARTICIPANTS_TO_SPHERES_SUCCESS,
  ADD_TO_SPHERES,
  ADD_TO_SPHERES_FAIL,
  ADD_TO_SPHERES_SUCCESS,
  ARCHIVE_PARTICIPANTS,
  ARCHIVE_PARTICIPANTS_FAIL,
  ARCHIVE_PARTICIPANTS_SUCCESS,
  CLEAR_REDUX_PARTICIPANTS_STATE,
  FETCH_PARTICIPANTS,
  FETCH_PARTICIPANTS_FAIL,
  FETCH_PARTICIPANTS_SUCCESS,
  FETCH_RESERVE_PARTICIPANTS,
  FETCH_RESERVE_PARTICIPANTS_FAIL,
  FETCH_RESERVE_PARTICIPANTS_SUCCESS,
  SET_INITIAL_REDUX_PARTICIPANTS_STATE,
} from "./Participants.types"
import { LOGOUT_SUCCESS } from "../User/User.types"
import * as _ from "lodash"

const INITIAL_STATE = {
  participants: null,
  total_entries: 0,
  fetching: false,
  fetching_reserve: false,
}

export const participantsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PARTICIPANTS:
      return {
        ...state,
        fetching: true,
      }
    case FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.participants,
        total_entries: action.total_entries,
        fetching: false,
      }
    case FETCH_PARTICIPANTS_FAIL:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    case FETCH_RESERVE_PARTICIPANTS:
      return {
        ...state,
        fetching_reserve: true,
      }
    case FETCH_RESERVE_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: _.uniqBy(
          [...state.participants, ...action.participants],
          "id"
        ),
        total_entries: action.total_entries,
        fetching_reserve: false,
      }
    case FETCH_RESERVE_PARTICIPANTS_FAIL:
      return {
        ...state,
        error: action.error,
        fetching_reserve: false,
      }
    case ADD_TO_SPHERES:
      return {
        ...state,
        fetching: true,
      }
    case ADD_TO_SPHERES_SUCCESS:
      return {
        ...state,
        participants: state.participants.filter(
          (participant) => participant.id !== action.participant_id
        ),
        fetching: false,
      }
    case ADD_TO_SPHERES_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case ADD_PARTICIPANTS_TO_SPHERES:
      return {
        ...state,
        fetching: true,
      }
    case ADD_PARTICIPANTS_TO_SPHERES_SUCCESS:
      return {
        ...state,
        participants: state.participants.filter(
          (participant) => !action.participants_ids.includes(participant.id)
        ),
        fetching: false,
      }
    case ADD_PARTICIPANTS_TO_SPHERES_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case ARCHIVE_PARTICIPANTS:
      return {
        ...state,
        fetching: true,
      }
    case ARCHIVE_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: state.participants.filter(
          (participant) => !action.participants_ids.includes(participant.id)
        ),
        fetching: false,
      }
    case ARCHIVE_PARTICIPANTS_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_PARTICIPANTS_STATE:
      return {}
    case SET_INITIAL_REDUX_PARTICIPANTS_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
