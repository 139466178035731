import axios from "axios"

export const fetchSharingDetails = async (token, callback) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/${token}`,
  })
  callback(data)
}

export const fetchSharedPerson = async (contactId, token, callback) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/shared_contact/${contactId}`,
    params: {
      token: token,
    },
    method: "GET",
  })

  callback(data.person)
}

export const fetchSharedNotesPerson = async (contactId, token, page, callback) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/get_shared_notes/${contactId}`,
    params: {
      token: token,
      page: page,
      per_page: 3,
    },
    method: "GET",
  })

  callback(data.notes, data.total_entries)
}

export const fetchSharedEventsPerson = async (contactId, token, page, callback) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/get_shared_events/${contactId}`,
    params: {
      token: token,
      page: page,
      per_page: 3,
    },
    method: "GET",
  })

  callback(data.events, data.total_entries)
}

export const fetchSharedInteractionsPerson = async (
  contactId,
  token,
  page,
  callback
) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/get_shared_interactions/${contactId}`,
    params: {
      token: token,
      page: page,
      per_page: 3,
    },
    method: "GET",
  })

  callback(data.interactions, data.total_entries)
}

export const fetchSharedRemindersPerson = async (
  contactId,
  token,
  page,
  callback
) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/get_shared_reminders/${contactId}`,
    params: {
      token: token,
      page: page,
      per_page: 3,
    },
    method: "GET",
  })

  callback(data.reminders, data.total_entries)
}

export const updateSharedPerson = async (contactId, values, token, callback) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/update_shared_contact/${contactId}`,
    data: { ...values, token: token },
    method: "PUT",
  })

  callback(data.person)
}

export const createSharedPerson = async (values, image, source, token, callback) => {
  const {
    data: { person },
  } = await axios.request({
    url: `/v1/collection_sharings/create_shared_contact`,
    data: { ...values, source: source, token: token },
    method: "POST",
  })
  if (image) {
    const form_data = new FormData()
    form_data.append("profile_image", image, image.name)
    form_data.append("token", token)

    const { data } = await axios.request({
      url: `/v1/collection_sharings/update_shared_contact/${person.id}`,
      data: form_data,
      method: "PATCH",
    })
    callback(data.person)
  } else {
    callback(person)
  }
}

export const updateSharedContactImage = async (id, image, token, callback) => {
  const form_data = new FormData()
  form_data.append("profile_image", image, image.name)
  form_data.append("token", token)
  const { data } = await axios.request({
    url: `/v1/collection_sharings/update_shared_contact/${id}`,
    data: form_data,
    method: "PATCH",
  })
  callback(data.person)
}

export const archiveSharedPerson = async (contactId, token, callback) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/delete_shared_contact/${contactId}`,
    data: { token: token },
    method: "DELETE",
  })

  callback(data.person)
}
