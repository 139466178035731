import React from "react"
import "./ImportanceIndicator.styles.scss"
import { Row } from "antd"

const ImportanceIndicator = ({ level, disabled = false }) => {
  const labels = {
    na: "N/A",
    not_important: "Low",
    medium: "Medium",
    important: "Important",
    very_important: "Very",
  }

  return (
    <Row className="ImportanceIndicator">
      <div
        className={
          disabled ? "ImportanceIndicator_Bars-disabled" : "ImportanceIndicator_Bars"
        }
      >
        <div className={`ImportanceIndicator_Bars_Bar-1 ${level}`} />
        <div className={`ImportanceIndicator_Bars_Bar-2 ${level}`} />
        <div className={`ImportanceIndicator_Bars_Bar-3 ${level}`} />
        <div className={`ImportanceIndicator_Bars_Bar-4 ${level}`} />
      </div>

      <p>{labels[level]}</p>
    </Row>
  )
}

export default ImportanceIndicator
