export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS"
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS"
export const FETCH_ACCOUNTS_FAIL = "FETCH_ACCOUNTS_FAIL"

export const ADD_ACCOUNT = "ADD_ACCOUNT"
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS"
export const ADD_ACCOUNT_FAIL = "ADD_ACCOUNT_FAIL"

export const MAKE_DEFAULT = "MAKE_DEFAULT"
export const MAKE_DEFAULT_SUCCESS = "MAKE_DEFAULT_SUCCESS"
export const MAKE_DEFAULT_FAIL = "MAKE_DEFAULT_FAIL"

export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS"
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL"

export const RESYNC_INTERACTIONS = "RESYNC_INTERACTIONS"
export const RESYNC_INTERACTIONS_START = "RESYNC_INTERACTIONS_START"
export const RESYNC_INTERACTIONS_SUCCESS = "RESYNC_INTERACTIONS_SUCCESS"
export const RESYNC_INTERACTIONS_FAIL = "RESYNC_INTERACTIONS_FAIL"

export const UPDATE_SIGNATURE = "UPDATE_SIGNATURE"
export const UPDATE_SIGNATURE_SUCCESS = "UPDATE_SIGNATURE_SUCCESS"
export const UPDATE_SIGNATURE_FAIL = "UPDATE_SIGNATURE_FAIL"

export const UPDATE_CRITERIA = "UPDATE_CRITERIA"
export const UPDATE_CRITERIA_SUCCESS = "UPDATE_CRITERIA_SUCCESS"
export const UPDATE_CRITERIA_FAIL = "UPDATE_CRITERIA_FAIL"

export const CLEAR_REDUX_ACCOUNTS_STATE = "CLEAR_REDUX_ACCOUNTS_STATE"
export const SET_INITIAL_REDUX_ACCOUNTS_STATE = "SET_INITIAL_REDUX_ACCOUNTS_STATE"

export const FETCH_CALENDARS = " FETCH_CALENDARS"
export const FETCH_CALENDARS_SUCCESS = " FETCH_CALENDARS_SUCCESS"
export const FETCH_CALENDARS_FAIL = " FETCH_CALENDARS_FAIL"

export const CREATE_STRIPE_SESSION = "CREATE_STRIPE_SESSION"
export const CREATE_STRIPE_SESSION_SUCCESS = "CREATE_STRIPE_SESSION_SUCCESS"
export const CREATE_STRIPE_SESSION_FAIL = "CREATE_STRIPE_SESSION_FAIL"

export const SHOW_UNSUBSCRIBE_NOTIFICATION = "SHOW_UNSUBSCRIBE_NOTIFICATION"

export const SHOW_COPIED_API_KEY_NOTIFICATION = "SHOW_COPIED_API_KEY_NOTIFICATION"
