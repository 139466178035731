import React, { useEffect, useState } from "react"
import axios from "axios"
import * as _ from "lodash"
import "./Import.styles.scss"
import { useLocation } from "react-router"
import { Button, Checkbox, Form, notification, Select, Table } from "antd"
import SpheresSelector from "../../../components/Database/SpheresSelector/SpheresSelector.component"
import CollectionSelector from "../../../components/Database/CollectionSelector/CollectionSelector.component"
import history from "../../../history"
import {
  fetchExpertises,
  fetchSpheres,
  fetchTopics,
} from "../../../redux/Collections/Collections.actions"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import RelatableLoader from "../../../components/UI/RelatableLoader/RelatableLoader.component"

const ImportPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [parsing, setParsing] = useState(false)
  const [firstRowAsHeader, setFirstRowAsHeader] = useState(false)
  const [rows, setRows] = useState([])
  const [mapping, setMapping] = useState([])
  const [spheres, setSpheres] = useState(null)
  const [topics, setTopics] = useState(null)
  const [expertises, setExpertises] = useState(null)
  const [importId, setImportId] = useState(null)
  const [fileName, setFileName] = useState(null)
  const params = useParams()

  const formatCell = (index, record) => {
    const mapped = mapping.find((col) => +col.id === +index)
    const labeled_values = [
      "email_addresses",
      "phone_numbers",
      "addresses",
      "profile_fields",
      "special_dates",
      "links",
    ]
    const collection_values = ["spheres", "topics", "expertises", "companies"]

    if (mapped && labeled_values.includes(mapped.value)) {
      return (
        <div>
          {formatLabeled(record, mapped.value).map((v) => (
            <>
              <span className={"ImportPage_Table_Cell-value"}>{v.value}</span>
              {v.name.length > 0 && (
                <span className={"ImportPage_Table_Cell-label"}>({v.name})</span>
              )}
              <br />
            </>
          ))}
        </div>
      )
    } else if (mapped && collection_values.includes(mapped.value)) {
      return (
        <>
          {formatCollections(record).map((v) => (
            <span className={"ImportPage_Table_Cell-sphere"}>{v.title}</span>
          ))}
        </>
      )
    } else {
      if (typeof record === "string") {
        return record
      } else {
        return null
      }
    }
  }

  const previewCSV = async () => {
    const id = location?.state?.id || params.id
    setImportId(id)
    const { data } = await axios.request({
      url: `/v1/people/import/${id}`,
    })
    const fileName = location?.state?.name || data.filename
    setFileName(fileName)
    setRows(data.preview)
  }

  useEffect(() => {
    previewCSV()

    if (!topics) {
      dispatch(fetchTopics())
    }
    if (!expertises) {
      dispatch(fetchExpertises())
    }
    if (!spheres) {
      dispatch(fetchSpheres())
    }
    // eslint-disable-next-line
  }, [])

  const mapColumn = (index, value) => {
    const newMapping = _.reverse(
      _.uniqBy(_.reverse([...mapping, { id: index, value: value }]), "id")
    )
    setMapping(newMapping)
  }

  const renderDefaultHeader = (rows) => {
    let head = []
    for (let i = 0; i < rows[0].length; i++) {
      head.push({
        title: () => {
          return (
            <div className={"ImportPage_Table_Header-container"}>
              <Form.Item validateStatus="success" help={[]}>
                <Select
                  onClear={() => {
                    const newMapping = mapping.filter((m) => m.id !== i)
                    setMapping(newMapping)
                  }}
                  allowClear={true}
                  onSelect={(value) => mapColumn(i, value)}
                  placeholder={"Import as..."}
                  className={"ImportPage_Table_Header-select"}
                >
                  <Select.Option label={"First name"} value={"first_name"}>
                    First name
                  </Select.Option>
                  <Select.Option label={"Last name"} value={"last_name"}>
                    Last name
                  </Select.Option>
                  <Select.Option label={"One liner"} value={"one_liner"}>
                    One liner
                  </Select.Option>
                  <Select.Option label={"Location"} value={"location"}>
                    Location
                  </Select.Option>
                  <Select.Option label={"Profile"} value={"profile_fields"}>
                    Profile Fields
                  </Select.Option>
                  <Select.Option label={"Links"} value={"links"}>
                    Links
                  </Select.Option>
                  <Select.Option label={"Birthday"} value={"birthday"}>
                    Birthday
                  </Select.Option>
                  <Select.Option label={"Special Dates"} value={"special_dates"}>
                    Special Dates
                  </Select.Option>
                  <Select.Option label={"Email"} value={"email_addresses"}>
                    Email Addresses
                  </Select.Option>
                  <Select.Option label={"Phone"} value={"phone_numbers"}>
                    Phone Numbers
                  </Select.Option>
                  <Select.Option label={"Addresses"} value={"addresses"}>
                    Addresses
                  </Select.Option>
                  <Select.Option label={"Spheres"} value={"spheres"}>
                    Spheres
                  </Select.Option>
                  <Select.Option label={"Topics"} value={"topics"}>
                    Topics
                  </Select.Option>
                  <Select.Option label={"Expertise"} value={"expertises"}>
                    Expertise
                  </Select.Option>
                  <Select.Option label={"Companies"} value={"companies"}>
                    Companies
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          )
        },
        dataIndex: i,
        key: i,
        className: "ImportPage_Table_Cell",
        render: (record) => formatCell(i, record),
      })
    }

    return head
  }

  const formatLinks = (v) => {
    const splitted = v.split(":")
    let label = null
    let record = null

    if (splitted.length >= 2) {
      label =
        splitted[0].includes("http") || splitted[0].includes("https")
          ? ""
          : splitted[0]

      splitted.every((s, index) => {
        if (s.includes("https")) {
          record = "https:" + splitted[index + 1]
          return false
        } else if (s.includes("http")) {
          record = "http:" + splitted[index + 1]
          return false
        } else {
          record = splitted[1]
          return true
        }
      })

      return { name: label.trim(), value: record.trim() }
    } else {
      record = splitted[0]
      return { name: "", value: record.trim() }
    }
  }

  const formatLabeled = (value, mapped_label) => {
    if (value) {
      const singular = value.split(",").map((v) => {
        if (mapped_label === "links") {
          return formatLinks(v)
        }
        const splitted = v.split(":")
        let label = null
        let record = null

        if (splitted.length === 2) {
          label = splitted[0]
          record = splitted[1]
          return { name: label.trim(), value: record.trim() }
        } else {
          record = splitted[0]
          return { name: "", value: record.trim() }
        }
      })
      return singular
    } else {
      return [{ name: "", value: "" }]
    }
  }
  const formatCollections = (value) => {
    if (value) {
      const singular = value.split(",")

      return singular.map((collection) => ({ title: collection.trim() }))
    } else {
      return []
    }
  }

  const importCSV = async () => {
    setParsing(true)
    const { data } = await axios.request({
      url: "/v1/people/import/confirm",
      data: {
        import_id: importId,
        spheres: spheres,
        topics: topics,
        expertises: expertises,
        mapping: mapping,
        first_row_as_header: firstRowAsHeader,
      },
      method: "POST",
    })
    setParsing(false)
    if (data.success) {
      notification.success({
        message: "Import CSV",
        description:
          "Successfully queued contacts for import. This may take some time depending on the file size.",
        className: "Notification-success",
      })
      setTimeout(() => {
        history.push("/database")
      }, 500)
    } else {
      notification.error({
        message: "Import CSV",
        description:
          data.error ||
          "Something went wrong. Please try again or contact us if the problem persists.",
        className: "Notification-error",
      })
    }
  }

  return (
    rows.length > 0 && (
      <div className={"ImportPage"}>
        <div className={"ImportPage_Header-container"}>
          <h3>
            Importing File <strong>{fileName}</strong>
          </h3>
          <div className={"ImportPage_HeaderCheckbox-container"}>
            <Checkbox
              className={"ImportPage_HeaderCheckbox-checkbox"}
              onChange={(e) => setFirstRowAsHeader(e.target.checked)}
            >
              The first line of this file is the header
            </Checkbox>
            <span className={"ImportPage_HeaderCheckbox-label"}>
              If so, we won’t import the first line in the file. This is common.{" "}
            </span>
          </div>
          <div className={"ImportPage_TopicsSelector-container"}>
            <span className={"ImportPage_TopicsSelector-label"}>
              Tag all imported people as...
            </span>
            <div style={{ flex: 1 }}>
              <CollectionSelector
                collectionType={"topics"}
                handleSubmit={(existingTopics, newTopics) => {
                  setTopics([
                    ...existingTopics,
                    ...newTopics.map((title) => ({ title: title })),
                  ])
                }}
              />
            </div>
          </div>
          <div className={"ImportPage_ExpertisesSelector-container"}>
            <span className={"ImportPage_ExpertisesSelector-label"}>
              Add all imported people to
            </span>
            <div style={{ flex: 1 }}>
              <CollectionSelector
                collectionType={"expertises"}
                handleSubmit={(existingExpertises, newExpertises) => {
                  setExpertises([
                    ...existingExpertises,
                    ...newExpertises.map((title) => ({ title: title })),
                  ])
                }}
              />
            </div>
          </div>
          <div className={"ImportPage_SpheresSelector-container"}>
            <span className={"ImportPage_SpheresSelector-label"}>
              Add all imported people to
            </span>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SpheresSelector
                placeholder={"Click to add all contacts to spheres"}
                collapsible
                drawerVisible={true}
                handleSubmit={(existingSpheres, newSpheres) => {
                  setSpheres([...existingSpheres, ...newSpheres])
                }}
              />
            </div>
          </div>
        </div>
        <div className={"ImportPage_Table-container"}>
          <Table
            className={"ImportPage_Table"}
            rowClassName={"ImportPage_Table_Row"}
            loading={{
              spinning: parsing,
              indicator: <RelatableLoader quote={true} loading={parsing} />,
            }}
            columns={renderDefaultHeader(rows)}
            dataSource={rows}
            scroll={{ x: true }}
          />
        </div>
        <div className={"ImportPage_SubmitButton-container"}>
          <span className={"ImportPage_SubmitButton-label"}>
            Review before importing, as this cannot be undone!
          </span>
          <Button
            disabled={
              !mapping ||
              (mapping &&
                !mapping.some(
                  (m) =>
                    m.value === "email_addresses" ||
                    m.value === "first_name" ||
                    m.value === "last_name"
                ))
            }
            className={"ImportPage_SubmitButton-button"}
            onClick={() => importCSV()}
          >
            Import
          </Button>
        </div>
      </div>
    )
  )
}

export default ImportPage
