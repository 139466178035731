import React, { useEffect } from "react"
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from "antd"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import * as yup from "yup"
import { createChallenge, updateChallenge } from "../../../redux/Admin/Admin.actions"
import moment from "moment/moment"
import TextArea from "antd/es/input/TextArea"
import { generateGoalName } from "../../../layout/MainLayout/RightPanel/ActivitiesTab/helpers"
import "./ChallengeDetailsModal.styles.scss"

const ChallengeDetailsModal = ({ challenge, visible, onCancel }) => {
  const dispatch = useDispatch()

  const allGoalsKeys = [
    "csv.completed",
    "interaction.created",
    "introduction.completed",
    "introduction.request_sent",
    "message.created",
    "person.created",
    "reminder.completed",
    "reminder.created",
    "split_message.created",
  ]

  const formatRequest = (values) => {
    return {
      name: values.name,
      description: values.description,
      one_liner: values.one_liner,
      start_date: values.start_date,
      end_date: values.end_date,
      status: values.status,
      goals: values.goals.filter((g) => g.number_of_todo > 0),
    }
  }

  const schema = yup.object().shape({
    name: yup.string().required(),
    // name: yup.string().required().typeError("Name is required"),
    description: yup.string().nullable(),
    one_liner: yup.string().nullable(),
    start_date: yup.date().required(),
    // start_date: yup.date().required().typeError("Start date is required"),
    end_date: yup.date().required(),
    // end_date: yup.date().required().typeError("End date is required"),
    status: yup.string(),
    goals: yup.array().of(
      yup.object().shape({
        type: yup.string(),
        number_of_todo: yup
          .number()
          .required()
          .typeError("Number of todo is required"),
      })
    ),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      one_liner: "",
      start_date: "",
      end_date: "",
      status: "created",
      goals: allGoalsKeys.map((key) => ({ type: key, number_of_todo: 0 })),
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (challenge.id) {
        dispatch(updateChallenge(challenge.id, formatRequest(values)))
      } else {
        dispatch(createChallenge(formatRequest(values)))
      }
      formik.resetForm()
      onCancel()
    },
  })

  const setFormikValues = (ch) => {
    formik.setValues({
      name: ch.name,
      description: ch.description,
      one_liner: ch.one_liner,
      start_date: ch.start_date,
      end_date: ch.end_date,
      status: ch.status,
      goals: formik.values.goals,
    })
    formik.values.goals.forEach((goal, i) => {
      const newGoal = ch.goals.find((x) => x.type === goal.type)
      if (newGoal) {
        formik.setFieldValue(`goals[${i}].number_of_todo`, newGoal.number_of_todo)
      }
    })
  }

  useEffect(() => {
    if (challenge.id) {
      setFormikValues(challenge)
    }
    // eslint-disable-next-line
  }, [challenge])

  const handleClickCancel = () => {
    formik.resetForm()
    onCancel()
  }

  return (
    <Modal
      closable={false}
      width="40vw"
      destroyOnClose
      footer={null}
      title={challenge.name || "New Challenge"}
      visible={visible}
      onCancel={handleClickCancel}
      className="ChallengeDetailsModal"
    >
      <Form
        className="ChallengeDetailsModal_Form"
        labelCol={{ span: 3 }}
        layout="horizontal"
      >
        <Form.Item
          label="Title"
          validateStatus={
            formik.touched.name && formik.errors.name ? "error" : "success"
          }
          help={formik.touched.name && formik.errors.name}
        >
          <Input
            placeholder="Name"
            key="name"
            name="name"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="One liner"
          validateStatus={
            formik.touched.one_liner && formik.errors.one_liner ? "error" : "success"
          }
          help={formik.touched.one_liner && formik.errors.one_liner}
        >
          <TextArea
            rows={2}
            placeholder="One liner"
            key="one_liner"
            name="one_liner"
            value={formik.values.one_liner}
            onChange={(e) => formik.setFieldValue("one_liner", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          validateStatus={
            formik.touched.description && formik.errors.description
              ? "error"
              : "success"
          }
          help={formik.touched.description && formik.errors.description}
        >
          <TextArea
            rows={4}
            placeholder="Description"
            key="description"
            name="description"
            value={formik.values.description}
            onChange={(e) => formik.setFieldValue("description", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Start Date"
          validateStatus={
            formik.touched.start_date && formik.errors.start_date
              ? "error"
              : "success"
          }
          help={formik.touched.start_date && formik.errors.start_date}
        >
          <DatePicker
            placeholder="Start Date"
            allowClear={false}
            key="start_date"
            name="start_date"
            format="MM/DD/YYYY"
            value={
              formik.values.start_date ? moment(formik.values.start_date) : null
            }
            onChange={(e) =>
              formik.setFieldValue(
                "start_date",
                //get date without TimeZone
                new Date(e?._d.getTime() - e?._d.getTimezoneOffset() * 60000)
              )
            }
          />
        </Form.Item>
        <Form.Item
          label="End Date"
          validateStatus={
            formik.touched.end_date && formik.errors.end_date ? "error" : "success"
          }
          help={formik.touched.end_date && formik.errors.end_date}
        >
          <DatePicker
            placeholder="End Date"
            allowClear={false}
            key="end_date"
            name="end_date"
            format="MM/DD/YYYY"
            value={formik.values.end_date ? moment(formik.values.end_date) : null}
            onChange={(e) =>
              formik.setFieldValue(
                "end_date",
                //get date without TimeZone
                new Date(e?._d.getTime() - e?._d.getTimezoneOffset() * 60000)
              )
            }
          />
        </Form.Item>
        <Form.Item
          label="Status"
          validateStatus={
            formik.touched.status && formik.errors.status ? "error" : "success"
          }
          help={formik.touched.status && formik.errors.status}
        >
          <Select
            key="status"
            name="status"
            value={formik.values.status}
            onSelect={(e) => formik.setFieldValue("status", e)}
          >
            <Select.Option value="created">Created</Select.Option>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="canceled">Canceled</Select.Option>
            <Select.Option value="finished">Finished</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Goals" className="ChallengeDetailsModal_Form_Goals">
          {formik.values.goals.map((goal, i) => (
            <Form.Item
              key={goal.type}
              className="ChallengeDetailsModal_Form_Goals_Goal"
              validateStatus={
                formik.touched.goals &&
                formik.errors.goals &&
                formik.touched.goals[i]?.number_of_todo &&
                formik.errors.goals[i]?.number_of_todo
                  ? "error"
                  : "success"
              }
              help={
                formik.touched.goals &&
                formik.errors.goals &&
                formik.touched.goals[i]?.number_of_todo &&
                formik.errors.goals[i]?.number_of_todo
              }
            >
              {generateGoalName(goal.type, goal.number_of_todo)}
              <InputNumber
                disabled={challenge.status !== "created" && challenge.id}
                key={goal.type}
                name={goal.type}
                min={0}
                value={goal.number_of_todo}
                onChange={(e) =>
                  formik.setFieldValue(`goals[${i}].number_of_todo`, e)
                }
              />
            </Form.Item>
          ))}
        </Form.Item>
        <Form.Item className="ChallengeDetailsModal_Form_SubmitWrapper">
          <Button type="primary" onClick={() => formik.submitForm()}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChallengeDetailsModal
