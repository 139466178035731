import React from "react"
import "./LoginPageTemplate.styles.scss"
import { Link } from "react-router-dom"
import EnvelopeIcon from "../../Icons/Envelope.icons"
import Icon, { UserOutlined } from "@ant-design/icons"
import { Avatar } from "antd"
import { StringParam, useQueryParam } from "use-query-params"

const PageTemplate = ({
  children,
  backgroundImage,
  subtitle = "Your relationships are your most important asset.",
}) => {
  const [collection_title] = useQueryParam("collection_title", StringParam)
  const [shared_by] = useQueryParam("shared_by", StringParam)

  return (
    <div className="PageTemplate">
      <div className="PageTemplate_Content">
        <div className="PageTemplate_Legal">
          <Link to="/terms">Terms of Service</Link> |{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </div>
        <div className="PageTemplate_Content_Form">
          <div>
            <img
              className="PageTemplate_Content_Form_Logo"
              src="../primaryv2.svg"
              alt="Relatable"
            />
            {collection_title && shared_by && (
              <div className={"PageTemplate_SharingWrapper"}>
                <Avatar icon={<UserOutlined />} />
                <div className={"PageTemplate_SharingWrapper_Header"}>
                  <span className={"PageTemplate_SharingWrapper_Header_Title"}>
                    {shared_by} wants to share his sphere of {collection_title} with
                    you.
                  </span>
                  <span className={"PageTemplate_SharingWrapper_Header_Description"}>
                    To gain access, please sign in or create a Relatable account.
                  </span>
                </div>
              </div>
            )}
            <h1>Welcome</h1>
            <h3>{subtitle}</h3>
            {children}
          </div>
        </div>
        <div className="PageTemplate_Content_Footer">
          <p>© Relatable 2024</p>
          <a href="mailto: care@relatable.one">
            <Icon component={EnvelopeIcon} />
            care@relatable.one
          </a>
        </div>
      </div>
      <div className="PageTemplate_BackgroundImage">
        <img src={backgroundImage} alt="Background" />
      </div>
    </div>
  )
}

export default PageTemplate
