import React, { useState } from "react"
import { Collapse, List, Spin, Tooltip } from "antd"
import Icon, { LoadingOutlined } from "@ant-design/icons"
import moment from "moment"
import SimpleMessage from "./SimpleMessage.component"
import Avatar from "../../../../../components/UI/Avatar/Avatar.component"
import CaretUpIcon from "../../../../../components/Icons/CaretUp.icons"
import CaretDownIcon from "../../../../../components/Icons/CaretDown.icons"
import HourglassIcon from "../../../../../components/Icons/Hourglass.icons"
import SendingIcon from "../../../../../components/Icons/Sending.icons"
import { fetchSplitMessage } from "../../../../../redux/Messages/Messages.actions"
import { useDispatch, useSelector } from "react-redux"
import ConfirmModal from "../../../../../components/Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"

const { Panel } = Collapse

const messagesStatusRunning = (isProcessing) => {
  if (isProcessing) {
    return (
      <div className="MessagesTab_Item_TrackingIcon">
        <Tooltip placement="topRight" title={`The messages are being processed...`}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
        </Tooltip>
      </div>
    )
  }
  return false
}

const renderPeopleNames = (messages, number_of_messages) => {
  let fullNames = messages[0].person.full_name
  if (messages.length > 1) {
    fullNames += `, ${messages[1].person.full_name}`
  }

  return (
    <>
      <span>{fullNames}</span>
      {number_of_messages > 2 && (
        <div className="MessagesTab_Item_Details_People_More">
          + {number_of_messages - 2} more
        </div>
      )}
    </>
  )
}

const SplitMessage = ({
  splitMessage,
  onUpdateSplitMessage,
  onClickResendButton,
}) => {
  const user = useSelector((state) => state.UserState)
  const dispatch = useDispatch()

  const [extendedTab, setExtendedTab] = useState(false)
  const [resendModalVisible, setResendModalVisible] = useState(false)

  const messagesStatusIcon = (timestamp, fully_sent) => {
    return (
      <Tooltip
        placement="topRight"
        title={
          <span>
            {fully_sent
              ? `The message was sent on ${moment(timestamp)
                  .tz(user.time_zone)
                  .format("LLL")}`
              : "Something went wrong while trying to send the messages"}
            <br />
            {!fully_sent && (
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  setResendModalVisible(true)
                }}
                className="MessagesTab_Item_TrackingIcon_ResendMessage"
              >
                Resend failed messages
              </span>
            )}
          </span>
        }
      >
        <Icon
          style={{ marginRight: "1px" }}
          component={SendingIcon}
          className={fully_sent ? "green" : "red"}
        />
      </Tooltip>
    )
  }

  const handleSetTab = (active) => {
    setExtendedTab(!extendedTab)
    if (
      active.length > 0 &&
      splitMessage.number_of_messages > 2 &&
      !splitMessage.all_messages_fetched
    ) {
      dispatch(fetchSplitMessage(splitMessage.id))
    }
  }

  const handleUpdateSplitMessage = () => {
    onUpdateSplitMessage(splitMessage)
  }

  return (
    <List.Item className="MessagesTab_Item" key={splitMessage.id}>
      <ConfirmModal
        title="Are you sure you want to resend this message?"
        visible={resendModalVisible}
        onCancel={() => setResendModalVisible(false)}
        onConfirm={onClickResendButton}
      />
      <Collapse bordered={false} onChange={handleSetTab} destroyInactivePanel={true}>
        <Panel
          showArrow={false}
          key="1"
          header={
            <div className="MessagesTab_PanelHeader">
              <div className="MessagesTab_Item_Header">
                <div className="MessagesTab_Item_Header_Meta">
                  <div className="MessagesTab_Item_Header_Meta_Avatar">
                    <Avatar
                      avatar={splitMessage.messages[0].person.avatar}
                      firstName={
                        splitMessage.messages[0].person.full_name?.split(" ")[0]
                      }
                      lastName={
                        splitMessage.messages[0].person.full_name?.split(" ")[1]
                      }
                      colorNumber={splitMessage.messages[0].person.id}
                      size={33}
                    />
                  </div>
                  <div className="MessagesTab_Item_Header_Meta_Content">
                    <h4 className="MessagesTab_Item_Header_Meta_Title">
                      {splitMessage.messages[0].person.full_name}
                    </h4>
                    <div className="MessagesTab_Item_Header_Meta_Description">
                      {splitMessage.subject}
                    </div>
                  </div>
                </div>
                <div className="MessagesTab_Item_TrackingIcon">
                  {messagesStatusRunning(splitMessage.is_processing) ||
                    (splitMessage.scheduled_send_date ? (
                      <Tooltip
                        placement="topRight"
                        title={
                          <span>
                            {`The message is scheduled for ${moment(
                              splitMessage.scheduled_send_date
                            )
                              .tz(user.time_zone)
                              .format("LLL")}
                               `}
                            <span
                              onClick={(e) => {
                                e.stopPropagation()
                                handleUpdateSplitMessage()
                              }}
                              className="MessagesTab_Item_TrackingIcon_UpdateSplitMessage"
                            >
                              {" "}
                              Click to edit
                            </span>
                          </span>
                        }
                      >
                        <Icon
                          component={HourglassIcon}
                          className={splitMessage.fully_sent ? "green" : "red"}
                        />
                      </Tooltip>
                    ) : (
                      <>
                        {messagesStatusIcon(
                          splitMessage.timestamp,
                          splitMessage.fully_sent
                        )}
                      </>
                    ))}
                </div>
              </div>
              <div className="MessagesTab_Item_Details">
                <div className="MessagesTab_Item_Details_People">
                  {renderPeopleNames(
                    splitMessage.messages,
                    splitMessage.number_of_messages
                  )}
                </div>
                <div className="MessagesTab_Item_Details_RateAndCaret">
                  {splitMessage.messages[0].tracking === "open" &&
                    !splitMessage.scheduled_send_date && (
                      <div className="MessagesTab_Item_Details_RateAndCaret_Rate">
                        {splitMessage.open_rate}%
                      </div>
                    )}
                  {extendedTab ? (
                    <Icon component={CaretUpIcon} />
                  ) : (
                    <Icon component={CaretDownIcon} />
                  )}
                </div>
              </div>
            </div>
          }
        >
          <div className="MessagesTab_Item_MessagesList">
            {splitMessage.messages.map((message) => (
              <SimpleMessage
                key={message.id}
                scheduledSendDate={splitMessage.scheduled_send_date}
                message={message}
                small={true}
              />
            ))}
          </div>
        </Panel>
      </Collapse>
    </List.Item>
  )
}

export default SplitMessage
