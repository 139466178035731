import {
  APPROVE_TRIGGER,
  APPROVE_TRIGGER_FAIL,
  APPROVE_TRIGGER_SUCCESS,
  CLEAR_REDUX_TRIGGERS_STATE,
  IGNORE_TRIGGER,
  IGNORE_TRIGGER_FAIL,
  IGNORE_TRIGGER_SUCCESS,
  SET_INITIAL_REDUX_TRIGGERS_STATE,
  UPDATE_TRIGGER,
  UPDATE_TRIGGER_FAIL,
  UPDATE_TRIGGER_SUCCESS,
} from "./Triggers.types"

export const approveTrigger = (id, callback) => {
  return {
    type: APPROVE_TRIGGER,
    id: id,
    callback: callback,
  }
}

export const approveTriggerSuccess = (trigger) => {
  return {
    type: APPROVE_TRIGGER_SUCCESS,
    trigger: trigger,
  }
}

export const approveTriggerFail = (error) => {
  return {
    type: APPROVE_TRIGGER_FAIL,
    error: error,
  }
}

export const ignoreTrigger = (id, callback) => {
  return {
    type: IGNORE_TRIGGER,
    id: id,
    callback: callback,
  }
}

export const ignoreTriggerSuccess = (trigger) => {
  return {
    type: IGNORE_TRIGGER_SUCCESS,
    trigger: trigger,
  }
}

export const ignoreTriggerFail = (error) => {
  return {
    type: IGNORE_TRIGGER_FAIL,
    error: error,
  }
}

export const updateTrigger = (id, status, callback) => {
  return {
    type: UPDATE_TRIGGER,
    id: id,
    status: status,
    callback: callback,
  }
}

export const updateTriggerSuccess = (trigger) => {
  return {
    type: UPDATE_TRIGGER_SUCCESS,
    trigger: trigger,
  }
}

export const updateTriggerFail = (error) => {
  return {
    type: UPDATE_TRIGGER_FAIL,
    error: error,
  }
}

export const clearReduxTriggersState = () => {
  return {
    type: CLEAR_REDUX_TRIGGERS_STATE,
  }
}

export const setInitialReduxTriggersState = () => {
  return {
    type: SET_INITIAL_REDUX_TRIGGERS_STATE,
  }
}
