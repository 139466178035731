import React from "react"
import PropTypes from "prop-types"
import "./SplitButton.styles.scss"
import { LoadingOutlined } from "@ant-design/icons"

const SplitButton = ({
  onClick,
  loading,
  disabled,
  fillColor,
  children,
  loadingBar,
}) => (
  <div style={{ position: "relative" }}>
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={`SplitButton SplitButton--${fillColor} ${
        (disabled || loading) && "SplitButton--disabled"
      } ${loadingBar && "SplitButton--withLoadingBar"}`}
    >
      {loading && <LoadingOutlined />}
      {children}
    </button>
    {loadingBar ? (
      <>
        <div
          className="SplitButton_LoadingBar--dark"
          style={{ width: `${(loadingBar / 300) * 100}%` }}
        />
        <div className="SplitButton_LoadingBar--light" />
      </>
    ) : null}
  </div>
)

SplitButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  loadingBar: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  fillColor: PropTypes.oneOf(["lightGreen", "lightRed", "blue"]),
}

SplitButton.defaultProps = {
  loading: false,
  disabled: false,
}

export default SplitButton
