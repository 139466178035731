import {
  ADD_EXTRA_TRIAL_DAYS,
  ADD_EXTRA_TRIAL_DAYS_FAIL,
  ADD_EXTRA_TRIAL_DAYS_SUCCESS,
  BACK_TO_ADMIN_SUCCESS,
  CLEAR_REDUX_ADMIN_STATE,
  CREATE_CHALLENGE,
  CREATE_CHALLENGE_FAIL,
  CREATE_CHALLENGE_SUCCESS,
  FETCH_CHALLENGES,
  FETCH_CHALLENGES_FAIL,
  FETCH_CHALLENGES_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_FAIL,
  FETCH_USERS_SUCCESS,
  SET_INITIAL_REDUX_ADMIN_STATE,
  TAKE_SESSION_SUCCESS,
  UPDATE_CHALLENGE,
  UPDATE_CHALLENGE_FAIL,
  UPDATE_CHALLENGE_SUCCESS,
  WIPE_ACCOUNTS,
  WIPE_ACCOUNTS_FAIL,
  WIPE_ACCOUNTS_SUCCESS,
} from "./Admin.types"
import { LOGOUT_SUCCESS } from "../User/User.types"

const INITIAL_STATE = {
  admin_refresh_token: null,
  is_impersonating: false,
  users: null,
  total_entries: 0,
  loading: false,
  challenges_loading: false,
  challenges: [],
}

const updateUserTrialDaysRemaining = (
  users,
  user_id,
  trial_days_remaining,
  user_status
) => {
  return users.map((u) => {
    if (u.id === user_id) {
      u.trial_days_remaining = trial_days_remaining
      u.status = user_status
    }
    return u
  })
}

export const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        total_entries: action.total_entries,
        loading: false,
      }
    case FETCH_USERS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case TAKE_SESSION_SUCCESS:
      return {
        ...state,
        admin_refresh_token: action.admin_refresh_token,
        is_impersonating: action.is_impersonating,
        loading: false,
      }
    case BACK_TO_ADMIN_SUCCESS:
      return {
        ...state,
        admin_refresh_token: null,
        is_impersonating: false,
        loading: false,
      }
    case ADD_EXTRA_TRIAL_DAYS:
      return {
        ...state,
        loading: true,
      }
    case ADD_EXTRA_TRIAL_DAYS_SUCCESS:
      return {
        ...state,
        users: updateUserTrialDaysRemaining(
          state.users,
          action.user_id,
          action.trial_days_remaining,
          action.user_status
        ),
        loading: false,
      }
    case ADD_EXTRA_TRIAL_DAYS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_CHALLENGES:
      return {
        ...state,
        challenges_loading: true,
      }
    case FETCH_CHALLENGES_SUCCESS:
      return {
        ...state,
        challenges_loading: false,
        challenges: action.challenges,
      }
    case FETCH_CHALLENGES_FAIL:
      return {
        ...state,
        challenges_loading: false,
        challenges: [],
      }
    case UPDATE_CHALLENGE:
      return {
        ...state,
        challenges_loading: true,
      }
    case UPDATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        challenges_loading: false,
        challenges: state.challenges.map((challenge) => {
          if (challenge.id === action.challenge.id) {
            return action.challenge
          }
          return challenge
        }),
      }
    case UPDATE_CHALLENGE_FAIL:
      return {
        ...state,
        challenges_loading: false,
      }
    case CREATE_CHALLENGE:
      return {
        ...state,
        challenges_loading: true,
      }
    case CREATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        challenges_loading: false,
        challenges: [...state.challenges, action.challenge],
      }
    case CREATE_CHALLENGE_FAIL:
      return {
        ...state,
        challenges_loading: false,
      }
    case WIPE_ACCOUNTS:
      return {
        ...state,
        loading: true,
      }

    case WIPE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case WIPE_ACCOUNTS_FAIL:
      return {
        ...state,
        loading: false,
      }

    case CLEAR_REDUX_ADMIN_STATE:
      return {}
    case SET_INITIAL_REDUX_ADMIN_STATE:
      return { ...INITIAL_STATE }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    default:
      return {
        ...state,
      }
  }
}
