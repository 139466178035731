import React, { useEffect, useState } from "react"
import "./MiniDashboardForm.styles.scss"
import { Tabs, Tooltip } from "antd"
import NewNoteTab from "../../Dashboard/DashboardForm/Tabs/NewNoteTab/NewNoteTab.component"
import NewInteractionTab from "../../Dashboard/DashboardForm/Tabs/NewInteractionTab/NewInteractionTab.component"
import NewTaskTab from "../../Dashboard/DashboardForm/Tabs/NewTaskTab/NewTaskTab.component"
import { editNoteClear } from "../../../redux/Interactions/Interactions.actions"
import { useDispatch } from "react-redux"
import NoteIcon from "../../Icons/Note.icons"
import UsersIcon from "../../Icons/Users.icons"
import CalendarCheckIcon from "../../Icons/CalendarCheck.icons"
import Icon from "@ant-design/icons"
import { openDrawerTab } from "../../../redux/App/App.actions"

const MiniDashboardForm = ({ contacts, defaultActiveTab }) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [currentTab, setCurrentTab] = useState(null)
  const [initialParticipants, setInitialParticipants] = useState([])
  const [initialPeople, setInitialPeople] = useState([])
  const [initialContent, setInitialContent] = useState("")

  const disableTabs = contacts?.length === 0 || contacts?.length > 40

  const setInitialsStates = () => {
    let tempInitialParticipants = []
    let tempInitialPeople = []
    let tempInitialContent = ""
    contacts.forEach((contact) => {
      if (contact.full_name) {
        tempInitialParticipants.push({
          id: contact.id,
          value: contact.full_name.replace(/ /g, "_"),
        })
        tempInitialPeople.push({
          id: contact.id,
          name: contact.full_name,
          value: contact.full_name.replace(/ /g, "_"),
        })
        tempInitialContent += contact.full_name
          ? `@${contact.full_name.replace(/ /g, "_")} `
          : ""
      }
    })

    setInitialParticipants(tempInitialParticipants)
    setInitialPeople(tempInitialPeople)
    setInitialContent(tempInitialContent)
  }

  useEffect(() => {
    if (contacts && contacts.length > 0) {
      setInitialsStates()
    } else {
      setInitialParticipants([])
      setInitialPeople([])
      setInitialContent("")
    }
    // eslint-disable-next-line
  }, [contacts])

  useEffect(() => {
    if (defaultActiveTab && defaultActiveTab.length) {
      setVisible(true)
      setCurrentTab(defaultActiveTab)
    } else {
      setVisible(false)
      setCurrentTab(null)
    }
  }, [defaultActiveTab])

  const handleAfterSubmit = () => {
    setVisible(false)
    setCurrentTab(null)
  }

  return (
    <div
      className={`MiniDashboardForm ${
        visible ? "MiniDashboardForm-visible" : "MiniDashboardForm-invisible"
      }`}
    >
      <Tabs
        className={"MiniDashboardForm_Tab_Wrapper"}
        moreIcon={null}
        activeKey={currentTab}
        defaultActiveKey={defaultActiveTab}
        onTabClick={(e) => {
          if (e === currentTab) {
            setVisible(false)
            setCurrentTab(null)
            dispatch(editNoteClear())
            dispatch(openDrawerTab(""))
          } else {
            setCurrentTab(e)
            setVisible(true)
            dispatch(editNoteClear())
          }
        }}
      >
        <Tabs.TabPane
          disabled={disableTabs}
          tab={
            disableTabs ? (
              <Tooltip
                className={"MiniDashboardForm_Tab_Button"}
                title={
                  contacts?.length === 0
                    ? "Create the contact first"
                    : "You can add a note for up to 40 users at the same time"
                }
              >
                <Icon className="MiniDashboardForm_Tab_Icon" component={NoteIcon} />
                <span className="MiniDashboardForm_Tab_Title">Note</span>
              </Tooltip>
            ) : (
              <div className={"MiniDashboardForm_Tab_Button"}>
                <Icon
                  className={!visible && "MiniDashboardForm_Tab_Icon"}
                  component={NoteIcon}
                />
                <span className="MiniDashboardForm_Tab_Title">Note</span>
              </div>
            )
          }
          key="note"
        >
          {visible && (
            <NewNoteTab
              minified
              initialContent={initialContent}
              initialParticipants={initialParticipants}
              afterSubmit={handleAfterSubmit}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={disableTabs}
          tab={
            disableTabs ? (
              <Tooltip
                className={"MiniDashboardForm_Tab_Button"}
                title={
                  contacts?.length === 0
                    ? "Create the contact first"
                    : "You can log a meeting for up to 40 users at the same time"
                }
              >
                <Icon className="MiniDashboardForm_Tab_Icon" component={UsersIcon} />
                <span className="MiniDashboardForm_Tab_Title">Meeting</span>
              </Tooltip>
            ) : (
              <div className={"MiniDashboardForm_Tab_Button"}>
                <Icon
                  className={!visible && "MiniDashboardForm_Tab_Icon"}
                  component={UsersIcon}
                />
                <span className="MiniDashboardForm_Tab_Title">Meeting</span>
              </div>
            )
          }
          key="interaction"
        >
          {visible && (
            <NewInteractionTab
              minified
              initialContent={initialContent}
              initialParticipants={initialParticipants}
              afterSubmit={handleAfterSubmit}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={disableTabs}
          tab={
            disableTabs ? (
              <Tooltip
                className={"MiniDashboardForm_Tab_Button"}
                title={
                  contacts?.length === 0
                    ? "Create the contact first"
                    : "You can add a reminder for up to 40 users at the same time"
                }
              >
                <Icon
                  className="MiniDashboardForm_Tab_Icon"
                  component={CalendarCheckIcon}
                />
                <span className="MiniDashboardForm_Tab_Title">Reminder</span>
              </Tooltip>
            ) : (
              <div className={"MiniDashboardForm_Tab_Button"}>
                <Icon
                  className={!visible && "MiniDashboardForm_Tab_Icon"}
                  component={CalendarCheckIcon}
                />
                <span className="MiniDashboardForm_Tab_Title">Reminder</span>
              </div>
            )
          }
          key="task"
        >
          {visible && (
            <NewTaskTab
              minified
              initialContent={initialContent}
              initialPeople={initialPeople}
              afterSubmit={handleAfterSubmit}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default MiniDashboardForm
