import {
  ADD_INTERACTION,
  ADD_INTERACTION_FAIL,
  ADD_INTERACTION_SUCCESS,
  ADD_NOTE,
  ADD_NOTE_FAIL,
  ADD_NOTE_SUCCESS,
  CLEAR_REDUX_INTERACTIONS_STATE,
  DELETE_EVENT,
  DELETE_EVENT_FAIL,
  DELETE_INTERACTION,
  DELETE_INTERACTION_FAIL,
  DELETE_INTERACTION_SUCCESS,
  EDIT_NOTE,
  EDIT_NOTE_CLEAR,
  SET_INITIAL_REDUX_INTERACTIONS_STATE,
} from "./Interactions.types"
import {
  FETCH_PERSON,
  FETCH_PERSON_FAIL,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSONS_EVENTS,
  FETCH_PERSONS_EVENTS_SUCCESS,
  FETCH_PERSONS_INTERACTIONS,
  FETCH_PERSONS_INTERACTIONS_SUCCESS,
  FETCH_PERSONS_NOTES,
  FETCH_PERSONS_NOTES_SUCCESS,
  FETCH_PERSONS_REMINDERS,
  FETCH_PERSONS_REMINDERS_SUCCESS,
} from "../People/People.types"
import { LOGOUT_SUCCESS } from "../User/User.types"

const INITIAL_STATE = {
  adding_notes: false,
  adding_interactions: false,
  adding_event: false,
  edited_note: null,
  fetching_notes: false,
  fetching_reminders: false,
  fetching_interactions: false,
  fetching_events: false,
}

export const interactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PERSON:
      return {
        ...state,
        fetching_notes: true,
        fetching_reminders: true,
        fetching_interactions: true,
        fetching_events: true,
      }
    case FETCH_PERSON_SUCCESS:
      return {
        ...state,
        fetching_notes: false,
        fetching_reminders: false,
        fetching_interactions: false,
        fetching_events: false,
      }
    case FETCH_PERSON_FAIL:
      return {
        ...state,
        fetching_notes: false,
        fetching_reminders: false,
        fetching_interactions: false,
        fetching_events: false,
      }
    case FETCH_PERSONS_NOTES:
      return {
        ...state,
        fetching_notes: true,
      }
    case FETCH_PERSONS_NOTES_SUCCESS:
      return {
        ...state,
        fetching_notes: false,
      }
    case ADD_NOTE:
      return {
        ...state,
        adding_notes: true,
      }
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        adding_notes: false,
      }
    case ADD_NOTE_FAIL:
      return {
        ...state,
        adding_notes: false,
      }
    case EDIT_NOTE:
      return {
        ...state,
        edited_note: action.note,
      }
    case EDIT_NOTE_CLEAR:
      return {
        ...state,
        edited_note: null,
      }
    case FETCH_PERSONS_REMINDERS:
      return {
        ...state,
        fetching_reminders: true,
      }
    case FETCH_PERSONS_REMINDERS_SUCCESS:
      return {
        ...state,
        fetching_reminders: false,
      }
    case FETCH_PERSONS_INTERACTIONS:
      return {
        ...state,
        fetching_interactions: true,
      }
    case FETCH_PERSONS_INTERACTIONS_SUCCESS:
      return {
        ...state,
        fetching_interactions: false,
      }
    case FETCH_PERSONS_EVENTS:
      return {
        ...state,
        fetching_events: true,
      }
    case FETCH_PERSONS_EVENTS_SUCCESS:
      return {
        ...state,
        fetching_events: false,
      }
    case ADD_INTERACTION:
      return {
        ...state,
        adding_interactions: true,
      }
    case ADD_INTERACTION_SUCCESS:
      return {
        ...state,
        adding_interactions: false,
      }
    case ADD_INTERACTION_FAIL:
      return {
        ...state,
        adding_interactions: false,
      }
    case DELETE_INTERACTION:
      return {
        ...state,
        adding_interactions: true,
      }
    case DELETE_INTERACTION_SUCCESS:
      return {
        ...state,
        adding_interactions: false,
      }
    case DELETE_INTERACTION_FAIL:
      return {
        ...state,
        adding_interactions: false,
      }
    case DELETE_EVENT:
      return {
        ...state,
        adding_event: true,
      }
    case DELETE_EVENT_FAIL:
      return {
        ...state,
        adding_event: false,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_INTERACTIONS_STATE:
      return {}
    case SET_INITIAL_REDUX_INTERACTIONS_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
