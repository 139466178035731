import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./SectionContact.styles.scss"
import { Form, Input, Select, Tooltip } from "antd"
import Icon from "@ant-design/icons"
import EnvelopeIcon from "../../../../Icons/Envelope.icons"
import HouseIcon from "../../../../Icons/House.icons"
import PhoneCallIcon from "../../../../Icons/PhoneCall.icons"
import PlusCircleIcon from "../../../../Icons/PlusCircle.icons"
import XCircleIcon from "../../../../Icons/XCircle.icons"
import {
  setSelectedContactId,
  setSplitMessageSenderVisible,
  showNoAccountNotification,
} from "../../../../../redux/App/App.actions"
import { useDispatch, useSelector } from "react-redux"
import getCustomLabels from "./CustomLabels.api"
import { formatPhoneNumber } from "../../../../../helpers/helpers"

const SectionContact = ({ formik, contact, mode, sendable = true }) => {
  const userAccounts = useSelector((state) => state.AccountsState.accounts)

  const dispatch = useDispatch()
  const [emailLabels, setEmailLabels] = useState([])
  const [phoneLabels, setPhoneLabels] = useState([])
  const [addressLabels, setAddressLabels] = useState([])

  const getScope = () => {
    if (mode !== "view") {
      return formik.values
    } else {
      return contact
    }
  }

  useEffect(() => {
    handleCustomLabels()
  }, [contact])

  const handleCustomLabels = () => {
    const defaultLabels = [
      { name: "Work", value: "Work" },
      { name: "Private", value: "Private" },
    ]
    getCustomLabels("email_addresses", (labels) =>
      setEmailLabels([...defaultLabels, ...labels])
    )
    getCustomLabels("phone_numbers", (labels) =>
      setPhoneLabels([...defaultLabels, ...labels])
    )
    getCustomLabels("addresses", (labels) =>
      setAddressLabels([...defaultLabels, ...labels])
    )
  }

  return (
    <>
      {(getScope()?.email_addresses?.length > 0 ||
        getScope()?.phone_numbers?.length > 0 ||
        getScope()?.addresses?.length > 0 ||
        mode === "editing") && (
        <section id="sectionContact">
          <h3 className="SectionTitle">
            Contact{" "}
            {contact?.first_name ||
              contact?.last_name ||
              (contact?.email_addresses?.length > 0
                ? contact.email_addresses[0].value
                : "")}
          </h3>
          <div className="SectionContact Section">
            {(getScope()?.email_addresses?.length > 0 || mode === "editing") && (
              <div className="SectionContact_Field">
                <Tooltip title="Email Addresses">
                  <Icon
                    className="SectionContact_Field_Icon"
                    component={EnvelopeIcon}
                  />
                </Tooltip>
                <div className="SectionContact_Field_Items">
                  {getScope()?.email_addresses?.length > 0 &&
                    getScope().email_addresses.map((email, i) =>
                      mode === "editing" ? (
                        <div
                          key={i}
                          className="SectionContact_Field_Items_EditableItem"
                        >
                          <div className="SectionContact_Field_Items_EditableItem_Inputs">
                            <Form.Item
                              validateStatus={
                                formik.touched.email_addresses &&
                                formik.errors.email_addresses &&
                                formik.touched.email_addresses[i]?.value &&
                                formik.errors.email_addresses[i]?.value
                                  ? "error"
                                  : "success"
                              }
                              help={
                                formik.touched.email_addresses &&
                                formik.errors.email_addresses &&
                                formik.touched.email_addresses[i]?.value &&
                                formik.errors.email_addresses[i]?.value
                              }
                            >
                              <Input
                                placeholder="Email address"
                                key={`email_addresses[${i}]`}
                                name={`email_addresses[${i}]`}
                                value={formik.values.email_addresses[i]?.value}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    `email_addresses[${i}].value`,
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Item>
                            <Form.Item>
                              <Select
                                key={`email_addresses[${i}]`}
                                name={`email_addresses[${i}]`}
                                value={
                                  formik.values.email_addresses[i]?.name || "Work"
                                }
                                onSelect={(e) =>
                                  formik.setFieldValue(
                                    `email_addresses[${i}].name`,
                                    e
                                  )
                                }
                              >
                                {emailLabels.map((label, i) => (
                                  <Select.Option key={i} value={label.value}>
                                    {label.name}
                                  </Select.Option>
                                ))}

                                <Select.Option
                                  value={
                                    "" /* This is a hack to make the select input work with the custom input */
                                  }
                                >
                                  <Input
                                    className={
                                      "SectionContact_Field_Items_EditableItem_SelectInput"
                                    }
                                    bordered={false}
                                    placeholder="Other"
                                    key={`email_addresses[${i}].other`}
                                    name={`email_addresses[${i}].other`}
                                    value={
                                      formik.values.email_addresses[i]?.name !==
                                        "Work" &&
                                      formik.values.email_addresses[i]?.name !==
                                        "Private"
                                        ? formik.values.email_addresses[i]?.name
                                        : ""
                                    }
                                    onPressEnter={(e) => {
                                      formik.setFieldValue(
                                        `email_addresses[${i}].name`,
                                        e.target.value
                                      )
                                      e.target.blur()
                                      e.stopPropagation()
                                    }}
                                    onClick={(e) => {
                                      e.target.focus()
                                      e.stopPropagation()
                                    }}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        `email_addresses[${i}].name`,
                                        e.target.value
                                      )
                                      formik.setFieldValue(
                                        `email_addresses[${i}].is_new_custom_label`,
                                        true
                                      )
                                    }}
                                  />
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className="SectionContact_Field_Items_EditableItem_DeleteButton"
                            onClick={() => {
                              formik.setFieldValue(
                                "emails",
                                formik.values.email_addresses.splice(i, 1)
                              )
                            }}
                          >
                            <Icon component={XCircleIcon} />
                          </div>
                        </div>
                      ) : (
                        <div key={i} className="SectionContact_Field_Items_Item">
                          <span
                            className="SectionContact_Field_Items_Item_EmailAddress"
                            onClick={() => {
                              if (sendable) {
                                const runningAccounts = userAccounts.filter(
                                  (a) => a.state === "running"
                                )
                                if (runningAccounts.length > 0) {
                                  dispatch(setSplitMessageSenderVisible(true))
                                  dispatch(setSelectedContactId(contact.id))
                                } else {
                                  dispatch(showNoAccountNotification())
                                }
                              }
                            }}
                          >
                            {email?.value}
                          </span>
                          <span className="SectionContact_Field_Items_Item_Name">
                            ({email?.name || "Work"})
                          </span>
                        </div>
                      )
                    )}
                  {mode === "editing" && (
                    <div
                      className="SectionContact_Field_Items_AddNewButton"
                      onClick={() =>
                        formik.setFieldValue(
                          "email_addresses",
                          formik.values.email_addresses.concat({
                            value: "",
                            name: "Work",
                          })
                        )
                      }
                    >
                      <Icon component={PlusCircleIcon} />
                      Add new email address
                    </div>
                  )}
                </div>
              </div>
            )}

            {(getScope()?.phone_numbers?.length > 0 || mode === "editing") && (
              <div className="SectionContact_Field">
                <Tooltip title="Phone Numbers">
                  <Icon
                    className="SectionContact_Field_Icon"
                    component={PhoneCallIcon}
                  />
                </Tooltip>
                <div className="SectionContact_Field_Items">
                  {getScope()?.phone_numbers?.length > 0 &&
                    getScope().phone_numbers.map((phone, i) =>
                      mode === "editing" ? (
                        <div
                          key={i}
                          className="SectionContact_Field_Items_EditableItem"
                        >
                          <div className="SectionContact_Field_Items_EditableItem_Inputs">
                            <Form.Item
                              validateStatus={
                                formik.touched.phone_numbers &&
                                formik.errors.phone_numbers &&
                                formik.touched.phone_numbers[i]?.value &&
                                formik.errors.phone_numbers[i]?.value
                                  ? "error"
                                  : "success"
                              }
                              help={
                                formik.touched.phone_numbers &&
                                formik.errors.phone_numbers &&
                                formik.touched.phone_numbers[i]?.value &&
                                formik.errors.phone_numbers[i]?.value
                              }
                            >
                              <Input
                                placeholder="Phone Number"
                                key={`phone_numbers[${i}]`}
                                name={`phone_numbers[${i}]`}
                                value={formik.values.phone_numbers[i].value}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `phone_numbers[${i}].value`,
                                    e.target.value
                                  )
                                }}
                              />
                            </Form.Item>
                            <Form.Item>
                              <Select
                                key={`phone_numbers[${i}]`}
                                name={`phone_numbers[${i}]`}
                                value={formik.values.phone_numbers[i].name || "Work"}
                                onSelect={(e) =>
                                  formik.setFieldValue(`phone_numbers[${i}].name`, e)
                                }
                              >
                                {phoneLabels.map((label, i) => (
                                  <Select.Option key={i} value={label.value}>
                                    {label.name}
                                  </Select.Option>
                                ))}
                                <Select.Option value={""}>
                                  <Input
                                    className={
                                      "SectionContact_Field_Items_EditableItem_SelectInput"
                                    }
                                    bordered={false}
                                    placeholder="Other"
                                    key={`phone_numbers[${i}].other`}
                                    name={`phone_numbers[${i}].other`}
                                    value={
                                      formik.values.phone_numbers[i].name !==
                                        "Work" &&
                                      formik.values.phone_numbers[i].name !==
                                        "Private"
                                        ? formik.values.phone_numbers[i].name
                                        : ""
                                    }
                                    onClick={(e) => {
                                      e.target.focus()
                                      e.stopPropagation()
                                    }}
                                    onPressEnter={(e) => {
                                      formik.setFieldValue(
                                        `phone_numbers[${i}].name`,
                                        e.target.value
                                      )
                                      e.target.blur()
                                      e.stopPropagation()
                                    }}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        `phone_numbers[${i}].name`,
                                        e.target.value
                                      )
                                      formik.setFieldValue(
                                        `phone_numbers[${i}].is_new_custom_label`,
                                        true
                                      )
                                    }}
                                  />
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className="SectionContact_Field_Items_EditableItem_DeleteButton"
                            onClick={() => {
                              formik.setFieldValue(
                                "phones",
                                formik.values.phone_numbers.splice(i, 1)
                              )
                            }}
                          >
                            <Icon component={XCircleIcon} />
                          </div>
                        </div>
                      ) : (
                        <div key={i} className="SectionContact_Field_Items_Item">
                          <span>{formatPhoneNumber(phone.value)}</span>
                          <span className="SectionContact_Field_Items_Item_Name">
                            ({phone.name || "Work"})
                          </span>
                        </div>
                      )
                    )}
                  {mode === "editing" && (
                    <div
                      className="SectionContact_Field_Items_AddNewButton"
                      onClick={() =>
                        formik.setFieldValue(
                          "phone_numbers",
                          formik.values.phone_numbers.concat({
                            value: "",
                            name: "Work",
                          })
                        )
                      }
                    >
                      <Icon component={PlusCircleIcon} />
                      Add new phone number
                    </div>
                  )}
                </div>
              </div>
            )}

            {(getScope()?.addresses?.length > 0 || mode === "editing") && (
              <div className="SectionContact_Field">
                <Tooltip title="Physical Addresses">
                  <Icon
                    className="SectionContact_Field_Icon"
                    component={HouseIcon}
                  />
                </Tooltip>
                <div className="SectionContact_Field_Items">
                  {getScope()?.addresses?.length > 0 &&
                    getScope().addresses.map((address, i) =>
                      mode === "editing" ? (
                        <div
                          key={i}
                          className="SectionContact_Field_Items_EditableItem"
                        >
                          <div className="SectionContact_Field_Items_EditableItem_Inputs">
                            <Form.Item
                              validateStatus={
                                formik.touched.addresses &&
                                formik.errors.addresses &&
                                formik.touched.addresses[i]?.value &&
                                formik.errors.addresses[i]?.value
                                  ? "error"
                                  : "success"
                              }
                              help={
                                formik.touched.addresses &&
                                formik.errors.addresses &&
                                formik.touched.addresses[i]?.value &&
                                formik.errors.addresses[i]?.value
                              }
                            >
                              <Input
                                placeholder="Physical Address"
                                key={`addresses[${i}]`}
                                name={`addresses[${i}]`}
                                value={formik.values.addresses[i].value}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `addresses[${i}].value`,
                                    e.target.value
                                  )
                                }}
                              />
                            </Form.Item>
                            <Form.Item>
                              <Select
                                key={`addresses[${i}]`}
                                name={`addresses[${i}]`}
                                value={formik.values.addresses[i].name || "Work"}
                                onSelect={(e) =>
                                  formik.setFieldValue(`addresses[${i}].name`, e)
                                }
                              >
                                {addressLabels.map((label, i) => (
                                  <Select.Option key={i} value={label.value}>
                                    {label.name}
                                  </Select.Option>
                                ))}
                                <Select.Option value={""}>
                                  <Input
                                    className={
                                      "SectionContact_Field_Items_EditableItem_SelectInput"
                                    }
                                    bordered={false}
                                    placeholder="Other"
                                    key={`addresses[${i}].other`}
                                    name={`addresses[${i}].other`}
                                    value={
                                      formik.values.addresses[i].name !== "Work" &&
                                      formik.values.addresses[i].name !== "Private"
                                        ? formik.values.addresses[i].name
                                        : ""
                                    }
                                    onPressEnter={(e) => {
                                      formik.setFieldValue(
                                        `addresses[${i}].name`,
                                        e.target.value
                                      )
                                      e.target.blur()
                                      e.stopPropagation()
                                    }}
                                    onClick={(e) => {
                                      e.target.focus()
                                      e.stopPropagation()
                                    }}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        `addresses[${i}].name`,
                                        e.target.value
                                      )
                                      formik.setFieldValue(
                                        `addresses[${i}].is_new_custom_label`,
                                        true
                                      )
                                    }}
                                  />
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className="SectionContact_Field_Items_EditableItem_DeleteButton"
                            onClick={() => {
                              formik.setFieldValue(
                                "addressess",
                                formik.values.addresses.splice(i, 1)
                              )
                            }}
                          >
                            <Icon component={XCircleIcon} />
                          </div>
                        </div>
                      ) : (
                        <div
                          key={i}
                          className="SectionContact_Field_Items_Item--address"
                        >
                          <span className="SectionContact_Field_Items_Item_Name">
                            ({address.name || "Work"})
                          </span>
                          <span>{address.value}</span>
                        </div>
                      )
                    )}
                  {mode === "editing" && (
                    <div
                      style={{ marginBottom: 0 }}
                      className="SectionContact_Field_Items_AddNewButton"
                      onClick={() =>
                        formik.setFieldValue(
                          "addresses",
                          formik.values.addresses.concat({
                            value: "",
                            name: "Work",
                          })
                        )
                      }
                    >
                      <Icon component={PlusCircleIcon} />
                      Add new physical address
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  )
}

SectionContact.propTypes = {
  formik: PropTypes.object.isRequired,
  contact: PropTypes.object,
}

export default SectionContact
