import React from "react"

const RepeatIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0ZM9.5 3.99738C11.0274 3.99738 12.4089 4.62056 13.4058 5.62555L15.0026 4.48908V9.61481L10.0972 7.97967L11.5422 6.95104C10.9828 6.50106 10.2739 6.23091 9.50003 6.23091C7.69456 6.23091 6.23092 7.69451 6.23092 9.50002C6.23092 11.3055 7.69452 12.7691 9.5 12.7691C10.7117 12.7691 11.7672 12.1087 12.3319 11.1294L14.2685 12.2426C13.3182 13.8913 11.5397 15.0026 9.5 15.0026C6.46108 15.0026 3.99738 12.5389 3.99738 9.5C3.99738 6.46111 6.46108 3.99738 9.5 3.99738Z"
      fill="#364152"
    />
  </svg>
)

export default RepeatIcon
