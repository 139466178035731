import { Pagination } from "antd"
import React, { useState } from "react"
import "./CustomPagination.styles.scss"
import Icon from "@ant-design/icons"
import ArrowLeftIcon from "../../Icons/ArrowLeft.icons"
import ArrowRightIcon from "../../Icons/ArrowRight.icons"

const CustomPagination = ({
  currentPage,
  total,
  onPageChange,
  hideTextButton,
  hideOnSinglePage = false,
  small = false,
  perPage = 10,
}) => {
  const [page, setPage] = useState(1)
  const onChange = (page) => {
    setPage(page)
    onPageChange(page)
  }

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <button className="CustomPagination_Button">
          <Icon component={ArrowLeftIcon} />
          {!hideTextButton && " Previous"}
        </button>
      )
    }

    if (type === "next") {
      return (
        <button className="CustomPagination_Button">
          {!hideTextButton && " Next"}
          <Icon component={ArrowRightIcon} />
        </button>
      )
    }

    if (type === "page") {
      return (
        <button
          className={`CustomPagination_Page ${
            currentPage === originalElement.props.children && "currentPage"
          }`}
        >
          {originalElement.props.children}
        </button>
      )
    }

    return originalElement
  }

  return (
    <Pagination
      className={`CustomPagination ${small ? "CustomPagination-small" : ""}`}
      showSizeChanger={false}
      hideOnSinglePage={hideOnSinglePage}
      current={page}
      onChange={onChange}
      total={total}
      defaultPageSize={perPage}
      itemRender={itemRender}
      responsive={true}
      size={small ? "small" : "default"}
    />
  )
}

export default CustomPagination
