import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  List,
  Row,
  Spin,
  Typography,
} from "antd"
import "./PersonTriggers.styles.scss"
import * as _ from "lodash"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const { Title, Text } = Typography

const PersonTriggers = (props) => {
  const [triggers, settriggers] = useState([])
  const [currentTrigger, setCurrentTrigger] = useState(0)
  const [selectedSpheres, setSelectedSpheres] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = useState(1)
  const [lastSlide, setLastSlide] = useState(false)

  const carouselRef = useRef(null)

  const loadTriggersAndReminders = async (user_id, page = 1, callback = null) => {
    const { data } = await axios.request({
      url: `/v1/daily_reminders/triggers`,
      params: {
        user_id: user_id,
        page: page,
      },
    })
    const newTriggers = _.uniqBy(triggers.concat(data.triggers), "id")
    settriggers(newTriggers)
    const isLastSlide = data.triggers.length === 0
    if (isLastSlide) {
      setCurrentTrigger(triggers.length)
    }
    setLastSlide(isLastSlide)
    callback && callback(isLastSlide)
  }

  const handleChange = (id, checked) => {
    // const nextSelectedSpheres = checked
    //   ? [...selectedSpheres, id]
    //   : selectedSpheres.filter((s) => s !== id)
    // setSelectedSpheres(nextSelectedSpheres)
  }

  const handleAddSphere = async (trigger_index) => {
    setLoaded(false)
    const newTriggers = [...triggers]
    newTriggers.splice(trigger_index, 1)
    settriggers(newTriggers)
    const id = triggers[trigger_index].id
    await axios.request({
      url: `v1/daily_reminders/add_to_sphere`,
      data: { user_id: props.user.id, Trigger_id: id, spheres: selectedSpheres },
      method: "POST",
    })
    setLoaded(true)
  }

  useEffect(() => {
    loadTriggersAndReminders(props.user.id, null).then((r) => {
      setLoaded(true)
    })
    // eslint-disable-next-line
  }, [props.user])

  useEffect(() => {
    setSelectedSpheres([])
    loaded && carouselRef.current.goTo(currentTrigger)
    // eslint-disable-next-line
  }, [currentTrigger, carouselRef.current])

  const renderTriggerCard = (trigger) => {
    const displayName =
      trigger.person.full_name && trigger.person.full_name.length > 0
        ? trigger.person.full_name
        : trigger.person.email.value
    return (
      <div key={trigger.id} className={"PersonTriggers_TriggerCard"}>
        <Card className={"PersonTriggers_Card"}>
          <Row
            style={{
              flexFlow: "row",
              alignItems: "baseline",
              marginBottom: "12px",
            }}
          >
            <Col xs={5}>
              <Avatar src={`${trigger.person.profile_image_url}`} size={40} />
            </Col>
            <Col xs={21}>
              <Text className="PersonTriggers_TriggerCard_Text" strong>
                {displayName}
              </Text>
            </Col>
          </Row>
          <Row className={"O"}>
            <div className={"PersonTriggers_Spheres"}>
              <Button
                className={"PersonTriggers_Button"}
                onClick={() => {
                  handleChange(trigger.id)
                }}
              >
                Remind Me
              </Button>
              <Button
                className={"PersonTriggers_Button"}
                onClick={() => {
                  handleChange(trigger.id)
                }}
              >
                Give Them More Time
              </Button>
              <Button
                className={"PersonTriggers_Button"}
                onClick={() => {
                  handleChange(trigger.id)
                }}
              >
                We Talked Recently - All Good!
              </Button>
            </div>
          </Row>
        </Card>
      </div>
    )
  }

  const NextButton = (props) => {
    const { className, style } = props
    return (
      <Button
        className={`${className}`}
        style={{
          ...style,
        }}
        onClick={() => {
          handleAddSphere(currentTrigger).then((r) => {
            carouselRef.current.goTo(currentTrigger)
            setSelectedSpheres([])
          })
        }}
      >
        Next Contact
      </Button>
    )
  }

  // const PrevButton = (props) => {
  //   const { className, style, onClick } = props
  //   return (
  //     <Button
  //       disabled={currentTrigger === 0}
  //       type="link"
  //       className={className}
  //       style={{ ...style }}
  //       onClick={onClick}
  //     >
  //       Previous Contact
  //     </Button>
  //   )
  // }

  return (
    <div className={"PersonTriggers_Container"}>
      <Row className={"PersonTriggers_Container_Header"}>
        <Col>
          <Row>
            <Title level={2}>Hola {props.user.first_name}!</Title>
          </Row>
          <Row>
            <Text>
              We haven't seen any recent conversations with these people. Should we
              remind you to reach out?
            </Text>
          </Row>
        </Col>
      </Row>

      <RelatableLoader loading={!loaded} style={{ margin: "50% auto" }} size="large">
        <Carousel
          draggable={false}
          swipe={false}
          ref={carouselRef}
          arrows={false}
          easing={"ease-in-out"}
          dots={false}
          infinite={false}
          initialSlide={currentTrigger}
          beforeChange={(oldIndex, newIndex) => {
            if (currentTrigger === triggers.length) {
              setLoaded(false)
              loadTriggersAndReminders(props.user.id, page + 1, (lastSlide) => {
                setLoaded(true)
                if (!lastSlide) {
                  carouselRef.current.goTo(currentTrigger + 1)
                  setPage(page + 1)
                }
              })
            }
          }}
        >
          {triggers.map((t) => renderTriggerCard(t))}
          <div className={"PersonTriggers_LastSlide"} key={triggers.length}>
            {lastSlide && (
              <>
                <Title level={2}>
                  Looks like you've processed everyone for today!
                  <br />
                  Check back soon!
                </Title>
              </>
            )}
          </div>
        </Carousel>
        <Row className={"PersonTriggers_ButtonContainer"}>
          {/*<PrevButton*/}
          {/*  onClick={() => {*/}
          {/*    if (currentTrigger > 0) {*/}
          {/*      setCurrentTrigger(currentTrigger - 1)*/}
          {/*      setLastSlide(false)*/}
          {/*    }*/}
          {/*  }}*/}
          {/*/>*/}
          <NextButton className={"PersonTriggers_ButtonContainer_Next"} />
        </Row>
      </RelatableLoader>
      <Row>
        <Text>Here are some people you wanted us to remind you about.</Text>
      </Row>
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <List
          dataSource={[]}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Avatar src={item.picture.large} />}
                title={<a href="https://ant.design">{item.name.last}</a>}
                description={item.email}
              />
              <div>Content</div>
            </List.Item>
          )}
        />
      </div>
    </div>
  )
}

export default PersonTriggers
