import React, { useState } from "react"
import { Button, DatePicker } from "antd"
import { disabledDate, disabledDateTime } from "../helpers"
import moment from "moment"
import "moment-timezone"
import "../MessageCreator.styles.scss"
import CalendarBlankIcon from "../../../../Icons/CalendarBlank.icons"
import Icon from "@ant-design/icons"
import XCircleIcon from "../../../../Icons/XCircle.icons"
import { useSelector } from "react-redux"

const SchedulerDateButton = ({ scheduledDate, onSetScheduledDate }) => {
  const [openScheduledDate, setOpenScheduledDate] = useState(!!scheduledDate)
  const [calendarOpened, setCalendarOpened] = useState(false)
  const user = useSelector((state) => state.UserState)

  const currentTimeZone = moment().tz(user.time_zone).format("z").toString()
  return (
    <div className="MessageCreator_SchedulerWrapper">
      <Button
        type={"link"}
        onClick={() => {
          setOpenScheduledDate(true)
          setCalendarOpened(true)
        }}
        className="MessageCreator_SchedulerButton"
      >
        Schedule message
      </Button>
      {openScheduledDate ? (
        <div className={"MessageCreator_PickerWrapper"}>
          <DatePicker
            allowClear={false}
            className={"MessageCreator_Picker"}
            format={`ddd D[,] YYYY[,] hh:mm  [(${currentTimeZone})]`}
            value={scheduledDate}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            showTime
            onOpenChange={(open) => setCalendarOpened(open)}
            open={calendarOpened}
            suffixIcon={
              <Icon
                component={CalendarBlankIcon}
                className="MessageCreator_Picker_Icon"
              />
            }
            onClick={() => {
              setCalendarOpened(true)
            }}
            onChange={(selectedDate) => {
              if (
                selectedDate &&
                moment().tz(user.time_zone).isBefore(selectedDate)
              ) {
                onSetScheduledDate(selectedDate)
                setCalendarOpened(false)
              } else {
                setTimeout(() => {
                  onSetScheduledDate(null)
                  setCalendarOpened(false)
                }, 1)
              }
            }}
          />
          <div
            className="MessageCreator_Scheduler_DeleteButton"
            onClick={() => {
              onSetScheduledDate(null)
              setOpenScheduledDate(false)
            }}
          >
            <Icon component={XCircleIcon} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default SchedulerDateButton
