import React, { useEffect, useState } from "react"
import "./IntroductionCreator.styles.scss"
import { Col, Divider, Form, Input, Row, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { fetchAccounts } from "../../../../../../../redux/Accounts/Accounts.actions"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../../../../redux/App/App.actions"
import TinyMCUEditor from "../../../../../../UI/TinyMCUEditor/TinyMCUEditor"
import CCMultiSelect from "../../../../../../UI/CCMultiSelect/CCMultiSelect.component"
import AttachmentsUpload from "../../../../../../UI/AttachmentsUpload/AttachmentsUpload.component"

const IntroductionCreator = ({
  visible,
  formik,
  participant1,
  participant2,
  handleSubmit,
  introduction = null,
  itemIndex = null,
}) => {
  const dispatch = useDispatch()
  const username = useSelector((state) => state.UserState.first_name)
  const userAccounts = useSelector((state) => state.AccountsState.accounts)

  const [introData, setIntroData] = useState({
    subject: null,
    body: null,
    mail_from: null,
    mail_1: null,
    mail_2: null,
    cc: [],
    files: [],
  })

  const [editing, setEditing] = useState(null)

  useEffect(() => {
    dispatch(fetchAccounts())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userAccounts) {
      const runningAccounts = userAccounts.filter((a) => a.state === "running")

      const defaultAccount =
        runningAccounts.find((a) => a.default) || runningAccounts[0]
      if (defaultAccount && defaultAccount.email) {
        setIntroData({ ...introData, mail_from: defaultAccount.email })
        if (formik) {
          formik.setFieldValue("from", defaultAccount.email)
        }
      }
    }
    // eslint-disable-next-line
  }, [userAccounts])

  useEffect(() => {
    if (visible && formik) {
      formik.setFieldValue(
        "participant_1_template",
        `<div>Hey ${participant1.first_name},<br/><br/>With your permission, I'd like to introduce you to ${participant2.name}<br/><br/>${formik.values.participant_2_description}<br/><br/>Let me know if you're OK with me making the introduction!<br/><br/>- ${username}</div>`
      )

      formik.setFieldValue(
        "participant_2_template",
        `<div>Hey ${participant2.first_name},<br><br>With your permission, I'd like to introduce you to ${participant1.name}<br><br>${formik.values.participant_1_description}<br><br>Let me know if you're OK with me making the introduction!<br><br>- ${username}</div>`
      )

      formik.setFieldValue(
        "template",
        `<div>It is my pleasure to introduce both of you.<br><br>${formik.values.preAmble} <br><br> ${participant1.name} - ${formik.values.participant_1_description} <br><br>${participant2.name} - ${formik.values.participant_2_description}<br><br>I hope you two have the opportunity to connect!<br><br>- ${username}</div>`
      )

      formik.setFieldValue(
        "subject",
        participant1 &&
          participant2 &&
          `Introducing ${
            participant1.full_name && participant1.full_name.length
              ? participant1.full_name
              : participant1.name
          } and ${
            participant2.full_name && participant2.full_name.length
              ? participant2.full_name
              : participant2.name
          }`
      )
    } else if (visible && introduction) {
      const defaultAccount = userAccounts.find((a) => a.default) || userAccounts[0]

      setIntroData({
        ...introData,
        mail_from: defaultAccount.email,
        body: `<div>It is my pleasure to introduce both of you.<br><br>${introduction.note} <br><br>${participant1.full_name} - ${participant1.description} <br><br> ${participant2.full_name} - ${participant2.description}<br><br>I hope you two have the opportunity to connect!<br><br>- ${username}</div>`,
        subject:
          participant1 &&
          participant2 &&
          `Introducing ${
            participant1.full_name && participant1.full_name.length
              ? participant1.full_name
              : participant1.name
          } and ${
            participant2.full_name && participant2.full_name.length
              ? participant2.full_name
              : participant2.name
          }`,
        mail_1: participant1 && participant1.emails.length && participant1.emails[0],
        mail_2: participant2 && participant2.emails.length && participant2.emails[0],
      })
    }
    // eslint-disable-next-line
  }, [visible])

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(
        "participant_1_subject",
        participant1 &&
          participant2 &&
          `Can I introduce you to ${
            participant2.full_name && participant2.full_name.length
              ? participant2.full_name
              : participant2.name
          }?`
      )
      formik.setFieldValue(
        "participant_2_subject",
        participant1 &&
          participant2 &&
          `Can I introduce you to ${
            participant1.full_name && participant1.full_name.length
              ? participant1.full_name
              : participant1.name
          }?`
      )

      if (participant1 && participant1.emails && participant1.emails.length) {
        formik.setFieldValue("participant_1_email", participant1.last_used_email)
      }
      if (participant2 && participant2.emails && participant2.emails.length) {
        formik.setFieldValue("participant_2_email", participant2.last_used_email)
      }
    }
    // eslint-disable-next-line
  }, [participant1, participant2])

  const openPersonProfile = (id) => {
    dispatch(setVisibleContactID(id))
    dispatch(setContactDrawerVisible(true))
    dispatch(setContactDrawerMode("view"))
  }

  const marginRight =
    formik?.values?.participant_1_id &&
    formik?.values?.participant_2_id &&
    !formik?.values?.participant_1_approval &&
    !formik?.values?.participant_2_approval

  return (
    <div className={"IntroductionCreator"}>
      {formik ? (
        <>
          <Form className={"IntroductionCreator_FormWrapper"} labelAlign={"left"}>
            {formik.values.participant_1_id && formik.values.participant_2_id && (
              <div className={`${!marginRight && "IntroductionCreator_From"}`}>
                <Form.Item
                  label={"From"}
                  validateStatus={
                    formik.touched.from && formik.errors.from ? "error" : "success"
                  }
                  help={
                    formik.touched.from && formik.errors.from
                      ? formik.errors.from
                      : []
                  }
                >
                  <Select
                    bordered={false}
                    key="from"
                    name="from"
                    value={formik.values.from}
                    onChange={(val) => formik.setFieldValue("from", val)}
                    onBlur={formik.handleBlur}
                    disabled={
                      !participant1 ||
                      !participant2 ||
                      !participant1.name ||
                      !participant2.name
                    }
                    placeholder={"From: "}
                  >
                    {userAccounts &&
                      userAccounts
                        .filter((a) => a.email && a.email.length && a.nylas_id)
                        .map(({ email }) => {
                          return <Select.Option value={email}>{email}</Select.Option>
                        })}
                  </Select>
                </Form.Item>
                <Divider />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {formik.values.participant_1_id &&
                formik.values.participant_1_approval && (
                  <div
                    className={"IntroductionCreator_ParticipantWrapper"}
                    style={{
                      marginRight: 15,
                      width: formik.values.participant_2_approval
                        ? editing === "participant_1_template"
                          ? "65%"
                          : "48%"
                        : "100%",
                    }}
                  >
                    <Form.Item
                      className={"IntroductionCreator_FormItem"}
                      label={
                        <span
                          onClick={() => {
                            openPersonProfile(formik.values.participant_1_id)
                          }}
                        >
                          {participant1 && participant1.name}
                        </span>
                      }
                    >
                      <Select
                        bordered={false}
                        key="participant_1_email"
                        name="participant_1_email"
                        value={formik.values.participant_1_email}
                        onChange={(val) =>
                          formik.setFieldValue("participant_1_email", val)
                        }
                        onBlur={formik.handleBlur}
                        style={{ width: "100%" }}
                        placeholder={"Email"}
                      >
                        {participant1 &&
                          participant1.emails &&
                          participant1.emails
                            .flat()
                            .filter((email) => {
                              return email && email.trim()?.length
                            })
                            .map((email) => {
                              return (
                                <Select.Option value={email}>{email}</Select.Option>
                              )
                            })}
                      </Select>
                    </Form.Item>
                    <Divider />

                    <Form.Item className={"IntroductionCreator_FormItem"} label="CC">
                      <CCMultiSelect
                        selectedPeople={formik.values.participant_1_cc}
                        setSelectedPeople={(val) =>
                          formik.setFieldValue("participant_1_cc", val)
                        }
                      />
                    </Form.Item>
                    <Divider />

                    <Form.Item
                      className={"IntroductionCreator_FormItem"}
                      label={"Subject"}
                    >
                      <Input
                        key="participant_1_subject"
                        name="participant_1_subject"
                        value={
                          formik.values.participant_1_subject || introData.subject
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={"Subject"}
                        bordered={false}
                      />
                    </Form.Item>
                    <Divider />
                    <TinyMCUEditor
                      focusHandler={(value, id) => {
                        setEditing(value ? id : editing)
                      }}
                      updateBody={(html) => {
                        formik.setFieldValue("participant_1_template", html)
                      }}
                      body={formik.values.participant_1_template}
                      toolbarId="participant_1_template"
                      hidePersonalization={true}
                      extras={[
                        <AttachmentsUpload
                          fileList={formik.values.participant_1_files}
                          handleFileList={(newValues) => {
                            formik.setFieldValue("participant_1_files", newValues)
                          }}
                        />,
                      ]}
                    />
                  </div>
                )}
              {formik.values.participant_2_id &&
                formik.values.participant_2_approval && (
                  <div
                    className={"IntroductionCreator_ParticipantWrapper"}
                    style={{
                      width: formik.values.participant_1_approval
                        ? editing === "participant_2_template"
                          ? "65%"
                          : "48%"
                        : "100%",
                    }}
                  >
                    <Form.Item
                      className={"IntroductionCreator_FormItem"}
                      label={
                        <span
                          onClick={() => {
                            openPersonProfile(formik.values.participant_2_id)
                          }}
                        >
                          {participant2 && participant2.name}
                        </span>
                      }
                    >
                      <Select
                        bordered={false}
                        key="participant_2_email"
                        name="participant_2_email"
                        value={formik.values.participant_2_email}
                        onChange={(val) =>
                          formik.setFieldValue("participant_2_email", val)
                        }
                        onBlur={formik.handleBlur}
                        style={{ width: "100%" }}
                        placeholder={"Email"}
                      >
                        {participant2 &&
                          participant2.emails &&
                          participant2.emails
                            .flat()
                            .filter((email) => email && email.trim().length)
                            .map((email) => {
                              return (
                                <Select.Option value={email}>{email}</Select.Option>
                              )
                            })}
                      </Select>
                    </Form.Item>
                    <Divider />
                    <Form.Item className={"IntroductionCreator_FormItem"} label="CC">
                      <CCMultiSelect
                        selectedPeople={formik.values.participant_2_cc}
                        setSelectedPeople={(val) =>
                          formik.setFieldValue("participant_2_cc", val)
                        }
                      />
                    </Form.Item>
                    <Divider />

                    <Form.Item
                      className={"IntroductionCreator_FormItem"}
                      label={"Subject"}
                    >
                      <Input
                        key="participant_2_subject"
                        name="participant_2_subject"
                        value={
                          formik.values.participant_2_subject || introData.subject
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={"Subject"}
                        bordered={false}
                      />
                    </Form.Item>
                    <Divider />
                    <TinyMCUEditor
                      focusHandler={(value, id) => {
                        setEditing(value ? id : editing)
                      }}
                      updateBody={(html) => {
                        formik.setFieldValue("participant_2_template", html)
                      }}
                      body={formik.values.participant_2_template}
                      toolbarId="participant_2_template"
                      hidePersonalization={true}
                      extras={[
                        <AttachmentsUpload
                          fileList={formik.values.participant_2_files}
                          handleFileList={(newValues) => {
                            formik.setFieldValue("participant_2_files", newValues)
                          }}
                        />,
                      ]}
                    />
                  </div>
                )}
            </div>

            {formik.values.participant_1_id &&
              formik.values.participant_2_id &&
              !formik.values.participant_1_approval &&
              !formik.values.participant_2_approval && (
                <Form layout={"horizontal"} labelAlign={"left"}>
                  <Row type={"flex"}>
                    <Col span={24}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1, marginRight: 15 }}>
                          <Form.Item
                            className={"IntroductionCreator_FormItem"}
                            label={
                              <span
                                onClick={() => {
                                  openPersonProfile(formik.values.participant_1_id)
                                }}
                              >
                                {participant1 && participant1.name}
                              </span>
                            }
                          >
                            <Select
                              bordered={false}
                              key="participant_1_email"
                              name="participant_1_email"
                              value={formik.values.participant_1_email}
                              onChange={(val) =>
                                formik.setFieldValue("participant_1_email", val)
                              }
                              onBlur={formik.handleBlur}
                              disabled={!participant1 || !participant1.name}
                              style={{ width: "100%" }}
                              placeholder={"Email"}
                            >
                              {participant1 &&
                                participant1.emails &&
                                participant1.emails
                                  .filter((e) => e && e.trim().length > 0)
                                  .map((email) => {
                                    return (
                                      <Select.Option value={email}>
                                        {email}
                                      </Select.Option>
                                    )
                                  })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Form.Item
                            className={"IntroductionCreator_FormItem"}
                            label={
                              <span
                                onClick={() => {
                                  openPersonProfile(formik.values.participant_2_id)
                                }}
                              >
                                {participant2 && participant2.name}
                              </span>
                            }
                          >
                            <Select
                              bordered={false}
                              key="participant_2_email"
                              name="participant_2_email"
                              value={formik.values.participant_2_email}
                              onChange={(val) =>
                                formik.setFieldValue("participant_2_email", val)
                              }
                              onBlur={formik.handleBlur}
                              disabled={!participant2 || !participant2.name}
                              style={{ width: "100%" }}
                              placeholder={"Email"}
                            >
                              {participant2 &&
                                participant2.emails &&
                                participant2.emails
                                  .filter((e) => e && e.trim().length > 0)
                                  .map((email) => {
                                    return (
                                      <Select.Option value={email}>
                                        {email}
                                      </Select.Option>
                                    )
                                  })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <Divider />
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          className={"IntroductionCreator_FormItem"}
                          style={{ width: "100%", marginBottom: 0 }}
                          labelAlign={"left"}
                          label="CC"
                        >
                          <CCMultiSelect
                            selectedPeople={formik.values.cc}
                            setSelectedPeople={(val) =>
                              formik.setFieldValue("cc", val)
                            }
                          />
                        </Form.Item>
                      </div>
                      <Divider />

                      <Form.Item
                        className={"IntroductionCreator_FormItem"}
                        label={"Subject"}
                      >
                        <Input
                          key="subject"
                          name="subject"
                          bordered={false}
                          value={formik.values.subject || introData.subject}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={
                            !participant1 ||
                            !participant2 ||
                            !participant1.name ||
                            !participant2.name
                          }
                          placeholder={"Subject"}
                        />
                      </Form.Item>
                      <Divider />
                      <TinyMCUEditor
                        updateBody={(html) => {
                          formik.setFieldValue("template", html)
                        }}
                        body={formik.values.template}
                        toolbarId="template1"
                        hidePersonalization={true}
                        extras={[
                          <AttachmentsUpload
                            fileList={formik.values.files}
                            handleFileList={(newValues) => {
                              formik.setFieldValue("files", newValues)
                            }}
                          />,
                        ]}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
          </Form>
        </>
      ) : (
        <>
          {introData.body && (
            <>
              <Form
                className={"IntroductionCreator_FormWrapper"}
                layout={"horizontal"}
                labelAlign={"left"}
              >
                <Form.Item label={"From"}>
                  <Select
                    bordered={false}
                    value={introData.mail_from}
                    onChange={(val) => {
                      setIntroData({ ...introData, mail_from: val })
                    }}
                  >
                    {userAccounts
                      .filter((account) => account.email && account.email.length > 0)
                      .map(({ email }) => (
                        <Select.Option key={email} value={email}>
                          {email}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Divider />
                <div style={{ display: "flex" }}>
                  <Form.Item
                    className={"IntroductionCreator_FormItem"}
                    style={{ width: "50%", marginBottom: 0 }}
                    labelAlign={"left"}
                    label={
                      <span style={{ fontSize: 14 }}>{participant1.full_name}</span>
                    }
                  >
                    <Select
                      bordered={false}
                      value={introData.mail_1}
                      onChange={(val) => setIntroData({ ...introData, mail_1: val })}
                      style={{ width: "100%" }}
                      placeholder={"Email"}
                    >
                      {participant1 &&
                        participant1.emails &&
                        participant1.emails
                          .filter((e) => e && e.trim().length > 0)
                          .map((email) => {
                            return (
                              <Select.Option value={email}>{email}</Select.Option>
                            )
                          })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className={"IntroductionCreator_FormItem"}
                    style={{ width: "50%", marginBottom: 0 }}
                    labelAlign={"left"}
                    label={
                      <span style={{ fontSize: 14 }}>{participant2.full_name}</span>
                    }
                  >
                    <Select
                      bordered={false}
                      value={introData.mail_2}
                      onChange={(val) => setIntroData({ ...introData, mail_2: val })}
                      style={{ width: "100%" }}
                      placeholder={"Email"}
                    >
                      {participant2 &&
                        participant2.emails &&
                        participant2.emails
                          .filter((e) => e && e.trim().length > 0)
                          .map((email) => {
                            return (
                              <Select.Option value={email}>{email}</Select.Option>
                            )
                          })}
                    </Select>
                  </Form.Item>
                </div>
                <Divider />
                <div style={{ display: "flex" }}>
                  <Form.Item
                    className={"IntroductionCreator_FormItem"}
                    style={{ width: "100%", marginBottom: 0 }}
                    labelAlign={"left"}
                    label="CC"
                  >
                    <CCMultiSelect
                      selectedPeople={introData.cc}
                      setSelectedPeople={(val) =>
                        setIntroData({ ...introData, cc: val })
                      }
                    />
                  </Form.Item>
                </div>
                <Divider />

                <Form.Item
                  className={"IntroductionCreator_FormItem"}
                  label={"Subject"}
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    bordered={false}
                    value={introData.subject}
                    onChange={(e) =>
                      setIntroData({ ...introData, subject: e.target.value })
                    }
                  />
                </Form.Item>
                <Divider />
                <TinyMCUEditor
                  updateBody={(html) => {
                    setIntroData({ ...introData, body: html })
                  }}
                  body={introData.body}
                  toolbarId={`template-${itemIndex ? itemIndex : 0}`}
                  hidePersonalization={true}
                  extras={[
                    <AttachmentsUpload
                      fileList={introData.files}
                      handleFileList={(newValues) => {
                        setIntroData({ ...introData, files: newValues })
                      }}
                    />,
                  ]}
                />
              </Form>
            </>
          )}
        </>
      )}
      <div
        className={"IntroductionCreator_SubmitButton"}
        onClick={
          formik
            ? () => handleSubmit()
            : () => {
                handleSubmit({
                  ...introData,
                  mail_to: [introData.mail_1, introData.mail_2],
                })
              }
        }
      >
        Send
      </div>
    </div>
  )
}

export default IntroductionCreator
