import moment from "moment/moment"

export const renderTemplate = (receiver, template, includeRelated) => {
  // eslint-disable-next-line
  const hasConnexionsAndInclude = includeRelated && receiver?.connexions?.length > 0
  if (hasConnexionsAndInclude) {
    receiver = {
      ...receiver,
      first_names: getFirstNames(receiver),
    }
  }
  //eslint-disable-next-line
  const regex = /\{\{[^\}]+\}\}/g
  return template?.replace(regex, (match) => {
    const rgx = /\{\{([^}]+)\}\}/g
    const m = rgx.exec(match)
    const field = m[0].slice(2, -2).trim()
    if (field === "first_name" && hasConnexionsAndInclude) {
      return receiver.first_names || receiver.first_name || ""
    }
    return receiver[field] || ""
  })
}

export const getFirstNames = (receiver) => {
  const names = [
    receiver.first_name,
    ...receiver.connexions.map((contact) => contact.first_name),
  ].filter(Boolean)

  return names.length > 2
    ? names.reduce(
        (acc, name, index, arr) =>
          acc + (index === arr.length - 1 ? ` and ${name}` : `${name}, `),
        ""
      )
    : names.join(" and ")
}

export const initSplitMessage = (splitMessage) => {
  const convertedSplitMessage = {}
  convertedSplitMessage["null"] = {
    body: splitMessage.body,
    from: splitMessage.from,
    split_message_id: splitMessage.id,
    scheduled_send_date: splitMessage.scheduled_send_date,
    subject: splitMessage.subject,
    to: null,
    person_id: null,
  }

  splitMessage.messages.forEach((m) => {
    convertedSplitMessage[m.person.id] = {
      body: m.body,
      from: m.from,
      message_id: m.id,
      person_id: m.person.id,
      subject: m.subject,
      to: m.to,
      cc: m.cc ? m.cc : [],
    }
  })

  return convertedSplitMessage
}

const range = (start, end) => {
  const result = []

  for (let i = start; i < end; i++) {
    result.push(i)
  }

  return result
}

export const disabledDate = (current) => {
  return current && moment().isAfter(current, "day")
}

export const disabledDateTime = (current) => {
  if (moment().isBefore(current, "day")) {
    return null
  } else {
    if (moment().isSameOrAfter(current, "hours")) {
      return {
        disabledHours: () => range(0, moment().hours()),
        disabledMinutes: () => range(0, moment().minutes()),
      }
    } else {
      return {
        disabledHours: () => range(0, moment().hours()),
        disabledMinutes: () => [],
      }
    }
  }
}

export const injectSignature = (
  newBody,
  selectedAccount,
  currentSelectedAccount
) => {
  if (newBody) {
    const signaturePattern = /<section id="signature">[\s\S]*?<\/section>/
    const tempBody = newBody.replace(signaturePattern, "")
    if (tempBody && tempBody !== "<div><br></div>") {
      if (tempBody.slice(-8) === "<br><br>") {
        newBody =
          tempBody.slice(0, -8) + "<br><br>" + currentSelectedAccount?.signature
      } else {
        newBody = tempBody + "<br><br>" + currentSelectedAccount?.signature
      }
    } else {
      newBody = "<br><br>" + currentSelectedAccount?.signature
    }
  } else {
    newBody = "<br><br>" + currentSelectedAccount?.signature
  }
  return newBody
}
