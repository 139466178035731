import React, { useEffect, useState } from "react"
import { Button, Col, Row, Table, Tooltip } from "antd"
import { PaperClipOutlined, PlusCircleFilled } from "@ant-design/icons/lib"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteTemplate,
  fetchTemplate,
  fetchTemplates,
  newTemplate,
  saveTemplate,
} from "../../../redux/Messages/Messages.actions"
import TemplateEditor from "./components/TemplateEditor.component"
import "./EmailTemplates.styles.scss"
import Text from "antd/lib/typography/Text"
import * as _ from "lodash"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const EmailTemplates = ({ openTemplateEditor = false }) => {
  const dispatch = useDispatch()
  const templates = useSelector((state) => state.Messages.templates)
  const selected_template = useSelector((state) => state.Messages.selected_template)

  const loading = useSelector((state) => state.Messages.loading)
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [showMore, setShowMore] = useState(false)
  const [templateEditorVisible, setTemplateEditorVisible] = useState(false)

  useEffect(() => {
    dispatch(fetchTemplates())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (openTemplateEditor) setTemplateEditorVisible(true)
  }, [openTemplateEditor])

  useEffect(() => {
    if (selected_template) {
      setCurrentTemplate(selected_template)
    }
  }, [selected_template])
  const handleSaveTemplate = (data) => {
    if (data.id === undefined) {
      dispatch(newTemplate(data))
    } else {
      dispatch(saveTemplate(data))
    }
    setTemplateEditorVisible(false)
    setCurrentTemplate(null)
  }

  const templatesColumns = [
    {
      width: "25%",
      title: "NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record) => (
        <Row className={"EmailTemplates_Table_Text"}>{record.name}</Row>
      ),
    },
    {
      width: "25%",
      title: "SUBJECT",
      key: "subject",
      render: (record) => (
        <span className={"EmailTemplates_Table_Text"}>{record.subject}</span>
      ),
    },
    {
      width: "25%",
      title: "BODY",
      key: "body",
      render: (record) => {
        const parsedBody = `${record.body}`
        return (
          <span className={"EmailTemplates_Table_Body"}>
            {parsedBody?.replace(/(<([^>]+)>)/gi, " ")}
          </span>
        )
      },
    },
    {
      width: "5%",
      title: (
        <Tooltip title="Attachments">
          <PaperClipOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      key: "attachments",
      render: (record) => {
        return (
          <span className={"EmailTemplates_Table_Attachments"}>
            {record.attachments_count > 0 ? record.attachments_count : ""}
          </span>
        )
      },
    },
    {
      width: "20%",
      title: "ACTIONS",
      key: "action",
      render: (record) => (
        <span>
          <Row>
            <Col span={12}>
              <Button
                onClick={() => {
                  setTemplateEditorVisible(true)
                  dispatch(fetchTemplate(record.id))
                }}
                color={"#1C9AFE"}
                type={"primary"}
              >
                Edit
              </Button>
            </Col>
            <Col span={12}>
              <Button
                onClick={() => {
                  dispatch(deleteTemplate(record.id))
                }}
                type={"link"}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </span>
      ),
    },
  ]

  return (
    <div>
      <Row className={"EmailTemplates_TopPanel"}>
        <Col
          className={"EmailTemplates_Add "}
          onClick={() => {
            setCurrentTemplate({ name: "New template", body: "" })
            setTemplateEditorVisible(true)
          }}
          span={12}
        >
          <PlusCircleFilled className={"EmailTemplates_Add-icon"} />
          <Text className={"EmailTemplates_Add-text underline"}>
            Add new Message Template
          </Text>
        </Col>
      </Row>
      <Table
        loading={{
          spinning: loading,
          indicator: <RelatableLoader quote={true} loading={loading} />,
        }}
        pagination={false}
        rowKey={(record) => `${record.id}`}
        className={"EmailTemplates_Table"}
        columns={templatesColumns}
        dataSource={
          showMore
            ? _.sortBy(templates, "timestamp").reverse()
            : _.sortBy(templates, "timestamp").reverse().slice(0, 5)
        }
      />
      {templates?.length > 5 && (
        <button
          className="EmailTemplates_LoadMore"
          onClick={() => setShowMore(true)}
        >
          Load more...
        </button>
      )}

      {currentTemplate && templateEditorVisible && (
        <TemplateEditor
          onCancel={() => {
            setCurrentTemplate(null)
            setTemplateEditorVisible(false)
          }}
          show={true}
          template={currentTemplate}
          onSave={(data) => handleSaveTemplate(data)}
        />
      )}
    </div>
  )
}

export default EmailTemplates
