import React from "react"
import "./OnboardingEWebinar.styles.scss"

export default function OnboardingCal() {
  return (
    <div className="OnboardingCal">
      <div
        id="w1708114039774"
        className="ewebinar__Widget ewebinar__RegForm_Root"
        style={{ width: "100%" }}
      >
        <form className="ewebinar__RegForm ewebinar--ltr">
          <div className="ewebinar__RegForm__Content">
            <div>
              <div className="ewebinar__RegForm__Field ewebinar__RegForm__Field--Sessions">
                <div
                  className="ewebinar__Session__Dropdown"
                  style={{ color: "#172747", borderColor: "#172747" }}
                >
                  <select
                    name="session"
                    className="ewebinar__Session__Dropdown__Select"
                  >
                    <option
                      className="ewebinar__FixedBar__Content__Session__Dropdown__Select"
                      value=""
                      disabled="disabled"
                      selected="selected"
                      hidden=""
                    >
                      Getting sessions...
                    </option>
                  </select>
                </div>
                <div className="ewebinar__RegForm__Field__Error"></div>
              </div>
              <div className="ewebinar__RegForm__Field">
                <div className="ewebinar__RegForm__Field__Input">
                  <input id="firstName" name="firstName" placeholder="First name" />
                </div>
                <div className="ewebinar__RegForm__Field__Error"></div>
              </div>
              <div className="ewebinar__RegForm__Field">
                <div className="ewebinar__RegForm__Field__Input">
                  <input id="lastName" name="lastName" placeholder="Last name" />
                </div>
                <div className="ewebinar__RegForm__Field__Error"></div>
              </div>
              <div className="ewebinar__RegForm__Field">
                <div className="ewebinar__RegForm__Field__Input">
                  <input id="email" name="email" placeholder="Email" />
                </div>
                <div className="ewebinar__RegForm__Field__Error"></div>
              </div>
            </div>
            <div className="ewebinar__RegForm__Captcha"></div>
            <div className="ewebinar__RegForm__Error"></div>
          </div>
          <div className="ewebinar__RegForm__Footer">
            <a
              className="ewebinar__RegisterButton__Wrap ewebinar--ltr"
              href="/#"
              style={{ textDecoration: "none" }}
            >
              <button
                className="ewebinar__Widget ewebinar__RegisterButton"
                type="submit"
                style={{
                  borderRadius: "8px",
                  background: "#FF7470",
                  color: "#ffffff",
                }}
              >
                <div className="ewebinar__Dots">
                  <span
                    className="ewebinar__LoadingDot"
                    style={{ background: "#ffffff" }}
                  ></span>
                  <span
                    className="ewebinar__LoadingDot"
                    style={{ background: "#ffffff" }}
                  ></span>
                  <span
                    className="ewebinar__LoadingDot"
                    style={{ background: "#ffffff" }}
                  ></span>
                </div>
                <span
                  className="ewebinar__ButtonText"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Sign Up Now
                </span>
              </button>
            </a>
          </div>
        </form>
      </div>
    </div>
  )
}
