export const FETCH_ACTIVITIES = "FETCH_ACTIVITIES"
export const FETCH_ACTIVITIES_SUCCESS = "FETCH_ACTIVITIES_SUCCESS"
export const FETCH_ACTIVITIES_FAIL = "FETCH_ACTIVITIES_FAIL"
export const FETCH_POINTS = "FETCH_POINTS"
export const FETCH_POINTS_SUCCESS = "FETCH_POINTS_SUCCESS"
export const FETCH_POINTS_FAIL = "FETCH_POINTS_FAIL"

export const CLEAR_REDUX_ACTIVITIES_STATE = "CLEAR_REDUX_ACTIVITIES_STATE"
export const SET_INITIAL_REDUX_ACTIVITIES_STATE =
  "SET_INITIAL_REDUX_ACTIVITIES_STATE"

export const FETCH_ACTIVE_CHALLENGES = "FETCH_ACTIVE_CHALLENGES"
export const FETCH_ACTIVE_CHALLENGES_SUCCESS = "FETCH_ACTIVE_CHALLENGES_SUCCESS"
export const FETCH_ACTIVE_CHALLENGES_FAIL = "FETCH_ACTIVE_CHALLENGES_FAIL"

export const FETCH_CHALLENGE = "FETCH_CHALLENGE"
export const FETCH_CHALLENGE_SUCCESS = "FETCH_CHALLENGE_SUCCESS"
export const FETCH_CHALLENGE_FAIL = "FETCH_CHALLENGE_FAIL"

export const JOIN_TO_CHALLENGE = "JOIN_TO_CHALLENGE"
export const JOIN_TO_CHALLENGE_SUCCESS = "JOIN_TO_CHALLENGE_SUCCESS"
export const JOIN_TO_CHALLENGE_FAIL = "JOIN_TO_CHALLENGE_FAIL"
