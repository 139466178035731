import React, { useEffect, useRef, useState } from "react"
import "./FlowSphere.scss"
import { Button, Dropdown, Input, Menu, Tooltip } from "antd"
import Icon, { EditOutlined, MoreOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import {
  createSphere,
  deleteSphere,
  fetchFlowSphere,
  renameSphere,
  saveFlowSphereAction,
} from "../../redux/Collections/Collections.actions"
import ConfirmModal from "../../components/Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import WaitStep from "../../components/FlowSphere/WaitStep.component"
import RemindStep from "../../components/FlowSphere/RemindStep.component"
import SendStep from "../../components/FlowSphere/SendStep.component"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { uuid } from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils"
import { fetchTemplates } from "../../redux/Messages/Messages.actions"
import { showNoAccountNotification } from "../../redux/App/App.actions"
import { fetchAccounts } from "../../redux/Accounts/Accounts.actions"
import TrashIcon from "../../components/Icons/Trash.icons"
import moment from "moment/moment"
import { useHistory, useParams } from "react-router"
import { showPeopleByIds } from "../../redux/People/People.actions"

const FlowSphere = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const sphere = useSelector((state) => state.CollectionsState.flowSphere)
  const userAccounts = useSelector((state) => state.AccountsState.accounts)

  const flowSphereTitleRef = useRef(null)

  const [flowSphereName, setFlowSphereName] = useState(null)
  const [lastOrder, setLastOrder] = useState(0)

  const [steps, setSteps] = useState([])
  const [deleteSphereConfirmVisible, setDeleteSphereConfirmVisible] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const [stepToDelete, setStepToDelete] = useState(null)

  useEffect(() => {
    if (sphere) {
      setFlowSphereName(sphere.title)
      setSteps(sphere.steps)
      setLastOrder(sphere.steps.length)
    } else {
      setFlowSphereName("My New Flow Sphere")
      setSteps([])
      setLastOrder(0)
    }
  }, [sphere])

  useEffect(() => {
    if (params.friendly_id) {
      dispatch(fetchFlowSphere(params.friendly_id))
    } else {
      dispatch(fetchFlowSphere(null))
    }
    // eslint-disable-next-line
  }, [params.friendly_id])

  useEffect(() => {
    dispatch(fetchTemplates())
    dispatch(fetchAccounts())
    // eslint-disable-next-line
  }, [])

  const handleSaveFlowSphere = (newSteps, toRemove = []) => {
    setUnsavedChanges(false)
    if (sphere?.id) {
      dispatch(saveFlowSphereAction(sphere?.id, newSteps, toRemove))
    } else {
      dispatch(createSphere("flow"))
    }
  }

  const handleRenameSphere = () => {
    if (sphere?.id && flowSphereName !== sphere.title) {
      dispatch(renameSphere(sphere.id, flowSphereName))
    }
    setUnsavedChanges(true)
  }

  const handleRemoveStep = (step) => {
    const newSteps = steps.filter((s) => s.order_key !== step.order_key)
    const items = Array.from(newSteps, (s) => s.order_key)
    setLastOrder(newSteps.length)
    setSteps(AddOrderToStep(items, newSteps))
    setStepToDelete(null)
    if (sphere.id) {
      handleSaveFlowSphere(AddOrderToStep(items, newSteps), [step.id])
    }
  }

  const handleAddStep = (step) => {
    if (step.step_type === "send") {
      const runningAccounts = userAccounts.filter((a) => a.state === "running")

      if (runningAccounts.length === 0) {
        dispatch(showNoAccountNotification())
        return
      }
    }

    setLastOrder(step.order)
    setSteps([...steps, step])
    setUnsavedChanges(true)
  }

  const updateSteps = (configuration, index) => {
    const newSteps = [...steps]
    if (newSteps[index].configuration !== configuration) {
      setUnsavedChanges(true)
    }
    newSteps[index].configuration = configuration
    setSteps(newSteps)
  }

  const handleReorderSteps = (result) => {
    if (!result.destination) return

    const items = Array.from(steps, (s) => s.order_key)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setSteps(AddOrderToStep(items, steps))
    setUnsavedChanges(true)
  }

  const AddOrderToStep = (items_order = null, items) => {
    return items.map((item) => {
      item.order = items_order.findIndex((o) => o === item.order_key) + 1
      if (item.order === -1) {
        item.order = items_order.length + item.order_key
      }
      return item
    })
  }

  const renderStep = (step, idx) => {
    switch (step.step_type) {
      case "wait":
        return (
          <WaitStep
            onUpdate={(configuration) => {
              updateSteps(configuration, idx)
            }}
            step={step}
          />
        )
      case "remind":
        return (
          <RemindStep
            onUpdate={(configuration) => {
              updateSteps(configuration, idx)
            }}
            step={step}
          />
        )
      case "send":
        return (
          <SendStep
            onUpdate={(configuration) => {
              updateSteps(configuration, idx)
            }}
            accounts={userAccounts?.filter((a) => a.state === "running")}
            step={step}
          />
        )
      default:
        return null
    }
  }

  const moreMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setDeleteSphereConfirmVisible(true)}>
        <span className={"FlowSphere_Buttons_Red"}>Delete Sphere</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="FlowSphere">
      <ConfirmModal
        title={`Are you sure you want to delete sphere?`}
        description={`Removing ${flowSphereName} will archive all the contacts that don't belong to any sphere.`}
        visible={deleteSphereConfirmVisible}
        onCancel={() => setDeleteSphereConfirmVisible(false)}
        onConfirm={() => {
          dispatch(deleteSphere(sphere.id))
          history.push("/dashboard")
        }}
      />
      <ConfirmModal
        title={`Are you sure you want to delete this step?`}
        description={`Deleting this step will cause anyone in this step to move to the next step instantly.`}
        visible={!!stepToDelete}
        onCancel={() => setStepToDelete(null)}
        onConfirm={() => handleRemoveStep(stepToDelete)}
      />
      <div className={"FlowSphere_Header"}>
        <div className={"FlowSphere_TitleContainer"}>
          <div className={"FlowSphere_EditTitle"}>
            <Input
              autoFocus={true}
              ref={flowSphereTitleRef}
              onKeyPress={(e) => {
                flowSphereTitleRef.current.input.style.width = `${
                  flowSphereName.length + 1
                }ch`
              }}
              style={{ width: `${flowSphereName?.length}ch` }}
              className={"FlowSphere_Title"}
              value={flowSphereName}
              onChange={(e) => setFlowSphereName(e.target.value)}
              onBlur={handleRenameSphere}
            />
            <EditOutlined className={`FlowSphere_EditIcon`} />
          </div>

          <div className={"FlowSphere_Buttons"}>
            <Tooltip
              title={
                sphere?.updated_at
                  ? ` Last updated: ${moment(sphere?.updated_at).format(
                      " hh:mm A, MMM Do"
                    )}`
                  : null
              }
            >
              <button
                className={`${
                  unsavedChanges
                    ? "FlowSphere_Buttons_Green"
                    : "FlowSphere_Buttons_Disabled"
                }`}
                onClick={() => unsavedChanges && handleSaveFlowSphere(steps)}
              >
                Save Changes
              </button>
            </Tooltip>

            {sphere?.id && (
              <Dropdown.Button
                type={"link"}
                icon={
                  <Icon
                    className="FlowSphere_Buttons_More"
                    component={MoreOutlined}
                  />
                }
                overlay={moreMenu}
                placement="topRight"
                trigger={["click"]}
              />
            )}
          </div>
        </div>
        <div className={"FlowSphere_SubHeader"}>
          <span className={"FlowSphere_SubHeader_Text"}>
            When you put a contact in this flow sphere, Relatable will follow these
            actions you have laid out here.
          </span>
        </div>
      </div>
      {steps.length > 0 && (
        <DragDropContext onDragEnd={handleReorderSteps}>
          <Droppable droppableId="LeftRail_Spheres">
            {(provided) => (
              <div
                className={"FlowSphere_Steps"}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {steps
                  .sort((a, b) => a.order - b.order)
                  .map((step, idx) => {
                    return (
                      <Draggable key={idx} draggableId={`${idx}`} index={idx}>
                        {(provided, snapshot) => (
                          <div
                            className={`FlowSphere_StepWrapper ${
                              snapshot.isDragging ? "dragging" : "no-dragging"
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className={"FlowSphere_Step FlowStep"}>
                              {renderStep(step, idx)}
                              {step.people?.length ? (
                                <Tooltip
                                  placement={"bottom"}
                                  title="Click to view people in the table"
                                >
                                  <Button
                                    type={"link"}
                                    onClick={() => {
                                      dispatch(showPeopleByIds(step.people))
                                    }}
                                  >
                                    {step.people.length} here
                                  </Button>
                                </Tooltip>
                              ) : null}
                              <Tooltip placement={"bottom"} title="Remove step">
                                <Icon
                                  component={TrashIcon}
                                  onClick={() => setStepToDelete(step)}
                                />
                              </Tooltip>
                            </div>
                            <span>then...</span>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {sphere && steps.length ? (
        <div className={"FlowSphere_Step FlowSphere_DoneStep"}>
          <span className={"FlowSphere_DoneStep_Text"}>Done!</span>
          {sphere.done_step_people?.length ? (
            <Tooltip placement={"bottom"} title="Click to view people in the table">
              <Button
                type={"link"}
                onClick={() => {
                  dispatch(showPeopleByIds(sphere.done_step_people))
                }}
              >
                {sphere.done_step_people?.length} here
              </Button>
            </Tooltip>
          ) : null}
        </div>
      ) : (
        <div className={"FlowSphere_Step FlowSphere_EmptyStep"}>No steps yet!</div>
      )}

      <div className={"FlowSphere_Step FlowSphere_Footer"}>
        <button
          onClick={() =>
            handleAddStep({
              order_key: uuid("wait"),
              step_type: "wait",
              order: lastOrder + 1,
            })
          }
        >
          Add a Wait Step
        </button>
        <button
          onClick={() =>
            handleAddStep({
              order_key: uuid("send"),
              step_type: "send",
              order: lastOrder + 1,
            })
          }
        >
          Add a Send Step
        </button>
        <button
          onClick={() =>
            handleAddStep({
              order_key: uuid("remind"),
              step_type: "remind",
              order: lastOrder + 1,
            })
          }
        >
          Add a Remind Step
        </button>
      </div>
    </div>
  )
}

export default FlowSphere
