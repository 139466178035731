import React from "react"

const EyeIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.93359 4.4375C4.30859 4.4375 2.05859 9.5 2.05859 9.5C2.05859 9.5 4.30859 14.5625 9.93359 14.5625C15.5586 14.5625 17.8086 9.5 17.8086 9.5C17.8086 9.5 15.5586 4.4375 9.93359 4.4375Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.93359 12.3125C11.4869 12.3125 12.7461 11.0533 12.7461 9.5C12.7461 7.9467 11.4869 6.6875 9.93359 6.6875C8.38029 6.6875 7.12109 7.9467 7.12109 9.5C7.12109 11.0533 8.38029 12.3125 9.93359 12.3125Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EyeIcon
