import React from "react"
import { Route } from "react-router-dom"

function ProtectedRoute({ children, isAuthorized, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorized
          ? children
          : (window.location.href = "https://try.relatable.one")
      }
    />
  )
}

export default ProtectedRoute
