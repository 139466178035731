import React, { useEffect, useState } from "react"

import "antd/dist/antd.css"

import { Spin, Tooltip } from "antd"

import "./ParticipantsTable.styles.scss"

import axios from "axios"
import moment from "moment/moment"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const DOMPurify = require("dompurify")(window)

const MessageCell = ({ participantId, details, time_zone }) => {
  const [loading, setLoading] = useState(false)

  const [body, setBody] = useState(null)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (participantId && expanded && !body) {
      setLoading(true)
      getParticipant()
    }
    // eslint-disable-next-line
  }, [expanded])

  const getParticipant = async () => {
    const { data } = await axios.request({
      url: `/v1/participants/${participantId}`,
      method: "GET",
    })

    if (data.body && data.subject) {
      setBody(data.body)
    } else {
      setBody(details.body)
    }
    setLoading(false)
  }

  return (
    <div>
      <RelatableLoader loading={loading}>
        <Tooltip placement="top" title="Click to see more">
          <div
            onClick={() => {
              if (details.body?.length > 70) {
                setExpanded(!expanded)
              }
            }}
            className="ParticipantsTable_Message"
          >
            <span className="ParticipantsTable_Message_Subject">
              {details.subject &&
                details.subject.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 50)}
            </span>

            {expanded && body ? (
              <div
                className="ParticipantsTable_Message_Body"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(body.split("\n").join("<br/>"), {
                    ADD_ATTR: ["target"],
                  }),
                }}
              />
            ) : (
              <span className="ParticipantsTable_Message_Body">
                {details.body?.replace(/<\/?[^>]+(>|$)/g, "")?.substring(0, 70)}
                {details.expandable ? "..." : ""}
              </span>
            )}

            {details.last_sent && (
              <span className="ParticipantsTable_Message_Date">
                {moment(details.last_sent).tz(time_zone).format("MMM Do, YYYY")}
              </span>
            )}
          </div>
        </Tooltip>
      </RelatableLoader>
    </div>
  )
}

export default MessageCell
