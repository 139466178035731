import React, { useEffect, useState } from "react"
import { List, Tabs } from "antd"
import "./IntroductionsTab.styles.scss"
import { useDispatch, useSelector } from "react-redux"
import { fetchIntroductions } from "../../../../redux/Introductions/Introductions.actions"
import IntroItem from "./IntroItem/IntroItem.component"
import EmptyState from "../../../../components/UI/EmptyState/EmptyState.ui"
import CustomPagination from "../../../../components/Database/CustomPagination/CustomPagination.component"
import RelatableLoader from "../../../../components/UI/RelatableLoader/RelatableLoader.component"

const { TabPane } = Tabs

const IntroductionsTab = () => {
  const loading = useSelector((state) => state.IntroductionsState.loading)
  const awaiting_intros = useSelector(
    (state) => state.IntroductionsState.awaiting.introductions
  )
  const completed_intros = useSelector(
    (state) => state.IntroductionsState.completed.introductions
  )
  const awaiting_total_entries = useSelector(
    (state) => state.IntroductionsState.awaiting.total_entries
  )
  const completed_total_entries = useSelector(
    (state) => state.IntroductionsState.completed.total_entries
  )

  const [inProcessIntroPage, setInProcessIntroPage] = useState(1)
  const [completedIntroPage, setCompletedIntroPage] = useState(1)

  const dispatch = useDispatch()

  const perPage = 9

  useEffect(() => {
    dispatch(fetchIntroductions("completed", completedIntroPage))
    // eslint-disable-next-line
  }, [completedIntroPage])

  useEffect(() => {
    dispatch(fetchIntroductions("awaiting", inProcessIntroPage))
    // eslint-disable-next-line
  }, [inProcessIntroPage])

  let emptyIntroText = (
    <EmptyState
      size="medium"
      quote="Don’t make friends who are comfortable to be with. Make friends who will force you to lever yourself up."
      quoteFrom="Thomas J. Watson"
      instructions="You can make introductions from the Dashboard, or by clicking on an individual contact."
    />
  )

  return (
    <Tabs className="IntroductionsTab">
      <TabPane key="in_process" tab="In process">
        <List
          loading={{
            spinning: loading,
            indicator: <RelatableLoader loading={loading} />,
          }}
          rowKey={(item) => item.id}
          renderItem={(item) => <IntroItem introduction={item} />}
          dataSource={awaiting_intros}
          locale={{ emptyText: emptyIntroText }}
        />
        <div className="IntroductionsTab_Pagination">
          <CustomPagination
            perPage={perPage}
            currentPage={inProcessIntroPage}
            onPageChange={setInProcessIntroPage}
            hideTextButton={true}
            hideOnSinglePage={!awaiting_intros?.length > 0}
            total={awaiting_total_entries}
            small={true}
          />
        </div>
      </TabPane>
      <TabPane key={"completed"} tab={"Completed"}>
        <List
          rowKey={(item) => item.id}
          renderItem={(item) => <IntroItem introduction={item} />}
          dataSource={completed_intros}
          locale={{ emptyText: emptyIntroText }}
        />
        <div className="IntroductionsTab_Pagination">
          <CustomPagination
            perPage={perPage}
            currentPage={completedIntroPage}
            onPageChange={setCompletedIntroPage}
            hideTextButton={true}
            hideOnSinglePage={!awaiting_intros?.length > 0}
            total={completed_total_entries}
            small={true}
          />
        </div>
      </TabPane>
    </Tabs>
  )
}

export default IntroductionsTab
