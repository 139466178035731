import { call, delay, fork, put, takeLatest } from "redux-saga/effects"
import {
  ADD_ACCOUNT,
  CREATE_STRIPE_SESSION,
  DELETE_ACCOUNT,
  FETCH_ACCOUNTS,
  FETCH_CALENDARS,
  MAKE_DEFAULT,
  RESYNC_INTERACTIONS,
  UPDATE_CRITERIA,
  UPDATE_SIGNATURE,
} from "./Accounts.types"
import {
  addAccountFail,
  addAccountSuccess,
  createStripeSessionFail,
  createStripeSessionSuccess,
  deleteAccountFail,
  deleteAccountSuccess,
  fetchAccountsFail,
  fetchAccountsSuccess,
  fetchCalendarsFail,
  fetchCalendarsSuccess,
  makeDefaultFail,
  makeDefaultSuccess,
  resyncInteractionsStart,
  resyncInteractionsSuccess,
  updateCriteriaFail,
  updateCriteriaSuccess,
  updateSignatureFail,
  updateSignatureSuccess,
} from "./Accounts.actions"
import axios from "axios"

export function* watchAccountsSaga() {
  yield takeLatest(FETCH_ACCOUNTS, getAccounts)
  yield takeLatest(ADD_ACCOUNT, addAccount)
  yield takeLatest(MAKE_DEFAULT, makeDefault)
  yield takeLatest(DELETE_ACCOUNT, deleteAccount)
  yield takeLatest(RESYNC_INTERACTIONS, resyncInteractions)
  yield takeLatest(UPDATE_SIGNATURE, updateSignature)
  yield takeLatest(UPDATE_CRITERIA, updateCriteria)
  yield takeLatest(FETCH_CALENDARS, fetchCalendars)
  yield takeLatest(CREATE_STRIPE_SESSION, createStripeSession)
}

function* getAccounts() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/accounts/all",
      })
    )
    yield put(
      fetchAccountsSuccess(
        data.accounts,
        data.services,
        data.split_messages_left_today,
        data.is_user_active
      )
    )
  } catch (error) {
    yield put(fetchAccountsFail(error))
  }
}

function* resyncInteractions(action) {
  try {
    yield delay(2000)
    yield put(resyncInteractionsStart())
    yield call(() =>
      axios.request({
        url: "/v1/nylas/resync",
        params: {
          grant_id: action.id,
          initialSync: action.initialSync,
        },
        method: "POST",
      })
    )
    yield put(resyncInteractionsSuccess())
  } catch (error) {
    console.log(error)
    // yield put(resyncInteractionsFail(error))
  }
}

function* addAccount(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/accounts/connect",
        params: {
          nylas_state: action.state,
          nylas_code: action.code,
        },
      })
    )
    yield put(addAccountSuccess(data.data, action.code))
    window.location.href = `${process.env.REACT_APP_NYLAS_CALLBACK_URI}?grant_id=${data.new_grant_id}`
  } catch (error) {
    yield put(addAccountFail(error))
  }
}

function* makeDefault(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/accounts/make_default",
        params: {
          account_id: action.id,
        },
        method: "POST",
      })
    )
    yield put(makeDefaultSuccess(data.account))
  } catch (error) {
    yield put(makeDefaultFail(error))
  }
}

function* deleteAccount(action) {
  try {
    yield fork(() =>
      axios.request({
        url: "/v1/accounts/delete",
        params: {
          account_id: action.id,
        },
        method: "POST",
      })
    )
    yield delay(2000)
    yield put(deleteAccountSuccess(action.id))
  } catch (error) {
    yield put(deleteAccountFail(error))
  }
}

function* updateSignature(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/accounts/update_signature/${action.account_id}`,
        data: {
          signature: action.signature,
        },
        method: "PUT",
      })
    )
    yield put(updateSignatureSuccess(action.account_id, action.signature))
  } catch (error) {
    yield put(updateSignatureFail(error))
  }
}

function* updateCriteria(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/accounts/update_criteria/${action.account_id}`,
        data: {
          criteria: action.criteria,
        },
        method: "PUT",
      })
    )
    yield put(updateCriteriaSuccess(action.account_id, action.criteria))
  } catch (error) {
    yield put(updateCriteriaFail(error))
  }
}

function* fetchCalendars(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/accounts/calendars/${action.account_id}`,

        method: "GET",
      })
    )
    yield put(fetchCalendarsSuccess(parseInt(action.account_id), data))
  } catch (error) {
    yield put(fetchCalendarsFail(error))
  }
}

function* createStripeSession() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/payments/create_stripe_session`,
        method: "GET",
      })
    )
    yield put(createStripeSessionSuccess(data.session_url))
  } catch (error) {
    yield put(createStripeSessionFail(error))
  }
}
