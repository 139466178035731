import axios from "axios"

const dimissContact = async (participant_id, callback) => {
  const { data } = await axios.request({
    url: "/v1/participants/archive",
    data: { participants_ids: [participant_id] },
    method: "PUT",
  })

  callback(data)
}

export default dimissContact
