import "./EmailComposerModal.styles.scss"
import React, { useEffect, useState } from "react"
import { Drawer, Modal, Skeleton } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  setInitCC,
  setScheduledSplitMessageSenderVisible,
  setSelectedContactId,
  setSplitMessageSenderVisible,
  toggleSuggestedMessagesPanel,
} from "../../../redux/App/App.actions"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import XIcon from "../../Icons/X.icons"
import {
  fetchAiDraft,
  setMessageDraft,
} from "../../../redux/Messages/Messages.actions"
import axios from "axios"

const { confirm } = Modal

const EmailComposerModal = ({
  visible,
  action = "sending",
  children,
  title = "New Email",
  cancelHandler = null,
  width = "62vw",
}) => {
  const dispatch = useDispatch()
  const contactId = useSelector((state) => state.AppState.visibleContactID)
  const showSuggestedMessages = useSelector(
    (state) => state.AppState.showSuggestedMessages
  )

  const containerRef = React.useRef(null)
  const [loading, setLoading] = useState(false)
  const [suggestedMessages, setSuggestedMessages] = useState([])

  useEffect(() => {
    if (showSuggestedMessages) {
      getPresetTemplates()
    }
  }, [showSuggestedMessages])

  const getPresetTemplates = async () => {
    setLoading(true)
    await axios
      .request({
        url: "/v1/preset_templates",
        method: "GET",
      })
      .then((r) => {
        setSuggestedMessages(r.data)
        setLoading(false)
      })
  }

  return (
    <Modal
      className={"EmailComposerModal"}
      closable={true}
      width={width}
      style={{ top: "5%", right: "440px" }}
      visible={visible}
      closeIcon={<XIcon />}
      onCancel={() => {
        confirm({
          centered: true,
          title: `Are you sure you want to close without ${action}?`,
          className: "EmailComposerModal_ConfirmModal",
          okButtonProps: { className: "EmailComposerModal_ConfirmButton" },
          cancelButtonProps: { className: "EmailComposerModal_ConfirmButton" },
          bodyStyle: { padding: "24px" },
          icon: <ExclamationCircleOutlined />,
          okText: "Yes",
          cancelText: "Back",
          onOk() {
            dispatch(setMessageDraft(null))
            dispatch(setSplitMessageSenderVisible(false))
            dispatch(setInitCC([]))
            dispatch(setSelectedContactId(null))
            dispatch(setScheduledSplitMessageSenderVisible(false))
            cancelHandler && cancelHandler()
          },
          onCancel() {},
        })
      }}
      destroyOnClose
      footer={null}
      title={<h3>{title}</h3>}
    >
      <div className="EmailComposerModal_Container">
        {children}
        {showSuggestedMessages && (
          <div
            ref={containerRef}
            className="EmailComposerModal_SuggestedMessagesContainer"
          >
            <Drawer
              mask={false}
              getContainer={() => containerRef.current}
              visible={showSuggestedMessages}
              onClose={() => dispatch(toggleSuggestedMessagesPanel())}
              closable={true}
              closeIcon={null}
              placement="right"
              width={300}
              className="EmailComposerModal_SuggestedMessagesDrawer"
            >
              <Skeleton loading={loading} active={loading}>
                <div className="EmailComposerModal_SuggestedMessages">
                  <span>Click on message and we'll draft an email for you</span>
                  <div className="EmailComposerModal_SuggestedMessages_List">
                    {suggestedMessages.map((message) => (
                      <div
                        onClick={() => {
                          dispatch(fetchAiDraft(contactId, null, null, message.id))
                        }}
                        key={message.id}
                        className="EmailComposerModal_SuggestedMessages_Message"
                      >
                        <strong>{message.description}</strong>
                        <p>{message.body.substring(0, 50)}...</p>
                      </div>
                    ))}
                  </div>
                </div>
              </Skeleton>
            </Drawer>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default EmailComposerModal
