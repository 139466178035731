import React from "react"
import PropTypes from "prop-types"
import "./ProgressBar.styles.scss"

const ProgressBar = ({ progress }) => (
  <div className="ProgressBar">
    <div className="ProgressBar_Back">{`${progress}% complete!`}</div>
    <div
      className="ProgressBar_Front"
      style={{ clipPath: `inset(0 0 0 ${progress}%)` }}
    >{`${progress}% complete!`}</div>
  </div>
)

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
}

ProgressBar.defaultProps = {
  progress: 0,
}

export default ProgressBar
