import React, { useState } from "react"
import { Badge, Input, Select, Switch, Tooltip, Typography } from "antd"
import {
  DeleteOutlined,
  GlobalOutlined,
  PlusCircleOutlined,
  UserOutlined,
  WarningFilled,
} from "@ant-design/icons"
import {
  deleteCollectionSharing,
  manageGlobalSharing,
  managePersonalSharing,
} from "../../../../redux/Collections/Collections.actions"
import { useDispatch, useSelector } from "react-redux"

const SphereSharing = ({ sphere }) => {
  const dispatch = useDispatch()
  const { Text, Paragraph, Title } = Typography
  const collectionSharings = useSelector(
    (state) => state.CollectionsState.collectionSharings
  )
  const globalSharing = collectionSharings?.find((cs) => cs.global === true)
  const [userAccessLevel, setUserAccessLevel] = useState(0)
  const [emailError, setEmailError] = useState(null)
  const [newEmail, setNewEmail] = useState("")
  const [selectedEmail, setSelectedEmail] = useState(null)

  const userAccessLevels = [
    { value: 0, title: "See basic information" },
    { value: 1, title: "See everything (including notes)" },
    { value: 2, title: "Add/edit this sphere" },
  ]
  const globalAccessLevels = [
    { value: 0, title: "See basic information" },
    { value: 1, title: "See everything (including notes)" },
  ]

  const validateEmail = (email) => {
    const re = /^\S+@\S+\.\S+$/
    if (collectionSharings.find((cs) => cs.email === email)) {
      setEmailError("Email is already there!")
    } else if (re.test(email)) {
      setEmailError(null)
    } else {
      setEmailError("Email is invalid!")
    }
  }

  const personalShare = (email, access_level) => {
    dispatch(
      managePersonalSharing(
        {
          collection_id: sphere.id,
          email: email,
          access_level: access_level,
        },
        "sphere"
      )
    )
    setNewEmail("")
  }

  const globalShare = (global, access_level) => {
    dispatch(
      manageGlobalSharing(
        {
          collection_id: sphere.id,
          access_level: access_level,
        },
        "sphere"
      )
    )
  }

  return (
    <div className={"SphereModal_SharingContainer"}>
      <div className={"SphereModal_ShareWrapper"}>
        <div className={"SphereModal_ShareHeader"}>
          <UserOutlined />
          <Title level={5}>Email sharing</Title>
        </div>
        <div className={"SphereModal_Column"}>
          <Text strong={true}>Share with...</Text>

          <Paragraph>
            They'll be able to create the Relatable account if they don't have one
            yet.
          </Paragraph>
          <div className={"SphereModal_PersonalShare"}>
            <div className={"SphereModal_PersonalShare_UserInput"}>
              <Input
                value={newEmail}
                onChange={(event) => {
                  if (event.target.value.length) {
                    setUserAccessLevel(0)
                    setSelectedEmail(null)
                    validateEmail(event.target.value)
                    setNewEmail(event.target.value)
                  } else {
                    setNewEmail(event.target.value)
                    setEmailError(false)
                  }
                }}
                suffix={
                  !emailError && newEmail.length ? (
                    <PlusCircleOutlined
                      style={{ color: "green" }}
                      onClick={() => {
                        personalShare(newEmail, userAccessLevel)
                      }}
                    />
                  ) : (
                    <span />
                  )
                }
                className={emailError ? "invalid" : ""}
                prefix={
                  emailError ? (
                    <Tooltip title={emailError}>
                      <WarningFilled />
                    </Tooltip>
                  ) : (
                    <span />
                  )
                }
                placeholder={"Type in the email"}
              />
            </div>
            <div className={"SphereModal_PersonalShare_AccessSelect"}>
              {" "}
              <Select
                defaultActiveFirstOption={true}
                className={"SphereModal_Select"}
                key="shareOptions"
                name="shareOptions"
                value={userAccessLevel}
                onChange={(val) => {
                  setUserAccessLevel(val)

                  if (selectedEmail) personalShare(selectedEmail, val)
                }}
              >
                {userAccessLevels.map((level) => {
                  return (
                    <Select.Option value={level.value}>{level.title}</Select.Option>
                  )
                })}
              </Select>
            </div>
            {collectionSharings
              .filter((cs) => cs.email !== null)
              .map((cs) => {
                return (
                  <div
                    onClick={() => {
                      setNewEmail("")
                      setUserAccessLevel(cs.access_level)
                      setSelectedEmail(cs.email)
                    }}
                    className={`SphereModal_PersonalShare_EmailContainer ${
                      selectedEmail === cs.email ? "selectedEmail" : ""
                    }`}
                  >
                    <span
                      className={"SphereModal_PersonalShare_EmailContainer_Text"}
                    >
                      {cs.email}
                    </span>
                    <span
                      className={"SphereModal_PersonalShare_EmailContainer_Text"}
                    >
                      {[...Array(cs.access_level + 1)].map((a) => {
                        return <Badge status={"success"} dot />
                      })}
                    </span>
                    <span
                      className={"SphereModal_PersonalShare_EmailContainer_Icon"}
                    >
                      <DeleteOutlined
                        onClick={() => {
                          dispatch(deleteCollectionSharing(cs.id))
                        }}
                      />
                    </span>
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      <div className={"SphereModal_ShareWrapper"}>
        <div className={"SphereModal_ShareHeader"}>
          <GlobalOutlined />
          <Title level={5}>Global sharing</Title>
          <Switch
            checked={!!globalSharing}
            onChange={(val) => {
              if (val) {
                globalShare(val, 0)
              } else {
                dispatch(deleteCollectionSharing(globalSharing.id))
              }
            }}
          />
        </div>
        <div className={`SphereModal_Column ${!globalSharing && "blurred"}`}>
          <Text strong={true}>Anyone with the link can...</Text>
          <div className={"SphereModal_GlobalShare"}>
            <div className={"SphereModal_GlobalShare_LinkInput"}>
              <Tooltip title={"Click to copy!"}>
                <Input
                  value={globalSharing?.global_sharing_url}
                  disabled={!globalSharing}
                  readOnly={true}
                  onClick={(e) =>
                    navigator.clipboard.writeText(globalSharing.global_sharing_url)
                  }
                />
              </Tooltip>{" "}
            </div>
            <div className={"SphereModal_GlobalShare_AccessSelect"}>
              {" "}
              <Select
                defaultActiveFirstOption={true}
                disabled={!globalSharing}
                className={"SphereModal_GlobalShare_Select"}
                key="shareOptions"
                name="shareOptions"
                value={globalSharing?.access_level}
                onChange={(val) => {
                  globalShare(true, val)
                }}
              >
                {globalAccessLevels.map((level) => {
                  return (
                    <Select.Option value={level.value}>{level.title}</Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SphereSharing
