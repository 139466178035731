import React from "react"
import "./ContactCard.styles.scss"
import Avatar from "../../../../UI/Avatar/Avatar.component"
import PropTypes from "prop-types"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../../redux/App/App.actions"

const ContactCard = ({ contact, type }) => {
  const user = useSelector((state) => state.UserState)
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )

  const dispatch = useDispatch()

  const handleShowPerson = (id, slug) => {
    if (type === "person") {
      if (contactDrawerVisible && id === visibleContactID) {
        dispatch(setVisibleContactID(null))
        dispatch(setContactDrawerVisible(false))
      } else {
        dispatch(setVisibleContactID(id, slug))
        dispatch(setContactDrawerVisible(true))
        dispatch(setContactDrawerMode("view"))
      }
    }
  }

  const renderLastContacted = () => {
    if (type === "person") {
      return `Last contacted: ${
        contact.last_sent
          ? moment(contact.last_sent).tz(user.time_zone).format("MMMM Do, YYYY")
          : "Never"
      }`
    } else if (type === "participant") {
      return contact.last_sent
        ? moment(contact.last_sent).tz(user.time_zone).format("MMMM Do, YYYY")
        : "Never"
    }
  }

  return (
    <div
      className={`ContactCard ContactCard--${type}`}
      onClick={() => handleShowPerson(contact.id, contact.slug)}
    >
      <Avatar
        avatar={contact.profile_image_url !== "" ? contact.profile_image_url : null}
        firstName={contact.first_name}
        lastName={contact.last_name}
        colorNumber={contact.id}
        size={74}
        shape="square"
      />
      <div>
        <h4>{contact.full_name || contact.email}</h4>
        {type === "person" && (
          <div className="ContactCard_Spheres">
            {contact.spheres?.slice(0, 2)?.map((sphere) => (
              <span key={sphere.id}>{sphere.title}</span>
            ))}
            {contact.spheres?.length > 2 && (
              <span>+{contact.spheres.length - 2}</span>
            )}
          </div>
        )}
        <p
          className={`ContactCard_LastContacted ${
            type === "participant" ? "ContactCard_LastContacted--participant" : ""
          }`}
        >
          {renderLastContacted()}
        </p>
        {type === "participant" && (
          <p className="ContactCard_Body">{contact.body}</p>
        )}
      </div>
    </div>
  )
}

ContactCard.propTypes = {
  contact: PropTypes.object,
  type: PropTypes.oneOf(["person", "participant"]),
}

ContactCard.defaultProps = {
  contact: {},
  type: "person",
}

export default ContactCard
