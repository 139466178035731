import React from "react"
import { Tooltip } from "antd"
import "./ContactProfileDrawer.styles.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  setSelectedContactId,
  setSplitMessageSenderVisible,
  showNoAccountNotification,
} from "../../../redux/App/App.actions"
import Icon from "@ant-design/icons"
import PhoneCallIcon from "../../Icons/PhoneCall.icons"
import EnvelopeIcon from "../../Icons/Envelope.icons"
import TextIcon from "../../Icons/Text.icons"

const QuickContactButtons = ({ contact }) => {
  const userAccounts = useSelector((state) => state.AccountsState.accounts)

  const dispatch = useDispatch()

  return (
    <div>
      <div className="ContactProfileDrawerWrapper_ContactButtons">
        {contact?.email_addresses?.length > 0 ? (
          <button
            className="ContactProfileDrawerWrapper_ContactButtons_Button"
            onClick={() => {
              const runningAccounts = userAccounts.filter(
                (a) => a.state === "running"
              )
              if (runningAccounts.length > 0) {
                dispatch(setSplitMessageSenderVisible(true))
                dispatch(setSelectedContactId(contact.id))
              } else {
                dispatch(showNoAccountNotification())
              }
            }}
          >
            <Icon
              className="ContactProfileDrawerWrapper_ContactButtons_Icon"
              component={EnvelopeIcon}
            />
            <span className="ContactProfileDrawerWrapper_ContactButtons_Title">
              Email
            </span>
          </button>
        ) : (
          <Tooltip title="Add a email address to quickly reach out">
            <button
              disabled={true}
              className="ContactProfileDrawerWrapper_ContactButtons_Button--disabled"
            >
              <Icon
                className="ContactProfileDrawerWrapper_ContactButtons_Icon"
                component={EnvelopeIcon}
              />
              <span className="ContactProfileDrawerWrapper_ContactButtons_Title">
                Email
              </span>
            </button>
          </Tooltip>
        )}

        {contact?.phone_numbers?.length > 0 ? (
          <>
            <a
              className="ContactProfileDrawerWrapper_ContactButtons_Button"
              href={`sms:${contact.phone_numbers[0].value?.replaceAll(" ", "")}`}
            >
              <Icon
                className="ContactProfileDrawerWrapper_ContactButtons_Icon"
                component={TextIcon}
              />
              <span className="ContactProfileDrawerWrapper_ContactButtons_Title">
                Text
              </span>
            </a>
            <a
              className="ContactProfileDrawerWrapper_ContactButtons_Button"
              href={`tel:${contact.phone_numbers[0].value?.replaceAll(" ", "")}`}
            >
              <Icon
                className="ContactProfileDrawerWrapper_ContactButtons_Icon"
                component={PhoneCallIcon}
              />
              <span className="ContactProfileDrawerWrapper_ContactButtons_Title">
                Call
              </span>
            </a>
          </>
        ) : (
          <>
            <Tooltip title="Add a phone number to quickly reach out">
              <button
                disabled={true}
                className="ContactProfileDrawerWrapper_ContactButtons_Button--disabled"
              >
                <Icon
                  className="ContactProfileDrawerWrapper_ContactButtons_Icon"
                  component={TextIcon}
                />
                <span className="ContactProfileDrawerWrapper_ContactButtons_Title">
                  Text
                </span>
              </button>
            </Tooltip>
            <Tooltip title="Add a phone number to quickly reach out">
              <button
                disabled={true}
                className="ContactProfileDrawerWrapper_ContactButtons_Button--disabled"
              >
                <Icon
                  className="ContactProfileDrawerWrapper_ContactButtons_Icon"
                  component={PhoneCallIcon}
                />
                <span className="ContactProfileDrawerWrapper_ContactButtons_Title">
                  Call
                </span>
              </button>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  )
}

export default QuickContactButtons
