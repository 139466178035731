import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { sendFocusModeEvent } from "../../../../redux/User/User.actions"

const StartScene = ({ goNext, onExit }) => {
  const user = useSelector((state) => state.UserState)

  const dispatch = useDispatch()

  const handleGoNext = () => {
    dispatch(sendFocusModeEvent("started"))
    goNext()
  }

  return (
    <div className="Scene StartScene">
      <h1>Welcome back {user?.first_name ? user.first_name : ""}.</h1>
      <h3>We have few Relatable Recommendations for you today.</h3>
      <h3 style={{ marginBottom: "80px" }}>
        Are you ready to re-engage your sphere?
      </h3>
      <button onClick={handleGoNext} className="primary">
        🤘🏼Let’s do it! I’m ready to go!
      </button>
      <button onClick={onExit}>😕 No thanks, take me to my dashboard</button>
    </div>
  )
}

export default StartScene
