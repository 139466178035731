import React, { useState } from "react"
import PropTypes from "prop-types"
import "./SectionActivity.styles.scss"
import Icon, { LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import * as _ from "lodash"
import InteractionItem from "./InteractionItem/InteractionItem.component"
import moment from "moment/moment"
import PlusInSquareIcons from "../../../../Icons/PlusInSquare.icons"
import { Pagination, Tooltip } from "antd"
import { openDrawerTab } from "../../../../../redux/App/App.actions"
import { ReactComponent as UserIcon } from "../../../../Icons/UserIcon.svg"

const SectionActivity = ({
  contact,
  loadMoreInteractions,
  loadMoreEvents,
  deletable = true,
  hideAddButton = false,
}) => {
  const [interactionsPage, setInteractionsPage] = useState(1)
  const [eventsPage, setEventsPage] = useState(1)

  const user = useSelector((state) => state.UserState)
  const loadingInteractions = useSelector(
    (state) => state.InteractionsState.fetching_interactions
  )
  const loadingEvents = useSelector(
    (state) => state.InteractionsState.fetching_events
  )

  const dispatch = useDispatch()

  const formatDate = (interaction) => {
    if (interaction.last_message_timestamp) {
      return moment
        .unix(interaction.last_message_timestamp)
        .tz(user.time_zone)
        .format("YYYY-MM-DD-HH")
    } else if (interaction.last_message_received) {
      return moment(interaction.last_message_received)
        .tz(user.time_zone)
        .format("YYYY-MM-DD-HH")
    } else if (interaction.last_message_sent) {
      return moment(interaction.last_message_sent)
        .tz(user.time_zone)
        .format("YYYY-MM-DD-HH")
    }
  }

  const lastCanonicalInteraction = () => {
    let lastInteractions = []
    Object.keys(contact.canonical_participants).length > 0 &&
      Object.keys(contact.canonical_participants).forEach((key) => {
        lastInteractions = [
          ...lastInteractions,
          ...contact.canonical_participants[key],
        ]
      })

    return lastInteractions[0].last_sent
  }

  return (
    <section id="sectionActivity">
      <h3 className="SectionTitle">
        Activity
        {!hideAddButton && (
          <Tooltip title="Add new meeting" placement="topRight">
            <Icon
              onClick={() => dispatch(openDrawerTab("interaction"))}
              component={PlusInSquareIcons}
            />
          </Tooltip>
        )}
      </h3>
      <div className="SectionActivity">
        <p>Interactions</p>
        {contact.canonical_participants &&
          Object.keys(contact.canonical_participants).length > 0 &&
          lastCanonicalInteraction() &&
          Object.keys(contact.canonical_participants).map((key) => (
            <div className="SectionActivity_TeamInteractions">
              <div className="SectionActivity_TeamInteractions_Icon">
                <Icon component={UserIcon} />
              </div>
              <div>
                <p>
                  <b>{key}</b> communications:
                </p>
                {contact.canonical_participants[key].map((p) => (
                  <p>
                    {p.user_name +
                      ": " +
                      (p.last_sent
                        ? moment(p.last_sent)
                            .tz(user.time_zone)
                            .format("MMM Do, YYYY")
                        : "N/A")}
                  </p>
                ))}
              </div>
            </div>
          ))}
        {loadingInteractions ? (
          <div className="SectionActivity_Loading">
            <LoadingOutlined />
            <span>Loading...</span>
          </div>
        ) : contact.interactions_total_entries > 0 ? (
          <div className="SectionActivity_Interactions">
            {_.sortBy(contact.interactions, (i) => {
              return formatDate(i)
            })
              .reverse()
              .map((interaction) => {
                return (
                  <InteractionItem
                    contactId={contact.id}
                    interaction={interaction}
                    deletable={deletable}
                    key={interaction.id}
                    resetPage={() => setInteractionsPage(1)}
                  />
                )
              })}
            {contact.interactions_total_entries > 3 && (
              <div className="Section_Pagination">
                <span>More Interactions: </span>
                <Pagination
                  showSizeChanger={false}
                  current={interactionsPage}
                  onChange={(newPage) => {
                    loadMoreInteractions(contact.id, newPage)
                    setInteractionsPage(newPage)
                  }}
                  hideOnSinglePage={true}
                  total={contact.interactions_total_entries}
                  defaultPageSize={3}
                />
              </div>
            )}
          </div>
        ) : (
          <span className="inactive">Interactions have not been added</span>
        )}
      </div>
      <div style={{ margin: "8px 0px 32px" }} className="SectionActivity">
        <p>Events</p>
        {loadingEvents ? (
          <div className="SectionActivity_Loading">
            <LoadingOutlined />
            <span>Loading...</span>
          </div>
        ) : contact.events_total_entries > 0 ? (
          <div className="SectionActivity_Interactions">
            {_.sortBy(contact.events, (i) => {
              return formatDate(i)
            })
              .reverse()
              .map((event) => {
                return (
                  <InteractionItem
                    contactId={contact.id}
                    interaction={{ ...event, type: "Event" }}
                    deletable={deletable}
                    key={event.id}
                    resetPage={() => setEventsPage(1)}
                  />
                )
              })}
            {contact.events_total_entries > 3 && (
              <div className="Section_Pagination">
                <span>More Events: </span>
                <Pagination
                  showSizeChanger={false}
                  current={eventsPage}
                  onChange={(newPage) => {
                    loadMoreEvents(contact.id, newPage)
                    setEventsPage(newPage)
                  }}
                  hideOnSinglePage={true}
                  total={contact.events_total_entries}
                  defaultPageSize={3}
                />
              </div>
            )}
          </div>
        ) : (
          <span className="inactive">Events have not been added</span>
        )}
      </div>
    </section>
  )
}

SectionActivity.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    interactions: PropTypes.array,
    interactions_total_entries: PropTypes.number,
    events: PropTypes.array,
    events_total_entries: PropTypes.number,
  }),
  loadMoreInteractions: PropTypes.func,
  loadMoreEvents: PropTypes.func,
  deletable: PropTypes.bool,
  hideAddButton: PropTypes.bool,
}
export default SectionActivity
