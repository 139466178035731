import axios from "axios"

export const fetchCountedEngagementStatuses = async () => {
  const { data } = await axios.request({
    url: "/v1/people/count_engagement_statuses",
    method: "GET",
  })

  const allEngagementStatuses = data.green_count + data.yellow_count

  if (allEngagementStatuses > 0) {
    return {
      green_count: data.green_count > 999 ? "999+" : data.green_count,
      yellow_count: data.yellow_count > 999 ? "999+" : data.yellow_count,
      green_count_percentage: (data.green_count / allEngagementStatuses) * 100,
      yellow_count_percentage: (data.yellow_count / allEngagementStatuses) * 100,
      total_count: allEngagementStatuses,
    }
  } else {
    return {
      green_count: 0,
      yellow_count: 0,
      green_count_percentage: 0,
      yellow_count_percentage: 0,
      total_count: 0,
    }
  }
}

export const fetchMeetings = async () => {
  const { data } = await axios.request({
    url: "/v1/get_meetings",
    method: "GET",
  })

  return data.meetings
}
