import React from "react"
import PropTypes from "prop-types"
import "./UpdateCollection.styles.scss"
import CollectionSelector from "../../../CollectionSelector/CollectionSelector.component"
import SectionWithFill from "../../../../UI/SectionWithFill/SectionWithFill.component"

const UpdateCollection = ({
  title,
  fillColor,
  collectionType,
  onSubmit,
  recordName,
  numberOfRecords,
  showAdd,
  showRemove,
}) => {
  const handleSubmitAdd = (existingCollections, newCollections) => {
    onSubmit([
      ...existingCollections,
      ...newCollections.map((title) => ({ title: title })),
    ])
  }

  const handleSubmitRemove = (existingCollections, newCollections) => {
    onSubmit(
      [...existingCollections, ...newCollections.map((title) => ({ title: title }))],
      true
    )
  }

  return (
    <SectionWithFill title={title} fillColor={fillColor}>
      {showAdd && (
        <div className="UpdateCollection_Add">
          <p>{`Add ${numberOfRecords} ${recordName} to:`}</p>
          <CollectionSelector
            disabled={numberOfRecords <= 0}
            submitMode="onClick"
            collectionType={collectionType}
            handleSubmit={handleSubmitAdd}
          />
        </div>
      )}
      {showRemove && (
        <div className="UpdateCollection_Remove">
          <p>{`Remove ${numberOfRecords} ${recordName} from:`}</p>
          <CollectionSelector
            disabled={numberOfRecords <= 0}
            submitMode={"onClick"}
            collectionType={collectionType}
            handleSubmit={handleSubmitRemove}
          />
        </div>
      )}
    </SectionWithFill>
  )
}

UpdateCollection.propTypes = {
  title: PropTypes.string.isRequired,
  fillColor: PropTypes.oneOf([
    "lightGrey",
    "lightYellow",
    "lightPink",
    "lightGreen",
    "white",
  ]),
  collectionType: PropTypes.oneOf(["spheres", "topics"]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  recordName: PropTypes.oneOf(["contacts", "people"]).isRequired,
  numberOfRecords: PropTypes.number.isRequired,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
}

UpdateCollection.defaultProps = {
  fillColor: "white",
  recordName: "contacts",
  numberOfRecords: 0,
  showAdd: true,
  showRemove: true,
}

export default UpdateCollection
