export const generateGoalName = (type, number_of_todo) => {
  switch (type) {
    case "csv.completed":
      return number_of_todo > 1
        ? `Upload ${number_of_todo} contacts`
        : `Upload ${number_of_todo} contact`
    case "interaction.created":
      return number_of_todo > 1
        ? `Log ${number_of_todo} interactions`
        : `Log ${number_of_todo} interaction`
    case "introduction.completed":
      return number_of_todo > 1
        ? `Complete ${number_of_todo} introductions`
        : `Complete ${number_of_todo} introduction`
    case "introduction.request_sent":
      return number_of_todo > 1
        ? `Send ${number_of_todo} introduction requests`
        : `Send ${number_of_todo} introduction request`
    case "message.created":
      return number_of_todo > 1
        ? `Send ${number_of_todo} messages`
        : `Send ${number_of_todo} message`
    case "person.created":
      return number_of_todo > 1
        ? `Add ${number_of_todo} contacts to spheres`
        : `Add ${number_of_todo} contact to sphere`
    case "reminder.completed":
      return number_of_todo > 1
        ? `Complete ${number_of_todo} reminders`
        : `Complete ${number_of_todo} reminder`
    case "reminder.created":
      return number_of_todo > 1
        ? `Create ${number_of_todo} reminders`
        : `Create ${number_of_todo} reminder`
    case "split_message.created":
      return number_of_todo > 1
        ? `Send ${number_of_todo} split messages`
        : `Send ${number_of_todo} split message`
    default:
      return "Unknown goal"
  }
}
