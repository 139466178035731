import React, { useEffect, useRef, useState } from "react"
import * as _ from "lodash"
import { Select, Spin } from "antd"
import getSearchedSources from "./getSearchedSources"
import RelatableLoader from "../../../../../UI/RelatableLoader/RelatableLoader.component"

const SourceOfRelationshipSelector = ({ currentElements = [], handleSubmit }) => {
  const select = useRef(null)

  const [collection, setCollection] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)

  const [debouncedSearch] = useState(() => _.debounce(getSearchedSources, 500))

  useEffect(() => {
    if (currentElements) {
      const s = currentElements.map((element) => ({
        value: element.id ? "sourceId_" + element.id : null,
        source_id: element.id,
        label: element.title,
        source_person_id: element.source_person_id,
      }))
      setSelected(s)
    }
    // eslint-disable-next-line
  }, [])

  const searchSourcesAndPeople = (value) => {
    setLoading(true)
    debouncedSearch(value, (data) => {
      setCollection(data)
      setLoading(false)
    })
  }

  return !collection ? (
    <RelatableLoader />
  ) : (
    <div>
      <Select
        onSearch={(value) => searchSourcesAndPeople(value)}
        notFoundContent={loading ? <RelatableLoader size="small" /> : null}
        bordered={false}
        mode="tags"
        ref={select}
        allowClear={true}
        labelInValue={true}
        value={selected}
        className="CollectionSelector"
        listHeight={200}
        placeholder="Source of Relationship"
        onChange={(labeled) => {
          setSelected(labeled)
          handleSubmit(labeled)
        }}
        dropdownClassName="CollectionSelector_Dropdown"
        getPopupContainer={(trigger) => trigger.parentNode}
        filterOption={false}
        options={collection}
      />
    </div>
  )
}

export default SourceOfRelationshipSelector
