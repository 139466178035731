import React from "react"
import PropTypes from "prop-types"
import "./SectionWithFill.styles.scss"

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const SectionWithFill = ({ children, title, fillColor }) => {
  return (
    <div className={`SectionWithFill SectionWithFill--${fillColor}`}>
      <h3>{capitalizeFirstLetter(title)}</h3>
      {children}
    </div>
  )
}

SectionWithFill.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  fillColor: PropTypes.oneOf([
    "lightGrey",
    "lightYellow",
    "lightPink",
    "lightGreen",
    "white",
  ]),
}

SectionWithFill.defaultProps = {
  fillColor: "white",
}

export default SectionWithFill
