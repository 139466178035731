import React, { useEffect, useRef, useState } from "react"
import "./NewNoteTab.styles.scss"
import { Mentions, Popover, Spin } from "antd"
import { fetchForMentions } from "./NewNoteTab.actions"
import { useDispatch, useSelector } from "react-redux"
import NoteIcon from "../../../../Icons/Note.icons"
import {
  addNote,
  updateNote,
} from "../../../../../redux/Interactions/Interactions.actions"
import * as _ from "lodash"
import PropTypes from "prop-types"
import Icon from "@ant-design/icons"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const { Option } = Mentions

const NewNoteTab = ({
  minified,
  initialContent,
  initialParticipants,
  afterSubmit,
}) => {
  const dispatch = useDispatch()
  const adding = useSelector((state) => state.InteractionsState.adding_notes)
  const editedNote = useSelector((state) => state.InteractionsState.edited_note)
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)

  const textarea = useRef(null)

  const [loading, setLoading] = useState(false)
  const [subject, setSubject] = useState("")
  const [noteContent, setNoteContent] = useState(initialContent)
  const [searchQuery, setSearchQuery] = useState("")
  const [prefix, setPrefix] = useState("@")
  const [participants, setParticipants] = useState([])
  const [topics, setTopics] = useState([])
  const [expertises, setExpertises] = useState([])
  const [selectedTopics, setSelectedTopics] = useState([])
  const [selectedExpertises, setSelectedExpertises] = useState([])

  const [selectedParticipants, setSelectedParticipants] =
    useState(initialParticipants)

  useEffect(() => {
    setNoteContent(initialContent)
    setSelectedParticipants(initialParticipants)
  }, [initialContent, initialParticipants])

  useEffect(() => {
    if (editedNote) {
      setNoteContent(editedNote.body)
      setSelectedParticipants(
        editedNote.participants.map((p) => ({
          id: p.person_id,
          value: p.person_full_name?.replace(/ /g, "_"),
        }))
      )

      setTimeout(() => {
        if (textarea && textarea.current && textarea.current.rcMentions) {
          textarea.current.focus()
          textarea.current.rcMentions.textarea.selectionStart =
            editedNote.body.length
          textarea.current.rcMentions.textarea.selectionEnd = editedNote.body.length
        }
      }, 1000)
    }
  }, [editedNote])

  const handleSearch = (searchQuery, _prefix) => {
    setSearchQuery(searchQuery)
    setPrefix(_prefix)
    setParticipants([])
    setTopics([])
    setExpertises([])
    setLoading(true)

    if (_prefix === "@") {
      loadParticipants(searchQuery)
    } else if (_prefix === "#") {
      loadTopics(searchQuery)
    } else if (_prefix === "~") {
      loadExpertises(searchQuery)
    }
  }

  const [debouncedSearch] = useState(() => _.debounce(handleSearch, 500))

  const handleChange = (note) => {
    setNoteContent(note)
  }

  const getMentions = (prefix) => {
    switch (prefix) {
      case "@":
        return participants || []
      case "#":
        return [...topics, { id: null, name: searchQuery }] || []
      case "~":
        return [...expertises, { id: null, name: searchQuery }] || []
      default:
        return []
    }
  }

  const loadExpertises = (query) => {
    fetchForMentions(query, null, "expertises").then((res) => {
      setExpertises(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const loadParticipants = (query) => {
    fetchForMentions(query, "SORT").then((res) => {
      setParticipants(res)
      setLoading(false)
    })
  }

  const loadTopics = (query) => {
    fetchForMentions(query, null, "topics").then((res) => {
      setTopics(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const handleSelect = (id, value, _prefix) => {
    if (_prefix === "@") {
      setSelectedParticipants([...selectedParticipants, { id: id, value: value }])
    } else if (_prefix === "#") {
      setSelectedTopics([...selectedTopics, { id: id, value: value }])
    } else if (_prefix === "~") {
      setSelectedExpertises([...selectedExpertises, { id: id, value: value }])
    }
  }

  const handleSubmit = () => {
    const people = _.uniqBy(selectedParticipants, "id").filter((p) =>
      noteContent.includes(`@${p.value}`)
    )

    const topics = _.uniqBy(selectedTopics, "id").filter((t) =>
      noteContent.includes(`#${t?.value}`)
    )

    const expertises = _.uniqBy(selectedExpertises, "id").filter((t) =>
      noteContent.includes(`~${t?.value}`)
    )

    if (people.length > 0) {
      if (editedNote) {
        dispatch(
          updateNote(
            editedNote.id,
            {
              subject: subject,
              body: noteContent,
              people: people,
              topics: topics,
              expertises: expertises,
              attachments: [],
              attachments_type: null,
            },
            visibleContactID
          )
        )
      } else {
        dispatch(
          addNote(
            {
              subject: subject,
              body: noteContent,
              people: people,
              topics: topics,
              expertises: expertises,
              attachments: [],
              attachments_type: null,
            },
            visibleContactID
          )
        )
      }
      setSubject("")
      setNoteContent("")
      setSelectedParticipants([])
      setSelectedExpertises([])
      setSelectedTopics([])

      if (afterSubmit) afterSubmit()
    } else {
      setSelectedParticipants([])
    }
  }

  return (
    <div className="CreateNote">
      {adding ? (
        <RelatableLoader />
      ) : (
        <div
          className={`CreateNote_Container ${
            minified ? "CreateNote_Container--minified" : ""
          }`}
        >
          <Mentions
            ref={textarea}
            className="CreateNote_Textarea"
            placeholder="Type note..."
            rows={6}
            loading={loading}
            onSearch={debouncedSearch}
            onChange={handleChange}
            prefix={["@", "#", "~"]}
            onSelect={(selected, _prefix) =>
              handleSelect(selected.id, selected.value, _prefix)
            }
            value={noteContent}
            autoFocus
          >
            {(getMentions(prefix) || []).map((mention) => {
              return (
                <Option
                  id={mention.id}
                  name={mention.name}
                  key={mention.id}
                  value={mention.name ? mention.name.replace(/ /g, "_") : ""}
                  className="antd-demo-dynamic-option"
                >
                  <span>{mention.name}</span>
                </Option>
              )
            })}
          </Mentions>
          <div
            className={`CreateNote_Footer ${
              minified ? "CreateNote_Footer--minified" : ""
            }`}
          >
            <span className="CreateNote_Footer_Helper">
              @ to attach this note to a person, # to add topics, ~ to add expertise
            </span>
            <Popover
              placement="topRight"
              trigger={!noteContent || !selectedParticipants.length ? ["hover"] : []}
              overlayClassName={"AddNotePopover"}
              title="Why can’t I save this note?"
              content={
                <p className="AddNotePopover_Content">
                  The note is there to help you remember the most important
                  information about the person important to you, hence you should
                  assign it to someone. Use <strong>@</strong> to do this.
                </p>
              }
            >
              <button
                className={`${
                  !noteContent || !selectedParticipants.length
                    ? "CreateNote_Footer_Button--disabled"
                    : ""
                } ${minified ? "Footer_Button--minified" : ""}`}
                disabled={!noteContent || !selectedParticipants.length}
                onClick={handleSubmit}
              >
                {editedNote ? "Update" : "Add"}
                <Icon
                  className="CreateNote_Footer_Button_Icon"
                  component={NoteIcon}
                />
              </button>
            </Popover>
          </div>
        </div>
      )}
    </div>
  )
}

NewNoteTab.propTypes = {
  initialContent: PropTypes.string.isRequired,
  initialParticipants: PropTypes.array.isRequired,
}

NewNoteTab.defaultProps = {
  initialContent: "",
  initialParticipants: [],
}

export default NewNoteTab
