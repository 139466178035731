import React from "react"
import "./SphereCreatorModal.styles.scss"
import PropTypes from "prop-types"
import { Modal } from "antd"
import {
  setShowStartBuildingCard,
  setSphereModalVisible,
} from "../../../../redux/App/App.actions"
import { useDispatch } from "react-redux"
import { createSphere } from "../../../../redux/Collections/Collections.actions"
import { useHistory } from "react-router-dom"

const SphereCreatorModal = ({ visible, handleClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Modal
      closable={false}
      closeIcon={null}
      footer={false}
      width={400}
      visible={visible}
      onCancel={handleClose}
      className={"SphereCreatorModal"}
    >
      <div className={"SphereCreatorModal_Header"}>
        <span className={"SphereCreatorModal_Title"}>
          What kind of Sphere do you want?
        </span>
        <div className={"SphereCreatorModal_Description"}>
          Choose a Sphere type to get started.
        </div>
        <div className={"SphereCreatorModal_Recommendations"}>
          Not sure?{" "}
          <button
            onClick={() => {
              dispatch(setShowStartBuildingCard(true))
              handleClose()
              if (history.location.pathname !== "/database") {
                history.push("/database")
              }
            }}
          >
            See our recommendations
          </button>
        </div>
      </div>
      <div className={"SphereCreatorModal_Content"}>
        <div
          className={"SphereCreatorModal_Link"}
          onClick={() => {
            handleClose()
            dispatch(setSphereModalVisible(true, null))
          }}
        >
          <span className={"SphereCreatorModal_Title"}>Simple Sphere</span>
          <div className={"SphereCreatorModal_Description"}>
            Prioritize similar types of people together and optionally set a
            stay-in-touch window
          </div>
        </div>
        <div
          onClick={() => {
            dispatch(createSphere("flow"))

            handleClose()
          }}
          className={"SphereCreatorModal_Link"}
        >
          <span className={"SphereCreatorModal_Title"}>Flow Sphere</span>
          <div className={"SphereCreatorModal_Description"}>
            Set up a sequence of actions to happen automatically (including emails
            and reminders)
          </div>
        </div>
        <div
          onClick={() => {
            dispatch(createSphere("stage"))

            handleClose()
          }}
          className={"SphereCreatorModal_Link"}
        >
          <span className={"SphereCreatorModal_Title"}>Stage Sphere</span>
          <div className={"SphereCreatorModal_Description"}>
            Track where your relationships are in a process
          </div>
        </div>
      </div>
      <div onClick={handleClose} className={"SphereCreatorModal_Footer"}>
        <span>Nevermind</span>
      </div>
    </Modal>
  )
}

SphereCreatorModal.propTypes = {
  visible: PropTypes.bool,
}

export default SphereCreatorModal
