import React from "react"
import { Modal } from "antd"
import "./ConfirmModal.styles.scss"

const ConfirmModal = ({ visible, onCancel, onConfirm, title, description }) => {
  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onCancel}
      centered={true}
      width={400}
      className={"ConfirmModal__modal"}
    >
      <div className="ConfirmModal">
        <div className={"ConfirmModal_Text"}>
          <span className={"ConfirmModal_Text_Title"}>{title}</span>
          <span className={"ConfirmModal_Text_Description"}>{description}</span>
        </div>

        <div className={"ConfirmModal_Buttons"}>
          <div
            className="ConfirmModal_YesButton"
            onClick={() => {
              onConfirm()
              onCancel()
            }}
          >
            Yes
          </div>
          <div className="ConfirmModal_NoButton" onClick={onCancel}>
            Never mind
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
