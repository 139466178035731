import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../Partner.styles.scss"
import RelatableLoader from "../../../components/UI/RelatableLoader/RelatableLoader.component"
import { Button, Table, Typography } from "antd"
import moment from "moment/moment"
import TakingSessionModal from "../../../components/Admin/TakingSessionModal/TakingSessionModal.component"
import { fetchPartner } from "../../../redux/User/User.actions"

const { Text } = Typography

const PartnerUsersListSection = ({ partner }) => {
  const partner_users = useSelector((state) => state.UserState.partner_users)
  const user = useSelector((state) => state.UserState)
  const loading = useSelector((state) => state.UserState.loading_partner_users)
  const dispatch = useDispatch()
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [takingSessionModalVisible, setTakingSessionModalVisible] = useState(false)

  useEffect(() => {
    dispatch(fetchPartner(partner.id))
  }, [])

  const columns = [
    {
      title: "Name",
      className: "name hoverable",
      key: "last_name",
      ellipsis: true,
      render: (record) => (
        <>
          <span className={"PeopleTable_FullNameWrap"}>
            <Text className={"PeopleTable_FullName"}>
              {record.first_name + " " + record.last_name}
            </Text>
          </span>
          <div className={"PeopleTable_OneLinerWrap"}>
            <Text className={"PeopleTable_OneLiner"}>{record.email}</Text>
          </div>
        </>
      ),
    },
    {
      width: "20%",
      title: "Last seen",
      className: "hoverable",
      key: "last_seen_at",
      sorter: (a, b) => new Date(a.last_seen_at) - new Date(b.last_seen_at),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      render: (record) => (
        <>
          <div className={"PeopleTable_RecencyContainer"}>
            {record.last_seen_at && (
              <Text className={"PeopleTable_RecencyText"}>
                {moment(record.last_seen_at)
                  .tz(user.time_zone)
                  .format("MMM Do, YYYY")}
              </Text>
            )}
          </div>
        </>
      ),
    },
  ]

  if (partner?.allow_dashboard_access) {
    columns.push({
      width: "20%",
      title: "Impersonate",
      className: "name hoverable",
      key: "impersonate",
      render: (record) => (
        <>
          <Button
            onClick={() => {
              setTakingSessionModalVisible(true)
            }}
            disabled={!record.allow_access}
          >
            TAKE SESSION
          </Button>
        </>
      ),
    })
  }

  const handleTableChange = (pagination, filter, sorter) => {
    dispatch(fetchPartner(partner.id))
  }

  return (
    <>
      <TakingSessionModal
        email={selectedEmail}
        visible={takingSessionModalVisible}
        onCancel={() => setTakingSessionModalVisible(false)}
      />
      <div className="Section">
        <h2>List of users</h2>
        <Table
          rowKey="id"
          tableLayout="fixed"
          className="PeopleTable"
          style={{ height: "auto", maxHeight: "80%" }}
          loading={{
            spinning: loading,
            indicator: <RelatableLoader quote={true} loading={loading} />,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                setSelectedEmail(record.email)
              },
            }
          }}
          scroll={{ x: true }}
          columns={columns}
          dataSource={partner_users}
          onChange={handleTableChange}
        />
      </div>
    </>
  )
}

export default PartnerUsersListSection
