import React from "react"
import PropTypes from "prop-types"
import "./NumberOfSelectedRecords.styles.scss"

const NumberOfSelectedRecords = ({ recordName, numberOfRecords }) => {
  const counter =
    numberOfRecords < 10 ? "0" + numberOfRecords.toString() : numberOfRecords

  const counterFontSize = () => {
    if (numberOfRecords < 100) {
      return "big"
    } else if (numberOfRecords < 1000) {
      return "regular"
    } else {
      return "small"
    }
  }

  return (
    <div className={"NumberOfSelectedRecords"}>
      <span>{`Number of selected ${recordName}:`}</span>
      <span
        className={`NumberOfSelectedRecords_Counter NumberOfSelectedRecords_Counter--${counterFontSize()}`}
      >
        {counter}
      </span>
    </div>
  )
}

NumberOfSelectedRecords.propTypes = {
  recordName: PropTypes.oneOf(["contacts", "people"]).isRequired,
  numberOfRecords: PropTypes.number.isRequired,
}

NumberOfSelectedRecords.defaultProps = {
  recordName: "contacts",
  numberOfRecords: 0,
}

export default NumberOfSelectedRecords
