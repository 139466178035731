import React, { useEffect, useState } from "react"
import "./StripeSessionCreatingModal.styles.scss"
import { Modal, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const StripeSessionCreatingModal = () => {
  const session_creating = useSelector(
    (state) => state.AccountsState.session_creating
  )
  const stripe_session_url = useSelector(
    (state) => state.AccountsState.stripe_session_url
  )
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (session_creating) {
      setShowModal(true)
    } else if (!session_creating && showModal) {
      if (stripe_session_url) {
        window.location.replace(stripe_session_url)
      } else {
        setShowModal(false)
      }
    }
    // eslint-disable-next-line
  }, [session_creating])

  return (
    <Modal
      visible={showModal}
      title={null}
      footer={null}
      closable={false}
      centered={true}
      className="StripeSessionCreatingModal"
      wrapClassName="WrapStripeSessionCreatingModal"
    >
      <div className="StripeSessionCreatingModal_Body">
        <div className="StripeSessionCreatingModal_Body_Processing">
          <RelatableLoader />
          Processing...
        </div>
        <span>
          You will be redirected to the secure payment page. It might take a few
          seconds.
        </span>
        <br />
        <br />
        <br />
        <span>
          Please do not refresh the page or click the "Back" or "Close" button of
          your browser.
        </span>
      </div>
    </Modal>
  )
}

export default StripeSessionCreatingModal
