import React, { useState } from "react"
import "../TeamsSection.styles.scss"
import "antd/dist/antd.css"
import "antd-country-phone-input/dist/index.css"
import { useDispatch } from "react-redux"
import ConfirmModal from "../../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import {
  joinToTeam,
  removeTeamInvitation,
} from "../../../../redux/User/User.actions"

const TeamInvitations = ({ team_invitation }) => {
  const dispatch = useDispatch()
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const handleJoinToTeam = () => {
    dispatch(joinToTeam(team_invitation.team_id))
  }

  const handleRemoveInvitation = () => {
    dispatch(removeTeamInvitation(team_invitation.team_id))
    setShowConfirmModal(false)
  }

  return (
    <div className="Subsection TeamInvitation">
      <h3>
        You've been invited to join <b>{team_invitation.owner_name}</b> team{" "}
        <b>{team_invitation.team_name}</b>
      </h3>
      <div>
        <button className="Button" onClick={handleJoinToTeam}>
          Join
        </button>
        <button
          className="Button Button--red"
          onClick={() => setShowConfirmModal(true)}
        >
          No Thanks
        </button>
      </div>
      <ConfirmModal
        title="Team Invitation"
        description="Are you sure you want to decline the invitation?"
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleRemoveInvitation}
        visible={showConfirmModal}
      />
    </div>
  )
}

export default TeamInvitations
