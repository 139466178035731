import React, { useState } from "react"
import "./FocusMode.styles.scss"
import { Drawer } from "antd"
import StartScene from "./Scenes/StartScene.component"
import { ReactComponent as LogoInCircle } from "../../../images/LogoInCircle.svg"
import ReviewingTriggerScene from "./Scenes/ReviewingTriggerScene.component"
import AddToDatabaseScene from "./Scenes/AddToDatabaseScene.component"
import RemindersListScene from "./Scenes/RemindersListScene.component"
import CelebrationScene from "./Scenes/CelebrationScene.component"
import RecordingInteractionScene from "./Scenes/RecordingInteractionScene.component"
import { useDispatch, useSelector } from "react-redux"
import { ReactComponent as CloseCircleIcon } from "../../Icons/CloseCircleIcon.svg"
import FinalScene from "./Scenes/FinalScene.component"
import Confetti from "react-confetti"
import { sendFocusModeEvent } from "../../../redux/User/User.actions"

const FocusMode = ({ onExit }) => {
  const triggers = useSelector((state) => state.RemindersState.triggers)
  const participants = useSelector((state) => state.ParticipantsState.participants)
  const remindersFromTriggers = useSelector((state) =>
    state.RemindersState.reminders.filter(
      (reminder) => reminder.type === "from_trigger"
    )
  )
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )

  const [currentScene, setCurrentScene] = useState("start")
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [scenesCounter, setScenesCounter] = useState(0)
  const [triggersCounter, setTriggersCounter] = useState(0)
  const [organizedContactsCounter, setOrganizedContactsCounter] = useState(0)
  const [reachedOutPeopleCounter, setReachedOutPeopleCounter] = useState(0)

  const limitOfScenes = 10

  const dispatch = useDispatch()

  const handleOnExit = () => {
    dispatch(sendFocusModeEvent("finished"))
    onExit()
  }

  const showRandomScene = () => {
    if (scenesCounter >= limitOfScenes) {
      setCurrentScene("final_scene")
    } else {
      setScenesCounter(scenesCounter + 1)
      const scenes = []

      if (triggers?.length > 0) {
        scenes.push("reviewing_trigger")
      }
      if (participants?.length > 0) {
        scenes.push("add_to_database")
      }

      if (remindersFromTriggers?.length > 0) {
        scenes.push("reminders_list")
      }

      if (scenes.length > 0) {
        setCurrentScene(scenes[Math.floor(Math.random() * scenes.length)])
      } else {
        setCurrentScene("final_scene")
      }
    }
  }

  const renderContent = () => {
    switch (currentScene) {
      case "start":
        return <StartScene onExit={handleOnExit} goNext={showRandomScene} />
      case "reviewing_trigger":
        return (
          <ReviewingTriggerScene
            trigger={triggers[0]}
            goNext={() => {
              setTriggersCounter(triggersCounter + 1)
              dispatch(sendFocusModeEvent("task_completed", "trigger"))
              setCurrentScene("celebration")
            }}
            setSelectedPerson={setSelectedPerson}
            recordInteraction={() => {
              setTriggersCounter(triggersCounter + 1)
              dispatch(sendFocusModeEvent("task_completed", "trigger"))
              setCurrentScene("recording_interaction")
            }}
          />
        )
      case "add_to_database":
        return (
          <AddToDatabaseScene
            participant={participants[0]}
            goNext={() => {
              setOrganizedContactsCounter(organizedContactsCounter + 1)
              dispatch(sendFocusModeEvent("task_completed", "organize_contacts"))
              setCurrentScene("celebration")
            }}
          />
        )
      case "reminders_list":
        return (
          <RemindersListScene
            reminders={remindersFromTriggers}
            setSelectedPerson={setSelectedPerson}
            recordInteraction={() => {
              setReachedOutPeopleCounter(reachedOutPeopleCounter + 1)
              dispatch(sendFocusModeEvent("task_completed", "reminder"))
              setCurrentScene("recording_interaction")
            }}
          />
        )
      case "recording_interaction":
        return (
          <RecordingInteractionScene
            selectedPerson={selectedPerson}
            goNext={() => setCurrentScene("celebration")}
            goBack={showRandomScene}
          />
        )
      case "celebration":
        if (scenesCounter >= limitOfScenes) {
          setCurrentScene("final_scene")
          break
        } else {
          return (
            <CelebrationScene
              goNext={showRandomScene}
              scenesCounter={scenesCounter}
              limitOfScenes={limitOfScenes}
            />
          )
        }
      case "final_scene":
        return (
          <FinalScene
            triggersCounter={triggersCounter}
            organizedContactsCounter={organizedContactsCounter}
            reachedOutPeopleCounter={reachedOutPeopleCounter}
            showAgainButton={
              triggers?.length > 0 ||
              participants?.length > 0 ||
              remindersFromTriggers?.length > 0
            }
            onExit={handleOnExit}
            goNext={() => {
              setScenesCounter(0)
              showRandomScene()
            }}
          />
        )
      default:
        return <StartScene onExit={handleOnExit} goNext={showRandomScene} />
    }
  }

  return (
    <Drawer
      title={null}
      visible={true}
      closable={false}
      width="100vw"
      className={
        contactDrawerVisible
          ? "FocusMode FocusMode--contactDrawerVisible"
          : "FocusMode"
      }
      footer={null}
    >
      {currentScene === "celebration" && (
        <Confetti
          numberOfPieces={200}
          onConfettiComplete={showRandomScene}
          recycle={false}
          gravity={0.2}
          tweenDuration={2000}
        />
      )}
      {currentScene === "final_scene" && (
        <Confetti numberOfPieces={600} gravity={0.2} recycle={false} />
      )}
      <button className="FocusMode_CloseButton" onClick={handleOnExit}>
        <CloseCircleIcon />
      </button>
      <div className="FocusMode_LogoBox">
        <LogoInCircle />
      </div>
      <div className="FocusMode_Content">{renderContent()}</div>
    </Drawer>
  )
}

export default FocusMode
