import {
  CLEAR_REDUX_ACTIVITIES_STATE,
  FETCH_ACTIVE_CHALLENGES,
  FETCH_ACTIVE_CHALLENGES_FAIL,
  FETCH_ACTIVE_CHALLENGES_SUCCESS,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_FAIL,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_CHALLENGE,
  FETCH_CHALLENGE_FAIL,
  FETCH_CHALLENGE_SUCCESS,
  FETCH_POINTS,
  FETCH_POINTS_FAIL,
  FETCH_POINTS_SUCCESS,
  JOIN_TO_CHALLENGE,
  JOIN_TO_CHALLENGE_FAIL,
  JOIN_TO_CHALLENGE_SUCCESS,
  SET_INITIAL_REDUX_ACTIVITIES_STATE,
} from "./Activities.types"
import { LOGOUT_SUCCESS } from "../User/User.types"

const INITIAL_STATE = {
  activities: null,
  points: null,
  total_entries: 0,
  leaderboard: [],
  loading: false,
  loading_challenges: false,
  loading_challenge: false,
  challenges: [],
  challenge: null,
}

export const activitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ACTIVITIES:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.activities,
        total_entries: action.total_entries,
        loading: false,
      }
    case FETCH_ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_POINTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_POINTS_SUCCESS:
      return {
        ...state,
        points: action.points,
        leaderboard: action.leaderboard,
        loading: false,
      }
    case FETCH_POINTS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case FETCH_ACTIVE_CHALLENGES:
      return {
        ...state,
        loading_challenges: true,
      }
    case FETCH_ACTIVE_CHALLENGES_SUCCESS:
      return {
        ...state,
        challenges: action.challenges,
        loading_challenges: false,
      }
    case FETCH_ACTIVE_CHALLENGES_FAIL:
      return {
        ...state,
        loading_challenges: false,
      }
    case FETCH_CHALLENGE:
      return {
        ...state,
        loading_challenge: true,
      }
    case FETCH_CHALLENGE_SUCCESS:
      return {
        ...state,
        challenge: action.challenge,
        loading_challenge: false,
      }
    case FETCH_CHALLENGE_FAIL:
      return {
        ...state,
        challenge: null,
        loading_challenge: false,
      }
    case JOIN_TO_CHALLENGE:
      return {
        ...state,
        loading_challenge: true,
        loading_challenges: true,
      }
    case JOIN_TO_CHALLENGE_SUCCESS:
      return {
        ...state,
        loading_challenge: false,
        loading_challenges: false,
        challenge: action.challenge,
        challenges: state.challenges.map((ch) => {
          if (ch.id === action.challenge.id) {
            return {
              id: action.challenge.id,
              name: action.challenge.name,
              description: action.challenge.description,
              one_liner: action.challenge.one_liner,
              percentage_progress: action.challenge.my_progress,
            }
          }
          return ch
        }),
      }
    case JOIN_TO_CHALLENGE_FAIL:
      return {
        ...state,
        loading_challenge: false,
        loading_challenges: false,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_ACTIVITIES_STATE:
      return {}
    case SET_INITIAL_REDUX_ACTIVITIES_STATE:
      return { ...INITIAL_STATE }
    default:
      return {
        ...state,
      }
  }
}
