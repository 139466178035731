import React, { useEffect, useState } from "react"
import "./SignaturesEditor.styles.scss"
import { Button, Col, Row, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { updateSignature } from "../../../redux/Accounts/Accounts.actions"
import TinyMCUEditor from "../../UI/TinyMCUEditor/TinyMCUEditor"

const removeSignatureTag = (signature) => {
  if (
    signature.slice(0, 24) === '<section id="signature">' &&
    signature.slice(-10) === "</section>"
  ) {
    return signature.slice(24, -10)
  }
  return signature
}

const SignaturesEditor = () => {
  const dispatch = useDispatch()
  const [body, setBody] = useState(null)
  const accounts = useSelector((state) => state.AccountsState.accounts)
  const [accountId, setAccountId] = useState(null)

  useEffect(() => {
    if (accounts && accounts.length) {
      setAccountId(accounts[0]?.id)
      if (accounts[0]?.signature) {
        setBody(removeSignatureTag(accounts[0].signature))
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleChangeSelectedAccountId = (id) => {
    setAccountId(id)
    setBody(
      removeSignatureTag(accounts.find((account) => account.id === id).signature)
    )
  }

  const handleUpdateBody = (newBody) => {
    setBody(newBody)
  }

  const handleClickSaveSignature = () => {
    if (Number.isInteger(accountId) && body) {
      dispatch(updateSignature(accountId, body))
    }
  }

  return (
    <div className="SignaturesEditor">
      <Row className="SignaturesEditor_BodyEditor">
        <Col span={8}>
          <Select
            onChange={(v) => handleChangeSelectedAccountId(v)}
            value={accountId}
          >
            {accounts &&
              accounts
                .filter((a) => a?.nylas_id !== null)
                .map((account) => (
                  <Select.Option key={account.id} value={account.id}>
                    {account.email}
                  </Select.Option>
                ))}
          </Select>
        </Col>
        <Col span={24}>
          <TinyMCUEditor
            mode="text"
            accountId={accountId}
            updateBody={(html) => handleUpdateBody(html)}
            body={body}
            toolbarId="signatureEditor"
            hidePersonalization={true}
          />
        </Col>
      </Row>
      <Row>
        <Button
          className="SignaturesEditor_SubmitButton"
          color={"#1C9AFE"}
          type={"primary"}
          onClick={handleClickSaveSignature}
        >
          Save
        </Button>
      </Row>
    </div>
  )
}

SignaturesEditor.propTypes = {}

export default SignaturesEditor
