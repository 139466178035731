import React from "react"
import { useReward } from "react-rewards"

const SubmitSphereButton = ({ recordId = null, children, className, onClick }) => {
  const { reward, isAnimating } = useReward(
    "rewardId_SubmitSphereButton",
    "confetti",
    {
      position: "absolute",
      lifetime: 40,
    }
  )
  return (
    <div
      style={{ position: "relative" }}
      className={className}
      onClick={(e) => {
        reward()
        setTimeout(() => {
          onClick(e)
        }, 800)
      }}
    >
      <div id={`rewardId_SubmitSphereButton`} />
      {children}
    </div>
  )
}
export default SubmitSphereButton
