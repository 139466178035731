import React from "react"

const LifebuoyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M18.3648 5.63477C19.2011 6.47055 19.8644 7.46292 20.317 8.55515C20.7696 9.64739 21.0025 10.8181 21.0025 12.0004C21.0025 13.1827 20.7696 14.3534 20.317 15.4456C19.8644 16.5379 19.2011 17.5302 18.3648 18.366L14.6523 14.6535C15.3542 13.9489 15.7483 12.9949 15.7483 12.0004C15.7483 11.0059 15.3542 10.0519 14.6523 9.34727L18.3648 5.63477Z"
      fill="currentColor"
    />
    <path
      opacity="0.2"
      d="M5.6338 18.366C4.79757 17.5302 4.13422 16.5379 3.68163 15.4456C3.22904 14.3534 2.99609 13.1827 2.99609 12.0004C2.99609 10.8181 3.22904 9.64739 3.68163 8.55515C4.13422 7.46292 4.79757 6.47055 5.6338 5.63477L9.3463 9.34727C8.64443 10.0519 8.25035 11.0059 8.25035 12.0004C8.25035 12.9949 8.64443 13.9489 9.3463 14.6535L5.6338 18.366Z"
      fill="currentColor"
    />
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.34727 9.34727L5.63477 5.63477"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6523 9.34727L18.3648 5.63477"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6523 14.6523L18.3648 18.3648"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.34727 14.6523L5.63477 18.3648"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LifebuoyIcon
