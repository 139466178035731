import axios from "axios"
import { notification } from "antd"

const updateSphereSteps = async (sphere_id, steps, callback) => {
  try {
    const { data } = await axios.request({
      url: `/v1/stage_sphere/${sphere_id}`,
      method: "PUT",
      data: {
        sphere_steps: steps,
      },
    })
    notification.success({
      message: "Stage Sphere",
      description: "Successfully updated Stage Sphere",
      className: "Notification-success",
      duration: 5,
    })
    callback(data)
  } catch (err) {
    notification.error({
      message: "Stage Sphere",
      description:
        "Something went wrong when trying to update Stage Sphere. Try again.",
      className: "Notification-error",
      duration: 5,
    })
    console.log(err)
  }
}

export default updateSphereSteps
