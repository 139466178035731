import React, { useEffect, useState } from "react"
import { Button, Col, DatePicker, Modal, notification, Row } from "antd"
import moment from "moment"
import axios from "axios"

const AccountsModal = ({ user_id, visible, onCancel }) => {
  const [accounts, setAccounts] = useState([])
  const fetchAccounts = async () => {
    await axios
      .request({
        url: "/v1/admin/accounts",
        method: "GET",
        params: {
          user_id: user_id,
        },
      })
      .then((r) => {
        setAccounts(r.data)
      })
  }

  const syncAccount = (id, syncFrom) => {
    axios
      .request({
        url: "/v1/admin/accounts/sync",
        method: "POST",
        data: {
          channel_id: id,
          timestamp: syncFrom,
        },
      })
      .then((r) => {
        fetchAccounts()
        notification.success({
          message: "Success",
          description: "Syncing started",
        })
      })
  }

  useEffect(() => {
    fetchAccounts()
  }, [user_id])

  return (
    <Modal
      closable={false}
      width={"40vw"}
      destroyOnClose
      footer={null}
      title={<h3>Manage accounts</h3>}
      visible={visible}
      onCancel={() => onCancel()}
    >
      {accounts.map((account) => (
        <div
          key={account.id}
          style={{
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <b>Email: {account.email}</b>
          <span>Nylas version: {account.grant_id ? "V3" : "V2"}</span>
          <span style={{ color: account.state === "running" ? "green" : "red" }}>
            State: {account.state}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "20px",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <span>From:</span>
            <DatePicker
              placeholder="Date"
              format={"MM/DD/YYYY"}
              style={{ width: "40%" }}
              value={account.syncFrom ? moment.unix(account.syncFrom) : null}
              onChange={(e) => {
                const newAccounts = accounts.map((a) => {
                  if (a.id === account.id) {
                    a.syncFrom = moment(e).unix()
                  }
                  return a
                })
                setAccounts(newAccounts)
              }}
            />
            <Button
              onClick={() => {
                syncAccount(account.id, account.syncFrom)
              }}
              style={{ width: "40%" }}
              type={"primary"}
              block
              disabled={!account.syncFrom || account.state !== "running"}
            >
              Start syncing
            </Button>
          </div>
        </div>
      ))}
    </Modal>
  )
}

export default AccountsModal
