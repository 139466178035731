import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./Partner.styles.scss"
import { getStatus } from "../../redux/User/User.actions"
import RelatableLoader from "../../components/UI/RelatableLoader/RelatableLoader.component"
import PartnerDetailsSection from "./PartnerDetailsSection/PartnerDetailsSection.page"
import PartnerUsersListSection from "./PartnerUsersListSection/PartnerUsersListSection.page"

const PartnerPage = () => {
  const partner = useSelector((state) => state.UserState.partner)
  const updating = useSelector((state) => state.UserState.updating)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStatus())
  }, [])

  return (
    <div className="PartnerPage">
      <h1>Partner Panel</h1>
      {!updating && partner ? (
        <>
          <PartnerDetailsSection />
          <PartnerUsersListSection partner={partner} />
        </>
      ) : (
        <RelatableLoader />
      )}
    </div>
  )
}

export default PartnerPage
