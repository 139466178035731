import React from "react"
import { Button, Col, Modal, Row } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { takeSession } from "../../../redux/Admin/Admin.actions"

const TakingSessionModal = ({ email, visible, onCancel }) => {
  const admin_refresh_token = useSelector((state) => state.UserState.refresh_token)
  const dispatch = useDispatch()

  return (
    <Modal
      closable={false}
      width={"40vw"}
      destroyOnClose
      footer={null}
      title={<h3>Take over session</h3>}
      visible={visible}
      onCancel={() => onCancel()}
    >
      <div style={{ padding: "2rem" }}>
        <span>
          You are trying to take over <b>{email}</b> session. Please confirm.
        </span>
        <hr style={{ margin: "1rem" }} />
        <div>
          <Row>
            <Col offset={1} md={11}>
              <Button
                onClick={() => {
                  onCancel()
                }}
                style={{ margin: "1rem 0" }}
                type={"danger"}
                block
              >
                CANCEL
              </Button>
            </Col>
            <Col offset={1} md={11}>
              <Button
                onClick={() => {
                  dispatch(takeSession(email, admin_refresh_token))
                }}
                style={{ margin: "1rem 0" }}
                type={"primary"}
                block
              >
                TAKE OVER SESSION
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

export default TakingSessionModal
