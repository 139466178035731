import React from "react"
import { Form, Input, Modal } from "antd"
import "./ChangePasswordModal.styles.scss"
import { useFormik } from "formik"
import { updateProfile } from "../../../../redux/User/User.actions"
import { useDispatch } from "react-redux"
import * as yup from "yup"

const ChangePasswordModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch()

  const schema = yup.object().shape({
    current_password: yup.string().required("Enter current password"),
    password: yup
      .string()
      .required("Enter new password")
      .min(12, "Password must have at least 12 characters")
      .matches(/.*[A-Z].*/, "Password must contain at least one UPPERCASE letter")
      .matches(/.*[0-9].*/, "Password must contain at least one number")
      .nullable(),
    password_confirmation: yup
      .string()
      .required("Repeat new password")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .nullable(),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      passwordRequired: true,
      password: null,
      password_confirmation: null,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(updateProfile(values))
      formik.resetForm()
      onCancel()
    },
  })

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onCancel}
      centered={true}
      width={400}
      className="ConfirmModal__modal"
    >
      <div className="ConfirmModal">
        <div className="ConfirmModal_Body">
          <Form.Item
            label="Current password"
            validateStatus={formik.errors.current_password ? "error" : "success"}
            help={
              formik.errors.current_password ? formik.errors.current_password : []
            }
          >
            <Input
              type="password"
              key="current_password"
              name="current_password"
              value={formik.values.current_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            validateStatus={
              formik.touched.password && formik.errors.password ? "error" : "success"
            }
            help={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : []
            }
          >
            <Input
              type="password"
              key="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
          <Form.Item
            label="Password confirmation"
            validateStatus={
              formik.touched.password_confirmation &&
              formik.errors.password_confirmation
                ? "error"
                : "success"
            }
            help={
              formik.touched.password_confirmation &&
              formik.errors.password_confirmation
                ? formik.errors.password_confirmation
                : []
            }
          >
            <Input
              type="password"
              key="password_confirmation"
              name="password_confirmation"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </div>
        <div className={"ConfirmModal_Buttons"}>
          <div
            className="ConfirmModal_YesButton"
            onClick={() => formik.submitForm()}
          >
            Save changes
          </div>
          <div
            className="ConfirmModal_NoButton"
            onClick={() => {
              formik.resetForm()
              onCancel()
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChangePasswordModal
