import React, { useState } from "react"
import * as _ from "lodash"
import moment from "moment"
import Popup from "./../Popup/Popup.component"
import { useDispatch, useSelector } from "react-redux"
import {
  approveIntroduction,
  deleteIntroduction,
} from "../../../../../redux/Introductions/Introductions.actions"
import Avatar from "../../../../../components/UI/Avatar/Avatar.component"
import ArrowsLeftRightIcon from "../../../../../components/Icons/ArrowsLeftRight.icons"
import Icon from "@ant-design/icons"
import TrashIcon from "../../../../../components/Icons/Trash.icons"
import IntroductionCreator from "../../../../../components/Dashboard/DashboardForm/Tabs/NewIntroTab/components/IntroductionCreator/IntroductionCreator.component"
import EmailComposerModal from "../../../../../components/UI/EmailComposerModal/EmailComposerModal.component"
import {
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../../redux/App/App.actions"

const IntroItem = ({ introduction }) => {
  const user = useSelector((state) => state.UserState)
  const [modalVisible, setModalVisible] = useState(false)
  const dispatch = useDispatch()

  const ApprovePopupText = ({ participant, idx }) => {
    return (
      <div className="IntroductionsTab_Popup_Content">
        <EmailComposerModal
          title={`Review & send the introductions!`}
          action={"sending"}
          visible={modalVisible}
          cancelHandler={() => {
            dispatch(setVisibleContactID(null))
            dispatch(setContactDrawerVisible(false))
            setModalVisible(false)
          }}
        >
          <IntroductionCreator
            participant1={introduction.participants[0]}
            participant2={introduction.participants[1]}
            handleSubmit={(config) => {
              dispatch(approveIntroduction(introduction.id, participant.id, config))
              setModalVisible(false)
            }}
            handleClose={() => setModalVisible(false)}
            visible={modalVisible}
            introduction={introduction}
            itemIndex={`${introduction.id}-${participant.id}`}
          />
        </EmailComposerModal>
        <div className="IntroductionsTab_Popup_Content_Header">
          <div className="IntroductionsTab_Popup_Content_Header_Title">
            Approve this introduction?
          </div>
          <button
            className="IntroductionsTab_Popup_Content_Header_ApproveButton"
            onClick={() => {
              if (
                introduction.participants.find((p) => +p.id !== +participant.id)
                  .state !== "AWAITING_APPROVAL"
              ) {
                setModalVisible(true)
              } else {
                dispatch(approveIntroduction(introduction.id, participant.id))
              }
              setApproveLVisible(false)
              setApproveRVisible(false)
            }}
          >
            Approve
          </button>
        </div>
        <div className="IntroductionsTab_Popup_Content_Body">
          If you know {participant.full_name} is open to the introduction, click
          Approve.
        </div>
      </div>
    )
  }

  const DeletePopupText = () => {
    return (
      <div className="IntroductionsTab_Popup_Content">
        <div className="IntroductionsTab_Popup_Content_Header">
          <div className="IntroductionsTab_Popup_Content_Header_Title">
            Delete this introduction?
          </div>
          <button
            className="IntroductionsTab_Popup_Content_Header_DeleteButton"
            onClick={(e) => {
              dispatch(deleteIntroduction(introduction.id))
              setDeleteVisible(false)
              e.stopPropagation()
            }}
          >
            Delete
          </button>
        </div>
        <div className="IntroductionsTab_Popup_Content_Body">
          Do you really want to delete this introduction?
        </div>
      </div>
    )
  }

  const [approveLVisible, setApproveLVisible] = useState(false)
  const [approveRVisible, setApproveRVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)

  const styleState = (state) => {
    switch (state) {
      case "AWAITING_APPROVAL":
        return "awaiting"
      case "APPROVED":
        return "approved"
      case "REJECTED":
        return "rejected"
      default:
        return null
    }
  }

  const formatState = (state) => {
    return _.capitalize(state ? state.replace("_", " ").toLowerCase() : " ")
  }

  return (
    <div className="IntroductionsTab_Item">
      <Popup
        content={<ApprovePopupText participant={introduction.participants[0]} />}
        visible={
          introduction.status !== "COMPLETED" &&
          approveLVisible &&
          introduction.participants[0].state !== "APPROVED"
        }
        handleVisibleChange={() => setApproveLVisible(!approveLVisible)}
      >
        <div className="IntroductionsTab_Item_PersonTile">
          <div className="IntroductionsTab_Item_PersonTile_AvatarAndName">
            <Avatar
              avatar={introduction.participants[0].avatar}
              firstName={introduction.participants[0].full_name?.split(" ")[0]}
              lastName={introduction.participants[0].full_name?.split(" ")[1]}
              colorNumber={introduction.participants[0].id}
              size={23}
            />
            <span
              className={"IntroductionsTab_Item_PersonTile_AvatarAndName_FullName"}
            >
              {introduction.participants[0].full_name}
            </span>
          </div>
          <span
            className={`IntroductionsTab_Item_PersonTile_Status IntroductionsTab_Item_PersonTile_Status-${styleState(
              introduction.participants[0].state
            )}`}
          >
            {formatState(introduction.participants[0].state)}
          </span>
          <span className="IntroductionsTab_Item_PersonTile_Date">
            Initiated{" "}
            {moment(introduction.participants[0].created_at)
              .tz(user.time_zone)
              .format("MMM Do, YYYY")}
          </span>
        </div>
      </Popup>
      <div className="IntroductionsTab_Item_IconContainer">
        <Icon component={ArrowsLeftRightIcon} />
      </div>
      <Popup
        content={<ApprovePopupText participant={introduction.participants[1]} />}
        visible={
          introduction.status !== "COMPLETED" &&
          approveRVisible &&
          introduction.participants[1].state !== "APPROVED"
        }
        handleVisibleChange={() => setApproveRVisible(!approveRVisible)}
      >
        <div className="IntroductionsTab_Item_PersonTile">
          <div className="IntroductionsTab_Item_PersonTile_AvatarAndName">
            <Avatar
              avatar={introduction.participants[1].avatar}
              firstName={introduction.participants[1].full_name?.split(" ")[0]}
              lastName={introduction.participants[1].full_name?.split(" ")[1]}
              colorNumber={introduction.participants[1].id}
              size={23}
            />
            <span
              className={"IntroductionsTab_Item_PersonTile_AvatarAndName_FullName"}
            >
              {introduction.participants[1].full_name}
            </span>
          </div>
          <span
            className={`IntroductionsTab_Item_PersonTile_Status IntroductionsTab_Item_PersonTile_Status-${styleState(
              introduction.participants[1].state
            )}`}
          >
            {formatState(introduction.participants[1].state)}
          </span>
          <span className="IntroductionsTab_Item_PersonTile_Date">
            {moment(introduction.participants[1].updated_at)
              .tz(user.time_zone)
              .format("MMM Do, YYYY")}
          </span>
        </div>
      </Popup>
      <Popup
        content={<DeletePopupText />}
        visible={deleteVisible}
        handleVisibleChange={() => setDeleteVisible(!deleteVisible)}
      >
        <div
          className="IntroductionsTab_Item_DeleteButton"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Icon component={TrashIcon} />
        </div>
      </Popup>
    </div>
  )
}

export default IntroItem
