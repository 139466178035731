import {
  ADD_PARTICIPANTS_TO_SPHERES,
  ADD_PARTICIPANTS_TO_SPHERES_FAIL,
  ADD_PARTICIPANTS_TO_SPHERES_SUCCESS,
  ADD_TO_SPHERES,
  ADD_TO_SPHERES_FAIL,
  ADD_TO_SPHERES_SUCCESS,
  ARCHIVE_PARTICIPANTS,
  ARCHIVE_PARTICIPANTS_FAIL,
  ARCHIVE_PARTICIPANTS_SUCCESS,
  CLEAR_REDUX_PARTICIPANTS_STATE,
  FETCH_PARTICIPANTS,
  FETCH_PARTICIPANTS_FAIL,
  FETCH_PARTICIPANTS_SUCCESS,
  FETCH_RESERVE_PARTICIPANTS,
  FETCH_RESERVE_PARTICIPANTS_FAIL,
  FETCH_RESERVE_PARTICIPANTS_SUCCESS,
  SET_INITIAL_REDUX_PARTICIPANTS_STATE,
} from "./Participants.types"

export const fetchParticipants = (
  page = 1,
  per_page = 15,
  filters = null,
  query = null,
  sortField = "last_sent",
  sortOrder = "desc"
) => {
  return {
    type: FETCH_PARTICIPANTS,
    page: page,
    per_page: per_page,
    query: query,
    filters: filters,
    sortField: sortField,
    sortOrder: sortOrder,
  }
}

export const fetchParticipantsSuccess = (participants, total_entries) => {
  return {
    type: FETCH_PARTICIPANTS_SUCCESS,
    participants: participants,
    total_entries: total_entries,
  }
}

export const fetchParticipantsFail = (error) => {
  return {
    type: FETCH_PARTICIPANTS_FAIL,
    error: error,
  }
}

export const fetchReserveParticipants = (
  page = 1,
  per_page = 15,
  filters = null,
  query = null,
  sortField = "last_sent",
  sortOrder = "desc"
) => {
  return {
    type: FETCH_RESERVE_PARTICIPANTS,
    page: page,
    per_page: per_page,
    query: query,
    filters: filters,
    sortField: sortField,
    sortOrder: sortOrder,
  }
}

export const fetchReserveParticipantsSuccess = (participants, total_entries) => {
  return {
    type: FETCH_RESERVE_PARTICIPANTS_SUCCESS,
    participants: participants,
    total_entries: total_entries,
  }
}

export const fetchReserveParticipantsFail = (error) => {
  return {
    type: FETCH_RESERVE_PARTICIPANTS_FAIL,
    error: error,
  }
}

export const addToSpheres = (
  participant,
  existingSpheres,
  newSpheres,
  quickAdd = false
) => {
  return {
    type: ADD_TO_SPHERES,
    participant: participant,
    existingSpheres: existingSpheres,
    newSpheres: newSpheres,
    quickAdd: quickAdd,
  }
}

export const addToSpheresSuccess = (participant_id, person) => {
  return {
    type: ADD_TO_SPHERES_SUCCESS,
    participant_id: participant_id,
    person: person,
  }
}

export const addToSpheresFail = (error) => {
  return {
    type: ADD_TO_SPHERES_FAIL,
    error: error,
  }
}

export const addParticipantsToSpheres = (
  participantsIds,
  existingSpheres,
  newSpheres,
  page,
  filters
) => {
  return {
    type: ADD_PARTICIPANTS_TO_SPHERES,
    participantsIds: participantsIds,
    existingSpheres: existingSpheres,
    newSpheres: newSpheres,
    page: page,
    filters: filters,
  }
}

export const addParticipantsToSpheresSuccess = (participants_ids) => {
  return {
    type: ADD_PARTICIPANTS_TO_SPHERES_SUCCESS,
    participants_ids: participants_ids,
  }
}

export const addParticipantsToSpheresFail = (error) => {
  return {
    type: ADD_PARTICIPANTS_TO_SPHERES_FAIL,
    error: error,
  }
}

export const archiveParticipants = (participants_ids, page, filters) => {
  return {
    type: ARCHIVE_PARTICIPANTS,
    participants_ids: participants_ids,
    filters: filters,
    page: page,
  }
}

export const archiveParticipantsSuccess = (participants_ids) => {
  return {
    type: ARCHIVE_PARTICIPANTS_SUCCESS,
    participants_ids: participants_ids,
  }
}

export const archiveParticipantsFail = (error) => {
  return {
    type: ARCHIVE_PARTICIPANTS_FAIL,
    error: error,
  }
}

export const clearReduxParticipantsState = () => {
  return {
    type: CLEAR_REDUX_PARTICIPANTS_STATE,
  }
}

export const setInitialReduxParticipantsState = () => {
  return {
    type: SET_INITIAL_REDUX_PARTICIPANTS_STATE,
  }
}
