import React, { useEffect, useState } from "react"
import { Button, Modal, Spin, Table } from "antd"
import "./ApiKeysModal.styles.scss"
import Icon, { CopyOutlined } from "@ant-design/icons"
import CancelIcon from "../../../Icons/Cancel.icons"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteAPIKey,
  getAPIKeys,
  regenerateAPIKey,
} from "../../../../redux/User/User.actions"
import { showCopiedAPIKeyNotification } from "../../../../redux/Accounts/Accounts.actions"
import ConfirmModal from "../../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import PlusCircleIcon from "../../../Icons/PlusCircle.icons"
import NewApiKeyForm from "./NewApiKeysForm.component"
import RelatableLoader from "../../../UI/RelatableLoader/RelatableLoader.component"

const ApiKeysModal = ({ visible, onClose }) => {
  const [hideAPIKeys, setHideAPIKeys] = useState(true)
  const [confirmRegenerateID, setConfirmRegenerateID] = useState(null)
  const [confirmDeleteID, setConfirmDeleteID] = useState(null)
  const [showFormAddNewKey, setShowFormAddNewKey] = useState(false)

  const dispatch = useDispatch()

  const user = useSelector((state) => state.UserState)

  useEffect(() => {
    dispatch(getAPIKeys())
  }, [])

  useEffect(() => {
    setShowFormAddNewKey(false)
  }, [user.loading_api_keys])

  const columns = [
    {
      title: "Name",
      dataIndex: "key_name",
      width: "20%",
      key: "key_name",
    },
    {
      title: "Value",
      key: "key_value",
      render: (record) => (
        <div>
          {hideAPIKeys
            ? record.key_value.slice(0, 4) + "XXXXXXXXXXXXXXXXXXXX"
            : record.key_value}
          <button
            className="CopyToClipboardButton"
            onClick={() => {
              navigator.clipboard.writeText(record.key_value)
              setHideAPIKeys(false)
              dispatch(showCopiedAPIKeyNotification())
            }}
          >
            <CopyOutlined />
          </button>
        </div>
      ),
    },
    {
      key: "impersonate",
      width: "30%",
      render: (record) => (
        <div style={{ display: "flex" }}>
          <Button onClick={() => setConfirmRegenerateID(record.id)}>
            Regenerate
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => setConfirmDeleteID(record.id)}
            type="primary"
            danger
          >
            Delete
          </Button>
        </div>
      ),
    },
  ]

  return (
    <div>
      <Modal
        destroyOnClose={true}
        footer={null}
        visible={visible}
        onCancel={onClose}
        width={860}
        closeIcon={
          <Icon component={CancelIcon} className="LinkedinModal_CloseIcon" />
        }
        className="ApiKeysModal__modal"
      >
        <div className="ApiKeysModal">
          <div className="ApiKeysModal_Header">
            <h3>Your API Keys</h3>
          </div>
          <div className="ApiKeysModal_Body">
            {user.loading_api_keys ? (
              <RelatableLoader />
            ) : (
              <div>
                <Table
                  className="ApiKeysModal_Body_KeyTable"
                  columns={columns}
                  dataSource={user.api_keys}
                  pagination={false}
                  tableLayout="fixed"
                  scroll={{
                    y: "360px",
                  }}
                />
              </div>
            )}
          </div>
          <div className="ApiKeysModal_Footer">
            {showFormAddNewKey ? (
              <NewApiKeyForm onCancel={() => setShowFormAddNewKey(false)} />
            ) : (
              <Button
                className="ApiKeysModal_Footer_AddNewKeyButton"
                type="primary"
                onClick={() => setShowFormAddNewKey(true)}
              >
                <Icon component={PlusCircleIcon} /> Add New Key
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <ConfirmModal
        description="Are you sure? This will affect any applications that are currently using this API key."
        title="Regenerate API Key"
        visible={confirmRegenerateID !== null}
        onCancel={() => setConfirmRegenerateID(null)}
        onConfirm={() => dispatch(regenerateAPIKey(confirmRegenerateID))}
      />
      <ConfirmModal
        description="Are you sure? This will affect any applications that are currently using this API key."
        title="Delete API Key"
        visible={confirmDeleteID !== null}
        onCancel={() => setConfirmDeleteID(null)}
        onConfirm={() => dispatch(deleteAPIKey(confirmDeleteID))}
      />
    </div>
  )
}

export default ApiKeysModal
