import React from "react"

const GoToArrayIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7143 0.125C14.975 0.125 16 1.13398 16 2.375V13.625C16 14.866 14.975 15.875 13.7143 15.875H2.28571C1.025 15.875 0 14.866 0 13.625V2.375C0 1.13398 1.025 0.125 2.28571 0.125H13.7143ZM5.71429 4.0625C5.23929 4.0625 4.85714 4.43867 4.85714 4.90625C4.85714 5.37383 5.23929 5.75 5.71429 5.75H9.075L4.25 10.4961C3.91429 10.8266 3.91429 11.3609 4.25 11.6879C4.58571 12.0148 5.12857 12.0184 5.46071 11.6879L10.2821 6.9418V10.5312C10.2821 10.9988 10.6643 11.375 11.1393 11.375C11.6143 11.375 11.9964 10.9988 11.9964 10.5312V4.90625C11.9964 4.43867 11.6143 4.0625 11.1393 4.0625H5.71429Z"
      fill="#49ABFD"
    />
  </svg>
)

export default GoToArrayIcon
