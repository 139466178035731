import React, { useEffect, useState } from "react"
import "./PublicView.styles.scss"
import { useFormik } from "formik"
import { Form, Input, notification, Spin } from "antd"
import * as yup from "yup"
import { useParams } from "react-router"
import { createNewContact, getInfo } from "./PublicView.api"
import ILikeIt from "./ILikeIt.svg"
import { LoadingOutlined } from "@ant-design/icons"
import Error from "../../../layout/ErrorBoundary/Error.component"

const PublicView = () => {
  const params = useParams()

  const [fullName, setFullName] = useState(null)
  const [showThanks, setShowThanks] = useState(false)
  const [adding, setAdding] = useState(false)
  const [loading, setLoading] = useState(true)
  const [personDontExist, setPersonDontExist] = useState(false)

  const schema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Please provide valid email")
      .required("Email is required"),
    phone: yup.string("Phone is invalid"),
    note: yup.string("Field is invalid"),
  })

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      note: "",
    },
    isInitialValid: false,
    validationSchema: schema,
    onSubmit: (values) => {
      setAdding(true)
      createNewContact(values, params.public_username, (data) => {
        if (data.error) {
          notification.error({
            message: "Profile Filler",
            description: data.error,
            className: "Notification-error",
          })
        } else {
          setAdding(false)
          setShowThanks(true)
          formik.resetForm()
        }
      })
    },
  })

  useEffect(() => {
    getInfo(params.public_username, (data) => {
      if (
        data.full_name !== null &&
        data.full_name !== "" &&
        data.full_name !== " "
      ) {
        setFullName(data.full_name)
      }
      setLoading(false)
      if (data.error) {
        setPersonDontExist(true)
      }
    })
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return null
  } else if (personDontExist) {
    return <Error />
  } else {
    return (
      <div className="PublicView">
        <div className="PublicView_Card">
          <h2>Hi, I'm {fullName || params.public_username.toLowerCase()}!</h2>
          {process.env.REACT_APP_ENV === "production" && (
            <a
              href={`${process.env.REACT_APP_PROD_API_URL}v1/users/get_vcard?public_username=${params.public_username}`}
            >
              Download my vCard
            </a>
          )}
          {process.env.REACT_APP_ENV === "staging" && (
            <a
              href={`${process.env.REACT_APP_STAGING_API_URL}v1/users/get_vcard?public_username=${params.public_username}`}
            >
              Download my vCard
            </a>
          )}
          {process.env.REACT_APP_ENV === "development" && (
            <a
              href={`${process.env.REACT_APP_DEV_API_URL}v1/users/get_vcard?public_username=${params.public_username}`}
            >
              Download my vCard
            </a>
          )}
        </div>
        <div className="PublicView_Card">
          <h2>Can we stay in touch?</h2>
          {showThanks ? (
            <div className="PublicView_Card_ThanksWrapper">
              <img src={ILikeIt} alt="I like it" />
              <h2>Thanks!</h2>
            </div>
          ) : (
            <Form className="PublicView_Form">
              <div className="PublicView_Form_Name">
                <span className="PublicView_Form_Name_Label">Name</span>
                <Form.Item
                  className="PublicView_Form_Name_First"
                  validateStatus={
                    formik.touched.first_name && formik.errors.first_name
                      ? "error"
                      : "success"
                  }
                  help={
                    formik.touched.first_name && formik.errors.first_name
                      ? formik.errors.first_name
                      : []
                  }
                >
                  <Input
                    placeholder="First"
                    key="first_name"
                    name="first_name"
                    disabled={adding}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
                <Form.Item
                  className="PublicView_Form_Name_Last"
                  validateStatus={
                    formik.touched.last_name && formik.errors.last_name
                      ? "error"
                      : "success"
                  }
                  help={
                    formik.touched.last_name && formik.errors.last_name
                      ? formik.errors.last_name
                      : []
                  }
                >
                  <Input
                    placeholder="Last"
                    key="last_name"
                    name="last_name"
                    disabled={adding}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </div>
              <Form.Item
                className="PublicView_Form_Email"
                label="Email"
                colon={false}
                validateStatus={
                  formik.touched.email && formik.errors.email ? "error" : "success"
                }
                help={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : []
                }
              >
                <Input
                  placeholder="Email"
                  key="email"
                  name="email"
                  type="email"
                  disabled={adding}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                className="PublicView_Form_Phone"
                label="Phone"
                colon={false}
                validateStatus={
                  formik.touched.phone && formik.errors.phone ? "error" : "success"
                }
                help={
                  formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone
                    : []
                }
              >
                <Input
                  placeholder="Phone"
                  key="phone"
                  name="phone"
                  type="tel"
                  disabled={adding}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                className="PublicView_Form_Note"
                label="Anything you'd like me to know?"
                colon={false}
                validateStatus={
                  formik.touched.note && formik.errors.note ? "error" : "success"
                }
                help={
                  formik.touched.note && formik.errors.note ? formik.errors.note : []
                }
              >
                <Input
                  placeholder="Share any notes, thoughts, etc"
                  key="note"
                  name="note"
                  disabled={adding}
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <button
                className="PublicView_Form_SaveButton"
                onClick={formik.submitForm}
                disabled={adding || !formik.isValid}
              >
                <Spin
                  spinning={adding}
                  indicator={
                    <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
                  }
                />{" "}
                Save
              </button>
            </Form>
          )}
        </div>
        <img
          className="LeftRail_Header_Logo"
          width={142}
          src={`${process.env.PUBLIC_URL}/primary.svg`}
          alt="Relatable"
        />
      </div>
    )
  }
}

export default PublicView
