import React, { useEffect, useRef, useState } from "react"
import "./Reminders.styles.scss"
import reactStringReplace from "react-string-replace"
import { useDispatch, useSelector } from "react-redux"
import {
  completeReminder,
  deleteReminder,
  updateReminder,
} from "../../../redux/Reminders/Reminders.actions"
import moment from "moment"
import Icon, { EditOutlined } from "@ant-design/icons"
import CheckCircleIcon from "../../Icons/CheckCircle.icons"
import CancelIcon from "../../Icons/Cancel.icons"
import PersonAvatarAndName from "../../UI/PersonAvatarAndName/PersonAvatarAndName.component"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../redux/App/App.actions"
import { DatePicker, Mentions, Tooltip } from "antd"
import { fetchForMentions } from "../DashboardForm/Tabs/NewTaskTab/NewTaskTab.actions"
import * as _ from "lodash"
import TrashIcon from "../../Icons/Trash.icons"
import ConfirmModal from "../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import RewardsCheckbox from "./RewardsCheckbox.component"

const { Option } = Mentions

const DashboardReminder = ({ reminder, index, size = "large" }) => {
  const textarea = useRef(null)

  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )
  const user = useSelector((state) => state.UserState)
  const [reminderEdited, setReminderEdited] = useState(null)
  const [newDate, setNewDate] = useState(null)
  const [newContent, setNewContent] = useState(null)
  const [loading, setLoading] = useState(false)
  const [prefix, setPrefix] = useState("@")
  const [people, setPeople] = useState([])
  const [topics, setTopics] = useState([])
  const [expertises, setExpertises] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [selectedTopics, setSelectedTopics] = useState([])
  const [selectedExpertises, setSelectedExpertises] = useState([])
  const [reminderCompleted, setReminderCompleted] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedExpertises([])
    setSelectedTopics([])

    if (reminderEdited) {
      textarea.current.focus()
      setNewContent(formatEditable(reminder))
      const tempInitialPeople = reminder.people.map((person) => ({
        id: person.id,
        full_name: person.full_name,
        value: person.full_name.replace(/ /g, "_"),
      }))

      setSelectedParticipants(tempInitialPeople)
      setNewDate(reminder.due_at)
    } else {
      setNewContent(null)
    }
    // eslint-disable-next-line
  }, [reminderEdited])

  const handleShowPerson = (id, slug) => {
    if (contactDrawerVisible && id === visibleContactID) {
      dispatch(setVisibleContactID(null))
      dispatch(setContactDrawerVisible(false))
    } else {
      dispatch(setVisibleContactID(id, slug))
      dispatch(setContactDrawerVisible(true))
      dispatch(setContactDrawerMode("view"))
    }
  }

  const formatForUpdate = (details) => {
    let formatted = details
    const personRegexp = new RegExp(/@[\S]+_?[\S]*/, "gu")
    if (personRegexp.test(formatted)) {
      const people = formatted.match(personRegexp)
      people.forEach((person) => {
        const p = person.replace("@", "").replace(/_/g, " ")
        formatted = formatted.replace(
          person,
          `{{${
            _.uniqBy(selectedParticipants, "id")
              .filter((x) => newContent.includes(`@${x.value}`))
              .find((per) => {
                return per.name === p || per.full_name === p
              }).id
          }}}`
        )
      })
      return formatted
    } else {
      return formatted
    }
  }

  const formatEditable = (reminder) => {
    let details = reminder.details
    let formatted = ""
    let idRegexp = /{{([0-9]+)}}/g

    if (idRegexp.test(details)) {
      formatted = reactStringReplace(details, idRegexp, (match, i) => {
        const person = reminder.people.find((person) => +person.id === +match)
        const fullName = person.full_name.replace(/ /g, "_") || ""
        return `@${fullName}`
      })
      formatted = formatted.join("")
    } else {
      formatted = details
    }

    return formatted
  }

  const formatDetails = (reminder) => {
    let details = reminder.details
    let formatted = ""
    let idRegexp = /{{([0-9]+)}}/g

    if (idRegexp.test(details)) {
      formatted = reactStringReplace(details, idRegexp, (match, i) => {
        return (
          <PersonAvatarAndName
            key={i}
            showPerson={handleShowPerson}
            person={
              reminder.people.find((person) => +person.id === +match) ||
              reminder.people[0]
            }
          />
        )
      })
    } else {
      formatted = details
    }

    return formatted
  }

  const handleSelect = (id, value, name, _prefix) => {
    if (_prefix === "@") {
      setSelectedParticipants([
        ...selectedParticipants,
        { id: id, full_name: name, value: value },
      ])
    } else if (_prefix === "#") {
      setSelectedTopics([...selectedTopics, { id: id, value: value }])
    } else if (_prefix === "~") {
      setSelectedExpertises([...selectedExpertises, { id: id, value: value }])
    }
  }

  const handleSearch = (searchQuery, _prefix) => {
    setSearchQuery(searchQuery)
    setPrefix(_prefix)
    setPeople([])
    setTopics([])
    setExpertises([])

    setLoading(true)

    if (_prefix === "@") {
      loadPeople(searchQuery)
    } else if (_prefix === "#") {
      loadTopics(searchQuery)
    } else if (_prefix === "~") {
      loadExpertises(searchQuery)
    }
  }
  const [debouncedSearch] = useState(() => _.debounce(handleSearch, 500))

  const loadPeople = (query) => {
    fetchForMentions(query, "SORT").then((res) => {
      setPeople(res.slice(0, 10))
      setLoading(false)
    })
  }

  const loadTopics = (query) => {
    fetchForMentions(query, null, "topics").then((res) => {
      setTopics(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const loadExpertises = (query) => {
    fetchForMentions(query, null, "expertises").then((res) => {
      setExpertises(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const getMentions = (prefix) => {
    switch (prefix) {
      case "@":
        return people || []
      case "#":
        return [...topics, { id: null, name: searchQuery, value: searchQuery }] || []
      case "~":
        return (
          [...expertises, { id: null, name: searchQuery, value: searchQuery }] || []
        )
      default:
        return []
    }
  }

  const handleCompleteReminder = () => {
    setReminderCompleted(true)
    dispatch(completeReminder(reminder.id, visibleContactID))
  }

  useEffect(() => {
    setReminderCompleted(reminder.status === "completed")
  }, [reminder])

  const handleDeleteReminder = () => {
    dispatch(deleteReminder(reminder.id))
  }

  return (
    <>
      <ConfirmModal
        title="Are you sure you want to delete this reminder?"
        visible={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        onConfirm={handleDeleteReminder}
      />
      <div
        className={`Reminders_Reminder Reminders_Reminder--type-${reminder.type} ${
          reminderEdited === reminder.id ? "Reminders_Reminder--editing" : ""
        }`}
        key={reminder.id}
      >
        <div>
          <Tooltip title="Mark as completed">
            <RewardsCheckbox
              id={reminder.id}
              reminderCompleted={reminderCompleted}
              handleCompleteReminder={handleCompleteReminder}
            />
          </Tooltip>
        </div>
        <div className={`Reminders_Reminder_Body--${size}`}>
          <div className="Reminders_Reminder_Body_Content">
            {reminderEdited === reminder.id ? (
              <Mentions
                ref={textarea}
                key="details"
                name="details"
                prefix={["@", "#", "~"]}
                placeholder={"Type details about task..."}
                rows={1}
                loading={loading}
                onSearch={debouncedSearch}
                onChange={(e) => setNewContent(e)}
                onSelect={(selected, _prefix) =>
                  handleSelect(selected.id, selected.value, selected.name, _prefix)
                }
                value={newContent}
              >
                {(getMentions(prefix) || []).map((mention) => {
                  return (
                    <Option
                      id={mention.id}
                      name={mention.name}
                      key={mention.id}
                      value={mention.name ? mention.name.replace(/ /g, "_") : ""}
                      className="antd-demo-dynamic-option"
                    >
                      <span>{mention.name}</span>
                    </Option>
                  )
                })}
              </Mentions>
            ) : (
              formatDetails(reminder)
            )}
          </div>
          <div className="Reminders_Reminder_Body_Footer">
            <div className="Reminders_Reminder_Body_Footer_LastContact">
              {reminderEdited === reminder.id ? (
                <DatePicker
                  key={"due_at"}
                  name={"due_at"}
                  format={"MM/DD/YYYY"}
                  allowClear={false}
                  value={
                    newDate
                      ? moment(newDate).tz(user.time_zone)
                      : moment(reminder.due_at).tz(user.time_zone)
                  }
                  onChange={(e) =>
                    setNewDate(
                      new Date(e?._d.getTime() - e?._d.getTimezoneOffset() * 60000)
                    )
                  }
                />
              ) : (
                <span>
                  By{" "}
                  {moment(reminder.due_at).tz(user.time_zone).format("MMM Do, YYYY")}
                </span>
              )}
            </div>
            {reminderEdited === reminder.id ? (
              <div className="Reminders_Reminder_Body_Footer_Buttons Reminders_Reminder_Body_Footer_EditedButtons">
                <button
                  onClick={(e) => {
                    dispatch(
                      updateReminder(reminder.id, {
                        name: reminder.name,
                        details: formatForUpdate(newContent),
                        due_at: newDate,
                        people: _.uniqBy(selectedParticipants, "id").filter((p) =>
                          newContent.includes(`@${p.value}`)
                        ),
                        topics: _.uniqBy(selectedTopics, "id").filter((t) =>
                          newContent.includes(`#${t?.value}`)
                        ),
                        expertises: _.uniqBy(selectedExpertises, "id").filter((t) =>
                          newContent.includes(`~${t?.value}`)
                        ),
                      })
                    )
                    setReminderEdited(null)
                    e.stopPropagation()
                  }}
                >
                  <Icon component={CheckCircleIcon} />
                  <span>Save</span>
                </button>
                <button
                  className={"Reminders_Reminder_Body_Footer_Buttons_Cancel"}
                  onClick={(e) => {
                    setReminderEdited(null)
                    e.stopPropagation()
                  }}
                >
                  <Icon component={CancelIcon} />
                  <span>Cancel</span>
                </button>
              </div>
            ) : (
              <div className="Reminders_Reminder_Body_Footer_Buttons">
                <button
                  onClick={(e) => {
                    setReminderEdited(reminder.id)
                    e.stopPropagation()
                  }}
                >
                  <Icon component={EditOutlined} />
                  <span>Edit</span>
                </button>
                <button
                  onClick={(e) => {
                    setConfirmModalVisible(true)
                    e.stopPropagation()
                  }}
                >
                  <Icon component={TrashIcon} />
                  <span>Remove</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardReminder
