import {
  ADD_INTERACTION,
  ADD_INTERACTION_FAIL,
  ADD_INTERACTION_SUCCESS,
  ADD_NOTE,
  ADD_NOTE_FAIL,
  ADD_NOTE_SUCCESS,
  CLEAR_REDUX_INTERACTIONS_STATE,
  DELETE_EVENT,
  DELETE_EVENT_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_INTERACTION,
  DELETE_INTERACTION_FAIL,
  DELETE_INTERACTION_SUCCESS,
  DELETE_NOTE,
  DELETE_NOTE_FAIL,
  DELETE_NOTE_SUCCESS,
  EDIT_NOTE,
  EDIT_NOTE_CLEAR,
  SET_INITIAL_REDUX_INTERACTIONS_STATE,
  UPDATE_NOTE,
  UPDATE_NOTE_FAIL,
  UPDATE_NOTE_SUCCESS,
} from "./Interactions.types"

export const addNote = (values, visible_contact_id) => {
  return {
    type: ADD_NOTE,
    subject: values.subject,
    body: values.body,
    people: values.people,
    topics: values.topics,
    expertises: values.expertises,
    attachments: values.attachments,
    attachments_type: values.attachments_type,
    visible_contact_id: visible_contact_id,
  }
}

export const addNoteSuccess = (note) => {
  return {
    type: ADD_NOTE_SUCCESS,
    note: note,
  }
}

export const addNoteFail = (error) => {
  return {
    type: ADD_NOTE_FAIL,
    error: error,
  }
}

export const deleteNote = (id, visible_contact_id) => {
  return {
    type: DELETE_NOTE,
    id: id,
    visible_contact_id: visible_contact_id,
  }
}

export const deleteNoteSuccess = (id) => {
  return {
    type: DELETE_NOTE_SUCCESS,
    id: id,
  }
}

export const deleteNoteFail = (error) => {
  return {
    type: DELETE_NOTE_FAIL,
    error: error,
  }
}

export const editNote = (note) => {
  return {
    type: EDIT_NOTE,
    note: note,
  }
}

export const editNoteClear = () => {
  return {
    type: EDIT_NOTE_CLEAR,
  }
}

export const updateNote = (id, values, visible_contact_id) => {
  return {
    type: UPDATE_NOTE,
    id: id,
    body: values.body,
    people: values.people,
    topics: values.topics,
    expertises: values.expertises,
    attachments: values.attachments,
    attachments_type: values.attachments_type,
    visible_contact_id: visible_contact_id,
  }
}

export const updateNoteSuccess = (note) => {
  return {
    type: UPDATE_NOTE_SUCCESS,
    note: note,
  }
}

export const updateNoteFail = (error) => {
  return {
    type: UPDATE_NOTE_FAIL,
    error: error,
  }
}

export const addInteraction = (values, reset = false) => {
  return {
    type: ADD_INTERACTION,
    subject: values.subject,
    body: values.body,
    interaction_method: values.interaction_method,
    last_message_timestamp: values.last_message_timestamp,
    people: values.people,
    topics: values.topics,
    expertises: values.expertises,
    reset: reset,
  }
}

export const addInteractionSuccess = (interaction) => {
  return {
    type: ADD_INTERACTION_SUCCESS,
    interaction: interaction,
  }
}

export const addInteractionFail = (error) => {
  return {
    type: ADD_INTERACTION_FAIL,
    error: error,
  }
}

export const clearReduxInteractionsState = () => {
  return {
    type: CLEAR_REDUX_INTERACTIONS_STATE,
  }
}

export const setInitialReduxInteractionsState = () => {
  return {
    type: SET_INITIAL_REDUX_INTERACTIONS_STATE,
  }
}

export const deleteInteraction = (id, visible_contact_id) => {
  return {
    type: DELETE_INTERACTION,
    id: id,
    visible_contact_id: visible_contact_id,
  }
}

export const deleteInteractionSuccess = (id) => {
  return {
    type: DELETE_INTERACTION_SUCCESS,
    id: id,
  }
}

export const deleteInteractionFail = (error) => {
  return {
    type: DELETE_INTERACTION_FAIL,
    error: error,
  }
}

export const deleteEvent = (id, visible_contact_id) => {
  return {
    type: DELETE_EVENT,
    id: id,
    visible_contact_id: visible_contact_id,
  }
}

export const deleteEventSuccess = (id) => {
  return {
    type: DELETE_EVENT_SUCCESS,
    id: id,
  }
}

export const deleteEventFail = (error) => {
  return {
    type: DELETE_EVENT_FAIL,
    error: error,
  }
}
