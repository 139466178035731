import React from "react"
import "./SkeletonLoader.styles.scss"
import { Divider, Skeleton, List, Avatar } from "antd"

const SkeletonLoader = ({ active }) => {
  return (
    active && (
      <>
        <Skeleton.Image style={{ flex: 1, height: 250 }} />
        <Divider />
        <div style={{ padding: 10 }}>
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
        </div>
        <Divider />
        <div style={{ padding: 10 }}>
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
          <Skeleton.Input
            size={"small"}
            active={active}
            style={{ marginBottom: 5 }}
          />
        </div>
        <Divider />
        <div style={{ padding: 10 }}>
          <Skeleton loading={active} active avatar>
            <List.Item.Meta
              avatar={<Avatar src={null} />}
              title={"Empty"}
              description={"Empty"}
            />
          </Skeleton>
          <Skeleton loading={active} active avatar>
            <List.Item.Meta
              avatar={<Avatar src={null} />}
              title={"Empty"}
              description={"Empty"}
            />
          </Skeleton>
        </div>
      </>
    )
  )
}

export default SkeletonLoader
