import React, { useState } from "react"
import "./NoteItem.styles.scss"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteNote,
  editNote,
} from "../../../../redux/Interactions/Interactions.actions"
import Icon from "@ant-design/icons"
import PencilSimpleLineIcon from "../../../Icons/PencilSimpleLine.icons"
import TrashIcon from "../../../Icons/Trash.icons"
import PropTypes from "prop-types"
import { urlify } from "../../../../helpers/helpers.jsx"
import ConfirmModal from "../../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import { Tooltip } from "antd"

const DOMPurify = require("dompurify")(window)

const NoteItem = ({ note, editable }) => {
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const user = useSelector((state) => state.UserState)

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)

  const dispatch = useDispatch()

  const handleDeleteNote = () => {
    dispatch(deleteNote(note.id, visibleContactID))
  }

  return (
    <div className="NoteItem">
      <ConfirmModal
        title="Are you sure you want to delete this note?"
        visible={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        onConfirm={handleDeleteNote}
      />
      {note.body && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(urlify(note.body).split("\n").join("<br/>"), {
              ADD_ATTR: ["target"],
            }),
          }}
        />
      )}
      <div className="NoteItem_Footer">
        <span>
          Updated at{" "}
          {moment(note.last_message_received || note.updated_at)
            .tz(user.time_zone)
            .format("YYYY-MM-DD")}
        </span>
        {editable && (
          <div className="NoteItem_Footer_Actions">
            <Tooltip title="Edit">
              <Icon
                onClick={() => dispatch(editNote(note))}
                component={PencilSimpleLineIcon}
              />
            </Tooltip>
            <Tooltip title="Remove">
              <Icon
                onClick={() => setConfirmModalVisible(true)}
                component={TrashIcon}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}

NoteItem.propTypes = {
  note: PropTypes.object,
}

export default NoteItem
