import React, { useState } from "react"
import "./Forms.styles.scss"
import { Divider, Form, Input } from "antd"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { authenticateUser, resetPassword } from "../../../redux/User/User.actions"
import axios from "axios"
import MfaModal from "./MfaModal"
import NylasAuthProvider from "./NylasAuthProvider.component"

const LoginForm = () => {
  const dispatch = useDispatch()
  const [forgotPassword, setForgotPassword] = useState(false)
  const [mfaModal, setMfaModal] = useState(false)
  const [mfaToken, setMfaToken] = useState(null)
  const [qrCode, setQrCode] = useState(null)

  const checkForMfa = async (payload) => {
    const { data } = await axios.request({
      url: "/user_mfa_session/check_for_mfa",
      data: {
        username: payload.email,
        password: payload.password,
      },
      method: "POST",
    })

    if (data.mfa_required) {
      setMfaModal(true)
      setMfaToken(data.token)
      setQrCode(data.qr)
    } else {
      dispatch(authenticateUser(payload.email, payload.password))
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      checkForMfa(values)
    },
  })

  return (
    <form className="LoginForm" onSubmit={formik.handleSubmit}>
      <MfaModal
        codeAuthentication={(code) => {
          dispatch(
            authenticateUser(formik.values.email, formik.values.password, code)
          )
        }}
        qrCode={qrCode}
        mfaToken={mfaToken}
        visible={mfaModal}
      />
      <p className="LoginForm_InputLabel">Email*</p>
      <Form.Item
        validateStatus={forgotPassword && !formik.values.email ? "error" : "success"}
        help={
          forgotPassword &&
          !formik.values.email &&
          "Please provide an email for password reset"
        }
      >
        <Input
          className="LoginForm_Input"
          name="email"
          placeholder="Enter your email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <p className="LoginForm_InputLabel">Password*</p>
      <Input
        className="LoginForm_Input"
        name="password"
        placeholder="Enter your password"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
      />
      <button className="LoginForm_Button">Submit</button>
      <div
        className="LoginForm_ForgotPassword"
        onClick={() => {
          setForgotPassword(true)
          formik.values.email && dispatch(resetPassword(formik.values.email))
        }}
      >
        Forgot password?
      </div>
      <div
        className="LoginForm_SighUp"
        onClick={() =>
          (window.location.href = "https://try.relatable.one/#home-signup")
        }
      >
        Don’t have an account?
        <div className="LoginForm_Link">Sign Up</div>
      </div>
      <Divider />
      <NylasAuthProvider action={"Sign in"} />
    </form>
  )
}

export default LoginForm
