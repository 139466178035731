import React from "react"
import { Button, Form, Input } from "antd"
import "./ApiKeysModal.styles.scss"
import { useDispatch } from "react-redux"
import { createAPIKey } from "../../../../redux/User/User.actions"
import * as yup from "yup"
import { useFormik } from "formik"

const NewApiKeyForm = ({ onCancel }) => {
  const dispatch = useDispatch()

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .min(3, "Name must have at least 3 characters")
      .max(40, "Name must be shorter than 40 characters"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(createAPIKey(values.name))
      formik.resetForm()
    },
  })

  return (
    <div className="NewApiKeyForm">
      <Form.Item
        validateStatus={formik.errors.name ? "error" : "success"}
        help={formik.errors.name ? formik.errors.name : []}
      >
        <Input
          placeholder="API key Name"
          key="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Button type="primary" onClick={() => formik.submitForm()}>
        Save
      </Button>
      <Button
        onClick={() => {
          formik.resetForm()
          onCancel()
        }}
      >
        Cancel
      </Button>
    </div>
  )
}

export default NewApiKeyForm
