import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  ADD_REMINDER,
  COMPLETE_REMINDER,
  DELETE_REMINDER,
  FETCH_ALL_REMINDERS_PEOPLE,
  FETCH_EVENTS,
  FETCH_REMINDERS,
  IGNORE_SPECIAL_DATE,
  UPDATE_REMINDER,
} from "./Reminders.types"
import {
  addReminderFail,
  addReminderSuccess,
  completeReminderFail,
  completeReminderSuccess,
  deleteReminderFail,
  deleteReminderSuccess,
  fetchAllRemindersPeopleFail,
  fetchAllRemindersPeopleSuccess,
  fetchEvents,
  fetchEventsFail,
  fetchEventsSuccess,
  fetchRemindersFail,
  fetchRemindersSuccess,
  ignoreSpecialDateFail,
  ignoreSpecialDateSuccess,
  updateReminderFail,
  updateReminderSuccess,
} from "./Reminders.actions"
import axios from "axios"
import { fetchActivities, fetchPoints } from "../Activities/Activities.actions"
import { fetchPersonsReminders, fetchSelectedPeople } from "../People/People.actions"
import { selectContacts, setSplitMessageSenderVisible } from "../App/App.actions"
import history from "../../history"

export function* watchRemindersSaga() {
  yield takeLatest(ADD_REMINDER, addReminder)
  yield takeLatest(UPDATE_REMINDER, updateReminder)

  yield takeLatest(FETCH_REMINDERS, fetchReminders)
  yield takeLatest(COMPLETE_REMINDER, completeReminder)
  yield takeLatest(DELETE_REMINDER, deleteReminder)
  yield takeLatest(FETCH_EVENTS, _fetchEvents)
  yield takeLatest(IGNORE_SPECIAL_DATE, ignoreSpecialDate)
  yield takeLatest(FETCH_ALL_REMINDERS_PEOPLE, fetchAllRemindersPeople)
}

function* addReminder(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/reminders",
        data: {
          name: action.name,
          details: action.details,
          due_at: action.due_at,
          people: action.people,
          topics: action.topics,
          expertises: action.expertises,
        },
        method: "POST",
      })
    )
    yield put(addReminderSuccess(data.reminder))
    yield put(fetchActivities(1, 12))
    yield put(fetchPersonsReminders(action.people[0].id, 1))
  } catch (error) {
    yield put(addReminderFail(error))
  }
}

function* fetchReminders(action) {
  try {
    // 100 is a maximum for remindersCount dropdown
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/reminders",
        method: "GET",
        params: {
          per_page: 100,
        },
      })
    )
    yield put(fetchRemindersSuccess(data.reminders))
  } catch (error) {
    yield put(fetchRemindersFail(error))
  }
}

function* completeReminder(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/reminders/complete/${action.id}`,
        data: { status: "completed" },
        method: "PATCH",
      })
    )
    yield all([
      put(completeReminderSuccess(data.reminder)),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
      put(fetchPersonsReminders(action.visibleContactID, 1)),
    ])
  } catch (error) {
    yield put(completeReminderFail(error))
  }
}

function* deleteReminder(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/reminders/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(deleteReminderSuccess(data.reminder))
  } catch (error) {
    yield put(deleteReminderFail(error))
  }
}

function* _fetchEvents(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/all_events`,
        params: {
          page: action.page,
          count: action.count,
        },
        method: "GET",
      })
    )
    yield put(
      fetchEventsSuccess(
        data.special_dates,
        data.triggers,
        data.job_change,
        data.total_entries,
        action.page,
        action.count
      )
    )
  } catch (error) {
    yield put(fetchEventsFail(error))
  }
}

function* ignoreSpecialDate(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/special_dates/ignore`,
        data: {
          special_date_id: action.special_date_id,
          person_id: action.person_id,
        },
        method: "PUT",
      })
    )
    if (action.callback) {
      yield action.callback()
    }
    yield put(ignoreSpecialDateSuccess())
    yield put(fetchEvents(1, 3))
  } catch (error) {
    yield put(ignoreSpecialDateFail(error))
  }
}

function* updateReminder(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/reminders/${action.id}`,
        data: {
          name: action.name,
          details: action.details,
          due_at: action.due_at,
          people: action.people,
          topics: action.topics,
          expertises: action.expertises,
        },
        method: "PUT",
      })
    )
    yield put(updateReminderSuccess(data.reminder))
  } catch (error) {
    yield put(updateReminderFail(error))
  }
}

function* fetchAllRemindersPeople() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/all_reminders_people_ids",
        method: "GET",
      })
    )
    yield put(selectContacts(data.people_ids.map((id) => ({ id }))))
    yield put(fetchSelectedPeople(data.people_ids))
    history.push("/database")
    yield put(setSplitMessageSenderVisible(true))
    yield put(fetchAllRemindersPeopleSuccess())
  } catch (error) {
    yield put(fetchAllRemindersPeopleFail(error))
  }
}
