import React from "react"
import CallContactCard from "../Components/CallContactCard/CallContactCard.component"
import PropTypes from "prop-types"

const RemindersListScene = ({ reminders, setSelectedPerson, recordInteraction }) => {
  const handleRecordInteraction = (person) => {
    setSelectedPerson(person)
    recordInteraction()
  }

  return (
    <div className="Scene RemindersListScene">
      <h2 style={{ marginBottom: "40px" }}>
        Great. Here’s your call list for today. Let’s start reaching out!
      </h2>
      {reminders.map((reminder) => (
        <CallContactCard
          contact={reminder.people[0]}
          onRecordInteraction={() => handleRecordInteraction(reminder.people[0])}
          key={reminder.id}
        />
      ))}
    </div>
  )
}

RemindersListScene.propTypes = {
  reminders: PropTypes.array,
  onSelectPerson: PropTypes.func,
  recordInteraction: PropTypes.func,
}

export default RemindersListScene
