import React, { useEffect, useRef, useState } from "react"
import * as _ from "lodash"
import { Select, Spin } from "antd"
import getSearchedPeople from "../../../../../pages/StageSphere/api/getSearchedPeople"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const RelatedContactsSelector = ({
  collectionType,
  collectionTitle,
  currentElements = [],
  handleSubmit,
  disabled = false,
}) => {
  const select = useRef(null)

  const [collection, setCollection] = useState([])

  const [selected, setSelected] = useState([])
  const [newElements, setNewElements] = useState([])
  const [loading, setLoading] = useState(false)

  const [debouncedSearch] = useState(() => _.debounce(getSearchedPeople, 500))

  useEffect(() => {
    if (currentElements) {
      const selected = currentElements.map((element) => ({
        label: element.name,
        value: element.person_id,
      }))
      setSelected(selected)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setNewElements(
      selected.filter(
        (element) =>
          !currentElements
            .map((element) => element.person_id)
            .includes(element.value)
      )
    )
    // eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    const existingElements = currentElements.filter((element) =>
      selected.some((s) => s.value === element.person_id)
    )
    handleSubmit(existingElements, newElements)
    // eslint-disable-next-line
  }, [newElements])

  const searchPeople = (value) => {
    setLoading(true)
    debouncedSearch(value, (data) => {
      setCollection(data.map((person) => ({ label: person.name, value: person.id })))
      setLoading(false)
    })
  }

  return !collection ? (
    <RelatableLoader />
  ) : (
    <div>
      <Select
        onSearch={(value) => {
          if (value.length > 2) {
            searchPeople(value)
          }
        }}
        notFoundContent={loading ? <RelatableLoader size="small" /> : null}
        disabled={disabled}
        bordered={false}
        mode={"tags"}
        ref={select}
        allowClear={true}
        value={selected}
        labelInValue={true}
        className="CollectionSelector"
        listHeight={200}
        placeholder={collectionTitle || _.capitalize(collectionType.toString())}
        onChange={(labeled) => {
          // const newSelected = labeled.filter(
          //   (labeled) =>
          //     selected.find((s) => s.value === labeled.value) ||
          //     collection.find((c) => c.value === labeled.value)
          // )
          setSelected(labeled)
        }}
        dropdownClassName="CollectionSelector_Dropdown"
        getPopupContainer={(trigger) => trigger.parentNode}
        showSearch={false}
        options={collection}
      />
    </div>
  )
}

export default RelatedContactsSelector
