import React, { useEffect } from "react"
import "./SuccessPayment.styles.scss"
import axios from "axios"
import { getStatus } from "../../redux/User/User.actions"
import { useDispatch } from "react-redux"

const SuccessPaymentPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    async function saveStripeCustomer() {
      await axios.request({
        url: "/v1/payments/save_stripe_customer",
        method: "PUT",
      })
      dispatch(getStatus())
    }

    saveStripeCustomer()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="SuccessPayment">
      <h1>THANK YOU!</h1>
      <span>
        As a small-yet-mighty bootstrapped business, we are so thrilled to have you
        as a customer and new best friend.
      </span>
      <p>This is how happy we are right now:</p>
      <img
        alt="Watch Video"
        className={"video_placeholder"}
        src={`${process.env.PUBLIC_URL}/success_payment_gifs/office_party_1.gif`}
      />
      <img
        alt="Watch Video"
        className={"video_placeholder"}
        src={`${process.env.PUBLIC_URL}/success_payment_gifs/office_party_2.gif`}
      />
      <img
        alt="Watch Video"
        className={"video_placeholder"}
        src={`${process.env.PUBLIC_URL}/success_payment_gifs/office_party_3.gif`}
      />
      <p>Yeah, so happy we couldn't settle on just one GIF.</p>
      <p>Have a fantastic day. We know we will!</p>
    </div>
  )
}

export default SuccessPaymentPage
