import React from "react"
import { Button, Col, Modal, Row } from "antd"

const SendWarningModal = ({ selected_users, visible, onCancel }) => {
  return (
    <Modal
      closable={false}
      width={"40vw"}
      destroyOnClose
      footer={null}
      title={<h3>Send warning</h3>}
      visible={visible}
      onCancel={() => onCancel()}
    >
      <div style={{ padding: "2rem" }}>
        <span>
          You are trying to send warning messages to
          <ul>
            {selected_users.map((u) => (
              <li>
                <b>{u.email}</b>
              </li>
            ))}
          </ul>
          Please confirm.
        </span>
        <hr style={{ margin: "1rem" }} />
        <div>
          <Row>
            <Col offset={1} md={11}>
              <Button
                onClick={() => {
                  onCancel()
                }}
                style={{ margin: "1rem 0" }}
                type={"danger"}
                block
              >
                CANCEL
              </Button>
            </Col>
            <Col offset={1} md={11}>
              <Button
                onClick={() => {
                  // dispatch(takeSession(emails, admin_refresh_token))
                }}
                style={{ margin: "1rem 0" }}
                type={"primary"}
                block
              >
                SEND WARNING
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

export default SendWarningModal
