import React from "react"
import "./FocusModeTab.styles.scss"
import { Link } from "react-scroll"
import { ReactComponent as ConquerPeak } from "../../../images/ConquerPeak.svg"

const FocusModeTab = ({ setShowFocusMode }) => {
  return (
    <div className="FocusModeTab">
      <ConquerPeak />
      <div className="FocusModeTab_Wrapper">
        <h2>Let the Sphere Sherpa guide you</h2>
        <span>
          There’s a lot of things you could do to grow your relationships. The Sphere
          Sherpa knows what to do next.
        </span>
      </div>
      <Link onClick={() => setShowFocusMode(true)} className="FocusModeTab_Button">
        Launch the Sherpa!
      </Link>
    </div>
  )
}

export default FocusModeTab
