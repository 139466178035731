import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./App"

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "./redux/store"

import * as serviceWorker from "./serviceWorker"
import { ConnectedRouter } from "connected-react-router"
import history from "./history"

// Sentry.init({
//   dsn: "https://5d2cac1f13c348d1a8576598d5bcaa04@o1153806.ingest.sentry.io/6233464",
//   integrations: [new BrowserTracing()],
//   environment: process.env.REACT_APP_ENV,
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
