import React, { useEffect, useState } from "react"
import "./MainLayout.styles.scss"
import { Layout } from "antd"
import RightPanel from "./RightPanel/RightPanel.component"
import { useDispatch, useSelector } from "react-redux"
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params"
import { fetchSpheres } from "../../redux/Collections/Collections.actions"
import ContactProfileDrawer from "../../components/Dashboard/ContactProfileDrawer/ContactProfileDrawer.component"
import {
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setLeftRailCollapsed,
  setRightRailCollapsed,
  setVisibleContactID,
} from "../../redux/App/App.actions"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary.layout"
import InfoBar from "../InfoBar/InfoBar.component"
import moment from "moment"
import LeftRail from "./LeftRail/LeftRail.layout"
import TrialExtensionsModal from "../../components/Dashboard/TrialExtensionsModal/TrialExtensionsModal.component"
import StripeSessionCreatingModal from "../../components/Account/StripeSessionCreatingModal/StripeSessionCreatingModal.component"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import useWindowDimensions from "./LeftRail/useWindowDimensions"

const { Content } = Layout

const MainLayout = ({ children }) => {
  const user = useSelector((state) => state.UserState)
  const history = useHistory()
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )
  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const visibleContactFriendlyID = useSelector(
    (state) => state.AppState.visibleContactFriendlyID
  )

  const [showInfoBar, setShowInfoBar] = useState(false)
  const params = useParams()
  const { width } = useWindowDimensions()

  const [showTrialExtensions, setShowTrialExtensions] = useState(false)
  const trial_days = useSelector((state) => state.UserState.trial_days_remaining)
  const leftRailCollapsed = useSelector((state) => state.AppState.leftRailCollapsed)
  const rightRailCollapsed = useSelector(
    (state) => state.AppState.rightRailCollapsed
  )
  const [query, setQuery] = useQueryParams({
    res: StringParam,
    people_page: NumberParam,
    people_by: StringParam,
    people_order: StringParam,
    people_filters: ArrayParam,
    participants_page: NumberParam,
    participants_by: StringParam,
    participants_order: StringParam,
  })

  useEffect(() => {
    if (width < 1500 && !rightRailCollapsed) {
      dispatch(setRightRailCollapsed(true))
    }

    if (width >= 1500 && rightRailCollapsed) {
      dispatch(setRightRailCollapsed(false))
    }

    const widthForLeftRail = contactDrawerVisible ? 1300 : 1000

    if (width < widthForLeftRail && !leftRailCollapsed) {
      dispatch(setLeftRailCollapsed(true))
    }

    if (width >= widthForLeftRail && leftRailCollapsed) {
      dispatch(setLeftRailCollapsed(false))
    }

    // eslint-disable-next-line
  }, [width, contactDrawerVisible])

  const cleanPermalink = () => {
    if (params.slug) {
      const existingPath = history.location.pathname
      const newPath = existingPath.replace(
        `/person/${params.slug}`,
        history.location.search
      )
      history.push(newPath)
    }
  }

  const updateUrlWithSlug = (slug) => {
    if (slug && params.slug !== slug) {
      const existingPath = history.location.pathname

      let newPath
      switch (true) {
        case existingPath === "/":
          newPath = `/dashboard/person/${slug}`
          break
        case /\/database\/duplicates/.test(existingPath):
          newPath = `/database/duplicates/person/${slug}`
          break
        case /\/database/.test(existingPath):
          newPath = `/database/person/${slug}` + history.location.search
          break
        case /\/uncategorized/.test(existingPath):
          newPath = `/uncategorized/person/${slug}` + history.location.search
          break
        case /\/dashboard/.test(existingPath):
          newPath = `/dashboard/person/${slug}` + history.location.search
          break
        case /\/account\/settings/.test(existingPath):
          newPath = `/account/settings/person/${slug}`
          break
        case /\/stage-sphere\/duplicates/.test(existingPath):
          newPath = `/database/duplicates/person/${slug}`
          break
        case /\/admin/.test(existingPath):
          newPath = `/admin/person/${slug}`
          break
        default:
          // check if existing path include /person/ and replace the slug
          if (/\/person\//.test(existingPath)) {
            newPath = existingPath.replace(/\/person\/[a-z0-9-]+/, `/person/${slug}`)
          } else {
            newPath = existingPath + `/person/${slug}`
          }

          break
      }

      history.push(newPath)
    }
  }

  useEffect(() => {
    if (params.slug) {
      dispatch(setVisibleContactID(null, params.slug))
    }

    const current_date = moment().tz(user.time_zone).startOf("day").toString()
    if (
      user.is_trialing &&
      trial_days < 7 &&
      localStorage.trial_modal_last_seen !== current_date
    ) {
      setShowTrialExtensions(true)
    }

    fetchSpheres()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (visibleContactFriendlyID) {
      updateUrlWithSlug(visibleContactFriendlyID || params.slug)
      if (!contactDrawerVisible) dispatch(setContactDrawerVisible(true))
    }
    // eslint-disable-next-line
  }, [visibleContactFriendlyID])

  useEffect(() => {
    if (!params.slug) {
      dispatch(setActiveTab(null))
      dispatch(setContactDrawerVisible(false))
      dispatch(setVisibleContactID(null))
    }
    // eslint-disable-next-line
  }, [params.slug])

  const dispatch = useDispatch()

  useEffect(() => {
    if (user.is_trialing || user.billing_account_status === "card_declined") {
      setShowInfoBar(true)
    } else {
      setShowInfoBar(false)
    }
  }, [user])

  return (
    <>
      <ErrorBoundary>
        <div>
          <StripeSessionCreatingModal />
          {showInfoBar && (
            <InfoBar
              trialing={user.is_trialing}
              card_declined={user.billing_account_status === "card_declined"}
            >
              {user.is_trialing && (
                <div className={"InfoBar_Buttons"}>
                  <div className={"InfoBar_DaysLeft"}>
                    <span>
                      {user.trial_days_remaining > 0
                        ? `${user.trial_days_remaining} days left!`
                        : `Your trial ended!`}
                    </span>
                    <div
                      onClick={() => setShowTrialExtensions(true)}
                      className={"InfoBar_AddButton"}
                    >
                      Add more time
                    </div>
                  </div>
                </div>
              )}
            </InfoBar>
          )}
          {showTrialExtensions && (
            <TrialExtensionsModal
              trial_days_remaining={trial_days}
              visible={showTrialExtensions}
              onClose={() => {
                localStorage.setItem(
                  "trial_modal_last_seen",
                  moment().tz(user.time_zone).startOf("day").toString()
                )
                setShowTrialExtensions(false)
              }}
            />
          )}
          <Layout className={"Layout"}>
            <LeftRail query={query} setQuery={setQuery} showInfoBar={showInfoBar} />
            <Content className="Layout_Content">
              <div
                className={`Layout_Content_Body ${
                  showInfoBar && "Layout_Content_Body--ShowInfoBar"
                }`}
              >
                {children}
              </div>
            </Content>
            <RightPanel showInfoBar={showInfoBar} />
            <ContactProfileDrawer
              visible={contactDrawerVisible}
              contactPermalinkId={params.slug}
              contactId={visibleContactID}
              handleClose={() => {
                cleanPermalink()
                dispatch(setActiveTab(null))
                dispatch(setContactDrawerVisible(false))
                dispatch(setVisibleContactID(null))
                dispatch(setContactDrawerMode("view"))
              }}
            />
          </Layout>
        </div>
      </ErrorBoundary>
    </>
  )
}

export default MainLayout
