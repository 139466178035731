import React from "react"

const MessageIcon = () => (
  <svg
    width="40"
    height="35"
    viewBox="0 0 40 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0041 32.5C31.0509 32.5 40.0041 25.2266 40.0041 16.25C40.0041 7.27344 31.0509 0 20.0041 0C8.95718 0 0.00405889 7.27344 0.00405889 16.25C0.00405889 19.7734 1.38687 23.0312 3.73062 25.6953C3.58218 27.6094 2.84 29.3125 2.05875 30.6094C1.62906 31.3281 1.19156 31.9062 0.871246 32.2969C0.707184 32.4922 0.582184 32.6406 0.488434 32.7422C0.441559 32.7891 0.410309 32.8281 0.386871 32.8516L0.363434 32.875C0.00405889 33.2344 -0.0975036 33.7656 0.0978089 34.2344C0.293121 34.7031 0.746246 35.0078 1.25406 35.0078C3.49625 35.0078 5.75406 34.3125 7.62906 33.5C9.41812 32.7187 10.9416 31.7891 11.8712 31.1094C14.3556 32.0078 17.1056 32.5078 20.0041 32.5078V32.5ZM10.0041 13.75C10.6671 13.75 11.303 14.0134 11.7718 14.4822C12.2407 14.9511 12.5041 15.587 12.5041 16.25C12.5041 16.913 12.2407 17.5489 11.7718 18.0178C11.303 18.4866 10.6671 18.75 10.0041 18.75C9.34102 18.75 8.70513 18.4866 8.23629 18.0178C7.76745 17.5489 7.50406 16.913 7.50406 16.25C7.50406 15.587 7.76745 14.9511 8.23629 14.4822C8.70513 14.0134 9.34102 13.75 10.0041 13.75ZM20.0041 13.75C20.6671 13.75 21.303 14.0134 21.7718 14.4822C22.2407 14.9511 22.5041 15.587 22.5041 16.25C22.5041 16.913 22.2407 17.5489 21.7718 18.0178C21.303 18.4866 20.6671 18.75 20.0041 18.75C19.341 18.75 18.7051 18.4866 18.2363 18.0178C17.7675 17.5489 17.5041 16.913 17.5041 16.25C17.5041 15.587 17.7675 14.9511 18.2363 14.4822C18.7051 14.0134 19.341 13.75 20.0041 13.75ZM27.5041 16.25C27.5041 15.587 27.7675 14.9511 28.2363 14.4822C28.7051 14.0134 29.341 13.75 30.0041 13.75C30.6671 13.75 31.303 14.0134 31.7718 14.4822C32.2407 14.9511 32.5041 15.587 32.5041 16.25C32.5041 16.913 32.2407 17.5489 31.7718 18.0178C31.303 18.4866 30.6671 18.75 30.0041 18.75C29.341 18.75 28.7051 18.4866 28.2363 18.0178C27.7675 17.5489 27.5041 16.913 27.5041 16.25Z"
      fill="currentColor"
    />
  </svg>
)

export default MessageIcon
