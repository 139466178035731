import React from "react"

const FolderNotchOpenIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M6.50625 12H11.025C11.1711 11.9984 11.314 11.9562 11.4375 11.8781L13.3125 10.6219C13.436 10.5438 13.5789 10.5016 13.725 10.5H19.5V8.25C19.5 8.05109 19.421 7.86032 19.2803 7.71967C19.1397 7.57902 18.9489 7.5 18.75 7.5H12.2531C12.091 7.49933 11.9333 7.44677 11.8031 7.35L9.19687 5.4C9.06674 5.30323 8.90904 5.25067 8.74688 5.25H3.75C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V19.5L5.8125 12.4688C5.86735 12.3298 5.96296 12.2106 6.08678 12.1269C6.2106 12.0433 6.35682 11.999 6.50625 12Z"
      fill="currentColor"
    />
    <path
      d="M3 19.5V6C3 5.80109 3.07902 5.61032 3.21967 5.46967C3.36032 5.32902 3.55109 5.25 3.75 5.25H8.74688C8.90904 5.25067 9.06674 5.30323 9.19687 5.4L11.8031 7.35C11.9333 7.44677 12.091 7.49933 12.2531 7.5H18.75C18.9489 7.5 19.1397 7.57902 19.2803 7.71967C19.421 7.86032 19.5 8.05109 19.5 8.25V10.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 19.5L5.8125 12.4688C5.86735 12.3298 5.96296 12.2106 6.08678 12.1269C6.2106 12.0433 6.35682 11.999 6.50625 12H11.025C11.1711 11.9984 11.314 11.9562 11.4375 11.8781L13.3125 10.6219C13.436 10.5438 13.5789 10.5016 13.725 10.5H21.4594C21.578 10.5 21.6949 10.5281 21.8005 10.582C21.9061 10.636 21.9975 10.7142 22.067 10.8102C22.1365 10.9063 22.1823 11.0175 22.2005 11.1347C22.2188 11.2519 22.2089 11.3717 22.1719 11.4844L19.5 19.5H3Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FolderNotchOpenIcon
