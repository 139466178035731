import axios from "axios"

const getUncategorized = async (page, sources, callback) => {
  const { data } = await axios.request({
    url: `/v1/participants`,
    params: {
      page: page,
      per_page: 20,
      filters: sources,
      field: "last_sent",
      order: "desc",
    },
    method: "GET",
  })

  callback(data)
}

export default getUncategorized
