import React from "react"
import PropTypes from "prop-types"
import "./BulkStrengthAndImportanceSelector.styles.scss"
import StrengthIndicator from "../../../StrengthIndicator/StrengthIndicator.component"
import ImportanceIndicator from "../../../ImportanceIndicator/ImportanceIndicator.component"

const StrengthValues = ["na", "weak", "average", "strong", "very_strong"]
const ImportanceValues = [
  "na",
  "not_important",
  "medium",
  "important",
  "very_important",
]

const BulkStrengthAndImportanceSelector = ({
  onUpdateStrength,
  onUpdateImportance,
  disabled,
}) => {
  return (
    <div className="BulkStrengthAndImportanceSelector">
      <div className="BulkStrengthAndImportanceSelector_Strength">
        <h3>Strength</h3>
        {StrengthValues.map((v, i) => (
          <div
            key={i}
            onClick={() => onUpdateStrength(v)}
            className="BulkStrengthAndImportanceSelector_Strength_Level"
          >
            <StrengthIndicator key={i} disabled={disabled} level={v} />
          </div>
        ))}
      </div>
      <div className="BulkStrengthAndImportanceSelector_Importance">
        <h3>Importance</h3>
        {ImportanceValues.map((v, i) => (
          <div
            key={i}
            onClick={() => onUpdateImportance(v)}
            className="BulkStrengthAndImportanceSelector_Importance_Level"
          >
            <ImportanceIndicator key={i} disabled={disabled} level={v} />
          </div>
        ))}
      </div>
    </div>
  )
}

BulkStrengthAndImportanceSelector.propTypes = {
  onUpdateStrength: PropTypes.func.isRequired,
  onUpdateImportance: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default BulkStrengthAndImportanceSelector
