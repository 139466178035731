import React, { useState } from "react"
import PropTypes from "prop-types"
import "./BulkActionsButtonsGroup.styles.scss"
import { Popover } from "antd"
import SplitButton from "../SplitButton/SplitButton.component"
import { useSelector } from "react-redux"
import Icon from "@ant-design/icons"
import ConfirmModal from "./ConfirmModal/ConfirmModal.component"
import ArchiveBoxIcon from "../../../../Icons/ArchiveBox.icons"
import IntersectIcon from "../../../../Icons/Intersect.icons"
import EnvelopeIcon from "../../../../Icons/Envelope.icons"
import InfoIcon from "../../../../Icons/Info.icons"
import ChooseMainContactModal from "./ChooseMainContactModal/ChooseMainContactModal.component"

const BulkActionsButtonsGroup = ({
  onClickSplitMessageButton,
  onClickSplitMessageCCButton,
  onClickArchiveButton,
  onClickMergeButton,
}) => {
  const selectedContactsIds = useSelector(
    (state) => state.AppState.selected_contacts_ids
  )
  const splitMessagesLeftToday = useSelector(
    (state) => state.AccountsState.split_messages_left_today
  )
  const fetchingSelectedPeople = useSelector(
    (state) => state.PeopleState.fetching_selected
  )
  const [mergeConfirmModalVisible, setMergeConfirmModalVisible] = useState(false)
  const [archiveConfirmModalVisible, setArchiveConfirmModalVisible] = useState(false)

  const numberOfRecords = selectedContactsIds.length
  const disabledSplitMessageButton =
    numberOfRecords <= 0 ||
    numberOfRecords > 300 ||
    splitMessagesLeftToday - numberOfRecords < 0
  const disabledArchiveButton = numberOfRecords <= 0
  const disabledMergeButton = numberOfRecords <= 0

  return (
    <div className="BulkActionsButtonsGroup">
      <ChooseMainContactModal
        visible={mergeConfirmModalVisible}
        onCancel={() => setMergeConfirmModalVisible(false)}
        onConfirm={onClickMergeButton}
      />
      <ConfirmModal
        title="Are you sure you want to archive?"
        description={
          "You can find contacts you archived by using the Filters tab in your database."
        }
        visible={archiveConfirmModalVisible}
        onCancel={() => setArchiveConfirmModalVisible(false)}
        onConfirm={onClickArchiveButton}
      />
      {numberOfRecords > 300 ? (
        <Popover
          placement="left"
          overlayClassName="BulkActionsButtonsGroup_Popover"
          title={
            <span className="BulkActionsButtonsGroup_Popover_Title">
              Why can’t I save this Split Message?
            </span>
          }
          content={
            <div className="BulkActionsButtonsGroup_Popover_Content">
              To protect your account from being marked as spam can only message 300
              contacts at a time.
            </div>
          }
        >
          <div>
            <SplitButton disabled={true}>
              <Icon className="SplitButton_Icon" component={EnvelopeIcon} />
              Split Message
            </SplitButton>
            <button
              className="SplitLinkButton SplitLinkButton--disabled"
              disabled={true}
            >
              Or CC everyone instead
            </button>
          </div>
        </Popover>
      ) : (
        <Popover
          placement="left"
          overlayClassName="BulkActionsButtonsGroup_Popover"
          content={
            <div className="BulkActionsButtonsGroup_Popover_Content">
              To prevent abuse and getting flagged for spam, you can split message up
              to 300 people a day.
            </div>
          }
        >
          <div>
            <SplitButton
              disabled={disabledSplitMessageButton}
              fillColor="lightGreen"
              loading={fetchingSelectedPeople}
              onClick={onClickSplitMessageButton}
              loadingBar={splitMessagesLeftToday}
            >
              <Icon className="SplitButton_Icon" component={EnvelopeIcon} />
              {`Split Message ${splitMessagesLeftToday} left today`}
              <Icon className="SplitButton_InfoIcon" component={InfoIcon} />
            </SplitButton>
            <button
              className="SplitLinkButton"
              disabled={fetchingSelectedPeople}
              onClick={onClickSplitMessageCCButton}
            >
              Or CC everyone instead
            </button>
          </div>
        </Popover>
      )}
      <SplitButton
        disabled={disabledArchiveButton}
        onClick={() => setArchiveConfirmModalVisible(true)}
        fillColor="lightRed"
      >
        <Icon component={ArchiveBoxIcon} className="SplitButton_Icon" />
        {`Archive ${numberOfRecords} contacts`}
      </SplitButton>
      <SplitButton
        disabled={disabledMergeButton}
        onClick={() => setMergeConfirmModalVisible(true)}
        fillColor="blue"
      >
        <Icon component={IntersectIcon} className="SplitButton_Icon" />
        {`Merge ${numberOfRecords} contacts`}
      </SplitButton>
    </div>
  )
}

BulkActionsButtonsGroup.propTypes = {
  onClickSplitMessageButton: PropTypes.func.isRequired,
  onClickSplitMessageCCButton: PropTypes.func.isRequired,
  onClickArchiveButton: PropTypes.func.isRequired,
  onClickMergeButton: PropTypes.func.isRequired,
}

export default BulkActionsButtonsGroup
