import React, { useEffect, useState } from "react"
import "./TemplateEditor.styles.scss"
import { useFormik } from "formik"
import { Divider, Input } from "antd"
import AttachmentsUpload from "../../../UI/AttachmentsUpload/AttachmentsUpload.component"
import EmailComposerModal from "../../../UI/EmailComposerModal/EmailComposerModal.component"
import TinyMCUEditor from "../../../UI/TinyMCUEditor/TinyMCUEditor"

const TemplateEditor = ({ template, onSave, onChange, onCancel, show }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      subject: "",
      body: "",
    },
  })

  const [body, setBody] = useState(null)
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (template) {
      setBody(template.body)
      setFileList(template.attachments || [])
      formik.setValues({
        name: template.name,
        subject: template.subject,
        body: template.body,
      })
    }
    // eslint-disable-next-line
  }, [template])

  return (
    <EmailComposerModal
      title={`Customize email to anyone!`}
      action={"saving"}
      visible={show}
      cancelHandler={() => onCancel()}
    >
      <div className={"TemplateEditor"}>
        <div className={"TemplateEditor_FormWrapper"} style={{ padding: "16px" }}>
          <Input
            key="name"
            name="name"
            placeholder={"Name"}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            bordered={false}
          />
          <Divider />

          <Input
            key="subject"
            name="subject"
            bordered={false}
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={"Subject"}
          />
          <Divider />

          <TinyMCUEditor
            mode="text"
            templateId={template?.id}
            extras={[
              <AttachmentsUpload
                fileList={fileList}
                handleFileList={(newValues) => {
                  setFileList(newValues)
                }}
              />,
            ]}
            updateBody={(html) => {
              setBody(html)
              formik.setFieldValue("body", html)
            }}
            body={body}
            toolbarId="templateEditor"
          />
          <div
            onClick={() => {
              onSave({
                ...formik.values,
                id: template.id,
                new_files:
                  fileList?.filter((x) => !template.attachments?.includes(x)) ||
                  fileList,
                deleted_files:
                  template.attachments?.filter((x) => !fileList.includes(x)) || [],
              })
            }}
            className={"TemplateEditor_SubmitButton"}
          >
            Save
          </div>
        </div>
      </div>
    </EmailComposerModal>
  )
}

export default TemplateEditor
