import React, { useState } from "react"
import PropTypes from "prop-types"
import "./SectionReminders.styles.scss"
import Icon, { LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import DashboardReminder from "../../../Reminders/DashboardReminder.component"
import PlusInSquareIcons from "../../../../Icons/PlusInSquare.icons"
import { Pagination, Tooltip } from "antd"
import { openDrawerTab } from "../../../../../redux/App/App.actions"

const SectionReminders = ({ contact, loadMore, hideAddButton = false }) => {
  const [page, setPage] = useState(1)

  const loading = useSelector((state) => state.InteractionsState.fetching_reminders)

  const dispatch = useDispatch()

  return (
    <section id="sectionReminders">
      <h3 className="SectionTitle">
        Reminders
        {!hideAddButton && (
          <Tooltip title="Add new reminder" placement="topRight">
            <Icon
              onClick={() => dispatch(openDrawerTab("task"))}
              component={PlusInSquareIcons}
            />
          </Tooltip>
        )}
      </h3>
      <div className="SectionReminders">
        {loading ? (
          <div className="SectionReminders_Loading">
            <LoadingOutlined />
            <span>Loading...</span>
          </div>
        ) : contact.reminders_total_entries > 0 ? (
          <div className="SectionReminders_Reminders">
            {contact.reminders.map((reminder) => (
              <DashboardReminder
                key={reminder.id}
                reminder={reminder}
                index={reminder.id}
                size="small"
              />
            ))}
            {contact.reminders_total_entries > 3 && (
              <div className="Section_Pagination">
                <span>More Reminders: </span>
                <Pagination
                  showSizeChanger={false}
                  current={page}
                  onChange={(newPage) => {
                    loadMore(contact.id, newPage)
                    setPage(newPage)
                  }}
                  hideOnSinglePage={true}
                  total={contact.reminders_total_entries}
                  defaultPageSize={3}
                />
              </div>
            )}
          </div>
        ) : (
          <span className="inactive">Reminders have not been added</span>
        )}
      </div>
    </section>
  )
}

SectionReminders.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    reminders: PropTypes.array,
    reminders_total_entries: PropTypes.number,
  }),
  loadMore: PropTypes.func.isRequired,
  hideAddButton: PropTypes.bool,
}
export default SectionReminders
