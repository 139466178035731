import React, { useState } from "react"
import "./AccountNavSections.styles.scss"
import { Link } from "react-scroll"

const AccountNavSections = ({ tabs, containerId, defaultTab = null }) => {
  const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : tabs[0])

  return (
    <div className="AccountNavSections">
      {tabs.map((tab) => (
        <Link
          style={{ width: tab.width }}
          key={tab.id}
          className={activeTab.id === tab.id ? `active` : ""}
          smooth
          spy
          duration={200}
          offset={-15}
          containerId={containerId}
          onSetActive={(value) => {
            const selectedTab = tabs.find((t) => `section${t.id}` === value)
            setActiveTab(selectedTab)
          }}
          isDynamic
          name={`section${tab.id}`}
          to={`section${tab.id}`}
        >
          {tab.name}
        </Link>
      ))}
      <hr
        style={{ width: activeTab.width, left: activeTab.paddingLeft }}
        className={`AccountNavSections_ActiveLine  AccountNavSections_ActiveLine--${activeTab}`}
      />
    </div>
  )
}

export default AccountNavSections
