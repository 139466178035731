import {
  ADD_TEAM_MEMBER,
  ADD_TEAM_MEMBER_FAIL,
  ADD_TEAM_MEMBER_SUCCESS,
  AUTHENTICATE_USER_SUCCESS,
  CANCEL_TEAM_INVITE_FAIL,
  CANCEL_TEAM_INVITE_SUCCESS,
  CLEAR_REDUX_USER_STATE,
  CREATE_API_KEY,
  CREATE_API_KEY_FAIL,
  CREATE_API_KEY_SUCCESS,
  CREATE_TEAM,
  CREATE_TEAM_FAIL,
  CREATE_TEAM_SUCCESS,
  DELETE_API_KEY,
  DELETE_API_KEY_FAIL,
  DELETE_API_KEY_SUCCESS,
  DELETE_TEAM,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_SUCCESS,
  FETCH_PARTNER,
  FETCH_PARTNER_FAIL,
  FETCH_PARTNER_SUCCESS,
  FETCH_TEAM,
  FETCH_TEAM_FAIL,
  FETCH_TEAM_SUCCESS,
  GET_API_KEYS,
  GET_API_KEYS_FAIL,
  GET_API_KEYS_SUCCESS,
  GET_STATUS_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_FINISHED_TASKS,
  GET_USER_FINISHED_TASKS_FAIL,
  GET_USER_FINISHED_TASKS_SUCCESS,
  JOIN_TO_TEAM,
  JOIN_TO_TEAM_FAIL,
  JOIN_TO_TEAM_SUCCESS,
  LEAVE_TEAM,
  LEAVE_TEAM_FAIL,
  LEAVE_TEAM_SUCCESS,
  LOGOUT,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  REGENERATE_API_KEY,
  REGENERATE_API_KEY_FAIL,
  REGENERATE_API_KEY_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  REMOVE_MEMBER_TEAM_FAIL,
  REMOVE_MEMBER_TEAM_SUCCESS,
  REMOVE_TEAM_INVITATION,
  REMOVE_TEAM_INVITATION_FAIL,
  REMOVE_TEAM_INVITATION_SUCCESS,
  RESET_LOGGING_OUT_STATE,
  SET_INITIAL_REDUX_USER_STATE,
  SET_REDIRECTING,
  SET_REFRESHING,
  UPDATE_AVATAR,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_PARTNER,
  UPDATE_PARTNER_FAIL,
  UPDATE_PARTNER_LOGO,
  UPDATE_PARTNER_LOGO_FAIL,
  UPDATE_PARTNER_LOGO_SUCCESS,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_TEAM,
  UPDATE_TEAM_FAIL,
  UPDATE_TEAM_SETTINGS,
  UPDATE_TEAM_SETTINGS_FAIL,
  UPDATE_TEAM_SETTINGS_SUCCESS,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TIMEZONE,
} from "./User.types"

const INITIAL_STATE = {
  id: null,
  role: null,
  access_token: null,
  refresh_token: null,
  first_name: null,
  last_name: null,
  email: null,
  avatar: null,
  updating: false,
  loggingOut: false,
  redirecting: false,
  status: null,
  created_at: null,
  is_trialing: false,
  billing_account_status: null,
  phone_number: {},
  daily_recommendations: true,
  track_opened: false,
  leaderboard_name: null,
  public_username: null,
  api_keys: [],
  trial_days_remaining: null,
  has_push_registration: false,
  signingUp: false,
  finished_tasks: [],
  finished_tasks_loading: false,
  time_zone: "US/Eastern",
  loading_api_keys: false,
  show_focus_mode: false,
  show_onboarding_flow: false,
  teams: [],
  team_invitations: [],
  fetching_team: true,
  updating_team: false,
  partner: null,
  partner_users: [],
  loading_partner_users: false,
  partner_logo: null,
  partner_welcome_videos: [],
}

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        id: action.id,
        email: action.email,
        first_name: action.first_name,
        last_name: action.last_name,
        avatar: action.avatar,
        access_token: action.token,
        refresh_token: action.refresh,
        role: action.role,
        phone_number: action.phone_number,
        daily_recommendations: action.daily_recommendations,
        track_opened: action.track_opened,
        bio: action.bio,
        leaderboard_name: action.leaderboard_name,
        refreshing: false,
        redirecting: false,
      }
    case REGISTER_USER:
      return {
        ...state,
        signingUp: true,
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        id: action.id,
        email: action.email,
        first_name: action.first_name,
        last_name: action.last_name,
        avatar: action.avatar,
        role: action.role,
        phone_number: action.phone_number,
        daily_recommendations: action.daily_recommendations,
        track_opened: action.track_opened,
        leaderboard_name: action.leaderboard_name,
        trial_days_remaining: action.trial_days_remaining,
        signingUp: false,
      }
    case REGISTER_USER_FAIL:
      return {
        ...state,
        signingUp: false,
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.values,
      }
    case UPDATE_AVATAR:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_PARTNER:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_PARTNER_SUCCESS:
      return {
        ...state,
        updating: false,
        partner: action.partner,
        partner_welcome_videos: action.partner.welcome_videos,
        partner_logo: action.partner.logo,
      }
    case UPDATE_PARTNER_FAIL:
      return {
        ...state,
        updating: false,
      }
    case UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        ...action.user,
        updating: false,
      }
    case UPDATE_AVATAR_FAIL:
      return {
        ...state,
        updating: false,
      }
    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
      }
    case LOGOUT_START:
      return {
        ...state,
        access_token: null,
        refresh_token: null,
      }
    case LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        loggingOut: false,
      }
    case RESET_LOGGING_OUT_STATE:
      return {
        ...state,
        loggingOut: false,
      }
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
        role: action.role,
        created_at: action.created_at,
        is_trialing: action.is_trialing,
        billing_account_status: action.billing_account_status,
        trial_days_remaining: action.trial_days_remaining,
        has_push_registration: action.has_push_registration,
        partner: action.partner,
        partner_logo: action.partner_logo,
        partner_welcome_videos: action.partner_welcome_videos,
      }
    case SET_REFRESHING:
      return {
        ...state,
        refreshing: action.refreshing,
      }
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        id: action.id,
        email: action.email,
        first_name: action.first_name,
        last_name: action.last_name,
        avatar: action.avatar,
        phone_number: action.phone_number,
        daily_recommendations: action.daily_recommendations,
        track_opened: action.track_opened,
        bio: action.bio,
        leaderboard_name: action.leaderboard_name,
        public_username: action.public_username,
        trial_days_remaining: action.trial_days_remaining,
        time_zone: action.time_zone,
        qr_code: action.qr_code,
        profile_filler_sphere: action.profile_filler_sphere,
        api_key: action.api_key,
        show_focus_mode: action.show_focus_mode,
        show_onboarding_flow: action.show_onboarding_flow,
        partner: action.partner,
        partner_logo: action.partner_logo,
        partner_welcome_videos: action.partner_welcome_videos,
      }
    case GET_USER_FINISHED_TASKS:
      return {
        ...state,
        finished_tasks_loading: true,
      }
    case GET_USER_FINISHED_TASKS_SUCCESS:
      return {
        ...state,
        finished_tasks: action.finished_tasks,
        finished_tasks_loading: false,
      }
    case GET_USER_FINISHED_TASKS_FAIL:
      return {
        ...state,
        finished_tasks_loading: false,
      }
    case CLEAR_REDUX_USER_STATE:
      return {}
    case SET_INITIAL_REDUX_USER_STATE:
      return { ...INITIAL_STATE }
    case UPDATE_TIMEZONE:
      return {
        ...state,
        time_zone: action.time_zone,
      }
    case GET_API_KEYS:
      return {
        ...state,
        loading_api_keys: true,
      }
    case GET_API_KEYS_SUCCESS:
      return {
        ...state,
        api_keys: action.api_keys,
        loading_api_keys: false,
      }
    case GET_API_KEYS_FAIL:
      return {
        ...state,
        loading_api_keys: false,
      }
    case CREATE_API_KEY:
      return {
        ...state,
        loading_api_keys: true,
      }
    case CREATE_API_KEY_SUCCESS:
      return {
        ...state,
        api_keys: action.api_keys,
        loading_api_keys: false,
      }
    case CREATE_API_KEY_FAIL:
      return {
        ...state,
        loading_api_keys: false,
      }
    case REGENERATE_API_KEY:
      return {
        ...state,
        loading_api_keys: true,
      }
    case REGENERATE_API_KEY_SUCCESS:
      return {
        ...state,
        api_keys: action.api_keys,
        loading_api_keys: false,
      }
    case REGENERATE_API_KEY_FAIL:
      return {
        ...state,
        loading_api_keys: false,
      }
    case UPDATE_PARTNER_LOGO:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_PARTNER_LOGO_SUCCESS:
      return {
        ...state,
        partner: action.partner,
        updating: false,
      }
    case UPDATE_PARTNER_LOGO_FAIL:
      return {
        ...state,
        updating: false,
      }
    case FETCH_PARTNER:
      return {
        ...state,
        loading_partner_users: true,
      }
    case FETCH_PARTNER_SUCCESS:
      return {
        ...state,
        partner_users: action.partner_users,
        loading_partner_users: false,
      }
    case FETCH_PARTNER_FAIL:
      return {
        ...state,
        loading_partner_users: false,
      }
    case DELETE_API_KEY:
      return {
        ...state,
        loading_api_keys: true,
      }
    case DELETE_API_KEY_SUCCESS:
      return {
        ...state,
        api_keys: action.api_keys,
        loading_api_keys: false,
      }
    case DELETE_API_KEY_FAIL:
      return {
        ...state,
        loading_api_keys: false,
      }
    case CREATE_TEAM:
      return {
        ...state,
        fetching_team: true,
      }
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        fetching_team: false,
        teams: [...state.teams, action.team],
      }
    case CREATE_TEAM_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case DELETE_TEAM:
      return {
        ...state,
        fetching_team: true,
      }
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        fetching_team: false,
        teams: state.teams.filter((team) => team.id !== action.removed_team_id),
      }
    case DELETE_TEAM_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case LEAVE_TEAM:
      return {
        ...state,
        fetching_team: true,
      }
    case LEAVE_TEAM_SUCCESS:
      return {
        ...state,
        fetching_team: false,
        teams: state.teams.filter((team) => team.id !== action.leaved_team_id),
      }
    case LEAVE_TEAM_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case ADD_TEAM_MEMBER:
      return {
        ...state,
        fetching_team: true,
      }
    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        fetching_team: false,
        teams: state.teams.map((team) => {
          if (team.id === action.team.id) {
            return action.team
          }
          return team
        }),
      }
    case ADD_TEAM_MEMBER_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case REMOVE_MEMBER_TEAM_SUCCESS:
      return {
        ...state,
        fetching_team: false,
        teams: state.teams.map((team) => {
          if (team.id === action.team_id) {
            return {
              ...team,
              members: team.members.filter(
                (member) => member.id !== action.member_id
              ),
            }
          }
          return team
        }),
      }
    case REMOVE_MEMBER_TEAM_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case CANCEL_TEAM_INVITE_SUCCESS:
      return {
        ...state,
        fetching_team: false,
        teams: state.teams.map((team) => {
          if (team.id === action.team_id) {
            return {
              ...team,
              invited_members: team.invited_members.filter(
                (i_m) => i_m.email !== action.member_email
              ),
            }
          }
          return team
        }),
      }
    case CANCEL_TEAM_INVITE_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case UPDATE_TEAM:
      return {
        ...state,
        fetching_team: true,
      }
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        fetching_team: false,
        teams: action.teams,
      }
    case UPDATE_TEAM_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case FETCH_TEAM:
      return {
        ...state,
        fetching_team: true,
      }
    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        teams: action.teams,
        team_invitations: action.team_invitations,
        fetching_team: false,
        updating_team: false,
      }
    case FETCH_TEAM_FAIL:
      return {
        ...state,
        fetching_team: false,
        updating_team: false,
      }
    case JOIN_TO_TEAM:
      return {
        ...state,
        fetching_team: true,
      }
    case JOIN_TO_TEAM_SUCCESS:
      return {
        ...state,
        teams: action.teams,
        team_invitations: action.team_invitations,
        fetching_team: false,
      }
    case JOIN_TO_TEAM_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case REMOVE_TEAM_INVITATION:
      return {
        ...state,
        fetching_team: true,
      }
    case REMOVE_TEAM_INVITATION_SUCCESS:
      return {
        ...state,
        team_invitations: action.team_invitations,
        fetching_team: false,
      }
    case REMOVE_TEAM_INVITATION_FAIL:
      return {
        ...state,
        fetching_team: false,
      }
    case UPDATE_TEAM_SETTINGS:
      return {
        ...state,
        updating_team: true,
      }
    case UPDATE_TEAM_SETTINGS_SUCCESS:
      return {
        ...state,
        teams: state.teams.map((team) => {
          if (team.id === action.team_id) {
            return {
              ...team,
              settings: action.new_settings,
            }
          }
          return team
        }),
        updating_team: false,
      }
    case UPDATE_TEAM_SETTINGS_FAIL:
      return {
        ...state,
        updating_team: false,
      }
    case SET_REDIRECTING:
      return {
        ...state,
        redirecting: action.redirecting,
      }
    default:
      return state
  }
}
