import {
  CLEAR_REDUX_ACTIVITIES_STATE,
  FETCH_ACTIVE_CHALLENGES,
  FETCH_ACTIVE_CHALLENGES_FAIL,
  FETCH_ACTIVE_CHALLENGES_SUCCESS,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_FAIL,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_CHALLENGE,
  FETCH_CHALLENGE_FAIL,
  FETCH_CHALLENGE_SUCCESS,
  FETCH_POINTS,
  FETCH_POINTS_FAIL,
  FETCH_POINTS_SUCCESS,
  JOIN_TO_CHALLENGE,
  JOIN_TO_CHALLENGE_FAIL,
  JOIN_TO_CHALLENGE_SUCCESS,
  SET_INITIAL_REDUX_ACTIVITIES_STATE,
} from "./Activities.types"

export const fetchActivities = (page, per_page) => {
  return {
    type: FETCH_ACTIVITIES,
    page: page,
    per_page: per_page,
  }
}

export const fetchActivitiesSuccess = (activities, total_entries) => {
  return {
    type: FETCH_ACTIVITIES_SUCCESS,
    activities: activities,
    total_entries: total_entries,
  }
}

export const fetchActivitiesFail = (error) => {
  return {
    type: FETCH_ACTIVITIES_FAIL,
    error: error,
  }
}

export const fetchPoints = (date_filter, option = "me") => {
  return {
    type: FETCH_POINTS,
    date_filter: date_filter,
    option: option,
  }
}

export const fetchPointsSuccess = (points, leaderboard) => {
  return {
    type: FETCH_POINTS_SUCCESS,
    points: points,
    leaderboard: leaderboard,
  }
}

export const fetchPointsFail = (error) => {
  return {
    type: FETCH_POINTS_FAIL,
    error: error,
  }
}

export const clearReduxActivitiesState = () => {
  return {
    type: CLEAR_REDUX_ACTIVITIES_STATE,
  }
}

export const setInitialReduxActivitiesState = () => {
  return {
    type: SET_INITIAL_REDUX_ACTIVITIES_STATE,
  }
}

export const fetchActiveChallenges = () => {
  return {
    type: FETCH_ACTIVE_CHALLENGES,
  }
}

export const fetchActiveChallengesSuccess = (challenges) => {
  return {
    type: FETCH_ACTIVE_CHALLENGES_SUCCESS,
    challenges: challenges,
  }
}

export const fetchActiveChallengesFail = (error) => {
  return {
    type: FETCH_ACTIVE_CHALLENGES_FAIL,
    error: error,
  }
}

export const fetchChallenge = (id) => {
  return {
    type: FETCH_CHALLENGE,
    id: id,
  }
}

export const fetchChallengeSuccess = (challenge) => {
  return {
    type: FETCH_CHALLENGE_SUCCESS,
    challenge: challenge,
  }
}

export const fetchChallengeFail = (error) => {
  return {
    type: FETCH_CHALLENGE_FAIL,
    error: error,
  }
}

export const joinToChallenge = (id) => {
  return {
    type: JOIN_TO_CHALLENGE,
    id: id,
  }
}

export const joinToChallengeSuccess = (challenge) => {
  return {
    type: JOIN_TO_CHALLENGE_SUCCESS,
    challenge: challenge,
  }
}

export const joinToChallengeFail = (error) => {
  return {
    type: JOIN_TO_CHALLENGE_FAIL,
    error: error,
  }
}
