import React from "react"

const HourglassIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16703 3.3125H13.828C13.9395 3.31269 14.0485 3.34603 14.141 3.40829C14.2336 3.47055 14.3055 3.55892 14.3477 3.66217C14.3899 3.76541 14.4005 3.87888 14.378 3.98813C14.3556 4.09739 14.3012 4.19752 14.2217 4.27578L8.9975 9.5L3.77328 4.27578C3.69381 4.19752 3.6394 4.09739 3.61696 3.98813C3.59453 3.87888 3.60509 3.76541 3.64729 3.66217C3.68949 3.55892 3.76144 3.47055 3.85398 3.40829C3.94653 3.34603 4.05549 3.31269 4.16703 3.3125V3.3125Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16703 15.6875H13.828C13.9395 15.6873 14.0485 15.654 14.141 15.5917C14.2336 15.5294 14.3055 15.4411 14.3477 15.3378C14.3899 15.2346 14.4005 15.1211 14.378 15.0119C14.3556 14.9026 14.3012 14.8025 14.2217 14.7242L8.9975 9.5L3.77328 14.7242C3.69381 14.8025 3.6394 14.9026 3.61696 15.0119C3.59453 15.1211 3.60509 15.2346 3.64729 15.3378C3.68949 15.4411 3.76144 15.5294 3.85398 15.5917C3.94653 15.654 4.05549 15.6873 4.16703 15.6875Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.375 6.125H5.625"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9.5V12.3125"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HourglassIcon
