import React from "react"

const GiftIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 7.5H3.75C3.33579 7.5 3 7.83579 3 8.25V11.25C3 11.6642 3.33579 12 3.75 12H20.25C20.6642 12 21 11.6642 21 11.25V8.25C21 7.83579 20.6642 7.5 20.25 7.5Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 12V18.75C19.5 18.9489 19.421 19.1397 19.2803 19.2803C19.1397 19.421 18.9489 19.5 18.75 19.5H5.25C5.05109 19.5 4.86032 19.421 4.71967 19.2803C4.57902 19.1397 4.5 18.9489 4.5 18.75V12"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 7.5V19.5"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2469 6.44043C15.1781 7.4998 12 7.4998 12 7.4998C12 7.4998 12 4.32168 13.0594 3.25293C13.4821 2.83024 14.0554 2.59277 14.6531 2.59277C15.2509 2.59277 15.8242 2.83024 16.2469 3.25293C16.6696 3.67562 16.907 4.2489 16.907 4.84668C16.907 5.44445 16.6696 6.01774 16.2469 6.44043V6.44043Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.7539 6.44043C8.82265 7.4998 12.0008 7.4998 12.0008 7.4998C12.0008 7.4998 12.0008 4.32168 10.9414 3.25293C10.5187 2.83024 9.94542 2.59277 9.34765 2.59277C8.74988 2.59277 8.17659 2.83024 7.7539 3.25293C7.33121 3.67562 7.09375 4.2489 7.09375 4.84668C7.09375 5.44445 7.33121 6.01774 7.7539 6.44043V6.44043Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GiftIcon
