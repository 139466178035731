import axios from "axios"

const getSearchedSources = async (searchQuery, callback) => {
  const { data } = await axios.request({
    url:
      searchQuery && searchQuery.length > 0
        ? "/v1/collections/sources/autocomplete"
        : "v1/collections/sources/popular",
    params: {
      q: searchQuery,
    },
    method: "GET",
  })

  const sources = data.data.map((source) => ({
    label: source.title,
    value: source.id
      ? "sourceId_" + source.id
      : "sourcePersonId_" + source.source_person_id,
  }))

  callback(sources)
}

export default getSearchedSources
