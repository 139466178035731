import React from "react"
import "./ChallengeTile.styles.scss"
import PropTypes from "prop-types"
import Icon from "@ant-design/icons"
import ProgressBar from "../../../../../components/UI/ProgressBar/ProgressBar.component"
import { ReactComponent as ConquerPeak } from "../../../../../images/ConquerPeak.svg"
import CaretRightIcon from "../../../../../components/Icons/CaretRight.icons"

const ChallengeTile = ({ id, title, one_liner, progress, openModal }) => {
  return (
    <div className="ChallengeTile" onClick={() => openModal(id)}>
      <ConquerPeak />
      <div>
        <h1
          className={
            progress === null
              ? "ChallengeTile_Title"
              : "ChallengeTile_Title--marginBottom"
          }
        >
          {title}
        </h1>
        {progress === null ? (
          <span>{one_liner}</span>
        ) : (
          <ProgressBar progress={progress} />
        )}
      </div>
      <button className="ChallengeTile_LearnMore">
        <Icon component={CaretRightIcon} />
      </button>
    </div>
  )
}

ChallengeTile.propTypes = {
  title: PropTypes.string.isRequired,
  one_liner: PropTypes.string.isRequired,
  progress: PropTypes.number,
  openModal: PropTypes.func.isRequired,
}
export default ChallengeTile
