import React, { useEffect, useState } from "react"
import { Input } from "antd"

const RemindStep = ({ step, onUpdate }) => {
  const [content, setContent] = useState("")

  useEffect(() => {
    if (step?.configuration?.content) setContent(step.configuration.content)
  }, [step])

  useEffect(() => {
    onUpdate({ content })
    // eslint-disable-next-line
  }, [content])

  const onChangeContent = (text) => {
    setContent(text)
  }

  return (
    <div className={"FlowSphere_StepContent"}>
      <span>Remind</span>
      <Input
        placeholder="me to..."
        key={`remind[${step.id}]`}
        name={`remind[${step.id}]`}
        value={content}
        onChange={(e) => onChangeContent(e.target.value)}
      />
    </div>
  )
}

export default RemindStep
