export const FETCH_INTRODUCTIONS = "FETCH_INTRODUCTIONS"
export const FETCH_INTRODUCTIONS_SUCCESS = "FETCH_INTRODUCTIONS_SUCCESS"
export const FETCH_INTRODUCTIONS_FAIL = "FETCH_INTRODUCTIONS_FAIL"

export const ADD_INTRODUCTION = "ADD_INTRODUCTION"
export const ADD_INTRODUCTION_SUCCESS = "ADD_INTRODUCTION_SUCCESS"
export const ADD_INTRODUCTION_FAIL = "ADD_INTRODUCTION_FAIL"

export const APPROVE_INTRODUCTION = "APPROVE_INTRODUCTION"
export const APPROVE_INTRODUCTION_SUCCESS = "APPROVE_INTRODUCTION_SUCCESS"
export const APPROVE_INTRODUCTION_FAIL = "APPROVE_INTRODUCTION_FAIL"

export const DELETE_INTRODUCTION = "DELETE_INTRODUCTION"
export const DELETE_INTRODUCTION_SUCCESS = "DELETE_INTRODUCTION_SUCCESS"
export const DELETE_INTRODUCTION_FAIL = "DELETE_INTRODUCTION_FAIL"

export const CLEAR_REDUX_INTRODUCTIONS_STATE = "CLEAR_REDUX_INTRODUCTIONS_STATE"
export const SET_INITIAL_REDUX_INTRODUCTIONS_STATE =
  "SET_INITIAL_REDUX_INTRODUCTIONS_STATE"
