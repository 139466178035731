import axios from "axios"

export const fetchSharingDetails = async (token, callback) => {
  const { data } = await axios.request({
    url: `/v1/collection_sharings/${token}`,
  })
  callback(data)
}

export const ignoreSharing = async (token, callback) => {
  const { data } = await axios.request({})

  callback(data)
}
