import React, { useEffect, useState } from "react"
import "./Database.styles.scss"
import { Button, Checkbox, Dropdown, Menu, Popover, Upload } from "antd"

import PeopleTable from "../../components/Database/PeopleTable/PeopleTable.component"
import ParticipantsTable from "../../components/Uncategorized/ParticipantsTable/ParticipantsTable.component"
import FiltersDrawer from "../../components/Database/FiltersDrawer/FiltersDrawer.component"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchCompanies,
  fetchExpertises,
  fetchLocations,
  fetchPopularCompanies,
  fetchPopularExpertises,
  fetchPopularLocations,
  fetchPopularSources,
  fetchPopularTopics,
  fetchSources,
  fetchSpheres,
  fetchTopics,
} from "../../redux/Collections/Collections.actions"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setCurrentLocationKey,
  setRightRailCollapsed,
  setSphereModalVisible,
  setUserPanelModalVisible,
  setVisibleContactID,
} from "../../redux/App/App.actions"
import SelectedDrawer from "../../components/Database/BulkActionsDrawer/BulkActionsDrawer.component"
import { ArrayParam, StringParam, useQueryParam } from "use-query-params"
import {
  exportContacts,
  fetchDuplicatesCount,
  fetchEnrichmentCount,
  fetchPeople,
} from "../../redux/People/People.actions"
import { fetchParticipants } from "../../redux/Participants/Participants.actions"
import { Link } from "react-router-dom"
import BulkActionsDrawer from "../../components/Uncategorized/BulkActionsDrawer/BulkActionsDrawer.component"
import SearchPeople from "../../components/UI/SearchPeople/SearchPeople.component"
import PlusCircleIcon from "../../components/Icons/PlusCircle.icons"
import SlidersHorizontalIcon from "../../components/Icons/SlidersHorizontal.icons"
import Icon, { MoreOutlined, ShareAltOutlined } from "@ant-design/icons"
import { CSSTransition } from "react-transition-group"
import { ReactComponent as SuperSmartDB } from "./../../images/SuperSmartDB.svg"
import { ReactComponent as PeopleWithMagnifyingGlass } from "./../../images/PeopleWithMagnifyingGlass.svg"
import { nylasAuthenticationURI } from "../../config/nylas"
import { fetchAccounts } from "../../redux/Accounts/Accounts.actions"
import BoltLightningIcon from "../../components/Icons/BoltLightning.icons"
import StartBuildingCard from "../../components/Database/./StartBuildingCard/StartBuildingCard.component"
import SingleSphereCard from "../../components/Database/SingleSphereCard/SingleSphereCard.component"
import axios from "axios"
import { useHistory } from "react-router"

const DatabasePage = () => {
  const dispatch = useDispatch()

  const [filtersOpened, setFiltersOpened] = useState(false)
  const [selectedOpened, setSelectedOpened] = useState(false)
  const [currentSpheres, setCurrentSpheres] = useState([])
  const [selectedSphere, setSelectedSphere] = useState(null)
  const [people_filters] = useQueryParam("people_filters", ArrayParam)
  const [participants_filters] = useQueryParam("participants_filters", ArrayParam)
  const [q] = useQueryParam("q", StringParam)

  const history = useHistory()

  const router = useSelector((state) => state.router)
  const locationKey = useSelector((state) => state.AppState.currentLocationKey)
  const showStartBuildingCard = useSelector(
    (state) => state.AppState.showStartBuildingCard
  )
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )

  const selectedContactsIds = useSelector(
    (state) => state.AppState.selected_contacts_ids
  )

  const people = useSelector((state) => state.PeopleState.people)
  const fetching = useSelector((state) => state.PeopleState.fetching)
  const total_entries = useSelector((state) => state.PeopleState.total_entries)
  const splitMessageModalVisible = useSelector(
    (state) => state.AppState.splitMessageSenderVisible
  )

  const participants = useSelector((state) => state.ParticipantsState.participants)
  const topics = useSelector((state) => state.CollectionsState.topics)
  const sources = useSelector((state) => state.CollectionsState.sources)
  const expertises = useSelector((state) => state.CollectionsState.expertises)
  const popularExpertises = useSelector(
    (state) => state.CollectionsState.popular_expertises
  )

  const popularTopics = useSelector((state) => state.CollectionsState.popular_topics)
  const popularSources = useSelector(
    (state) => state.CollectionsState.popular_sources
  )
  const companies = useSelector((state) => state.CollectionsState.companies)
  const spheres = useSelector((state) => state.CollectionsState.spheres)

  const selectedParticipantsIds = useSelector(
    (state) => state.AppState.selected_participants_ids
  )
  const duplicatesTotalEntries = useSelector(
    (state) => state.PeopleState.duplicatesTotalEntries
  )

  const peopleEnrichmentsCount = useSelector(
    (state) => state.PeopleState.enrichments_total_entries
  )

  const popularCompanies = useSelector(
    (state) => state.CollectionsState.popular_companies
  )

  const locations = useSelector((state) => state.CollectionsState.locations)
  const popularLocations = useSelector(
    (state) => state.CollectionsState.popular_locations
  )
  const rightRailCollapsed = useSelector(
    (state) => state.AppState.rightRailCollapsed
  )
  const user = useSelector((state) => state.UserState)
  const [prevRightRailCollapsed, setPrevRightRailCollapsed] = useState(false)
  const [participantsTableIsEmpty, setParticipantsTableIsEmpty] = useState(false)

  useEffect(() => {
    if (contactDrawerVisible || filtersOpened || selectedOpened) {
      setPrevRightRailCollapsed(rightRailCollapsed)
      dispatch(setRightRailCollapsed(false))
    } else if (prevRightRailCollapsed) {
      dispatch(setRightRailCollapsed(true))
    }
    // eslint-disable-next-line
  }, [contactDrawerVisible, filtersOpened, selectedOpened])

  const reloadData = (force = false) => {
    if (!people || !people.length || force) {
      dispatch(fetchPeople(1, 20, null, people_filters, "last_sent", "desc"))
    }
    if (
      ((!participants || !participants.length) && !participants_filters) ||
      force
    ) {
      dispatch(fetchParticipants(1, 5, participants_filters))
    }
    if (!topics || !popularTopics || force) {
      dispatch(fetchTopics())
      dispatch(fetchPopularTopics([2]))
    }
    if (!sources || !popularSources || force) {
      dispatch(fetchSources())
      dispatch(fetchPopularSources([2]))
    }
    if (!expertises || !popularExpertises || force) {
      dispatch(fetchExpertises())
      dispatch(fetchPopularExpertises([2]))
    }
    if (!companies || !popularCompanies || force) {
      dispatch(fetchCompanies())
      dispatch(fetchPopularCompanies([2]))
    }
    if (!locations || !popularLocations || force) {
      dispatch(fetchLocations())
      dispatch(fetchPopularLocations([2]))
    }

    dispatch(fetchDuplicatesCount())
    dispatch(fetchSpheres())
    dispatch(fetchAccounts())
    dispatch(fetchEnrichmentCount())
  }

  const { SubMenu } = Menu

  const shareSpheresMenu = (
    <Menu mode="inline" className="DatabasePage_ShareSpheresMenu">
      {spheres?.map((s) => {
        return (
          <Menu.Item
            onClick={() => {
              dispatch(setSphereModalVisible(true, s.id))
            }}
            key={s.title}
          >
            {s.title}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const menu = (
    <Menu mode="inline">
      <SubMenu title="CSV Export" className="DatabasePage_ExportCSVMenu">
        <Menu.Item
          onClick={() => {
            dispatch(exportContacts(true, null, null))
          }}
          key={"all"}
        >
          Export all contacts
        </Menu.Item>
        <SubMenu key="spheres" title="Export a sphere">
          <div className="DatabasePage_ExportCSVMenu_SpheresMenu">
            {currentSpheres.length > 0 && (
              <div className="DatabasePage_ExportCSVMenu_SpheresMenu_Sphere ExportSpheresButton">
                <Button
                  disabled={currentSpheres.length === 0}
                  type={"link"}
                  onClick={() => {
                    dispatch(
                      exportContacts(
                        false,
                        currentSpheres.map((s) => s.id),
                        null
                      )
                    )
                  }}
                >
                  Export {currentSpheres.length} spheres
                </Button>
              </div>
            )}
            {spheres?.map((sphere) => (
              <div
                className="DatabasePage_ExportCSVMenu_SpheresMenu_Sphere"
                onClick={(e) => e.stopPropagation()}
                key={sphere.title}
              >
                <Checkbox
                  checked={currentSpheres.find((s) => s.title === sphere.title)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCurrentSpheres([sphere, ...currentSpheres])
                    } else {
                      setCurrentSpheres(
                        currentSpheres.filter((s) => s.id !== sphere.id)
                      )
                    }
                    e.stopPropagation()
                  }}
                />
                <span>{sphere.title}</span>
              </div>
            ))}
          </div>
        </SubMenu>
        <Menu.Item
          onClick={() => {
            dispatch(exportContacts(false, null, selectedContactsIds))
          }}
          key="selected"
          disabled={selectedContactsIds.length === 0}
        >
          Export selected contacts
          <b>
            {selectedContactsIds.length > 0 && ` (${selectedContactsIds.length})`}
          </b>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )

  useEffect(() => {
    if (selectedContactsIds.length) {
      setFiltersOpened(false)
      setSelectedOpened(true)
    } else {
      setSelectedOpened(false)
    }
  }, [selectedContactsIds, splitMessageModalVisible])

  useEffect(() => {
    reloadData()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (people_filters) {
      const regex = /^\s*spheres.id:\s*(\d+)\s*$/
      const matchingFilter = people_filters.find((filter) => regex.test(filter))
      const digits = matchingFilter?.match(regex)[1]
      let sphere = null
      if (digits) {
        sphere = spheres?.find((s) => s.id === parseInt(digits))
      }
      if (sphere) {
        setSelectedSphere(parseInt(digits))
      } else {
        setSelectedSphere(null)
      }
    } else {
      setSelectedSphere(null)
    }
  }, [people_filters, spheres])

  useEffect(() => {
    if (locationKey === router.location.key) {
      reloadData(true)
    } else if (router.location.key) {
      dispatch(setCurrentLocationKey(router.location.key))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (contactDrawerVisible) {
      setFiltersOpened(false)
      setSelectedOpened(false)
    }
  }, [contactDrawerVisible])

  useEffect(() => {
    if ((filtersOpened || selectedOpened) && contactDrawerVisible) {
      dispatch(setContactDrawerVisible(false))
    }
    // eslint-disable-next-line
  }, [filtersOpened, selectedOpened])

  const connectAccount = () => {
    nylasAuthenticationURI(null, (url) => {
      window.location.replace(url)
    })
  }

  const uploadCSV = async (file) => {
    const formData = new FormData()
    formData.append("file", file, file.name)
    const { data } = await axios.request({
      url: "/v1/people/import",
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
      method: "POST",
    })
    history.push(`/account/settings/csv/${data.import.id}`, {
      name: file.name,
      id: data.import.id,
    })
  }

  const AddDropdownMenu = (
    <Menu>
      <Menu.Item key="add_new">
        <div
          onClick={() => {
            dispatch(setVisibleContactID(null))
            dispatch(setContactDrawerMode("editing"))
            dispatch(setContactDrawerVisible(!contactDrawerVisible))
          }}
        >
          Add new contact
        </div>
      </Menu.Item>
      <Menu.Item key="import_from_calendar">
        <a href="account/settings?section=Accounts">Import from email/calendar</a>
      </Menu.Item>
      <Menu.Item key="import_from_csv">
        <Upload accept={".csv"} action={(file) => uploadCSV(file)}>
          Import a spreadsheet
        </Upload>
      </Menu.Item>
      <Menu.Item key="get_my_link">
        <div onClick={() => dispatch(setUserPanelModalVisible(true))}>
          Get my link
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="DatabasePage">
      {["intern", "rookie"].includes(user.status) && (
        <div className="DatabasePage_WelcomeInfo">
          <SuperSmartDB />
          <div>
            <h3>Welcome to your super-smart database!</h3>
            <span>
              These are the people who matter most. From here, you’ll be able to
              filter and find certain people, add notes and other details, and reach
              out. We do a lot of magic behind the scenes to keep this as up-to-date
              as possible. <br />
              <br />
              You choose who shows up here - just{" "}
              <button onClick={connectAccount}>
                connect your email and calendar
              </button>{" "}
              and then, from the bottom of this page, add anyone to one or more
              Spheres.
            </span>
          </div>
        </div>
      )}
      <div className="DatabasePage_Header">
        <div
          className={`DatabasePage_Header_Search ${
            (people_filters || q) &&
            !fetching &&
            "DatabasePage_Header_Search--withCounter"
          }`}
        >
          <SearchPeople />
          {(people_filters || q) && !fetching && (
            <p className="DatabasePage_Header_Search_Counter">
              {total_entries} contact(s)
            </p>
          )}
        </div>
        <div className="DatabasePage_Header_Menu">
          {(duplicatesTotalEntries > 0 || peopleEnrichmentsCount > 0) && (
            <Link
              to="/database/duplicates"
              className="DatabasePage_Header_Menu_Item"
            >
              <Icon component={BoltLightningIcon} style={{ marginRight: "-3px" }} />
              {duplicatesTotalEntries + peopleEnrichmentsCount} Suggestions
            </Link>
          )}
          <Link to="/database/spheralizer" className="DatabasePage_Header_Menu_Item">
            <div className="DatabasePage_Header_Menu_Item_SpheralizerIcon" />
            Spheralizer
          </Link>
          <div
            className="DatabasePage_Header_Menu_Item"
            onClick={() => {
              setSelectedOpened(false)
              setFiltersOpened(!filtersOpened)
            }}
          >
            <Icon component={SlidersHorizontalIcon} />
            Filters
          </div>
          <FiltersDrawer
            visible={filtersOpened}
            handleClose={() => setFiltersOpened(false)}
          />
          <SelectedDrawer
            visible={selectedOpened}
            handleClose={() => setSelectedOpened(false)}
          />
          <Dropdown
            className="DatabasePage_Header_Menu_Item"
            overlay={AddDropdownMenu}
          >
            <div onClick={(e) => e.preventDefault()}>
              <Icon component={PlusCircleIcon} />
              Add
            </div>
          </Dropdown>
          <Popover trigger="hover" content={shareSpheresMenu}>
            <div className="DatabasePage_Header_Menu_Item">
              <Icon style={{ fontSize: "20px" }} component={ShareAltOutlined} />
              Share
            </div>
          </Popover>
          <Popover trigger="click" content={menu}>
            <div className="DatabasePage_Header_Menu_Item">
              <Icon style={{ fontSize: "20px" }} component={MoreOutlined} />
            </div>
          </Popover>
        </div>
      </div>
      {showStartBuildingCard || (people?.length === 0 && spheres?.length === 0) ? (
        <StartBuildingCard />
      ) : (
        <>
          {selectedSphere && spheres.find((s) => s.id === selectedSphere) && (
            <SingleSphereCard
              sphere={spheres.find((s) => s.id === selectedSphere)}
            />
          )}

          <CSSTransition in={!!people} timeout={1000} classNames="fade" appear>
            <PeopleTable />
          </CSSTransition>
        </>
      )}
      {(!participantsTableIsEmpty || participants_filters?.length > 0) && (
        <>
          {["intern", "rookie"].includes(user.status) && (
            <>
              <div
                className="DatabasePage_WelcomeInfo"
                style={{ marginTop: "20px" }}
              >
                <PeopleWithMagnifyingGlass />
                <div>
                  <h3>Are these people important?</h3>
                  <span>
                    Once you’ve{" "}
                    <button onClick={connectAccount}>
                      connect your email and calendar
                    </button>
                    , Relatable goes to work pulling in everyone you’ve communicated
                    with.
                    <br />
                    <br />
                    For the people you want to remember and stay in touch with, add
                    them to one or more Sphere. The rest you can Dismiss (you can
                    always come back to them later).
                  </span>
                </div>
              </div>
            </>
          )}
          <CSSTransition in={true} timeout={1200} classNames="fade" appear>
            <ParticipantsTable
              perPage={5}
              setParticipantsTableIsEmpty={setParticipantsTableIsEmpty}
            />
          </CSSTransition>
        </>
      )}
      <BulkActionsDrawer visible={selectedParticipantsIds.length > 0} />
    </div>
  )
}

export default DatabasePage
