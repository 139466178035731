import React, { useEffect, useState } from "react"
import * as yup from "yup"
import "./NewIntroTab.styles.scss"
import { AutoComplete, Checkbox, Form, Input, Spin } from "antd"
import { fetchForMentions } from "./NewIntroTab.actions"
import { useDispatch, useSelector } from "react-redux"
import { addIntroduction } from "../../../../../redux/Introductions/Introductions.actions"
import { useFormik } from "formik"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../../redux/App/App.actions"
import ArrowsLeftRightIcon from "../../../../Icons/ArrowsLeftRight.icons"
import Icon from "@ant-design/icons"
import EmailComposerModal from "../../../../UI/EmailComposerModal/EmailComposerModal.component"
import IntroductionCreator from "./components/IntroductionCreator/IntroductionCreator.component"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const { TextArea } = Input
const TAB_REQUIRED_FIELDS = [
  "participant_1_id",
  "participant_1_description",
  "participant_1_email",

  "participant_2_id",
  "participant_2_description",
  "participant_2_email",
]

const NewIntroTab = ({ contact = null }) => {
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  const [participants, setParticipants] = useState([])
  const [participant1, setParticipant1] = useState({ name: "", emails: [] })
  const [participant2, setParticipant2] = useState({ name: "", emails: [] })
  const [loading, setLoading] = useState(false)

  const adding = useSelector((state) => state.IntroductionsState.adding)

  const schema = yup.object().shape({
    from: yup.string().required("Email is required"),
    subject: yup.string(),
    template: yup.string(),
    participant_1_id: yup
      .string()
      .min(1)
      .required("You need to select participants")
      .notOneOf([yup.ref("participant_2_id")], (e) =>
        e.value !== undefined
          ? "You cannot introduce this person to themself"
          : "You need to select participants"
      ),
    participant_1_approval: yup.bool().required(),
    participant_1_email: yup.string().required("This person has no email"),
    participant_1_subject: yup.string().when("participant_1_approval", {
      is: (val) => val,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    participant_1_template: yup.string().when("participant_1_approval", {
      is: (val) => val,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    participant_2_id: yup
      .string()
      .required("You need to select participants")
      .min(1)
      .notOneOf([yup.ref("participant_1_id")], (e) =>
        e.value !== undefined
          ? "You cannot introduce this person to themself"
          : "You need to select participants"
      ),
    participant_2_approval: yup.bool().required(),
    participant_2_email: yup.string().required("This person has no email"),
    participant_2_subject: yup.string().when("participant_2_approval", {
      is: (val) => val,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    participant_2_template: yup.string().when("participant_2_approval", {
      is: (val) => val,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      preAmble: "",
      from: "",
      subject: "",
      cc: [],
      files: [],
      template: "",
      participant_1_id: "",
      participant_1_description: "",
      participant_1_approval: true,
      participant_1_email: "",
      participant_1_cc: [],
      participant_1_files: [],
      participant_1_subject: "",
      participant_1_template: "",
      participant_2_id: "",
      participant_2_description: "",
      participant_2_approval: true,
      participant_2_email: "",
      participant_2_cc: [],
      participant_2_files: [],
      participant_2_subject: "",
      participant_2_template: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(addIntroduction(formatIntro(values)))
      formik.resetForm()
      setParticipant1(null)
      setParticipant2(null)
      setModalVisible(false)
    },
  })

  const openProfileDrawer = (id) => {
    if (id) {
      dispatch(setVisibleContactID(id))
      dispatch(setContactDrawerVisible(true))
      dispatch(setContactDrawerMode("view"))
    }
  }

  const handleSelect = (val, option, id) => {
    openProfileDrawer(val)

    if (id === 1) {
      formik.setFieldValue("participant_1_id", val)
      formik.setFieldValue("participant_1_description", setDescriptionToPerson(val))
      formik.setFieldValue(
        "participant_1_email",
        participants.find((p) => +p.id === +val).last_used_email || ""
      )

      setParticipant1({
        ...participant1,
        first_name: participants.find((p) => +p.id === +val).first_name,
        last_name: participants.find((p) => +p.id === +val).last_name,
        name: option.children,
        emails: participants.find((p) => +p.id === +val).emails,
        last_used_email: participants.find((p) => +p.id === +val).last_used_email,
      })
      setParticipants([])
    } else {
      formik.setFieldValue("participant_2_id", val)
      formik.setFieldValue("participant_2_description", setDescriptionToPerson(val))
      formik.setFieldValue(
        "participant_2_email",
        participants.find((p) => +p.id === +val).last_used_email || ""
      )
      setParticipant2({
        ...participant2,
        first_name: participants.find((p) => +p.id === +val).first_name,
        last_name: participants.find((p) => +p.id === +val).last_name,
        name: option.children,
        emails: participants.find((p) => +p.id === +val).emails,
        last_used_email: participants.find((p) => +p.id === +val).last_used_email,
      })
      setParticipants([])
    }
  }

  const handleChange = (val, id) => {
    if (id === 1) {
      setParticipant1(val)
    } else {
      setParticipant2(val)
    }
  }

  const handleSearch = (query) => {
    setLoading(true)
    loadParticipants(query)
  }

  const loadParticipants = (query) => {
    fetchForMentions(query, "SORT").then((res) => {
      setParticipants(res)
      setLoading(false)
    })
  }

  const setDescriptionToPerson = (person_id) => {
    const people = participants.find((p) => parseInt(p.id) === parseInt(person_id))
    return people.description ? people.description : ""
  }

  const formatIntro = (values) => {
    return {
      note: values.preAmble,
      mail_from: values.from,
      subject: values.subject,
      cc: values.cc,
      files: values.files,
      participant_1_files: values.participant_1_files,
      participant_2_files: values.participant_2_files,
      body: values.template,
      introduction_participants_attributes: [
        {
          description: values.participant_1_description,
          person_id: values.participant_1_id,
          preapproved: !values.participant_1_approval,
          mail_to: values.participant_1_email,
          cc: values.participant_1_cc,
          subject: values.participant_1_subject,
          body: values.participant_1_template,
          mail_from: values.from,
        },
        {
          description: values.participant_2_description,
          person_id: values.participant_2_id,
          preapproved: !values.participant_2_approval,
          mail_to: values.participant_2_email,
          cc: values.participant_2_cc,
          subject: values.participant_2_subject,
          body: values.participant_2_template,
          mail_from: values.from,
        },
      ],
    }
  }

  useEffect(() => {
    if (contact) {
      formik.setFieldValue("participant_1_id", contact.id)
      formik.setFieldValue(
        "participant_1_description",
        contact.introduction_description
      )
      setParticipant1({
        emails: [contact.email_addresses.map((em) => em.value)],
        name: contact.full_name,
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="CreateIntroduction">
      {adding ? (
        <RelatableLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <EmailComposerModal
            width={"73vw"}
            title={`Customize and send the introduction request`}
            action={"saving"}
            visible={modalVisible}
            cancelHandler={() => {
              dispatch(setVisibleContactID(null))
              dispatch(setContactDrawerVisible(false))
              formik.setFieldValue("participant_1_template", "")
              formik.setFieldValue("participant_2_template", "")
              setModalVisible(false)
            }}
          >
            <IntroductionCreator
              handleSubmit={() => formik.submitForm()}
              visible={modalVisible}
              formik={formik}
              participant1={participant1}
              participant2={participant2}
            />
          </EmailComposerModal>

          <Form.Item
            className="CreateIntroduction_InputForm"
            validateStatus={
              formik.touched.preAmble && formik.errors.preAmble ? "error" : "success"
            }
            help={
              formik.touched.preAmble && formik.errors.preAmble
                ? formik.errors.preAmble
                : null
            }
          >
            <Input
              key="preAmble"
              name="preAmble"
              placeholder="Why you're introducing these two..."
              value={formik.values.preAmble}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoFocus
            />
          </Form.Item>
          <div className="CreateIntroduction_Body">
            <div>
              <span>Introduce...</span>
              <Form.Item
                className="CreateIntroduction_Body_Select"
                validateStatus={
                  formik.touched.participant_1_id &&
                  (formik.errors.participant_1_id ||
                    formik.errors.participant_1_email)
                    ? "error"
                    : "success"
                }
                help={
                  formik.touched.participant_1_id
                    ? formik.errors.participant_1_id
                      ? formik.errors.participant_1_id
                      : formik.errors.participant_1_email
                    : null
                }
              >
                <AutoComplete
                  placeholder="Name..."
                  allowClear={true}
                  onClear={() => {
                    formik.setFieldValue("participant_1_id", "")
                  }}
                  loading={loading}
                  value={participant1 ? participant1.name : ""}
                  onSearch={handleSearch}
                  onChange={(val) => {
                    handleChange(val, 1)
                  }}
                  onSelect={(e, f) => {
                    handleSelect(e, f, 1)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      formik.setFieldValue("participant_1_id", "")
                      setParticipants([])
                      setLoading(true)
                      loadParticipants()
                    }
                  }}
                  onFocus={() => openProfileDrawer(formik.values.participant_1_id)}
                  onBlur={() => {
                    setParticipants([])
                    formik.setFieldTouched("participant_1_id", true)
                  }}
                >
                  {participants.map((p) => {
                    return (
                      <AutoComplete.Option key={p.id} value={p.id}>
                        {p.name}
                      </AutoComplete.Option>
                    )
                  })}
                </AutoComplete>
              </Form.Item>
              <Form.Item className="CreateIntroduction_Body_Textarea">
                <TextArea
                  placeholder="Description..."
                  key="participant_1_description"
                  name="participant_1_description"
                  value={formik.values.participant_1_description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Checkbox
                key="participant_1_approval"
                name="participant_1_approval"
                checked={formik.values.participant_1_approval}
                onChange={formik.handleChange}
              >
                <span>
                  Email them asking for permission first (strongly recommended)
                </span>
              </Checkbox>
            </div>
            <div className="CreateIntroduction_Body_Divider">
              <Icon component={ArrowsLeftRightIcon} />
            </div>
            <div>
              <span>...To</span>
              <Form.Item
                className="CreateIntroduction_Body_Select"
                validateStatus={
                  formik.touched.participant_2_id &&
                  (formik.errors.participant_2_id ||
                    formik.errors.participant_2_email)
                    ? "error"
                    : "success"
                }
                help={
                  formik.touched.participant_2_id
                    ? formik.errors.participant_2_id
                      ? formik.errors.participant_2_id
                      : formik.errors.participant_2_email
                    : null
                }
              >
                <AutoComplete
                  placeholder="Name..."
                  allowClear={true}
                  onClear={() => {
                    formik.setFieldValue("participant_2_id", "")
                  }}
                  loading={loading}
                  value={participant2 ? participant2.name : ""}
                  onSearch={handleSearch}
                  onChange={(val) => {
                    handleChange(val, 2)
                  }}
                  onSelect={(e, f) => {
                    handleSelect(e, f, 2)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      formik.setFieldValue("participant_2_id", "")
                      setParticipants([])
                      setLoading(true)
                      loadParticipants()
                    }
                  }}
                  onFocus={() => openProfileDrawer(formik.values.participant_2_id)}
                  onBlur={() => {
                    setParticipants([])
                    formik.setFieldTouched("participant_2_id", true)
                  }}
                >
                  {participants.map((p) => {
                    return (
                      <AutoComplete.Option key={p.id} value={p.id}>
                        {p.name}
                      </AutoComplete.Option>
                    )
                  })}
                </AutoComplete>
              </Form.Item>
              <Form.Item className="CreateIntroduction_Body_Textarea">
                <TextArea
                  placeholder="Description..."
                  key="participant_2_description"
                  name="participant_2_description"
                  value={formik.values.participant_2_description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Checkbox
                key="participant_2_approval"
                name="participant_2_approval"
                checked={formik.values.participant_2_approval}
                onChange={formik.handleChange}
              >
                <span>
                  Email them asking for permission first (strongly recommended)
                </span>
              </Checkbox>
            </div>
          </div>
          <div className="CreateIntroduction_Footer">
            <button
              type={"button"}
              className={
                !formik.values.participant_1_id || !formik.values.participant_2_id
                  ? "CreateIntroduction_Footer_Button--disabled"
                  : ""
              }
              disabled={
                !formik.values.participant_1_id || !formik.values.participant_2_id
              }
              onClick={() => {
                formik.setFieldTouched("participant_1_description")
                formik.setFieldTouched("participant_2_description")
                formik.validateForm().then((res) => {
                  if (
                    !TAB_REQUIRED_FIELDS.some((field) => {
                      return Object.keys(res).includes(field)
                    })
                  ) {
                    dispatch(setVisibleContactID(formik.values.participant_1_id))
                    dispatch(setContactDrawerVisible(true))
                    dispatch(setContactDrawerMode("view"))
                    setModalVisible(true)
                  }
                })
              }}
            >
              Start Introduction
              <Icon
                className="CreateIntroduction_Footer_Button_Icon"
                component={ArrowsLeftRightIcon}
              />
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default NewIntroTab
