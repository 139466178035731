import React from "react"
import { Tooltip } from "antd"
import "../AccountsSection/AccountsSection.styles.scss"
import "./AppsSection.styles.scss"

const AppsSection = () => {
  return (
    <div className="AccountSettings_AppsSection Wrapper">
      <h2>Apps</h2>
      <div className="AccountSettings_AppsSection_Body">
        <div className=" AccountOption">
          <div className=" AccountOption_Description">
            <h3>Get iOS App</h3>
            <span>
              This will take you to the App Store. You’ll automatically get new
              releases!
            </span>
          </div>
          <a
            className="BlueButton"
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/relatable-crm/id1668247614"
          >
            Go to App Store
          </a>
        </div>
        <div className=" AccountOption">
          <div className=" AccountOption_Description">
            <h3>Get Android App</h3>
            <span>
              This will take you to the Play Store. You’ll receive updates
              automatically.
            </span>
          </div>
          <a
            className="BlueButton"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.relatable.crm"
          >
            Go to Play Store
          </a>
        </div>
        <div className=" AccountOption">
          <div className=" AccountOption_Description">
            <h3>Get Chrome Extension</h3>
            <span>
              Our Chrome extension is brand new, and lets you add and update your
              LinkedIn connections into Relatable.
            </span>
          </div>
          <a
            className="BlueButton"
            target="_blank"
            rel="noopener noreferrer"
            href="https://chrome.google.com/webstore/detail/relatable-crm/dbpiijgjfgnelhkmnklfebcnhdlecodc"
          >
            Go to Chrome Store
          </a>
        </div>
        <div className=" AccountOption">
          <div className=" AccountOption_Description">
            <h3>Relatable for Mac</h3>
            <span>
              When you install Relatable to your desktop, you'll be able to sync
              phone calls and text messages/iMessage from your iPhone into Relatable.
            </span>
          </div>

          <a
            className="BlueButton"
            target="_blank"
            rel="noopener noreferrer"
            href="https://relatable.helpscoutdocs.com/article/40-imessage-call-syncing"
          >
            See how it works
          </a>
        </div>
      </div>
    </div>
  )
}

export default AppsSection
