import React, { useState } from "react"
import { Button, Modal } from "antd"
import { useDispatch } from "react-redux"
import { wipeAccounts } from "../../../redux/Admin/Admin.actions"

const WipeAccountModal = ({ selected_users, visible, onCancel, onWipeAccount }) => {
  const dispatch = useDispatch()
  const [input, setInput] = useState("")

  return (
    <Modal
      closable={false}
      width={"40vw"}
      destroyOnClose
      footer={null}
      title={<h3>Wipe account</h3>}
      visible={visible}
      onCancel={() => onCancel()}
    >
      <div style={{ padding: "2rem" }}>
        <span>
          You are trying to wipe {selected_users.length} accounts belonging to:{" "}
          <ul>
            {selected_users.map((u) => (
              <li>
                <b>{u.email}</b>
              </li>
            ))}
          </ul>
          Please confirm.
        </span>
        <input
          style={{ width: "100%" }}
          placeholder="Type WIPE ACCOUNT to confirm"
          onChange={(e) => setInput(e.target.value)}
        />
        <div style={{ display: "flex" }}>
          <Button
            onClick={onCancel}
            style={{ margin: "1rem" }}
            type={"primary"}
            block
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (input !== "WIPE ACCOUNT") {
                return alert("Please type WIPE ACCOUNT to confirm")
              } else {
                dispatch(wipeAccounts(selected_users.map((user) => user.id)))
                onCancel()
                onWipeAccount()
              }
            }}
            style={{ margin: "1rem" }}
            type={"danger"}
            block
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default WipeAccountModal
