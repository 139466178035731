import React, { useState } from "react"
import ContactCard from "../Components/ContactCard/ContactCard.component"
import {
  approveTrigger,
  ignoreTrigger,
  updateTrigger,
} from "../../../../redux/Triggers/Triggers.actions"
import { useDispatch } from "react-redux"
import RecencySelector from "../../../Database/RecencySelector/RecencySelector.component"
import dateWithinInterval from "../../../../helpers/dateWithinInterval"
import { deleteContact, editContact } from "../../../../redux/People/People.actions"

const ReviewingTriggerScene = ({
  trigger,
  goNext,
  setSelectedPerson,
  recordInteraction,
}) => {
  const [settingRecency, setSettingRecency] = useState(false)

  const ruleSphere =
    trigger?.type === "trigger"
      ? trigger.person?.spheres.find((s) => s.id === trigger.rule.collection_id)
      : null

  const dispatch = useDispatch()

  const handleIgnoreTrigger = () => {
    dispatch(ignoreTrigger(trigger.id, goNext))
  }

  const handleRemoveFromSphere = () => {
    const newSpheres = trigger.person.spheres.filter((s) => s.id !== ruleSphere?.id)
    dispatch(
      editContact(trigger.person.id, {
        spheres: [...newSpheres],
      })
    )
    handleIgnoreTrigger()
    if (newSpheres.length === 0) {
      dispatch(deleteContact(trigger.person.id))
    }
  }

  const handleArchiveContact = () => {
    dispatch(deleteContact(trigger.person.id))
    handleIgnoreTrigger()
  }

  const handleRecordInteraction = () => {
    setSelectedPerson(trigger.person)
    dispatch(
      approveTrigger(trigger.id, () => setTimeout(() => recordInteraction(), 200))
    )
  }

  const handleApproveTrigger = () => {
    dispatch(approveTrigger(trigger.id, goNext))
  }

  return (
    <div className="Scene ReviewingTriggerScene">
      <ContactCard contact={trigger.person} type="person" />
      <RecencySelector
        id={trigger.person.id}
        opened={settingRecency}
        onClose={() => setSettingRecency(false)}
        onSubmit={(date) => {
          if (dateWithinInterval(date, trigger.rule.interval)) {
            dispatch(updateTrigger(trigger.id, "date_updated", goNext))
          } else {
            goNext()
          }
          setSettingRecency(false)
        }}
      />
      <h2 style={{ marginTop: "40px" }}>
        It looks like you haven’t spoken to{" "}
        {trigger.person.full_name ? trigger.person.full_name.split(" ")[0] : ""} in a
        while.
      </h2>
      <h3 style={{ marginBottom: "40px" }}>
        Do you want us to remind you to get back in touch?
      </h3>
      <button onClick={handleApproveTrigger} className="primary">
        👍🏼 Yes, add{" "}
        {trigger.person.full_name ? trigger.person.full_name.split(" ")[0] : ""} to
        my reminders
      </button>
      <button onClick={handleRecordInteraction} className="primary">
        📞 I want to reach out to them right now
      </button>
      <button onClick={() => setSettingRecency(true)}>
        🧑‍💻 I spoke to them recently
      </button>
      <button onClick={handleIgnoreTrigger}>
        ⏰ No, I’ll wait til another time
      </button>
      <button onClick={handleRemoveFromSphere}>
        👎🏼 Remove them from my <b>{ruleSphere?.title}</b>
      </button>
      <button onClick={handleArchiveContact}>🙅🏾 Archive them entirely</button>
    </div>
  )
}

export default ReviewingTriggerScene
