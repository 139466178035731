import axios from "axios"
import { store } from "../redux/store"
import { authenticateUserSuccess, logout } from "../redux/User/User.actions"
import { apiURL } from "./serverConf"

export const setupAxios = () => {
  axios.defaults.baseURL = apiURL()
  axios.defaults.headers.post["Content-Type"] = "application/json"
  axios.interceptors.request.use(
    function (config) {
      const token = store.getState().UserState.access_token
      if (token != null) {
        config.headers.authorization = `Bearer ${
          store.getState().UserState.access_token
        }`
      }

      return config
    },
    function (err) {
      return Promise.reject(err)
    }
  )
  axios.interceptors.response.use(
    function (response) {
      // If the request succeeds, we don't have to do anything and just return the response
      return response
    },
    function (error) {
      const errorResponse = error.response
      if (isTokenExpiredError(errorResponse, error.config.url)) {
        return resetTokenAndReattemptRequest(error)
      }
      return Promise.reject(error)
    }
  )

  function isTokenExpiredError(errorResponse, url) {
    return errorResponse.status === 401 && url !== `/oauth/token`
    // Your own logic to determine if the error is due to JWT token expired returns a boolean value
  }

  let startedRefreshingAt = null

  // This is the list of waiting requests that will retry after the JWT refresh complete
  let subscribers = []

  function getResetToken() {
    return store.getState().UserState.refresh_token
  }

  function saveRefreshToken(data) {
    store.dispatch(
      authenticateUserSuccess(
        data.id,
        data.email,
        data.first_name,
        data.last_name,
        data.avatar,
        data.access_token,
        data.refresh_token,
        data.role,
        data.phone_number,
        data.daily_recommendations,
        data.track_opened,
        data.bio,
        data.leaderboard_name,
        data.trial_days_remaining
      )
    )
  }

  function resetTokenAndReattemptRequest(error) {
    try {
      const { response: errorResponse } = error
      const resetToken = getResetToken() // Your own mechanism to get the refresh token to refresh the JWT token
      if (!resetToken) {
        // We can't refresh, throw the error anyway
        return Promise.reject(error)
      }
      /* Proceed to the token refresh procedure
                              We create a new Promise that will retry the request,
                              clone all the request configuration from the failed
                              request in the error object. */
      const retryOriginalRequest = new Promise((resolve) => {
        /* We need to add the request retry to the queue
                                        since there another request that already attempt to
                                        refresh the token */
        addSubscriber((access_token) => {
          errorResponse.config.headers.authorization = `Bearer ${access_token}`
          resolve(axios(errorResponse.config))
        })
      })
      // check if the token has been refreshed in the last 60 seconds
      if (!startedRefreshingAt || Date.now() - startedRefreshingAt > 60000) {
        new Promise(function (resolve, reject) {
          startedRefreshingAt = Date.now()
          axios
            .post("/oauth/token", {
              refresh_token: store.getState().UserState.refresh_token,
              grant_type: "refresh_token",
            })
            .then((response, error) => {
              const data = response.data
              saveRefreshToken(data) // save the newly refreshed token for other requests to use
              onAccessTokenFetched(data.access_token)
              axios.defaults.headers.authorization = `Bearer ${data.access_token}`
            })
            .catch((err) => {
              store.dispatch(logout(true))
              return Promise.reject(err)
            })
        })
      }
      return retryOriginalRequest
    } catch (err) {
      return Promise.reject(err)
    }
  }

  function onAccessTokenFetched(access_token) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach((callback) => callback(access_token))
    subscribers = []
  }

  function addSubscriber(callback) {
    subscribers.push(callback)
  }
}
