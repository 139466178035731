import React, { useEffect, useState } from "react"
import "./EnrichmentsSection.styles.scss"
import { Col, Table, Typography } from "antd"
import Avatar from "../../../components/UI/Avatar/Avatar.component"

import { useDispatch, useSelector } from "react-redux"

import {
  approveEnrichment,
  fetchEnrichments,
  ignoreEnrichment,
} from "../../../redux/People/People.actions"
import Icon from "@ant-design/icons"
import {
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../redux/App/App.actions"
import CustomPagination from "../../../components/Database/CustomPagination/CustomPagination.component"
import EnvelopeIcon from "../../Icons/Envelope.icons"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const { Text, Paragraph } = Typography

const EnrichmentsSection = () => {
  const dispatch = useDispatch()
  const peopleEnrichments = useSelector((state) => state.PeopleState.enrichments)
  const loading = useSelector((state) => state.PeopleState.loading)
  const peopleEnrichmentsTotalEntries = useSelector(
    (state) => state.PeopleState.enrichments_total_entries
  )

  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )

  const [page, setPage] = useState(1)

  const handleClickViewPerson = (personId) => {
    if (contactDrawerVisible && +visibleContactID === +personId) {
      dispatch(setContactDrawerVisible(false))
      dispatch(setVisibleContactID(null))
      dispatch(setActiveTab(null))
    } else if (contactDrawerVisible && +visibleContactID !== +personId) {
      dispatch(setContactDrawerMode("view"))
      dispatch(setActiveTab(null))
      dispatch(setVisibleContactID(+personId))
    } else {
      dispatch(setActiveTab(null))
      dispatch(setContactDrawerVisible(true))
      dispatch(setVisibleContactID(personId))
    }
  }

  const handleAddEnrichments = (id) => {
    dispatch(approveEnrichment(id))
    setPage(1)
  }

  const handleIgnoreEnrichments = (id) => {
    dispatch(ignoreEnrichment(id))
    setPage(1)
  }

  const handleTableChange = (page) => {
    setPage(page)
    dispatch(fetchEnrichments(page))
  }

  useEffect(() => {
    dispatch(fetchEnrichments(page))
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      key: "person",
      render: (record) => (
        <div className="EnrichmentsSection_Table_Row">
          <div className="EnrichmentsSection_Table_Avatar">
            <Avatar
              avatar={
                record?.person?.profile_image_url !== ""
                  ? record?.person?.profile_image_url
                  : null
              }
              firstName={record?.person?.first_name}
              lastName={record?.person?.last_name}
              email={record?.person?.email?.value}
              colorNumber={record?.person?.id}
              size={40}
            />
          </div>
          {
            <div className="EnrichmentsSection_Table_DetailsWrap">
              <div
                onClick={() => {
                  handleClickViewPerson(record.person.id)
                }}
                className="EnrichmentsSection_Table_FullName"
              >
                {record?.person?.first_name &&
                record.person?.first_name.trim().length > 0
                  ? record?.person?.first_name + " " + record?.person?.last_name
                  : record?.person?.email?.value}
              </div>
              {record.person.email && record.person.email?.value?.length > 0 && (
                <div
                  onClick={() => {
                    handleClickViewPerson(record.person.id)
                  }}
                  className="EnrichmentsSection_Table_Email"
                >
                  {record.person.email.value}
                </div>
              )}
            </div>
          }
        </div>
      ),
    },
    {
      key: "enrichments",
      render: (record) => (
        <div className="EnrichmentsSection_Table_Enrichments">
          <div className="EnrichmentsSection_Table_Enrichments_Title">
            <Icon
              className={"EnrichmentsSection_Table_Enrichments_Title_Icon"}
              component={EnvelopeIcon}
            />
            Taken from e-mail signature
          </div>
          <div className="EnrichmentsSection_Table_Enrichments_Details">
            {record.first_name && (
              <div className="EnrichmentsSection_Table_Enrichments_Details_Row">
                <h4>First name</h4>
                <span>{record.first_name}</span>
              </div>
            )}
            {record.last_name && (
              <div className="EnrichmentsSection_Table_Enrichments_Details_Row">
                <h4>Last name</h4>
                <span>{record.last_name}</span>
              </div>
            )}
            {record.one_liner && (
              <div className="EnrichmentsSection_Table_Enrichments_Details_Row">
                <h4>One liner</h4>
                <span>{record.one_liner}</span>
              </div>
            )}
            {record.phone_number && (
              <div className="EnrichmentsSection_Table_Enrichments_Details_Row">
                <h4>Phone</h4>
                <span>{record.phone_number}</span>
              </div>
            )}
            {record.link && (
              <div className="EnrichmentsSection_Table_Enrichments_Details_Row">
                <h4>Link</h4>
                <span>{record.link}</span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      key: "action",
      render: (record) => (
        <div className="EnrichmentsSection_Table_ButtonWrap">
          <div>
            <button
              onClick={() => handleAddEnrichments(record.id)}
              className="EnrichmentsSection_Table_ButtonMerge"
            >
              Add
            </button>
          </div>
          <Col>
            <button
              onClick={() => handleIgnoreEnrichments(record.id)}
              className="EnrichmentsSection_Table_ButtonNope"
            >
              Nope
            </button>
          </Col>
        </div>
      ),
    },
  ]

  return (
    <>
      {peopleEnrichments?.length > 0 ? (
        <div className="EnrichmentsSection">
          <>
            <div className="EnrichmentsSection_Header">
              <div>
                <Text className="EnrichmentsSection_Header_Title">
                  We found more information on {peopleEnrichmentsTotalEntries} of
                  your contacts
                </Text>
                <Paragraph className="EnrichmentsSection_Header_Text">
                  Relatable's army of owls regularly scans for more information on
                  your relationships, including looking at e-mail signatures.
                </Paragraph>
              </div>
            </div>
            <Table
              loading={{
                spinning: loading,
                indicator: <RelatableLoader quote={true} loading={loading} />,
              }}
              showHeader={false}
              pagination={false}
              rowKey={(record) => `${record.id}`}
              className="EnrichmentsSection_Table"
              columns={columns}
              dataSource={peopleEnrichments}
              onChange={handleTableChange}
              rowClassName={(record, index) => {
                return `profile_variation${record?.person?.id % 3}`
              }}
            />
            <CustomPagination
              total={peopleEnrichmentsTotalEntries}
              currentPage={page}
              perPage={3}
              onPageChange={handleTableChange}
            />
          </>
        </div>
      ) : null}
    </>
  )
}

export default EnrichmentsSection
