import { all, call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import {
  ADD_INTERACTION,
  ADD_NOTE,
  DELETE_EVENT,
  DELETE_INTERACTION,
  DELETE_NOTE,
  UPDATE_NOTE,
} from "./Interactions.types"
import {
  addInteractionFail,
  addInteractionSuccess,
  addNoteFail,
  addNoteSuccess,
  deleteEventFail,
  deleteEventSuccess,
  deleteInteractionFail,
  deleteInteractionSuccess,
  deleteNoteFail,
  deleteNoteSuccess,
  editNoteClear,
  updateNoteFail,
  updateNoteSuccess,
} from "./Interactions.actions"
import {
  fetchPerson,
  fetchPersonsEvents,
  fetchPersonsInteractions,
  fetchPersonsNotes,
} from "../People/People.actions"
import { fetchActivities, fetchPoints } from "../Activities/Activities.actions"
import { openDrawerTab } from "../App/App.actions"

export function* watchInteractionsSaga() {
  yield takeLatest(ADD_NOTE, addNote)
  yield takeLatest(DELETE_NOTE, deleteNote)
  yield takeLatest(UPDATE_NOTE, updateNote)
  yield takeLatest(ADD_INTERACTION, addInteraction)
  yield takeLatest(DELETE_INTERACTION, deleteInteraction)
  yield takeLatest(DELETE_EVENT, deleteEvent)
}

function* addNote(action) {
  try {
    const formData = new FormData()
    yield call(async () => {
      action.attachments.forEach((attachment) => {
        let localUri = attachment
        let filename = localUri.split("/").pop()
        let match = /\.(\w+)$/.exec(filename)
        let type =
          match && action.attachments_type === "photo"
            ? `image/${match[1]}`
            : `audio/x-${match[1]}`
        formData.append(`attachments[]`, { uri: localUri, name: filename, type })
      })
      formData.append("body", action.body)

      action.people.forEach((person) => {
        formData.append(`people[]`, JSON.stringify(person))
      })
      action.topics.forEach((topic) => {
        formData.append(`topics[]`, JSON.stringify(topic))
      })
      action.expertises.forEach((expertise) => {
        formData.append(`expertises[]`, JSON.stringify(expertise))
      })
    })
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/notes",
        data: formData,
        method: "POST",
      })
    )
    yield all([
      put(addNoteSuccess(data.note)),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
      put(openDrawerTab("")),
    ])
    if (action.visible_contact_id) {
      yield put(fetchPersonsNotes(action.visible_contact_id, 1))
    }
  } catch (error) {
    yield put(addNoteFail(error))
  }
}

function* updateNote(action) {
  try {
    const formData = new FormData()
    yield call(async () => {
      action.attachments.forEach((attachment) => {
        let localUri = attachment
        let filename = localUri.split("/").pop()
        let match = /\.(\w+)$/.exec(filename)
        let type =
          match && action.attachments_type === "photo"
            ? `image/${match[1]}`
            : `audio/x-${match[1]}`
        formData.append(`attachments[]`, { uri: localUri, name: filename, type })
      })
      formData.append("body", action.body)

      action.people.forEach((person) => {
        formData.append(`people[]`, JSON.stringify(person))
      })

      action.topics.forEach((topic) => {
        formData.append(`topics[]`, JSON.stringify(topic))
      })

      action.expertises.forEach((expertise) => {
        formData.append(`expertises[]`, JSON.stringify(expertise))
      })
    })
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/notes/${action.id}`,
        data: formData,
        method: "PUT",
      })
    )
    yield put(updateNoteSuccess(data.note))
    yield put(editNoteClear())
    yield put(openDrawerTab(""))
    yield put(fetchPersonsNotes(action.visible_contact_id, 1))
  } catch (error) {
    yield put(updateNoteFail(error))
    yield put(editNoteClear())
  }
}

function* deleteNote(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/notes/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(deleteNoteSuccess(action.id))
    yield put(fetchPersonsNotes(action.visible_contact_id, 1))
  } catch (error) {
    yield put(deleteNoteFail(error))
  }
}

function* deleteInteraction(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/interactions/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(fetchPersonsInteractions(action.visible_contact_id, 1))
    yield put(deleteInteractionSuccess(action.id))
  } catch (error) {
    yield put(deleteInteractionFail(error))
  }
}

function* deleteEvent(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/events/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(fetchPersonsEvents(action.visible_contact_id, 1))
    yield put(deleteEventSuccess(action.id))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

function* addInteraction(action) {
  try {
    const formData = new FormData()
    yield call(async () => {
      formData.append("subject", action.subject)
      formData.append("body", action.body)
      formData.append("interaction_method", action.interaction_method)
      formData.append("last_message_timestamp", action.last_message_timestamp)

      action.people.forEach((person) => {
        formData.append(`people[]`, JSON.stringify(person))
      })
      action.topics.forEach((topic) => {
        formData.append(`topics[]`, JSON.stringify(topic))
      })
      action.expertises.forEach((expertise) => {
        formData.append(`expertises[]`, JSON.stringify(expertise))
      })
    })
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/interactions",
        data: formData,
        method: "POST",
      })
    )
    if (action.interaction_method === "Manual") {
      yield put(fetchPerson(action.people[0].id))
    }
    yield all([
      put(addInteractionSuccess(data.interaction)),
      put(fetchActivities(1, 12)),
      put(fetchPersonsInteractions(action.people[0].id, 1)),
      put(fetchPoints()),
    ])
  } catch (error) {
    yield put(addInteractionFail(error))
  }
}
