import axios from "axios"

const changePersonStep = async (payload, callback = null) => {
  const { data } = await axios.request({
    url: `/v1/stage_sphere/update_person_step`,
    data: {
      person_id: payload.person_id,
      sphere_step_id: payload.step_id,
      collection_id: payload.collection_id,
    },
    method: "POST",
  })

  callback && callback(data)
}

export default changePersonStep
