import React, { useEffect } from "react"
import "../MessageCreator.styles.scss"
import { Col, Divider, Modal, Row, Tooltip } from "antd"
import { useSelector } from "react-redux"
import _ from "lodash"
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import RelatedContacts from "../../../../Icons/RelatedContacts.icons"
import Icon from "@ant-design/icons"

const { confirm } = Modal

const ReceiversList = ({
  receivers,
  onSetReceivers,
  onRemoveReceiver,
  activeReceiver,
  onSetActiveReceiver,
  onResetIndividualMessages,
  invalidReceivers,
  includeRelatedContacts,
}) => {
  const selectedContacts = useSelector((state) => state.AppState.selected_contacts)
  const selectedContactId = useSelector(
    (state) => state.AppState.selected_contact_id
  )
  const selectedContact = useSelector((state) =>
    state.PeopleState.people.find((p) => p.id === selectedContactId)
  )

  useEffect(() => {
    // onSetReceivers(selectedContacts.length > 0 ? selectedContacts : [contactId])
    if (selectedContact) {
      onSetReceivers([selectedContact])
      setTimeout(() => onSetActiveReceiver(selectedContact), 100)
    } else if (selectedContacts.length > 0) {
      onSetReceivers(selectedContacts)
    } else {
      onSetReceivers([])
    }
    // eslint-disable-next-line
  }, [selectedContacts, selectedContactId])

  const handleRemoveReceiver = (id) => {
    const _receivers = receivers.filter((r) => r.id !== id)
    onSetReceivers(_receivers)
  }
  const renderTooltip = (r) => {
    switch (true) {
      case invalidReceivers.includes(r.id):
        return "This person has invalid email address selected"
      case _.isEmpty(r.email_addresses):
        return "We don’t have an email address on file for this person"
      case r.moved_to_cc:
        return "They are now CC’d on a message to their related contact"
      default:
        return ""
    }
  }

  return (
    <>
      {selectedContactId ? null : (
        <div className={"MessageCreator_Contacts"}>
          <span className={"MessageCreator_PreviewText"}>Quick Preview</span>
          <div
            onClick={() => {
              confirm({
                title:
                  "Editing the original template will overwrite any changes you have made to  any individuals messages?",
                okButtonProps: { className: "MessageCreator_ConfirmButton" },
                cancelButtonProps: {
                  className: "MessageCreator_ConfirmButton",
                },
                bodyStyle: { padding: "24px" },
                icon: <ExclamationCircleOutlined />,
                okText: "Ok",
                cancelText: "Cancel",
                onOk() {
                  onSetActiveReceiver(null)
                  onResetIndividualMessages()
                },
                onCancel() {},
              })
            }}
            className={`MessageCreator_ContactContainer ${
              activeReceiver === null ? "active" : "inactive"
            }`}
          >
            Original Email
          </div>
          <Divider style={{ margin: "12px 0px" }} />
          <div style={{ maxHeight: "65vh", overflowY: "scroll", paddingRight: 8 }}>
            {receivers.map((r, idx) => {
              return (
                <Tooltip key={idx} title={renderTooltip(r)}>
                  <Row
                    className={`MessageCreator_ContactContainer ${
                      activeReceiver === r ? "active" : "inactive"
                    } ${
                      (_.isEmpty(r.email_addresses) || r.moved_to_cc) && "disabled"
                    }  ${invalidReceivers.includes(r.id) && "invalid"}`}
                    onClick={() => {
                      if (!_.isEmpty(r.email_addresses) && !r.moved_to_cc) {
                        onSetActiveReceiver(r)
                      }
                    }}
                  >
                    <Col className={"MessageCreator_ContactContainer_Label"} md={22}>
                      {!r.full_name?.trim()
                        ? r?.email_addresses[0]?.value
                          ? r?.email_addresses[0]?.value
                          : r?.first_name || r?.last_name
                        : r?.full_name}
                      {includeRelatedContacts &&
                      r.connexions.length > 0 &&
                      r.connexions.map((c) => c.email).filter(Boolean).length > 0 &&
                      !r.moved_to_cc &&
                      !_.isEmpty(r.email_addresses) ? (
                        <Icon component={RelatedContacts} />
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <CloseOutlined
                        onClick={(evt) => {
                          evt.stopPropagation()
                          handleRemoveReceiver(r.id)
                          onRemoveReceiver(r.id)
                        }}
                      />
                    </Col>
                  </Row>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default ReceiversList
