import {
  Avatar as AntdAvatar,
  Col,
  List,
  Pagination,
  Row,
  Select,
  Skeleton,
  Spin,
  Tooltip,
  Typography,
} from "antd"
import { GlobalOutlined } from "@ant-design/icons/lib"
import "./ActivitiesTab.styles.scss"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchActiveChallenges,
  fetchActivities,
  fetchChallenge,
  fetchPoints,
} from "../../../../redux/Activities/Activities.actions"
import moment from "moment"
import {
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../redux/App/App.actions"
import SparklesIcon from "../../../../components/Icons/Sparkle.icons"
import InfoIcon from "../../../../components/Icons/Info.icons"
import Icon from "@ant-design/icons"
import ActivityScoreSVG from "../../../../images/ActivityScore.svg"
import { Link } from "react-router-dom"
import ChallengeTile from "./ChallengeTile/ChallengeTile.component"
import ChallengeModal from "././ChallengeModal/ChallengeModal.component"
import Avatar from "../../../../components/UI/Avatar/Avatar.component"
import RelatableLoader from "../../../../components/UI/RelatableLoader/RelatableLoader.component"

const DOMPurify = require("dompurify")(window)

const { Text, Title } = Typography

const ActivitiesTab = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserState)

  const dateLabelsOptions = [
    {
      label: "This year",
      value: moment().tz(user.time_zone).startOf("year").format(),
    },
    {
      label: "Last 30 days",
      value: moment().tz(user.time_zone).subtract(1, "months").format(),
    },
    {
      label: "Last 7 days",
      value: moment().tz(user.time_zone).subtract(7, "days").format(),
    },
    {
      label: "Last 24 hours",
      value: moment().tz(user.time_zone).subtract(24, "hours").format(),
    },
  ]
  const [page, setPage] = useState(1)
  const [leaderboardSwitch, setLeaderboardSwitch] = useState("me")
  const [openChallengeModal, setOpenChallengeModal] = useState(false)
  const [optionFilter, setOptionFilter] = useState(dateLabelsOptions[1])
  const activities = useSelector((state) => state.Activities.activities)
  const points = useSelector((state) => state.Activities.points)
  const total_entries = useSelector((state) => state.Activities.total_entries)
  const loading = useSelector((state) => state.Activities.loading)
  const leaderboard = useSelector((state) => state.Activities.leaderboard)
  const challenges = useSelector((state) => state.Activities.challenges)

  useEffect(() => {
    dispatch(fetchActivities(page, 12))
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (loading === false) {
      initalizeIntersection()
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    setPage(1)
    dispatch(fetchActivities(page, 12))
    dispatch(fetchActiveChallenges())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(fetchPoints(optionFilter.value, leaderboardSwitch))
    // eslint-disable-next-line
  }, [optionFilter.value, leaderboardSwitch])

  const handleChangeOption = (option) => {
    setOptionFilter(option)
  }

  const intersectionCallback = (entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate-avatar")
      }
    }
  }

  const initalizeIntersection = () => {
    const observer = new IntersectionObserver(intersectionCallback)

    const items = document.querySelectorAll(".ActivitiesTab_Item .ant-avatar")
    for (const item of items) {
      observer.observe(item)
    }
  }

  const handleOpenChallengeModal = (challengeId) => {
    dispatch(fetchChallenge(challengeId))
    setOpenChallengeModal(true)
  }

  const renderLeaderboard = () => {
    if (leaderboard?.length) {
      return (
        <List
          itemLayout="horizontal"
          dataSource={leaderboard}
          footer={
            <div className={"ActivitiesTab_Footer"}> Resets every Sunday!</div>
          }
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className={"ActivitiesTab_PlaceBadge"}>{item.place}</div>
                }
                title={
                  <div
                    className={`ActivitiesTab_LeaderContainer ${
                      item.it_is_me ? "ActivitiesTab_LeaderContainer--me" : null
                    }`}
                  >
                    <span>
                      {/*{leaderboardSwitch === "past" ? (*/}
                      {/*  <div>{pastDescription(index)}</div>*/}
                      {/*) : (*/}
                      <Tooltip title={item.leaderboard_name}>
                        {item.leaderboard_name}{" "}
                      </Tooltip>
                      {/*)}*/}
                    </span>
                    <Text className={"ActivitiesTab_BoldText"}>{item.score}</Text>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )
    } else {
      return (
        <Text className={"ActivitiesTab_BoldText"}>
          There are currently no leaders
        </Text>
      )
    }
  }

  const calcFontSize = (length) => {
    let size
    switch (true) {
      case length === 3:
        size = 35
        break
      case length === 4:
        size = 30
        break
      case length >= 5:
        size = 25
        break
      default:
        size = 40
    }

    return `${size}px`
  }

  const renderAvatar = (item) => {
    if (item.person?.id === null) {
      return (
        <GlobalOutlined
          style={{ fontSize: 30 }}
          className={"ActivitiesTab_Item_Icon"}
        />
      )
    }
    switch (item.type) {
      case "system":
        if (item.person.avatar) {
          return (
            <span className={"ant-avatar"}>
              <img
                src={`${item.person.avatar}`}
                onLoad={(e) => {
                  if (e.currentTarget.classList.contains("animate-avatar")) {
                    e.currentTarget.classList.remove("animate-avatar")
                  } else {
                    e.currentTarget.classList.add("animate-avatar")
                  }
                }}
                alt="Person avatar"
              />
            </span>
          )
        } else {
          return (
            <Avatar
              avatar={item.person.avatar}
              firstName={item.person.full_name?.split(" ")[0]}
              lastName={item.person.full_name?.split(" ")[1]}
              email={item.person.email?.value}
              colorNumber={item.person.id}
              size={30}
            />
          )
        }
      case "me":
        return <AntdAvatar src={`${user.avatar}`} size={30} />
      default:
        return (
          <GlobalOutlined
            style={{ fontSize: 30 }}
            className={"ActivitiesTab_Item_Icon"}
          />
        )
    }
  }
  const renderHeader = () => {
    return (
      <RelatableLoader loading={loading} quote={true}>
        <div className={"ActivitiesTab_ScoreContainer"}>
          <div className={"ActivitiesTab_ScoreWrapper"}>
            <Title
              style={{ fontSize: calcFontSize(points?.toString().length) }}
              className={"ActivitiesTab_Score"}
            >
              {points}
            </Title>
            <Icon style={{ width: "28px" }} component={SparklesIcon} />
          </div>
          <div className={"ActivitiesTab_ScoreCol"}>
            <Text className={"ActivitiesTab_BoldText"}>
              Network Activity Score
              <Tooltip
                title={
                  "Your network activity score measures how many actions you’ve taken to improve your relationships recently. Every action you do in Relatable adds to your score!"
                }
              >
                {" "}
                <Icon style={{ width: "20px" }} component={InfoIcon} />
              </Tooltip>
            </Text>
            <Row className={"ActivitiesTab_FilterContainer"}>
              <Select
                bordered={false}
                className={"ActivitiesTab_Select"}
                disabled={loading}
                defaultValue={dateLabelsOptions[1]}
                onChange={(value, option) => handleChangeOption(option)}
                labelInValue={true}
                options={dateLabelsOptions}
              />
            </Row>
          </div>
        </div>
        {!user?.hide_leaderboard && (
          <div className={"ActivitiesTab_Highscores"}>
            <div className={"ActivitiesTab_HighscoresList"}>
              {user.leaderboard_name && (
                <div className="ActivitiesTab_HighscoresList_Switch">
                  Show{" "}
                  <button
                    className={leaderboardSwitch !== "me" ? "clickable" : null}
                    onClick={() => setLeaderboardSwitch("me")}
                    disabled={leaderboardSwitch === "me"}
                  >
                    Me
                  </button>
                  |
                  <button
                    className={leaderboardSwitch !== "top" ? "clickable" : null}
                    onClick={() => setLeaderboardSwitch("top")}
                    disabled={leaderboardSwitch === "top"}
                  >
                    Top
                  </button>
                  |
                  <button
                    className={leaderboardSwitch !== "past" ? "clickable" : null}
                    onClick={() => setLeaderboardSwitch("past")}
                    disabled={leaderboardSwitch === "past"}
                  >
                    Past
                  </button>
                </div>
              )}
              {renderLeaderboard()}
              {!user.leaderboard_name && (
                <div className={"ActivitiesTab_LinkContainer"}>
                  Add yourself to the competition -{" "}
                  <Link className={"ActivitiesTab_Link"} to="/account/settings">
                    click here
                  </Link>
                </div>
              )}
            </div>
            <div className={"ActivitiesTab_ImageContainer"}>
              <img src={ActivityScoreSVG} alt="Acticity Score" />
            </div>
          </div>
        )}
        {challenges.map((challenge) => (
          <ChallengeTile
            key={challenge.id}
            id={challenge.id}
            openModal={handleOpenChallengeModal}
            one_liner={challenge.one_liner}
            title={challenge.name}
            progress={challenge.percentage_progress}
          />
        ))}
        <div className={"ActivitiesTab_Recent ActivitiesTab_BoldText"}>
          Recent activity
        </div>
      </RelatableLoader>
    )
  }

  const renderActivity = (item) => {
    return (
      <List.Item className={"ActivitiesTab_Item"} key={item.id || item}>
        <Skeleton loading={!activities || loading} active avatar>
          <List.Item.Meta
            avatar={renderAvatar(item)}
            title={
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.content),
                }}
                onClick={(event) => {
                  if (item.person?.id) {
                    dispatch(setContactDrawerVisible(true))
                    dispatch(setVisibleContactID(item.person.id, item.person.slug))
                  }
                }}
                className={`ActivitiesTab_Item_Content ${
                  item.type === "error" ? "error" : ""
                }`}
              />
            }
            description={
              <Row>
                <Col md={12} className={"ActivitiesTab_Item_Date"}>
                  {moment.unix(item.timestamp).tz(user.time_zone).format("LLL")}
                </Col>
                {item.points > 0 && (
                  <Col md={3} className={"ActivitiesTab_Item_Points"} span={1}>
                    +{item.points}
                    <Icon style={{ width: "18px" }} component={SparklesIcon} />
                  </Col>
                )}
              </Row>
            }
          />
        </Skeleton>
      </List.Item>
    )
  }

  return (
    <div className="ActivitiesTab">
      {openChallengeModal && (
        <ChallengeModal
          onClose={() => setOpenChallengeModal(false)}
          visible={openChallengeModal}
        />
      )}
      <List
        header={renderHeader()}
        style={{
          height: "calc(100% - 50px)",
          overflowY: "scroll",
          marginBottom: "1rem",
        }}
        renderItem={renderActivity}
        dataSource={
          activities
            ? activities
            : Array(13)
                .fill(null)
                .map((u, i) => i)
        }
      />
      <Row type={"flex"} align={"middle"} justify={"end"}>
        <Col span={24}>
          <Pagination
            style={{ float: "right" }}
            onChange={(page) => setPage(page)}
            size={"small"}
            total={total_entries}
            showSizeChanger={false}
            hideOnSinglePage={true}
            pageSize={12}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ActivitiesTab
