import React, { useState } from "react"
import "./InteractionTextarea.styles.scss"
import { Form, Mentions, Spin } from "antd"
import { fetchForMentions } from "../../../DashboardForm/Tabs/NewNoteTab/NewNoteTab.actions"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import moment from "moment"
import { addInteraction } from "../../../../../redux/Interactions/Interactions.actions"
import * as _ from "lodash"
import PropTypes from "prop-types"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const { Option } = Mentions

const InteractionTextarea = ({ initialContent, person, afterSubmit, method }) => {
  const dispatch = useDispatch()
  const adding = useSelector((state) => state.InteractionsState.adding_interactions)
  const user = useSelector((state) => state.UserState)

  const [prefix, setPrefix] = useState("@")

  const [loading, setLoading] = useState(false)
  const [participants, setParticipants] = useState([])
  const [topics, setTopics] = useState([])
  const [expertises, setExpertises] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const formatTimestamp = () => {
    const fDate = moment().tz(user.time_zone).format("YYYY-MM-DD")
    const fTime = moment().tz(user.time_zone).format("HH:mm:ss")

    return moment(fDate + "T" + fTime)
      .tz(user.time_zone)
      .format()
  }

  const formik = useFormik({
    initialValues: {
      body: initialContent,
      participants: [],
      topics: [],
      expertises: [],
    },
    onSubmit: (values) => {
      const personFormattedName = person.full_name.replace(" ", "_")
      const newBody = `Connect @${personFormattedName} via ${method} ${values.body}`
      const newParticipants = [
        ...values.participants,
        { id: person.id, value: personFormattedName },
      ]
      dispatch(
        addInteraction(
          {
            subject: `Connect via ${method}`,
            body: newBody,
            interaction_method: method,
            last_message_timestamp: formatTimestamp(),
            people: _.uniqBy(newParticipants, "id").filter((p) =>
              newBody.includes(`@${p.value}`)
            ),
            topics: _.uniqBy(values.topics, "id").filter((t) =>
              values.body.includes(`#${t?.value}`)
            ),
            expertises: _.uniqBy(values.expertises, "id").filter((t) =>
              values.body.includes(`~${t?.value}`)
            ),
          },
          true
        )
      )
      if (afterSubmit) afterSubmit()
    },
  })

  const handleSubmit = () => {
    formik.submitForm().then(() => formik.resetForm())
  }
  const getMentions = (prefix) => {
    switch (prefix) {
      case "@":
        return participants || []
      case "#":
        return [...topics, { id: null, name: searchQuery }] || []
      case "~":
        return [...expertises, { id: null, name: searchQuery }] || []
      default:
        return []
    }
  }

  const loadTopics = (query) => {
    fetchForMentions(query, null, "topics").then((res) => {
      setTopics(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const handleSearch = (searchQuery, _prefix) => {
    setSearchQuery(searchQuery)
    setPrefix(_prefix)
    setParticipants([])
    setTopics([])
    setExpertises([])
    setLoading(true)

    if (_prefix === "@") {
      loadParticipants(searchQuery)
    } else if (_prefix === "#") {
      loadTopics(searchQuery)
    } else if (_prefix === "~") {
      loadExpertises(searchQuery)
    }
  }

  const [debouncedSearch] = useState(() => _.debounce(handleSearch, 500))

  const loadExpertises = (query) => {
    fetchForMentions(query, null, "expertises").then((res) => {
      setExpertises(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const loadParticipants = (query) => {
    fetchForMentions(query, "SORT").then((res) => {
      setParticipants(res.slice(0, 10))
      setLoading(false)
    })
  }

  const handleSelect = (id, value, _prefix) => {
    if (_prefix === "@") {
      formik.setFieldValue("participants", [
        ...formik.values.participants,
        { id: id, value: value },
      ])
    } else if (_prefix === "#") {
      formik.setFieldValue("topics", [
        ...formik.values.topics,
        { id: id, value: value },
      ])
    } else if (_prefix === "~") {
      formik.setFieldValue("expertises", [
        ...formik.values.expertises,
        { id: id, value: value },
      ])
    }
  }

  return (
    <>
      <div className="InteractionTextarea">
        {adding ? (
          <RelatableLoader />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="InteractionTextarea_Container">
              <div className="InteractionTextarea_Header">
                <span className="InteractionTextarea_Header_Helper">
                  Connect {person.full_name} via {method}
                </span>
              </div>
              <Form.Item
                validateStatus={
                  formik.touched.body && formik.errors.body ? "error" : "success"
                }
                help={
                  formik.touched.body && formik.errors.body ? formik.errors.body : ""
                }
              >
                <Mentions
                  key="body"
                  name="body"
                  className="InteractionTextarea_Textarea"
                  placeholder="Type description..."
                  rows={6}
                  prefix={["@", "#", "~"]}
                  loading={loading}
                  onSearch={debouncedSearch}
                  onChange={(e) => formik.setFieldValue("body", e)}
                  onBlur={() => formik.setFieldTouched("body", true)}
                  onSelect={(selected, _prefix) =>
                    handleSelect(selected.id, selected.value, _prefix)
                  }
                  value={formik.values.body}
                  autoFocus
                >
                  {(getMentions(prefix) || []).map((mention) => (
                    <Option
                      id={mention.id}
                      name={mention.name}
                      key={mention.id}
                      value={mention.name ? mention.name.replace(/ /g, "_") : ""}
                      className="antd-demo-dynamic-option"
                    >
                      <span>{mention.name}</span>
                    </Option>
                  ))}
                </Mentions>
              </Form.Item>
              <div className="InteractionTextarea_Footer">
                <span className="InteractionTextarea_Footer_Helper">
                  @ to attach this meeting to a person, # to add topics, ~ to add
                  expertise
                </span>
              </div>
            </div>
          </form>
        )}
      </div>
      <button
        onClick={handleSubmit}
        style={{ marginTop: "32px" }}
        className="primary"
      >
        👍🏼 Save and update their contact profile
      </button>
    </>
  )
}

InteractionTextarea.propTypes = {
  initialContent: PropTypes.string.isRequired,
  participant: PropTypes.object,
  afterSubmit: PropTypes.func,
  method: PropTypes.oneOf(["call", "sms"]),
}

InteractionTextarea.defaultProps = {
  initialContent: "",
  method: "call",
}

export default InteractionTextarea
