import { Button, Table } from "antd"
import axios from "axios"
import React, { useEffect, useState } from "react"
import PresetTemplateModal from "./PresetTemplateModal.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const PresetTemplates = () => {
  const [preset_templates, setPresetTemplates] = useState([])
  const [currentPresetTemplate, setCurrentPresetTemplate] = useState(null)
  const [presetTemplatesModalVisible, setPresetTemplatesModalVisible] =
    useState(false)

  const [loading, setLoading] = useState(false)

  const getPresetTemplates = async () => {
    setLoading(true)
    await axios
      .request({
        url: "/v1/preset_templates",
        method: "GET",
      })
      .then((r) => {
        setPresetTemplates(r.data)
        setLoading(false)
      })
  }

  const deletePresetTemplate = (id) => async () => {
    setLoading(true)
    await axios
      .request({
        url: `/v1/preset_templates/${id}`,
        method: "DELETE",
      })
      .then(() => {
        getPresetTemplates()
        setLoading(false)
      })
  }

  useEffect(() => {
    getPresetTemplates()
  }, [])

  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: "20%",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
      width: "20%",
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (record) => [
        <Button
          onClick={() => {
            setCurrentPresetTemplate(record)
            setPresetTemplatesModalVisible(true)
          }}
        >
          Edit
        </Button>,
        <Button type="danger" onClick={deletePresetTemplate(record.id)}>
          Delete
        </Button>,
      ],
    },
  ]

  return (
    <div className="ChallengeTable">
      <PresetTemplateModal
        visible={presetTemplatesModalVisible}
        handleClickCancel={() => {
          setPresetTemplatesModalVisible(false)
          setCurrentPresetTemplate(null)
        }}
        presetTemplate={currentPresetTemplate}
        handleGetPresetTemplates={getPresetTemplates}
      />
      <Button
        className="ChallengeTable_NewChallengeButton"
        type="primary"
        onClick={() => {
          setPresetTemplatesModalVisible(true)
          setCurrentPresetTemplate({})
        }}
      >
        New Preset Template
      </Button>
      <Table
        rowKey="id"
        tableLayout="fixed"
        className="ChallengesTable"
        style={{ height: "auto", maxHeight: "80%" }}
        loading={{
          spinning: loading,
          indicator: <RelatableLoader quote={true} loading={loading} />,
        }}
        columns={columns}
        dataSource={preset_templates}
        pagination={false}
      />
    </div>
  )
}

export default PresetTemplates
