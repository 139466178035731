import { Calendar, Col, Divider, Row, Select, Tooltip } from "antd"
import React, { useState } from "react"
import "./RecencySelector.styles.scss"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { addInteraction } from "../../../redux/Interactions/Interactions.actions"

const CalendarHeader = ({ value, onChange }) => {
  const start = 0
  const end = 12
  const monthOptions = []

  const current = value.clone()
  const localeData = value.localeData()
  const months = []
  for (let i = 0; i < 12; i++) {
    current.month(i)
    months.push(localeData.monthsShort(current))
  }

  for (let index = start; index < end; index++) {
    monthOptions.push(
      <Select.Option className="month-item" key={`${index}`}>
        {months[index]}
      </Select.Option>
    )
  }
  const month = value.month()

  const year = value.year()
  const options = []
  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    )
  }
  return (
    <div style={{ paddingBottom: 10 }}>
      <Row style={{ width: "100%" }} gutter={[10, 10]} type="flex" justify="center">
        <Col
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Select
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ zIndex: 9999 }}
            value={String(month)}
            onChange={(selectedMonth) => {
              const newValue = value.clone()
              newValue.month(parseInt(selectedMonth, 10))
              onChange(newValue)
            }}
          >
            {monthOptions}
          </Select>
        </Col>
        <Col
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Select
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ zIndex: 9999 }}
            onChange={(newYear) => {
              const now = value.clone().year(newYear)
              onChange(now)
            }}
            value={String(year)}
          >
            {options}
          </Select>
        </Col>
      </Row>
    </div>
  )
}

const RecencySelectorText = ({ id, onSubmit }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserState)
  const [currentValue, setCurrentValue] = useState(moment().tz(user.time_zone))

  return (
    <div>
      <Row gutter={[10, 20]}>
        <Col
          span={24}
          className={"RecencySelector_Option"}
          onClick={() => {
            const date = moment().tz(user.time_zone).format()
            dispatch(
              addInteraction({
                subject: "Manually added interaction",
                body: "Manually added interaction",
                last_message_timestamp: date,
                interaction_method: "Manual",
                people: [{ id: id }],
                expertises: [],
                topics: [],
              })
            )
            if (onSubmit) onSubmit(date)
          }}
        >
          Just now
        </Col>
        <Col
          span={24}
          className={"RecencySelector_Option"}
          onClick={() => {
            const date = moment().tz(user.time_zone).subtract(7, "d").format()
            dispatch(
              addInteraction({
                subject: "Manually added interaction",
                body: "Manually added interaction",
                last_message_timestamp: date,
                interaction_method: "Manual",
                people: [{ id: id }],
                expertises: [],

                topics: [],
              })
            )
            if (onSubmit) onSubmit(date)
          }}
        >
          Last week
        </Col>
        <Col
          span={24}
          className={"RecencySelector_Option"}
          onClick={() => {
            const date = moment().tz(user.time_zone).subtract(1, "months").format()
            dispatch(
              addInteraction({
                subject: "Manually added interaction",
                body: "Manually added interaction",
                last_message_timestamp: date,
                interaction_method: "Manual",
                people: [{ id: id }],
                expertises: [],

                topics: [],
              })
            )
            if (onSubmit) onSubmit(date)
          }}
        >
          Last month
        </Col>
        <Divider style={{ margin: 2 }} />
        <Col span={24}>
          <Calendar
            value={currentValue}
            onSelect={(value) => {
              const date = moment(value).tz(user.time_zone).format()
              dispatch(
                addInteraction({
                  subject: "Manually added interaction",
                  body: "Manually added interaction",
                  last_message_timestamp: date,
                  interaction_method: "Manual",
                  people: [{ id: id }],
                  expertises: [],
                  topics: [],
                })
              )
              if (onSubmit) onSubmit(date)
            }}
            fullscreen={false}
            headerRender={({ value, type, onChange, onTypeChange }) => (
              <CalendarHeader
                value={value}
                type={type}
                onChange={(date) => {
                  setCurrentValue(date)
                }}
                onTypeChange={(type) => {
                  onTypeChange(type)
                }}
              />
            )}
          />
        </Col>
      </Row>
    </div>
  )
}

const RecencySelector = ({ children, id, opened, onSubmit, onClose }) => {
  return (
    <Tooltip
      title={<RecencySelectorText id={id} onSubmit={onSubmit} />}
      trigger={"click"}
      onVisibleChange={(visible) => {
        if (!visible) {
          onClose()
        }
      }}
      visible={opened}
      placement={"top"}
      icon={null}
      overlayClassName={"RecencySelector"}
    >
      {children}
    </Tooltip>
  )
}

export default RecencySelector
