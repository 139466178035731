import axios from "axios"

const addToSpheres = async (participant_id, spheres, callback) => {
  const { data } = await axios.request({
    url: "/v1/participants/add_participants_to_sphere",
    data: {
      spheres: spheres,
      participants_ids: [participant_id],
    },
    method: "PUT",
  })

  callback(data)
}

export default addToSpheres
