import React from "react"

const WizardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <path
      stroke="currentColor"
      d="M 3 19.5 L 7.902344 8.46875 C 8.621094 6.859375 9.792969 5.492188 11.277344 4.546875 L 18.164062 0.140625 C 18.304688 0.0507812 18.46875 0.00390625 18.632812 0.00390625 C 19.109375 0 19.5 0.390625 19.5 0.871094 L 19.5 0.945312 C 19.5 1.070312 19.476562 1.1875 19.433594 1.296875 L 16.632812 8.292969 C 16.542969 8.511719 16.5 8.746094 16.5 8.980469 C 16.5 9.238281 16.554688 9.496094 16.660156 9.734375 L 21 19.5 L 11.292969 19.5 L 11.84375 17.839844 L 13.738281 17.207031 C 14.042969 17.105469 14.25 16.820312 14.25 16.496094 C 14.25 16.171875 14.042969 15.886719 13.738281 15.78125 L 11.84375 15.148438 L 11.210938 13.257812 C 11.109375 12.957031 10.824219 12.75 10.5 12.75 C 10.175781 12.75 9.890625 12.957031 9.789062 13.261719 L 9.15625 15.15625 L 7.261719 15.789062 C 6.957031 15.890625 6.75 16.175781 6.75 16.5 C 6.75 16.824219 6.957031 17.109375 7.261719 17.210938 L 9.15625 17.84375 L 9.707031 19.5 Z M 13.105469 6.632812 C 13.054688 6.476562 12.914062 6.375 12.75 6.375 C 12.585938 6.375 12.445312 6.476562 12.394531 6.632812 L 12.078125 7.578125 L 11.132812 7.894531 C 10.976562 7.945312 10.875 8.085938 10.875 8.25 C 10.875 8.414062 10.976562 8.554688 11.132812 8.605469 L 12.078125 8.921875 L 12.394531 9.867188 C 12.445312 10.023438 12.585938 10.125 12.75 10.125 C 12.914062 10.125 13.054688 10.023438 13.105469 9.867188 L 13.421875 8.921875 L 14.367188 8.605469 C 14.523438 8.554688 14.625 8.414062 14.625 8.25 C 14.625 8.085938 14.523438 7.945312 14.367188 7.894531 L 13.421875 7.578125 Z M 1.5 21 L 22.5 21 C 23.328125 21 24 21.671875 24 22.5 C 24 23.328125 23.328125 24 22.5 24 L 1.5 24 C 0.671875 24 0 23.328125 0 22.5 C 0 21.671875 0.671875 21 1.5 21 Z M 1.5 21 "
    />
  </svg>
)

export default WizardIcon
