import React, { useState } from "react"
import { Card } from "antd"
import NewNoteTab from "./Tabs/NewNoteTab/NewNoteTab.component"
import NewIntroTab from "./Tabs/NewIntroTab/NewIntroTab.component"
import NewInteractionTab from "./Tabs/NewInteractionTab/NewInteractionTab.component"
import NewTaskTab from "./Tabs/NewTaskTab/NewTaskTab.component"
import Icon from "@ant-design/icons"
import NoteIcon from "../../Icons/Note.icons"
import UsersIcon from "../../Icons/Users.icons"
import CalendarCheckIcon from "../../Icons/CalendarCheck.icons"
import ArrowsLeftRightIcon from "../../Icons/ArrowsLeftRight.icons"
import "./DashboardForm.style.scss"
import DashboardFormTab from "./DashboardFormTab.component"
import PlantIcon from "../../Icons/Plant.icons"
import GrowRelationshipsTab from "./Tabs/GrowRelationshipsTab/GrowRelationshipsTab.component"

const DashboardForm = () => {
  const [activeTab, setActiveTab] = useState(null)
  const [collapsed, setCollapsed] = useState(true)

  const DashboardFormTabs = [
    {
      key: "grow_relationships",
      tab: (
        <DashboardFormTab
          title="grow_relationships"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCollapsed={setCollapsed}
        >
          <Icon component={PlantIcon} className="DashboardForm_PlantIcon" />
          <span>Grow Relationships</span>
        </DashboardFormTab>
      ),
    },
    {
      key: "note",
      tab: (
        <DashboardFormTab
          title="note"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCollapsed={setCollapsed}
        >
          <Icon component={NoteIcon} />
          <span>Add a Note</span>
        </DashboardFormTab>
      ),
    },
    {
      key: "interaction",
      tab: (
        <DashboardFormTab
          title="interaction"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCollapsed={setCollapsed}
        >
          <Icon component={UsersIcon} />
          <span>Log a Meeting</span>
        </DashboardFormTab>
      ),
    },
    {
      key: "task",
      tab: (
        <DashboardFormTab
          title="task"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCollapsed={setCollapsed}
        >
          <Icon component={CalendarCheckIcon} />
          <span>Add a Reminder</span>
        </DashboardFormTab>
      ),
    },
    {
      key: "introduction",
      tab: (
        <DashboardFormTab
          title="introduction"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCollapsed={setCollapsed}
        >
          <Icon component={ArrowsLeftRightIcon} />
          <span>Make an Introduction</span>
        </DashboardFormTab>
      ),
    },
  ]

  const DashboardFormContent = {
    grow_relationships: <GrowRelationshipsTab />,
    note: <NewNoteTab />,
    interaction: <NewInteractionTab />,
    task: <NewTaskTab />,
    introduction: <NewIntroTab />,
  }
  return (
    <Card
      tabList={DashboardFormTabs}
      className={`DashboardForm ${collapsed ? "DashboardForm--collapsed" : null}`}
      activeTabKey={activeTab}
      onTabChange={(tab) => {
        setActiveTab(tab)
        setCollapsed(false)
      }}
    >
      {DashboardFormContent[activeTab]}
    </Card>
  )
}

export default DashboardForm
