import React from "react"

export const urlify = (text, string = true) => {
  if (!text) return null

  const urlRegex = /(((https?:\/\/)|(www\.))(?:[^\s]+))/gi
  //var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url, b, c) => {
    const url2 = c === "www." ? "http://" + url : url
    if (string) {
      return (
        '<a href="' +
        url2 +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      )
    } else {
      return (
        <a href={`"${url2}"`} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      )
    }
  })
}

export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  let digits = phoneNumber.replace(/\D/g, "")

  // Check if it's a valid USA number
  if (digits.length === 10 || (digits.length === 11 && digits.startsWith("1"))) {
    // Format USA number
    let formattedNumber = digits.replace(
      /(\d{1})?(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3, p4) => {
        if (p1) {
          return `1 (${p2}) ${p3}-${p4}`
        } else {
          return `(${p2}) ${p3}-${p4}`
        }
      }
    )
    return formattedNumber
  } else {
    // Format universally
    return digits.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "+$1 $2 $3 $4")
  }
}
