import React from "react"
import { Link } from "react-router-dom"
import { Col, Row } from "antd"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"

const PrivacyPage = () => {
  const dispatch = useDispatch()

  return (
    <Row style={{ height: "100%", maxHeight: "100vh", overflow: "auto" }}>
      <Col span={12} offset={6} style={{ overflow: "auto" }}>
        <Link to="/">Back to Relatable</Link>
        <hr />
        <h1>Privacy Policy</h1>
        <p className="c29">
          <span className="c5">
            Our Services enables you to strengthen, track and leverage your contacts
            and communications with your contacts by using a variety of automated and
            machine-learning methods to analyze information you have authorized the
            Services to access (&ldquo;Content&rdquo;) in your mobile device, email
            accounts, and in other online, cloud or mobile services
            (&ldquo;Authorized Sources&rdquo;). This means that Relatable will have
            access to, and will analyze, among other things, your full emails,
            contact lists and other materials. All analysis will be conducted
            consistent with this Privacy Policy, which you should review carefully.
          </span>
        </p>
        <h2 className="c12" id="h.i7fac24421h">
          <span className="c6">Our Policy</span>
        </h2>
        <p className="c20">
          <span className="c31">
            This privacy policy (the &ldquo;Privacy Policy&rdquo;) governs how we
            collect and process personal information through our websites including{" "}
          </span>
          <span className="c9">
            <a className="c24" href="/">
              www.relatable.one
            </a>
          </span>
          <span className="c5">
            &nbsp;(the &ldquo;Site&rdquo;), our customer relationship management
            applications and related services, and through our normal business
            activities including when an individual or business registers to use our
            Services (a &ldquo;Customer&rdquo;) or corresponds with us by post,
            phone, email or otherwise (collectively, the &ldquo;Services&rdquo;).
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            In this Privacy Policy, &ldquo;Relatable&rdquo;, &ldquo;we&rdquo;,
            &ldquo;us&rdquo;, &ldquo;our&rdquo; and other similar references mean
            RogerTen, LLC., a company established in the United States of America.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            The term &ldquo;personal information&rdquo; as used in this Privacy
            Policy shall mean any information that enables us to identify you,
            directly or indirectly, by reference to an identifier such as your name,
            identification number, location data, online identifier or one or more
            factors specific to you. Personal information excludes anonymous
            information or information that has had the identity of an individual
            permanently removed.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            By registering for and using the Services, you accept the practices
            described in this Privacy Policy.
          </span>
        </p>
        <h2 className="c12" id="h.ujm7i2n18y6o">
          <span className="c6">What Information Do We Collect?</span>
        </h2>
        <h3 className="c18" id="h.aggsnoeo1sry">
          <span className="c15">1. Information Customers Provide to Us</span>
        </h3>
        <p className="c11">
          <span className="c5">
            We collect and store personal information, such as your name, address,
            email address, phone, usernames and passwords when you register to use
            our Services, ask a question, complete a form, conduct a transaction,
            send us an email or attend our events. If you do not provide the
            requested information, you may not be able to access or use some of the
            features and functions of our Services.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            Furthermore, when you contact us (online or offline) in connection with a
            request for information or access the Services, we collect personal
            information necessary to fulfill your request, to grant you access to the
            Services and to provide you with support. In order to register to use our
            Services, payment and billing information will also be required and we
            will maintain purchase records and transactional information which will
            contain personal information about you and your use of the Services.
          </span>
        </p>
        <h3 className="c18" id="h.ihezr64q1yg5">
          <span className="c15">
            2. Information Processed on Behalf Of Our Customers
          </span>
        </h3>
        <p className="c11">
          <span className="c5">
            When Customers use our Services, Relatable will collect personal
            information and other information (&ldquo;Content&rdquo;) relating to
            third parties contained in mobile devices, email accounts and in other
            online, cloud or mobile services (&ldquo;Authorized Sources&rdquo;) where
            our Customers have authorized the Services to access such Authorized
            Sources. using the Services and providing us with access to Authorized
            Sources, Customers warrant they have the right to provide us with access
            to each Authorized Sources, as well as to the personal information and
            Content contained therein.
          </span>
        </p>
        <h3 className="c18" id="h.bjstzlg1wj8u">
          <span className="c15">3. Information We Automatically Collect</span>
        </h3>
        <p className="c11">
          <span className="c5">
            We collect content or other information that you may provide or create
            when you interact with the Services. We may also automatically collect
            certain usage information when you access our Services, such as Internet
            Protocol (&ldquo;IP&rdquo;) addresses, log files, unique device
            identifiers, pages viewed, browser type, any links you click on to leave
            or interact with the Services, and other usage information collected from
            cookies and other tracking technologies (described below). We also
            collect IP addresses from users when they log into the Services and may
            also, when you enable location-based Services, collect Global Positioning
            System (GPS) location data and/or motion data.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            We collect general statistics to track user trends for our Services. The
            information that is collected in this manner does NOT provide us with any
            personal information about our users, unless combined with other
            information we hold. This allows us to improve the content and operation
            of the Services, to determine the most effective ways to communicate with
            our users and to undertake research and analysis. We also use this
            technical information to prevent the introduction of viruses and hackers
            into the Services.
          </span>
        </p>
        <h3 className="c18" id="h.405us97w80fu">
          <span className="c15">4. Information We Collect From Other Sources</span>
        </h3>
        <p className="c11">
          <span className="c5">
            We may also collect information from other companies or third parties or
            from various service providers to supplement the personal information and
            Content provided by our Customers to help us maintain the accuracy of
            personal information and improve our Services.
          </span>
        </p>
        <h2 className="c12" id="h.p6wfjo7zbxlv">
          <span className="c6">How Do We Use Cookies?</span>
        </h2>
        <p className="c20">
          Cookies, web beacons and clear gifs are methods used to uniquely identify
          the device you are using to interact with the Services. We use these
          technologies to recognize you, optimize the Services for you and to gather
          information about how users use the Services. Any information provided to
          third parties through these technologies may provide general information
          for the enhancement of your user experience by providing more relevant
          advertising. If you block or refuse these technologies, you may not be able
          to access or use certain features or functions of our Services and may have
          a degraded experience.{" "}
        </p>
        <h2 className="c12" id="h.8mw26ijbyin6">
          <span className="c6">How Does Relatable Use Information?</span>
        </h2>
        <h3 className="c34" id="h.5g7yyf72orn5">
          <span className="c15">
            We will use your personal information for the following purposes:
          </span>
        </h3>
        <ul className="c14 lst-kix_jfpsg2rqed0g-0 start">
          <li className="c10">
            <span className="c4">To confirm your identity,</span>
          </li>
          <li className="c10">
            <span className="c4">To complete transactions,</span>
          </li>
          <li className="c10">
            <span className="c4">
              To provide, operate and maintain the Services,
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              To operate our business, including internal business intelligence
              purposes, to conduct research, product development and enhancement,
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              To respond to your requests and deliver the products and services you
              request,
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              To provide support related to the Services and related customer
              services,
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              To help us protect our Services, including to combat fraud and protect
              personal information,
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              To inform you of changes made to our Site and Services,
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              To ensure that our Services are presented most effectively for you and
              your device;
            </span>
          </li>
          <li className="c10">
            <span className="c4">To display content based on your interests;</span>
          </li>
        </ul>
        <h2 className="c12" id="h.giefqrjdtoqr">
          <span className="c6">
            Aggregating and Sharing Combined Information From Many Users
          </span>
        </h2>
        <p className="c11">
          <span className="c5">
            At no time would we aggregate and share information with other users in a
            manner that would allow you or any other person to be identified.
          </span>
        </p>
        <h2 className="c12" id="h.ufx8n6qzsm3p">
          <span className="c6">
            Will Relatable Disclose The Information It Collects To Outside Parties?
          </span>
        </h2>
        <p className="c7">
          <span className="c23">
            In addition to the Aggregating and Sharing Combined Information section,
            above, Relatable may disclose personal information and Content we collect
            with the parties below for the purposes set out in this policy:
          </span>
        </p>
        <ul className="c14 lst-kix_cmde7o9chbf1-0 start">
          <li className="c10">
            <span className="c4">A member of our group or our affiliates,</span>
          </li>
          <li className="c10">
            <span className="c4">
              Our professional advisors such as auditors, accountants and lawyers,
              etc., and
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              Companies that provide services to help us with our business
              activities, such as data storage, maintenance services, database
              management, web analytics and payment processing.
            </span>
          </li>
        </ul>
        <p className="c29">
          <span className="c5">
            We may also disclose personal information and Content if required to do
            so by law or in good-faith belief that disclosure is necessary (a) to
            obey the law or comply with legal process served on us or our affiliates;
            (b) to protect and defend our rights or property or the rights or
            property of other users of our Service; or (c) to act in an emergency to
            protect the personal safety of users of our Services or the public.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            As Relatable continues to develop its business, we might sell or buy
            lines of business or assets. If all or substantially all of
            Relatable&rsquo;s business (or a line of Relatable&rsquo;s business) or
            assets (or the assets of a line of Relatable&rsquo;s business) are
            acquired by a third party, personal information will generally be one of
            the transferred business assets. We reserve the right to include your
            personal information, collected as an asset, in any such transfer to a
            third party. If so, you will be provided advance notice of the impending
            change of control, so that you may have the opportunity to terminate your
            access to the Service, if necessary.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            We require all third parties to respect the security of your personal
            information and to treat it in accordance with the law. We do not allow
            our third-party service providers to use your personal information for
            their own purposes and only permit them to process your personal
            information for specified purposes and in accordance with our
            instructions.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            Where personal information relating to European-based individuals is
            shared with a third party located in a non-EU country that has not
            received an adequacy decision by the European Commission, we rely on
            appropriate safeguards, such as the European Commission-approved Standard
            Contractual Clauses and EU-U.S. and Swiss-U.S. Privacy Shield Frameworks,
            to transfer the data.
          </span>
        </p>
        <h2 className="c12" id="h.kkhgmht3hcia">
          <span className="c6">Access To Your Authorized Services And CRM.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            The primary function of the Services is to receive Access to your Content
            through your Authorized Services and sync that information to your
            designated CRM. To do this we must access your Authorized Services with
            the login information you use to access them. We will request your user
            name, password, and any other login data that you have set up with your
            Authorized Sources to enable access. We use this information to update
            and maintain your Content and to enhance the Services over time.
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            We also work with other companies or developers to optimize the Services
            and you may choose to sync, link or connect other third party services,
            over time. Sometimes we may let you know about another service or
            product, or another company may let you know about a service or product
            related to Relatable&rsquo;s Service. It will be clear who is referring
            the service or product, and who is providing the service or product. If
            you choose to accept these services, providing your consent to either the
            third party or to us, we may exchange your information, including your
            personal information, as well as information about how you interact with
            each company&#39;s service or product. This exchange of information is
            necessary to maintain business operations and to provide the ongoing
            service you&#39;ve requested. By requesting or accepting these products
            or services, you are permitting us to provide your information, including
            your personal information, to the other party.
          </span>
        </p>
        <h2 className="c12" id="h.nfceyw8glu7y">
          <span className="c6">What You Can Do to Manage Your Privacy.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            You can view and edit your personal information through the Services. You
            have a choice about the use of information that identifies you, marketing
            communications you receive from us, and our use of cookies and other
            tracking technologies.
          </span>
        </p>
        <h3 className="c18" id="h.i6yyia38ktlg">
          <span className="c15">i. Updating Your Personal Information.</span>
        </h3>
        <p className="c11">
          <span className="c5">
            In connection with your right to manage personal information you provide
            to us, you may access, update, change, correct or request deletion of
            your information either through the Services or through our customer
            support.
          </span>
        </p>
        <h3 className="c18" id="h.wsf9el3z1pqz">
          <span className="c15">ii. Managing Marketing Communications.</span>
        </h3>
        <p className="c11">
          <span className="c5">
            We will honor your choices when it comes to receiving marketing
            communications from us.
          </span>
        </p>
        <p className="c7">
          <span className="c23">
            You have the following choices if you have been receiving marketing
            communications from us that you no longer wish to receive:
          </span>
        </p>
        <ul className="c14 lst-kix_dpubfi93qf5y-0 start">
          <li className="c10">
            <span className="c4">
              Subject to your control, our mobile applications may send push
              notifications to your mobile device. If you are receiving push
              notifications and no longer wish to receive these types of
              communications, you may turn them off at the device level.
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              Remember that even if you choose not to receive marketing
              communications from us, we will continue to send you mandatory service
              or transactional communications.
            </span>
          </li>
        </ul>
        <h2 className="c12" id="h.k32gcefx8vbn">
          <span className="c6">Social Media Features.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Our Services may use social media features, such as Facebook sharing
            (&quot;Social Media Features&quot;). These features may collect your IP
            address and which page you are visiting within our Services, and may set
            a cookie to enable the feature to function properly. Social Media
            Features are either hosted by a third party or hosted directly on our
            Services. Your interactions with these features are governed by the
            privacy statement of the company providing the relevant Social Media
            Features.
          </span>
        </p>
        <h2 className="c12" id="h.e27mhusl92na">
          <span className="c6">
            What Security Procedures Does Relatable Use To Protect Personal
            Information And Content?
          </span>
        </h2>
        <p className="c11">
          <span className="c5">
            We use physical, electronic, and administrative safeguards to assist us
            in preventing unauthorized access, maintaining data accuracy, and
            correctly using your personal information and any Content. We use
            reasonable efforts to avoid the presence of malware on our Site or as
            part of the Services. Except to the extent due to our gross negligence or
            willful misconduct, we expressly disclaim, and you release us from any
            liability associated with any technical issues caused by the Services,
            including any malware communicated to your computer or mobile device
            through any use of the Services. Except as specified in this Privacy
            Policy, we use commercially reasonable efforts to limit access to your
            personal information and any Content to the employees, agents, and
            officers of Relatable who need the information for their jobs.
          </span>
        </p>
        <h2 className="c12" id="h.5h9ajtnmlpa4">
          <span className="c6">External Links</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Other companies or organizations may have links to their websites in our
            Services. We are not responsible for how these other companies or
            organizations collect, use, disclose, or secure the information that you
            provide them. If you choose to access a third party website through a
            link provided as part of our Services, you do so at your own risk and
            subject to any terms of service or privacy policy (if any) associated
            with such third party website. Similarly, we use third party-provided
            APIs and other tools to receive information from accounts to which you
            give us access (e.g., your email account(s) and your CRM platform). We
            are not responsible for errors or other issues associated with the
            functionality of such APIs and other tools.
          </span>
        </p>
        <h2 className="c12" id="h.996z8q53nzgs">
          <span className="c6">Collection Of Information From Children</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Relatable does not direct our Services specifically to children. We do
            not knowingly collect or use personal information from children under
            thirteen (13). If we learn that a user is under thirteen (13) years of
            age, we will promptly delete any personal information we have collected
            about that user. If you are under the age of thirteen (13), please do not
            send us any information about yourself.
          </span>
        </p>
        <h2 className="c12" id="h.jeojpkl8nqnr">
          <span className="c6">Consent To Processing In The United States.</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Your personal information will be stored in the United States where we
            are headquartered. We also work with third-party service providers in
            different countries (for example, we store personal information on Amazon
            Web Services servers in the United States, we use servers provided by
            Heroku, a cloud application platform based in the United States, and we
            have support teams located in the Philippines).
          </span>
        </p>
        <p className="c11">
          <span className="c5">
            By providing any personal information and/or Content to Relatable, all
            users, including, without limitation, users in Canada and the member
            states of the European Union, fully understand and unambiguously consent
            to this Privacy Policy and to the transfer of such personal information
            across international borders in accordance with Relatable&rsquo;s
            standard operations, including the collection, storage, and processing of
            such information in the United States of America and the Philippines or
            other countries in which our employees and contractors may be located.
          </span>
        </p>
        <h2 className="c12" id="h.l5ht4fg5imlz">
          <span className="c6">How Long Do We Retain Information?</span>
        </h2>
        <h3 className="c18" id="h.fsdol4wkk7l9">
          <span className="c15">Information Customers Provide To Us</span>
        </h3>
        <p className="c11">
          <span className="c5">
            We will store your personal information, in a form which permits us to
            identify you for as long as your account is active or as needed to
            provide the Services. We will retain personal information for a period of
            12 months after your account is closed, unless you instruct us to delete
            the personal information. We will retain and use your personal
            information after this period as necessary to comply with our legal
            obligations, resolve disputes and enforce our agreements.
          </span>
        </p>
        <h3 className="c18" id="h.ak1er6dbpkxw">
          <span className="c15">Information Processed On Behalf Of Customers</span>
        </h3>
        <p className="c11">
          <span className="c5">
            We will store personal information we process on behalf of our Customers
            for as long as needed to provide the Services to our Customers. We will
            retain personal information for a period of 12 months after your account
            is closed, unless you instruct us to delete the personal information. We
            will retain and use personal information after this period as necessary
            to comply with our legal obligations, resolve disputes and enforce our
            agreements.
          </span>
        </p>
        <h2 className="c12" id="h.oz91odiusa8g">
          <span className="c6">Your California Privacy Rights</span>
        </h2>
        <p className="c11">
          <span className="c5">
            As stated in this Privacy Policy, we do not share information with third
            parties for their direct marketing purposes unless you affirmatively
            agree to such disclosure &mdash; typically by opting in to receive
            information from a third party that is participating in some activity
            described in our Services. If you do ask us to share your information
            with a third party for its marketing purposes, we will only share
            information in connection with that specific activity, as we do not share
            information with any third party on a continual basis. To prevent
            disclosure of your personal information for use in direct marketing by a
            third party, do not opt in to such use when you provide personal
            information as part of the Services. Please note that whenever you opt in
            to receive future communications from a third party, your information
            will be subject to the third party&rsquo;s privacy policy. If you later
            decide that you do not want that third party to use your information, you
            will need to contact the third party directly, as we have no control over
            how third parties use information. You should always review the privacy
            policy of any party that collects your information to determine how that
            entity will handle your information.
          </span>
        </p>
        <p className="c20">
          <span className="c31">
            California customers may request further information about our compliance
            with this law by emailing{" "}
          </span>
          <span className="c9">legal@relatable.one</span>
          <span className="c5">
            . Please note that we are only required to respond to one request per
            customer each year, and we are not required to respond to requests made
            by means other than through this email address.
          </span>
        </p>
        <h2 className="c12" id="h.s9ejfhmflun8">
          <span className="c6">Your European Privacy Rights</span>
        </h2>
        <h3 className="c2" id="h.h0ny2dveyqib">
          <span className="c16">For European residents only.</span>
        </h3>
        <h3 className="c2 c19" id="h.h0ny2dveyqib-1">
          <span className="c16" />
        </h3>
        <h3 className="c2" id="h.h0ny2dveyqib-2">
          <span className="c4">
            We collect and process personal information about you only where we have
            a legal basis for doing so under applicable data protection law,
            including under the GDPR. The legal bases will depend on the purpose for
            which we process your personal information. This means we collect and use
            your personal information only where:
          </span>
        </h3>
        <ul className="c14 lst-kix_9aubkn255kux-0 start">
          <li className="c10">
            <span className="c4">
              We need it to provide the Services under a contract to which you are a
              party, or in order to take steps at your request prior to entering into
              a contract;
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              It satisfies a legitimate interest (which is not overridden by your
              rights and interests), such as for research and development, to market
              and promote the Services and to protect our legal rights and interests;
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              You give us consent to do so for a specific purpose; or
            </span>
          </li>
          <li className="c10">
            <span className="c4">
              We need to process your personal information to comply with a legal
              obligation.
            </span>
          </li>
        </ul>
        <p className="c29">
          <span className="c5">
            If you have consented to our use of personal information about you for a
            specific purpose, you have the right to change your mind at any time.
          </span>
        </p>
        <p className="c20">
          <span className="c31">
            We may use your email address to send you newsletter or marketing emails.
            We will ask for your consent in line with the applicable law when you
            first provide your personal information. You can opt out by following the
            unsubscribe instructions included in these emails, or you can contact us
            on{" "}
          </span>
          <span className="c9">legal@relatable.one</span>
          <span className="c5">.</span>
        </p>
        <p className="c11">
          <span className="c5">
            In certain circumstances, you have rights under the GDPR in relation to
            your personal information. These privacy rights do not apply to Relatable
            where we process your personal information as a data processor on behalf
            of our Customers. Where this is the case, any request to exercise your
            European privacy rights should be directed to our Customer (i.e. the data
            controller).
          </span>
        </p>
        <h3 className="c18" id="h.l5d4m35epmzm">
          <span className="c15">Request access to your personal information.</span>
        </h3>
        <p className="c11">
          <span className="c5">
            You may have the right to request access to any personal information we
            hold about you as well as related information, including the purposes for
            processing the personal information, the recipients or categories of
            recipients with whom the personal information has been shared, where
            possible, the period for which the personal information will be stored,
            the source of the personal information, and the existence of any
            automated decision making.
          </span>
        </p>
        <h3 className="c18" id="h.a8tp76q2e8gp">
          <span className="c15">
            Request correction of your personal information.
          </span>
        </h3>
        <p className="c11">
          <span className="c5">
            You may have the right to obtain without undue delay the rectification of
            any inaccurate personal information we hold about you.
          </span>
        </p>
        <h3 className="c18" id="h.xljaclno4til">
          <span className="c15">Request erasure of your personal information.</span>
        </h3>
        <p className="c11">
          <span className="c5">
            You may have the right to request that personal information held about
            you is deleted. Request restriction of processing your personal
            information. You may have the right to prevent or restrict processing of
            your personal information.
          </span>
        </p>
        <h3 className="c18" id="h.fss1scfvknq">
          <span className="c15">Request transfer of your personal information.</span>
        </h3>
        <p className="c11">
          <span className="c5">
            You may have the right to request transfer of personal information
            directly to a third party where this is technically feasible.
          </span>
        </p>
        <p className="c3">
          <span className="c21" />
        </p>
        <p className="c11">
          <span className="c5">
            Where you believe that we have not complied with our obligation under
            this Privacy Policy or European data protection law, you have the right
            to make a complaint to an EU Data Protection Authority, such as the UK
            Information Commissioner&rsquo;s Office.
          </span>
        </p>
        <h2 className="c12" id="h.cb2uq7rlaz2l">
          <span className="c6">Modification And Notification Of Changes</span>
        </h2>
        <p className="c20">
          <span className="c31">
            Relatable may, at our sole discretion, edit this Privacy Policy. Notice
            of all changes to the Privacy Policy will be provided as discussed in the{" "}
          </span>
          <span className="c9">
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#1890ff",
                cursor: "pointer",
              }}
              className="c24"
              onClick={() => dispatch(push("/terms"))}
            >
              Terms of Service
            </button>
          </span>
          <span className="c5">
            . You may terminate your access to the Services and your account at any
            time, also pursuant to the method described in the Terms of Service.
          </span>
        </p>

        <h2 className="c12" id="h.cb2uq7rlaz2l">
          <span className="c6">Use of Google APIs</span>
        </h2>
        <p className="c20">
          <span className="c31">
            Relatable's use of information received from Google APIs will adhere to
            the{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements. Relatable's use and transfer to
            any other app of information received from Google APIs will adhere to{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.
          </span>
        </p>

        <h2 className="c12" id="h.2ity95ynkcsc">
          <span className="c6">In Summary</span>
        </h2>
        <p className="c11">
          <span className="c5">
            Relatable is committed to protecting your privacy. The information
            collected from our Services helps us better understand our market and
            consumer interests. This allows us to serve our customers and business
            associates with the level of satisfaction they deserve.
          </span>
        </p>
        <p className="c33">
          <span className="c31">
            If you have questions or suggestions regarding this Privacy Policy or our
            privacy practices, please send an email message to{" "}
          </span>
          <span className="c9 c25">legal@relatable.one.</span>
        </p>
        <p className="c3">
          <span className="c21" />
        </p>
        <p className="c3">
          <span className="c21" />
        </p>
      </Col>
    </Row>
  )
}

export default PrivacyPage
