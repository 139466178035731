import React from "react"

const CalendarCheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6871 3.69434H4.31211C3.97038 3.69434 3.69336 3.97136 3.69336 4.31309V16.6881C3.69336 17.0298 3.97038 17.3068 4.31211 17.3068H16.6871C17.0288 17.3068 17.3059 17.0298 17.3059 16.6881V4.31309C17.3059 3.97136 17.0288 3.69434 16.6871 3.69434Z"
      stroke="currentColor"
      strokeWidth="1.155"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2129 2.45703V4.93203"
      stroke="currentColor"
      strokeWidth="1.155"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.78711 2.45703V4.93203"
      stroke="currentColor"
      strokeWidth="1.155"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.69336 7.40723H17.3059"
      stroke="currentColor"
      strokeWidth="1.155"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2836 10.501L9.67164 13.9041L7.71484 12.0479"
      stroke="currentColor"
      strokeWidth="1.155"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CalendarCheckIcon
