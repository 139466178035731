import React, { useEffect, useState } from "react"
import { Divider, Popover, Skeleton } from "antd"
import "./InteractionModal.styles.scss"
import axios from "axios"
import EmptyState from "../../../../../UI/EmptyState/EmptyState.ui"
import InteractionContent from "././Contents/InteractionContent.component"
import EventContent from "././Contents/EventContent.component"
import ThreadContent from "././Contents/ThreadContent.component"
import CancelIcon from "../../../../../Icons/Cancel.icons"
import Icon from "@ant-design/icons"

const ModalContent = ({ interaction, contactId, onClosePopover }) => {
  const [messageDetails, setMessageDetails] = useState(null)
  const [interactionDetails, setInteractionDetails] = useState(null)
  const [eventDetails, setEventDetails] = useState(null)
  const [loading, setLoading] = useState(false)

  const getInteraction = async () => {
    const { data } = await axios.request({
      url: `/v1/interactions/${interaction.id}`,
      params: { contact_id: contactId },
      method: "GET",
    })

    if (data.messages) {
      setMessageDetails(data.messages[0])
    }
    setLoading(false)
  }

  const getEvent = async () => {
    const { data } = await axios.request({
      url: `/v1/events/${interaction.id}`,
      params: { contact_id: contactId },
      method: "GET",
    })

    if (data.event) {
      setEventDetails(data.event)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (interaction) {
      if (interaction.type === "Thread") {
        setLoading(true)
        getInteraction().catch(() => {
          setLoading(false)
        })
      } else if (interaction.type === "Event") {
        setLoading(true)
        getEvent().catch(() => {
          setLoading(false)
        })
      } else {
        setInteractionDetails(interaction)
      }
    }
    // eslint-disable-next-line
  }, [interaction])

  return (
    <div className="ModalContent">
      <button className="ModalContent_CloseButton" onClick={onClosePopover}>
        <Icon component={CancelIcon} />
      </button>
      {loading ? (
        <>
          <Skeleton.Input active size={"large"} style={{ marginBottom: 5 }} />
          <Divider />
          <Skeleton.Input active size={"small"} style={{ marginBottom: 5 }} />
          <Skeleton.Input active size={"small"} style={{ marginBottom: 5 }} />
          <Skeleton.Input active size={"small"} style={{ marginBottom: 5 }} />
          <Skeleton.Input active size={"small"} style={{ marginBottom: 5 }} />
          <Skeleton.Input active size={"small"} style={{ marginBottom: 5 }} />
          <Skeleton.Input active size={"small"} style={{ marginBottom: 5 }} />
          <Skeleton.Input active size={"small"} style={{ marginBottom: 5 }} />
        </>
      ) : interaction && (messageDetails || eventDetails || interactionDetails) ? (
        <>
          {messageDetails && <ThreadContent messageDetails={messageDetails} />}
          {eventDetails && <EventContent eventDetails={eventDetails} />}
          {interactionDetails && (
            <InteractionContent interactionDetails={interactionDetails} />
          )}
        </>
      ) : (
        <>
          <div style={{ padding: 10 }}>
            <EmptyState instructions="Sorry, we were unable to load messages linked with that thread." />
          </div>
        </>
      )}{" "}
    </div>
  )
}

const InteractionModal = ({
  interaction,
  children,
  onVisibleChange,
  contactId,
  visible,
}) => {
  return (
    <Popover
      overlayClassName="InteractionModal"
      overlayStyle={{ paddingRight: 40, width: 800 }}
      onVisibleChange={(visible) => onVisibleChange(visible)}
      placement="left"
      title={
        interaction && (
          <div className={"InteractionModal__Subject"}>{interaction.subject}</div>
        )
      }
      content={
        <ModalContent
          contactId={contactId}
          interaction={interaction}
          onClosePopover={() => onVisibleChange(false)}
        />
      }
      trigger={"click"}
      visible={visible}
    >
      {children}
    </Popover>
  )
}

export default InteractionModal
