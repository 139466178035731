import React, { useEffect, useRef, useState } from "react"
import { Tooltip } from "antd"

const OverflowTip = ({ children, placement, titleClass }) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const textElementRef = useRef()
  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    )
  }, [])
  return (
    <Tooltip
      title={children}
      visible={tooltipVisible && isOverflowed}
      onVisibleChange={setTooltipVisible}
      placement={placement}
    >
      <div
        className={titleClass}
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}

export default OverflowTip
