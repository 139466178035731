import React from "react"
import "./CollectionSelector.styles.scss"
import { Modal } from "antd"

const ConfirmPopup = ({ open, goBack, cancelChanges }) => {
  return (
    <Modal
      className="CollectionSelector_ConfirmPopup"
      visible={open}
      footer={null}
      closable={false}
      width="300px"
    >
      <p>If you want to apply your changes, click Done.</p>
      <div className={"CollectionSelector_ConfirmPopup_Buttons"}>
        <div className="CollectionSelector_ConfirmPopup_YesButton" onClick={goBack}>
          Go Back
        </div>
        <div
          className="CollectionSelector_ConfirmPopup_NoButton"
          onClick={cancelChanges}
        >
          Cancel Changes
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmPopup
