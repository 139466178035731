import { useParams } from "react-router"
import React, { useEffect, useState } from "react"
import axios from "axios"
import OrganizeContacts from "../../components/Dashboard/MobileDailyReminders/OrganizeContacts.component"
import PersonTriggers from "../../components/Dashboard/MobileDailyReminders/PersonTriggers.component"

const DailyReminders = () => {
  const params = useParams()
  const [user, setUser] = useState(null)
  const [type, setType] = useState(null)

  const loadTokenInfo = async (token) => {
    const { data } = await axios.request({
      url: `/v1/daily_reminders/token/${token}`,
    })
    setUser(data.user)
    setType(data.type)
  }

  useEffect(() => {
    //    load token and user
    loadTokenInfo(params.token)
  }, [params.token])

  return (
    <div>
      {type === "DailyToken::Contacts" && <OrganizeContacts user={user} />}
      {type === "DailyToken::Triggers" && <PersonTriggers user={user} />}
    </div>
  )
}

export default DailyReminders
