import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import { userReducer } from "./User/User.reducer"
import { peopleReducer } from "./People/People.reducer"
import { participantsReducer } from "./Participants/Participants.reducer"
import { collectionsReducer } from "./Collections/Collections.reducer"
import { accountsReducer } from "./Accounts/Accounts.reducer"
import { introductionsReducer } from "./Introductions/Introductions.reducer"
import { interactionReducer } from "./Interactions/Interactions.reducer"
import { notificationsReducer } from "./notificationsReducer"
import { remindersReducer } from "./Reminders/Reminders.reducer"
import { triggersReducer } from "./Triggers/Triggers.reducer"
import { activitiesReducer } from "./Activities/Activities.reducer"
import { appState } from "./App/App.reducer"
import { messagesReducer } from "./Messages/Messages.reducer"
import { adminReducer } from "./Admin/Admin.reducer"

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    AppState: appState,
    UserState: userReducer,
    PeopleState: peopleReducer,
    ParticipantsState: participantsReducer,
    CollectionsState: collectionsReducer,
    AccountsState: accountsReducer,
    IntroductionsState: introductionsReducer,
    InteractionsState: interactionReducer,
    RemindersState: remindersReducer,
    TriggersState: triggersReducer,
    Notifications: notificationsReducer,
    Activities: activitiesReducer,
    Messages: messagesReducer,
    AdminState: adminReducer,
  })

export default rootReducer
