import axios from "axios"
import * as _ from "lodash"

const getSearchedPeople = async (searchQuery, callback, mode = "SEARCH") => {
  const { data } = await axios.request({
    url: "/v1/people/autocomplete",
    params: {
      q: searchQuery,
      mode: mode,
    },
    method: "GET",
  })

  const people = _.uniqBy(
    data.people.map((person) => ({
      id: person.value,
      name: person.text,
      first_name: person.first_name,
      full_name: person.full_name,
      last_name: person.last_name,
      description: person.description,
      emails: person.emails,
      last_used_email: person.last_used_email,
    })),
    "id"
  )

  callback(people)
}

export default getSearchedPeople
