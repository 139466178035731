export const FETCH_PARTICIPANTS = "FETCH_PARTICIPANTS"
export const FETCH_PARTICIPANTS_SUCCESS = "FETCH_PARTICIPANTS_SUCCESS"
export const FETCH_PARTICIPANTS_FAIL = "FETCH_PARTICIPANTS_FAIL"

export const FETCH_RESERVE_PARTICIPANTS = "FETCH_RESERVE_PARTICIPANTS"
export const FETCH_RESERVE_PARTICIPANTS_SUCCESS =
  "FETCH_RESERVE_PARTICIPANTS_SUCCESS"
export const FETCH_RESERVE_PARTICIPANTS_FAIL = "FETCH_RESERVE_PARTICIPANTS_FAIL"

export const ADD_TO_SPHERES = "ADD_TO_SPHERES"
export const ADD_TO_SPHERES_SUCCESS = "ADD_TO_SPHERES_SUCCESS"
export const ADD_TO_SPHERES_FAIL = "ADD_TO_SPHERES_FAIL"

export const ADD_PARTICIPANTS_TO_SPHERES = "ADD_PARTICIPANTS_TO_SPHERES"
export const ADD_PARTICIPANTS_TO_SPHERES_SUCCESS =
  "ADD_PARTICIPANTS_TO_SPHERES_SUCCESS"
export const ADD_PARTICIPANTS_TO_SPHERES_FAIL = "ADD_PARTICIPANTS_TO_SPHERES_FAIL"

export const ARCHIVE_PARTICIPANTS = "ARCHIVE_PARTICIPANTS"
export const ARCHIVE_PARTICIPANTS_SUCCESS = "ARCHIVE_PARTICIPANTS_SUCCESS"
export const ARCHIVE_PARTICIPANTS_FAIL = "ARCHIVE_PARTICIPANTS_FAIL"

export const CLEAR_REDUX_PARTICIPANTS_STATE = "CLEAR_REDUX_PARTICIPANTS_STATE"
export const SET_INITIAL_REDUX_PARTICIPANTS_STATE =
  "SET_INITIAL_REDUX_PARTICIPANTS_STATE"
