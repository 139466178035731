import React, { useState } from "react"
import "./ContactDrawerNavSections.styles.scss"
import { Link } from "react-scroll"

const ContactDrawerNavSections = ({ tabs, containerId }) => {
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [clicked, setClicked] = useState(false)

  return (
    <div className="ContactDrawerNavSections">
      {tabs.map((tab) => (
        <Link
          style={{ width: tab.width }}
          key={tab.name}
          className={activeTab.name === tab.name ? `active${activeTab.name}` : ""}
          smooth
          spy
          duration={400}
          offset={-15}
          containerId={containerId}
          onSetActive={() => {
            if (tab === activeTab || !clicked) {
              setActiveTab(tab)
              setClicked(false)
            }
          }}
          onClick={() => {
            setActiveTab(tab)
            setClicked(true)
          }}
          to={`section${tab.name}`}
        >
          {tab.name}
        </Link>
      ))}
      <hr
        style={{ width: activeTab.width, left: activeTab.paddingLeft }}
        className={`ContactDrawerNavSections_ActiveLine ContactDrawerNavSections_ActiveLine--${activeTab.name}`}
      />
    </div>
  )
}

export default ContactDrawerNavSections
