import axios from "axios"

const removeSphereStep = async (id, callback) => {
  const { data } = await axios.request({
    url: `/v1/stage_sphere/delete_step`,
    data: {
      sphere_step_id: id,
    },
    method: "DELETE",
  })

  callback(data)
}

export default removeSphereStep
