import React from "react"
import "./Error.styles.scss"
import BugfixingIcon from "./BugfixingIcon.component"

const Error = () => (
  <div className="Error">
    <div>
      <BugfixingIcon className="Error_Drawing" />
    </div>
    <div className="Error_Details">
      <span className="Error_Details_Title">Error</span>
      <div className="Error_Details_Description">
        Something went wrong and we couldn't display this content.
        <br />
        Please try again in a while and <br />
        if the error persists please contact us.
      </div>
    </div>
  </div>
)

export default Error
