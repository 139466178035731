export const FETCH_MESSAGES = "FETCH_MESSAGES"
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS"
export const FETCH_MESSAGES_FAIL = "FETCH_MESSAGES_FAIL"
export const FETCH_MESSAGES_OPEN_RATE = "FETCH_MESSAGES_OPEN_RATE"
export const FETCH_MESSAGES_OPEN_RATE_SUCCESS = "FETCH_MESSAGES_OPEN_RATE_SUCCESS"
export const FETCH_MESSAGES_OPEN_RATE_FAIL = "FETCH_MESSAGES_OPEN_RATE_FAIL"

export const SEND_SPLIT_MESSAGE = "SEND_SPLIT_MESSAGE"
export const SEND_SPLIT_MESSAGE_SUCCESS = "SEND_SPLIT_MESSAGE_SUCCESS"
export const SEND_SPLIT_MESSAGE_FAIL = "SEND_SPLIT_MESSAGE_FAIL"
export const SCHEDULED_SPLIT_MESSAGE_SUCCESS = "SCHEDULED_SPLIT_MESSAGE_SUCCESS"
export const SCHEDULED_SPLIT_MESSAGE_FAIL = "SCHEDULED_SPLIT_MESSAGE_FAIL"
export const UPDATE_SCHEDULED_SPLIT_MESSAGE = "UPDATE_SCHEDULED_SPLIT_MESSAGE"
export const UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS =
  "UPDATE_SCHEDULED_SPLIT_MESSAGE_SUCCESS"
export const UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL =
  "UPDATE_SCHEDULED_SPLIT_MESSAGE_FAIL"
export const DELETE_SCHEDULED_SPLIT_MESSAGE = "DELETE_SCHEDULED_SPLIT_MESSAGE"
export const DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS =
  "DELETE_SCHEDULED_SPLIT_MESSAGE_SUCCESS"
export const DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL =
  "DELETE_SCHEDULED_SPLIT_MESSAGE_FAIL"

export const FETCH_TEMPLATES = "FETCH_TEMPLATES"
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS"
export const FETCH_TEMPLATES_FAIL = "FETCH_TEMPLATES_FAIL"

export const FETCH_TEMPLATE = "FETCH_TEMPLATE"
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS"
export const FETCH_TEMPLATE_FAIL = "FETCH_TEMPLATE_FAIL"

export const FETCH_SPLIT_MESSAGE = "FETCH_SPLIT_MESSAGE"
export const FETCH_SPLIT_MESSAGE_SUCCESS = "FETCH_SPLIT_MESSAGE_SUCCESS"
export const FETCH_SPLIT_MESSAGE_FAIL = "FETCH_SPLIT_MESSAGE_FAIL"

export const FETCH_SPLIT_MESSAGE_DETAILS = "FETCH_SPLIT_MESSAGE_DETAILS"
export const FETCH_SPLIT_MESSAGE_DETAILS_SUCCESS =
  "FETCH_SPLIT_MESSAGE_DETAILS_SUCCESS"
export const FETCH_SPLIT_MESSAGE_DETAILS_FAIL = "FETCH_SPLIT_MESSAGE_DETAILS_FAIL"

export const SAVE_TEMPLATE = "SAVE_TEMPLATE"
export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS"
export const SAVE_TEMPLATE_FAIL = "SAVE_TEMPLATE_FAIL"

export const DELETE_TEMPLATE = "DELETE_TEMPLATE"
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS"
export const DELETE_TEMPLATE_FAIL = "DELETE_TEMPLATE_FAIL"

export const NEW_TEMPLATE = "NEW_TEMPLATE"
export const NEW_TEMPLATE_SUCCESS = "NEW_TEMPLATE_SUCCESS"
export const NEW_TEMPLATE_FAIL = "NEW_TEMPLATE_FAIL"

export const UPLOAD_MESSAGE_IMAGE = "UPLOAD_MESSAGE_IMAGE"
export const UPLOAD_MESSAGE_IMAGE_FAIL = "UPLOAD_MESSAGE_IMAGE_FAIL"
export const UPLOAD_MESSAGE_IMAGE_SUCCESS = "UPLOAD_MESSAGE_IMAGE_SUCCESS"

export const CLEAR_REDUX_MESSAGES_STATE = "CLEAR_REDUX_MESSAGES_STATE"
export const SET_INITIAL_REDUX_MESSAGES_STATE = "SET_INITIAL_REDUX_MESSAGES_STATE"

export const SET_LOADING = "SET_LOADING"

export const RESEND_MESSAGE = "RESEND_MESSAGE"
export const RESEND_MESSAGE_FAIL = "RESEND_MESSAGE_FAIL"
export const RESEND_MESSAGE_SUCCESS = "RESEND_MESSAGE_SUCCESS"

export const FETCH_AI_DRAFT = "FETCH_AI_DRAFT"
export const FETCH_AI_DRAFT_SUCCESS = "FETCH_AI_DRAFT_SUCCESS"
export const FETCH_AI_DRAFT_FAIL = "FETCH_AI_DRAFT_FAIL"

export const SET_MESSAGE_DRAFT = "SET_MESSAGE_DRAFT"
