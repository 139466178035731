import React, { useState } from "react"
import "../TeamsSection.styles.scss"
import "antd/dist/antd.css"
import "antd-country-phone-input/dist/index.css"
import { Checkbox, Dropdown, Input, Menu } from "antd"
import { CloseOutlined } from "@ant-design/icons/lib"
import PencilSimpleLineIcon from "../../../Icons/PencilSimpleLine.icons"
import Icon from "@ant-design/icons"
import {
  addTeamMember,
  cancelTeamInvite,
  deleteTeam,
  leaveTeam,
  removeMemberTeam,
  resendTeamInvitation,
  UpdateTeam,
  updateTeamSettings,
} from "../../../../redux/User/User.actions"
import { useDispatch, useSelector } from "react-redux"
import ConfirmModal from "../../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import RelatableLoader from "../../../UI/RelatableLoader/RelatableLoader.component"

const TeamDetails = ({ team }) => {
  const dispatch = useDispatch()
  const updating_team = useSelector((state) => state.UserState.updating_team)
  const [newMemberEmail, setNewMemberEmail] = useState("")
  const [newTeamName, setTeamName] = useState(team.name)
  const [editingName, setEditingName] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showLeaveModal, setShowLeaveModal] = useState(false)
  const [memberToRemove, setMemberToRemove] = useState(null)
  const [emailInviteToCancel, setEmailInviteToCancel] = useState(null)
  const [teamSettings, setTeamSettings] = useState(team.settings)

  const handleDeleteTeam = () => {
    dispatch(deleteTeam(team.id))
    setShowDeleteModal(false)
  }

  const handleLeaveTeam = () => {
    dispatch(leaveTeam(team.id, team.owner_id))
    setShowLeaveModal(false)
  }

  const handleRemoveMember = () => {
    dispatch(removeMemberTeam(team.id, memberToRemove))
    setMemberToRemove(null)
  }

  const handleCancelInvite = () => {
    dispatch(cancelTeamInvite(team.id, emailInviteToCancel))
    setEmailInviteToCancel(null)
  }

  const handleAddMember = () => {
    dispatch(addTeamMember(team.id, newMemberEmail))
    setNewMemberEmail("")
  }

  const handleResendEmail = (email) => {
    dispatch(resendTeamInvitation(team.id, email))
  }

  const handleUpdateSettings = (newTeamSettings) => {
    dispatch(updateTeamSettings(team.id, newTeamSettings))
  }

  return (
    <div className="Subsection TeamDetails">
      <div className="TeamDetails_Header">
        {editingName ? (
          <div className="TeamDetails_Header_EditNameForm">
            <Input
              className="NewMemberInput"
              key="name"
              name="name"
              placeholder="Enter new name"
              value={newTeamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            <button
              className="Button NewMemberButton"
              onClick={() => {
                dispatch(UpdateTeam(team.id, newTeamName))
                setEditingName(false)
              }}
            >
              Save
            </button>
          </div>
        ) : (
          <>
            <h3>{team.name}</h3>
            {team.you_are_owner && (
              <Icon
                className="RenameIcon"
                component={PencilSimpleLineIcon}
                onClick={() => setEditingName(true)}
              />
            )}
          </>
        )}

        <p>{team.description}</p>
      </div>
      <div className="TeamDetails_Body">
        <div className="TeamDetails_Body_TeamMembers">
          <p>Team Members</p>
          <ul>
            {team.members?.map((member) => (
              <li
                key={member.id}
                className={
                  member.is_admin || member.first_name === "You"
                    ? "bold_name"
                    : "member"
                }
              >
                <span>
                  {member.first_name
                    ? member.first_name + " " + member.last_name
                    : member.email}{" "}
                  {member.is_admin ? "(admin)" : ""}
                </span>
                {team.you_are_owner && member.first_name !== "You" && (
                  <CloseOutlined
                    onClick={() => setMemberToRemove(member.id)}
                    className="RemoveButton"
                  />
                )}
              </li>
            ))}
            {team.you_are_owner && (
              <>
                {team.invited_members?.map((i_m) => (
                  <li key={i_m.id}>
                    <div>
                      Invited:{" "}
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="resend"
                              onClick={() => handleResendEmail(i_m.email)}
                            >
                              Resend email
                            </Menu.Item>
                            <Menu.Item
                              key="canel"
                              onClick={() => setEmailInviteToCancel(i_m.email)}
                              style={{ color: "red" }}
                            >
                              Cancel invitation
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["hover"]}
                      >
                        <span>{i_m.email}</span>
                      </Dropdown>
                    </div>
                  </li>
                ))}
                <li>
                  <Input
                    className="NewMemberInput"
                    key="email"
                    name="email"
                    placeholder="Enter new member's email"
                    value={newMemberEmail}
                    onChange={(e) => setNewMemberEmail(e.target.value)}
                  />
                  <button
                    onClick={handleAddMember}
                    className="Button NewMemberButton"
                  >
                    Add
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="TeamDetails_Body_Settings">
          <p>Settings</p>
          {updating_team ? (
            <RelatableLoader />
          ) : (
            <ul>
              {team.settings?.map((setting, i) => (
                <li key={i}>
                  <Checkbox
                    checked={setting.checked}
                    disabled={!team.you_are_owner || i < 3}
                    onChange={(e) => {
                      const newSettings = [...teamSettings]
                      newSettings[i].checked = e.target.checked
                      handleUpdateSettings(newSettings)
                    }}
                  />
                  <div>{setting.title}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="TeamDetails_Footer">
        {team.you_are_owner ? (
          <button
            className="Button Button--red"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Team (yikes!)
          </button>
        ) : (
          <button
            className="Button Button--red"
            onClick={() => setShowLeaveModal(true)}
          >
            Leave Team
          </button>
        )}
      </div>
      <ConfirmModal
        title="Delete Team"
        description="Are you sure? This can't be undone."
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteTeam}
        visible={showDeleteModal}
      />
      <ConfirmModal
        title="Leave Team"
        description="Are you sure you want to leave this team?"
        onCancel={() => setShowLeaveModal(false)}
        onConfirm={handleLeaveTeam}
        visible={showLeaveModal}
      />
      <ConfirmModal
        title="Remove Team Member"
        description="Are you sure? This will remove all of their contact information, and any spheres they have created."
        onCancel={() => setMemberToRemove(null)}
        onConfirm={handleRemoveMember}
        visible={memberToRemove !== null}
      />
      <ConfirmModal
        title="Cancel Invitation"
        description="Are you sure you want to cancel this invitation?"
        onCancel={() => setEmailInviteToCancel(null)}
        onConfirm={handleCancelInvite}
        visible={emailInviteToCancel !== null}
      />
    </div>
  )
}

export default TeamDetails
