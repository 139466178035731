import React from "react"
import { Select } from "antd"
import PropTypes from "prop-types"
import ListWithCheckbox from "../ListWithCheckbox/ListWithCheckbox.component"
import SectionWithFill from "../../../../UI/SectionWithFill/SectionWithFill.component"
import "./MultiSelectWithList.styles.scss"

const MultiSelectWithList = ({
  onApplyFilter,
  filters,
  options,
  popular_options,
  title,
  fillColor,
  useSingularTitle = false,
}) => {
  const singularTitle = (title) => {
    switch (title) {
      case "spheres":
        return "sphere"
      case "topics":
        return "topic"
      case "companies":
        return "company"
      case "location":
        return "location"
      case "expertises":
        return "expertises"
      case "sources":
        return "sources"
      default:
        return ""
    }
  }

  return (
    <SectionWithFill
      title={useSingularTitle ? title : singularTitle()}
      fillColor={fillColor}
    >
      <Select
        className={"MultiSelectWithList"}
        getPopupContainer={(trigger) => trigger.parentNode}
        optionLabelProp={"visible"}
        labelInValue={true}
        value={
          filters.find((f) => f.field === `${title}.id`)
            ? options
                .filter((t) =>
                  filters
                    .find((f) => f.field === `${title}.id`)
                    .values.includes(t.id.toString())
                )
                .map((t) => ({ label: t.title, key: t.id }))
            : []
        }
        placeholder={`Search by ${singularTitle(title)} name`}
        dropdownStyle={{ zIndex: 9999 }}
        size={"large"}
        mode={"multiple"}
        onChange={(e, f) => {
          f.filter((el) => Object.keys(el).length !== 0).forEach((t) => {
            onApplyFilter(true, {
              field: `${title}.id`,
              value: t.key.toString(),
            })
          })
        }}
        onDeselect={(e) => {
          onApplyFilter(false, {
            field: `${title}.id`,
            value: options.find((option) => option.title === e.label).id.toString(),
          })
        }}
      >
        {options.map((option) => (
          <Select.Option
            key={option.id}
            value={option.title}
            label={option.id}
            visible={option.title}
          >
            {option.title}
          </Select.Option>
        ))}
      </Select>
      <ListWithCheckbox
        onApplyFilter={onApplyFilter}
        filters={filters}
        title={["importance", "strength"].includes(title) ? title : title + ".id"}
        options={options}
        popular_options={popular_options}
      />
    </SectionWithFill>
  )
}

MultiSelectWithList.propTypes = {
  onApplyFilter: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  options: PropTypes.array,
  popular_options: PropTypes.array,
  title: PropTypes.oneOf(["spheres", "topics", "companies", "location", "sources"])
    .isRequired,
  fillColor: PropTypes.oneOf([
    "lightGrey",
    "lightYellow",
    "lightPink",
    "lightGreen",
    "white",
  ]),
}

MultiSelectWithList.defaultProps = {
  fillColor: "white",
}

export default MultiSelectWithList
