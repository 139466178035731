import React from "react"
import { useSelector } from "react-redux"

const FinalScene = ({
  triggersCounter,
  organizedContactsCounter,
  reachedOutPeopleCounter,
  showAgainButton,
  onExit,
  goNext,
}) => {
  const user = useSelector((state) => state.UserState)
  return (
    <div className="Scene FinalScene">
      <h1>FANTASTIC WORK{user?.first_name ? `, ${user.first_name}!` : "!"}</h1>
      <h3>A great network is built on small, consistent actions.</h3>
      <h3 style={{ marginBottom: "28px" }}>You crushed it. We’re proud of you. </h3>
      <div className="FinalScene_Summary">
        <p>
          <span>{organizedContactsCounter}</span> Contacts Organized
        </p>
        <p>
          <span>{triggersCounter}</span> Suggestions Reviewed
        </p>
        <p>
          <span>{reachedOutPeopleCounter}</span> People Reached Out To
        </p>
      </div>
      <button style={{ marginTop: "52px" }} onClick={onExit} className="primary">
        Thanks! Take me to my dashboard.
      </button>
      {showAgainButton && (
        <button onClick={goNext} className="primary">
          Ya know what... let’s do it again!
        </button>
      )}
    </div>
  )
}

export default FinalScene
