import React, { useEffect, useState } from "react"
import "./NewInteractionTab.styles.scss"
import { DatePicker, Form, Input, Mentions, Popover, Select, Spin } from "antd"
import { fetchForMentions } from "../NewNoteTab/NewNoteTab.actions"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as yup from "yup"
import moment from "moment"
import { addInteraction } from "../../../../../redux/Interactions/Interactions.actions"
import * as _ from "lodash"
import PropTypes from "prop-types"
import Icon from "@ant-design/icons"
import UsersIcon from "../../../../Icons/Users.icons"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const { Option } = Mentions

const methodValues = [
  { key: "sms", label: "SMS" },
  { key: "call", label: "Call" },
  {
    key: "gift",
    label: "Gift",
  },
  { key: "card", label: "Card" },
  { key: "in_person", label: "In Person" },
  { key: "manual", label: "Other" },
]

const NewInteractionTab = ({
  minified,
  initialContent,
  initialParticipants,
  afterSubmit,
}) => {
  const dispatch = useDispatch()
  const adding = useSelector((state) => state.InteractionsState.adding_interactions)
  const user = useSelector((state) => state.UserState)

  const [prefix, setPrefix] = useState("@")

  const [loading, setLoading] = useState(false)
  const [participants, setParticipants] = useState([])
  const [topics, setTopics] = useState([])
  const [expertises, setExpertises] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const formatTimestamp = (date, time) => {
    const fDate = date.format("YYYY-MM-DD")
    const fTime = time.format("HH:mm:ss")

    return moment(fDate + "T" + fTime)
      .tz(user.time_zone)
      .format()
  }

  const schema = yup.object().shape({
    subject: yup.string(),
    body: yup.string().required("Interactions must have a content"),
    method: yup
      .string()
      .oneOf(["sms", "gift", "card", "call", "in_person", "other", "manual"])
      .required("You have to specify type of the interaction"),
    date: yup.date().nullable().required("Date is required"),
    time: yup.date().nullable().required("Time is required"),
    participants: yup
      .array()
      .of(yup.object().shape({ id: yup.number().required() }))
      .required(),
  })

  const formik = useFormik({
    initialValues: {
      subject: "",
      body: initialContent,
      method: "call",
      date: moment().tz(user.time_zone),
      time: moment().tz(user.time_zone),
      participants: initialParticipants,
      topics: [],
      expertises: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        addInteraction(
          {
            subject: values.subject,
            body: values.body,
            interaction_method: values.method,
            last_message_timestamp: formatTimestamp(
              moment(values.date).tz(user.time_zone),
              values.time
            ),
            people: _.uniqBy(values.participants, "id").filter((p) =>
              values.body.includes(`@${p.value}`)
            ),
            topics: _.uniqBy(values.topics, "id").filter((t) =>
              values.body.includes(`#${t?.value}`)
            ),
            expertises: _.uniqBy(values.expertises, "id").filter((t) =>
              values.body.includes(`~${t?.value}`)
            ),
          },
          true
        )
      )
      if (afterSubmit) afterSubmit()
    },
  })

  useEffect(() => {
    formik.setFieldValue("body", initialContent)
    formik.setFieldValue("participants", initialParticipants)
    // eslint-disable-next-line
  }, [initialContent, initialParticipants])

  const handleSubmit = () => {
    const people = _.uniqBy(formik.values.participants, "id").filter((p) =>
      formik.values.body.includes(`@${p.value}`)
    )

    if (people.length > 0) {
      formik.submitForm().then(() => formik.resetForm())
    } else {
      formik.setFieldValue("participants", [])
    }
  }

  const getMentions = (prefix) => {
    switch (prefix) {
      case "@":
        return participants || []
      case "#":
        return [...topics, { id: null, name: searchQuery }] || []
      case "~":
        return [...expertises, { id: null, name: searchQuery }] || []
      default:
        return []
    }
  }

  const loadTopics = (query) => {
    fetchForMentions(query, null, "topics").then((res) => {
      setTopics(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const handleSearch = (searchQuery, _prefix) => {
    setSearchQuery(searchQuery)
    setPrefix(_prefix)
    setParticipants([])
    setTopics([])
    setExpertises([])
    setLoading(true)

    if (_prefix === "@") {
      loadParticipants(searchQuery)
    } else if (_prefix === "#") {
      loadTopics(searchQuery)
    } else if (_prefix === "~") {
      loadExpertises(searchQuery)
    }
  }

  const [debouncedSearch] = useState(() => _.debounce(handleSearch, 500))

  const loadExpertises = (query) => {
    fetchForMentions(query, null, "expertises").then((res) => {
      setExpertises(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const loadParticipants = (query) => {
    fetchForMentions(query, "SORT").then((res) => {
      setParticipants(res.slice(0, 10))
      setLoading(false)
    })
  }

  const handleSelect = (id, value, _prefix) => {
    if (_prefix === "@") {
      formik.setFieldValue("participants", [
        ...formik.values.participants,
        { id: id, value: value },
      ])
    } else if (_prefix === "#") {
      formik.setFieldValue("topics", [
        ...formik.values.topics,
        { id: id, value: value },
      ])
    } else if (_prefix === "~") {
      formik.setFieldValue("expertises", [
        ...formik.values.expertises,
        { id: id, value: value },
      ])
    }
  }

  return (
    <div className="CreateInteraction">
      {adding ? (
        <RelatableLoader loading={adding} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Form.Item
            className={`CreateInteraction_Subject ${
              minified ? "CreateInteraction_Subject--minified" : ""
            }`}
            validateStatus={
              formik.touched.subject && formik.errors.subject ? "error" : "success"
            }
            help={
              formik.touched.subject && formik.errors.subject
                ? formik.errors.subject
                : null
            }
          >
            <Input
              key="subject"
              name="subject"
              value={formik.values.subject}
              placeholder="Type subject..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
          <div
            className={`CreateInteraction_InputGroup ${
              minified ? "CreateInteraction_InputGroup--minified" : ""
            }`}
          >
            <Form.Item
              className="CreateInteraction_InputGroup_Select"
              validateStatus={
                formik.touched.method && formik.errors.method ? "error" : "success"
              }
              help={
                formik.touched.method && formik.errors.method
                  ? formik.errors.method
                  : null
              }
            >
              <Select
                key="method"
                name="method"
                value={formik.values.method}
                onSelect={(e) => formik.setFieldValue("method", e)}
                onBlur={formik.handleBlur}
                defaultValue="call"
              >
                {methodValues.map((method) => (
                  <Option value={method.key} key={method.key}>
                    <span>Type: {method.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={`CreateInteraction_InputGroup_DateInput ${
                minified ? "CreateInteraction_InputGroup_DateInput--minified" : ""
              }`}
              validateStatus={
                formik.touched.date && formik.errors.date ? "error" : "success"
              }
              help={
                formik.touched.date && formik.errors.date ? formik.errors.date : null
              }
            >
              <DatePicker
                key="date"
                name="date"
                format="MM/DD/YYYY"
                value={
                  formik.values.date
                    ? moment(formik.values.date).tz(user.time_zone)
                    : null
                }
                allowClear={false}
                onChange={(e) =>
                  formik.setFieldValue(
                    "date", //get date without TimeZone
                    new Date(e?._d.getTime() - e?._d.getTimezoneOffset() * 60000)
                  )
                }
                onBlur={() => formik.setFieldTouched("date", true)}
              />
            </Form.Item>
          </div>
          <div
            className={`CreateInteraction_Container ${
              minified ? "CreateInteraction_Container--minified" : ""
            }`}
          >
            <Form.Item
              validateStatus={
                formik.touched.body && formik.errors.body ? "error" : "success"
              }
              help={
                formik.touched.body && formik.errors.body ? formik.errors.body : ""
              }
            >
              <Mentions
                key="body"
                name="body"
                className="CreateInteraction_Textarea"
                placeholder="Type description..."
                rows={6}
                prefix={["@", "#", "~"]}
                loading={loading}
                onSearch={debouncedSearch}
                onClick={(e) => formik.setFieldTouched("body", true)}
                onChange={(e) => formik.setFieldValue("body", e)}
                onBlur={() => formik.setFieldTouched("body", true)}
                onSelect={(selected, _prefix) =>
                  handleSelect(selected.id, selected.value, _prefix)
                }
                value={formik.values.body}
                autoFocus
              >
                {(getMentions(prefix) || []).map((mention) => (
                  <Option
                    id={mention.id}
                    name={mention.name}
                    key={mention.id}
                    value={mention.name ? mention.name.replace(/ /g, "_") : ""}
                    className="antd-demo-dynamic-option"
                  >
                    <span>{mention.name}</span>
                  </Option>
                ))}
              </Mentions>
            </Form.Item>
            <div
              className={`CreateInteraction_Footer ${
                minified ? "CreateInteraction_Footer--minified" : ""
              }`}
            >
              <span className="CreateInteraction_Footer_Helper">
                @ to attach this meeting to a person, # to add topics, ~ to add
                expertise
              </span>
              <Popover
                placement="topRight"
                trigger={
                  !formik.isValid ||
                  formik.errors.participants ||
                  !formik.values.participants.length
                    ? ["hover"]
                    : []
                }
                title="Why can’t I save this interaction?"
                overlayClassName={"AddInteractionPopover"}
                content={
                  <p className="AddInteractionPopover_Content">
                    The interaction is there to save the events with some of your
                    contacts, hence you should assign it to someone. Use{" "}
                    <strong>@</strong> to do this.
                  </p>
                }
              >
                <button
                  className={`${
                    !formik.isValid ||
                    Object.keys(formik.touched).length === 0 ||
                    !formik.values.participants.length
                      ? "CreateInteraction_Footer_Button--disabled"
                      : ""
                  } ${minified ? "Footer_Button--minified" : ""}`}
                  disabled={
                    !formik.isValid ||
                    Object.keys(formik.touched).length === 0 ||
                    !formik.values.participants.length
                  }
                  onClick={handleSubmit}
                >
                  Add
                  <Icon
                    className="CreateInteraction_Footer_Button_Icon"
                    component={UsersIcon}
                  />
                </button>
              </Popover>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

NewInteractionTab.propTypes = {
  initialContent: PropTypes.string.isRequired,
  initialParticipants: PropTypes.array.isRequired,
}

NewInteractionTab.defaultProps = {
  initialContent: "",
  initialParticipants: [],
}

export default NewInteractionTab
