import React, { useEffect, useRef, useState } from "react"
import "./NewTaskTab.styles.scss"
import { DatePicker, Form, Mentions, Popover, Spin } from "antd"
import { fetchForMentions } from "./NewTaskTab.actions"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as yup from "yup"
import moment from "moment"
import { addReminder } from "../../../../../redux/Reminders/Reminders.actions"
import * as _ from "lodash"
import PropTypes from "prop-types"
import CalendarCheckIcon from "../../../../Icons/CalendarCheck.icons"
import Icon from "@ant-design/icons"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const { Option } = Mentions

const NewTaskTab = ({ minified, initialContent, initialPeople, afterSubmit }) => {
  const dispatch = useDispatch()
  const textarea = useRef(null)
  const adding = useSelector((state) => state.RemindersState.adding)
  const user = useSelector((state) => state.UserState)

  const [loading, setLoading] = useState(false)
  const [prefix, setPrefix] = useState("@")
  const [people, setPeople] = useState([])
  const [topics, setTopics] = useState([])
  const [expertises, setExpertises] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const schema = yup.object().shape({
    details: yup.string().required("Tasks must have a content"),
    due_at: yup.date().nullable().required("Date is required"),
    people: yup.array().of(yup.object().shape({ id: yup.number().required() })),
  })

  const getMentions = (prefix) => {
    switch (prefix) {
      case "@":
        return people || []
      case "#":
        return [...topics, { id: null, name: searchQuery, value: searchQuery }] || []
      case "~":
        return (
          [...expertises, { id: null, name: searchQuery, value: searchQuery }] || []
        )
      default:
        return []
    }
  }

  const formatDetails = (details) => {
    let formatted = details
    const personRegexp = new RegExp(/@[\S]+_?[\S]*/, "gu")
    if (personRegexp.test(formatted)) {
      const people = formatted.match(personRegexp)
      people.forEach((person) => {
        const p = person.replace("@", "").replace(/_/g, " ")
        formatted = formatted.replace(
          person,
          `{{${
            _.uniqBy(formik.values.people, "id")
              .filter((x) => formik.values.details.includes(`@${x.value}`))
              .find((per) => {
                return per.name === p || per.full_name === p
              }).id
          }}}`
        )
      })
      return formatted
    } else {
      return formatted
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      details: initialContent,
      due_at: moment().tz(user.time_zone),
      people: initialPeople,
      topics: [],
      expertises: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        addReminder({
          name: values.name,
          details: formatDetails(values.details),
          due_at: values.due_at,
          people: _.uniqBy(values.people, "id").filter((p) =>
            values.details.includes(`@${p.value}`)
          ),
          topics: _.uniqBy(values.topics, "id").filter((t) =>
            values.details.includes(`#${t?.value}`)
          ),
          expertises: _.uniqBy(values.expertises, "id").filter((t) =>
            values.details.includes(`~${t?.value}`)
          ),
        })
      )
      if (afterSubmit) afterSubmit()
    },
  })

  const handleSubmit = () => {
    const people = _.uniqBy(formik.values.people, "id").filter((p) =>
      formik.values.details.includes(`@${p.value}`)
    )

    if (people.length > 0) {
      formik.submitForm().then(() => formik.resetForm())
    } else {
      formik.setFieldValue("people", [])
    }
  }

  useEffect(() => {
    formik.setFieldValue("details", initialContent)
    formik.setFieldValue("people", initialPeople)
    // eslint-disable-next-line
  }, [initialContent, initialPeople])

  const handleSearch = (searchQuery, _prefix) => {
    setSearchQuery(searchQuery)
    setPrefix(_prefix)
    setPeople([])
    setTopics([])
    setExpertises([])

    setLoading(true)

    if (_prefix === "@") {
      loadPeople(searchQuery)
    } else if (_prefix === "#") {
      loadTopics(searchQuery)
    } else if (_prefix === "~") {
      loadExpertises(searchQuery)
    }
  }

  const [debouncedSearch] = useState(() => _.debounce(handleSearch, 500))

  const loadPeople = (query) => {
    fetchForMentions(query, "SORT").then((res) => {
      setPeople(res.slice(0, 10))
      setLoading(false)
    })
  }

  const loadTopics = (query) => {
    fetchForMentions(query, null, "topics").then((res) => {
      setTopics(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const loadExpertises = (query) => {
    fetchForMentions(query, null, "expertises").then((res) => {
      setExpertises(res.data.slice(0, 10).map((t) => ({ id: t.id, name: t.title })))
      setLoading(false)
    })
  }

  const handleSelect = (id, name, value, _prefix) => {
    if (_prefix === "@") {
      formik.setFieldValue("people", [
        ...formik.values.people,
        { id: id, full_name: name, value: value },
      ])
    } else if (_prefix === "#") {
      formik.setFieldValue("topics", [
        ...formik.values.topics,
        { id: id, value: value },
      ])
    } else if (_prefix === "~") {
      formik.setFieldValue("expertises", [
        ...formik.values.expertises,
        { id: id, value: value },
      ])
    }
  }

  return (
    <div className="CreateTask">
      {adding ? (
        <RelatableLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div
            className={`CreateTask_DueDate ${
              minified ? "CreateTask_DueDate--minified" : ""
            }`}
          >
            <span className="CreateTask_DueDate_DateLabel">Due date:</span>
            <Form.Item
              className={`CreateTask_DueDate_DateInput ${
                minified ? "CreateTask_DueDate_DateInput--minified" : ""
              }`}
              validateStatus={
                formik.touched.due_at && formik.errors.due_at ? "error" : "success"
              }
              help={
                formik.touched.due_at && formik.errors.due_at
                  ? formik.errors.due_at
                  : null
              }
            >
              <DatePicker
                key={"due_at"}
                name={"due_at"}
                format={"MM/DD/YYYY"}
                allowClear={false}
                value={
                  formik.values.due_at
                    ? moment(formik.values.due_at).tz(user.time_zone)
                    : null
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "due_at", //get date without TimeZone
                    new Date(e?._d.getTime() - e?._d.getTimezoneOffset() * 60000)
                  )
                }
                onBlur={() => formik.setFieldTouched("due_at", true)}
              />
            </Form.Item>
          </div>
          <div
            className={`CreateTask_Container ${
              minified ? "CreateTask_Container--minified" : ""
            }`}
          >
            <Form.Item
              validateStatus={
                formik.touched.details && formik.errors.details ? "error" : "success"
              }
              help={
                formik.touched.details && formik.errors.details
                  ? formik.errors.details
                  : ""
              }
            >
              <Mentions
                ref={textarea}
                key="details"
                name="details"
                prefix={["@", "#", "~"]}
                className="CreateTask_Textarea"
                placeholder={"Type details about task..."}
                rows={6}
                loading={loading}
                onSearch={debouncedSearch}
                onChange={(e) => formik.setFieldValue("details", e)}
                onBlur={() => formik.setFieldTouched("details", true)}
                onSelect={(selected, _prefix) =>
                  handleSelect(selected.id, selected.name, selected.value, _prefix)
                }
                value={formik.values.details}
                autoFocus
              >
                {(getMentions(prefix) || []).map((mention) => {
                  return (
                    <Option
                      id={mention.id}
                      name={mention.name}
                      key={mention.id}
                      value={mention.name ? mention.name.replace(/ /g, "_") : ""}
                      className="antd-demo-dynamic-option"
                    >
                      <span>{mention.name}</span>
                    </Option>
                  )
                })}
              </Mentions>
            </Form.Item>
            <div
              className={`CreateTask_Footer ${
                minified ? "CreateTask_Footer--minified" : ""
              }`}
            >
              <span className="CreateTask_Footer_Helper">
                @ to attach this task to a person, # to add topics, ~ to add
                expertise
              </span>
              <Popover
                placement="topRight"
                trigger={!formik.isValid || formik.values.people ? ["hover"] : []}
                title="Why can’t I save this reminder?"
                overlayClassName={"AddInteractionPopover"}
                content={
                  <p className="AddInteractionPopover_Content">
                    You can't add a reminder without contacts. Use <strong>@</strong>{" "}
                    to do this.
                  </p>
                }
              >
                <button
                  className={`${
                    !formik.isValid || !formik.values.people.length
                      ? "CreateTask_Footer_Button--disabled"
                      : ""
                  } ${minified ? "Footer_Button--minified" : ""}`}
                  disabled={!formik.isValid || !formik.values.people.length}
                  onClick={handleSubmit}
                >
                  Add
                  <Icon
                    className="CreateTask_Footer_Button_Icon"
                    component={CalendarCheckIcon}
                  />
                </button>
              </Popover>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

NewTaskTab.propTypes = {
  initialContent: PropTypes.string.isRequired,
  initialPeople: PropTypes.array.isRequired,
}

NewTaskTab.defaultProps = {
  initialContent: "",
  initialPeople: [],
}

export default NewTaskTab
