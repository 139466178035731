import React from "react"
import "./EmptyState.styles.scss"

const emptyState = (props) => {
  return props.show || true ? (
    <div className={`EmptyState EmptyState_Size_${props.size || "large"}`}>
      {props.quote ? (
        <h5 className={"EmptyState_Quote"}>
          {props.quote} <span>- {props.quoteFrom}</span>
        </h5>
      ) : (
        ""
      )}
      <span className={"EmptyState_Instructions"}>{props.instructions}</span>
    </div>
  ) : null
}

export default emptyState
