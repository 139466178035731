import { Tooltip } from "antd"
import React from "react"
import "./ImportanceSelector.styles.scss"
import ImportanceIndicator from "../ImportanceIndicator/ImportanceIndicator.component"
import { useDispatch } from "react-redux"
import { editContact } from "../../../redux/People/People.actions"

const ImportanceSelectorText = ({ id }) => {
  const dispatch = useDispatch()

  return (
    <div>
      <div
        className={"ImportanceSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { importance: "na" }))
        }}
      >
        <ImportanceIndicator level={"na"} />
      </div>
      <div
        className={"ImportanceSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { importance: "not_important" }))
        }}
      >
        <ImportanceIndicator level={"not_important"} />
      </div>
      <div
        className={"ImportanceSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { importance: "medium" }))
        }}
      >
        <ImportanceIndicator level={"medium"} />
      </div>
      <div
        className={"ImportanceSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { importance: "important" }))
        }}
      >
        <ImportanceIndicator level={"important"} />
      </div>
      <div
        className={"ImportanceSelector_Option"}
        onClick={() => {
          dispatch(editContact(id, { importance: "very_important" }))
        }}
      >
        <ImportanceIndicator level={"very_important"} />
      </div>
    </div>
  )
}

const ImportanceSelector = ({ children, id, opened }) => {
  return (
    <Tooltip
      title={<ImportanceSelectorText id={id} />}
      visible={opened}
      placement={"bottom"}
      icon={null}
      overlayClassName={"ImportanceSelector"}
    >
      {children}
    </Tooltip>
  )
}

export default ImportanceSelector
