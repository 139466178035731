import axios from "axios"

export const createNewContact = async (params, username, callback) => {
  try {
    const { data } = await axios.request({
      url: `/v1/users/profile_filler`,
      params: {
        ...params,
        public_username: username,
      },
      method: "POST",
    })

    callback(data)
  } catch (error) {
    callback({ error: error.response.data.error })
  }
}

export const getInfo = async (username, callback) => {
  try {
    const { data } = await axios.request({
      url: `/v1/users/get_info`,
      params: {
        public_username: username,
      },
      method: "GET",
    })

    callback(data)
  } catch (error) {
    callback({ error: error.response.data.error })
  }
}
