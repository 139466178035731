import moment from "moment/moment"

const dateWithinInterval = (date, interval) => {
  switch (interval) {
    case "never":
      return moment(date).isSameOrAfter(moment().subtract(999, "years"), "day")
    case "weekly":
      return moment(date).isSameOrAfter(moment().subtract(1, "week"), "day")
    case "monthly":
      return moment(date).isSameOrAfter(moment().subtract(1, "month"), "day")
    case "bi-monthly":
      return moment(date).isSameOrAfter(moment().subtract(2, "months"), "day")
    case "quarterly":
      return moment(date).isSameOrAfter(moment().subtract(3, "months"), "day")
    case "semi-annually":
      return moment(date).isSameOrAfter(moment().subtract(6, "months"), "day")
    case "annually":
      return moment(date).isSameOrAfter(moment().subtract(1, "year"), "day")
    default:
      return false
  }
}

export default dateWithinInterval
