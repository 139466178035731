import React from "react"
import "../InteractionModal.styles.scss"
import { urlify } from "../../../../../../../helpers/helpers.jsx"

const DOMPurify = require("dompurify")(window)

const InteractionContent = ({ interactionDetails }) => (
  <>
    {interactionDetails && interactionDetails.participants.length > 0 && (
      <div className={"InteractionModal__Info"}>
        <span>Participants:</span>{" "}
        <div>
          {interactionDetails && interactionDetails.participants.length > 0
            ? interactionDetails.participants.map((participant, i) => {
                if (participant.full_name) {
                  return (
                    <span className={`InteractionModal__Value`}>
                      {participant.full_name}
                      {participant.email ? `(${participant.email})` : ""}
                      {i !== interactionDetails.participants.length - 1 && ", "}
                    </span>
                  )
                } else {
                  return (
                    <span className={`InteractionModal__Value`}>
                      {participant.email}
                      {i !== interactionDetails.participants.length - 1 && ", "}
                    </span>
                  )
                }
              })
            : "---"}
        </div>
      </div>
    )}
    {interactionDetails?.body && (
      <div
        className={`InteractionModal__Body`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            urlify(interactionDetails.body).split("\n").join("<br/>"),
            {
              ADD_ATTR: ["target"],
            }
          ),
        }}
      />
    )}
  </>
)

export default InteractionContent
