import React, { useState } from "react"
import { Button, Modal } from "antd"
import { useDispatch } from "react-redux"
import { setAsFreeAccount } from "../../../redux/Admin/Admin.actions"

const SetAsFreeAccountModal = ({ email, id, visible, onCancel }) => {
  const [input, setInput] = useState("")
  const dispatch = useDispatch()

  return (
    <Modal
      closable={false}
      width={"40vw"}
      destroyOnClose
      footer={null}
      title={<h3>Set as free account</h3>}
      visible={visible}
      onCancel={() => onCancel()}
    >
      <div style={{ padding: "2rem" }}>
        <span>
          You are trying to set the account belonging to <b>{email}</b> as a free
          account.
        </span>
        <input
          style={{ width: "100%" }}
          placeholder="Type SET AS FREE to confirm"
          onChange={(e) => setInput(e.target.value)}
        />
        <div style={{ display: "flex" }}>
          <Button
            onClick={onCancel}
            style={{ margin: "1rem" }}
            type={"primary"}
            block
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (input !== "SET AS FREE") {
                return alert("Please type SET AS FREE to confirm")
              } else {
                dispatch(setAsFreeAccount(id))
                onCancel()
              }
            }}
            style={{ margin: "1rem" }}
            type={"danger"}
            block
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SetAsFreeAccountModal
