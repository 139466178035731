import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Dropdown, List, Menu, Skeleton, Tooltip } from "antd"
import moment from "moment"
import "./EventCard.styles.scss"
import {
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../../../../redux/App/App.actions"
import Icon from "@ant-design/icons"
import Avatar from "../../../UI/Avatar/Avatar.component"
import CancelIcon from "../../../Icons/Cancel.icons"
import {
  fetchEvents,
  ignoreSpecialDate,
} from "../../../../redux/Reminders/Reminders.actions"
import {
  ignoreTrigger,
  updateTrigger,
} from "../../../../redux/Triggers/Triggers.actions"
import { deleteContact, editContact } from "../../../../redux/People/People.actions"
import RecencySelector from "../../../Database/RecencySelector/RecencySelector.component"
import dateWithinInterval from "../../../../helpers/dateWithinInterval"
import RemindMeButton from "./RemindMeButton.component"

const EventCard = ({ item, i, count, page }) => {
  const dispatch = useDispatch()

  const visibleContactID = useSelector((state) => state.AppState.visibleContactID)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )
  const user = useSelector((state) => state.UserState)

  const ruleSphere =
    item.type === "trigger"
      ? item.person?.spheres.find((s) => s.id === item.rule.collection_id)
      : null

  const [settingRecency, setSettingRecency] = useState(false)

  const handleReloadEvents = () => {
    dispatch(fetchEvents(1, page * 3))
  }

  const handleClickViewPerson = () => {
    const personId = item.person.id
    const personSlug = item.person.slug
    if (contactDrawerVisible && +visibleContactID === +personId) {
      dispatch(setContactDrawerVisible(false))
      dispatch(setVisibleContactID(null))
      dispatch(setActiveTab(null))
    } else if (contactDrawerVisible && +visibleContactID !== +personId) {
      dispatch(setContactDrawerMode("view"))
      dispatch(setActiveTab(null))
      dispatch(setVisibleContactID(+personId, personSlug))
    } else {
      dispatch(setActiveTab(null))
      dispatch(setContactDrawerVisible(true))
      dispatch(setVisibleContactID(personId, personSlug))
    }
  }

  const handleClickIgnoreEvent = (eventId, personId, itemType) => {
    if (["special_date", "birthday"].includes(itemType)) {
      dispatch(ignoreSpecialDate(eventId, personId, 1, count * page))
    }
  }

  const isLeapYearAnd29Feb = (date) => {
    const currentYear = parseInt(moment().tz(user.time_zone).format("YYYY"))
    return (
      date &&
      currentYear % 4 !== 0 &&
      moment(date).tz(user.time_zone).format("MM-DD") === "02-29"
    )
  }

  const timeFromNow = (date) => {
    if (date) {
      const currentYear = parseInt(moment().format("YYYY"))
      const currentMonth = moment().format("M")
      const currentDate = moment().format("YYYY-MM-DD")
      const dateMonth = moment(date).format("M")
      let formattedDate

      if (currentMonth === "12" && dateMonth === "1") {
        formattedDate = moment(date).format(`${currentYear + 1}-MM-DD`)
      } else if (currentMonth === "1" && dateMonth === "12") {
        formattedDate = moment(date).format(`${currentYear - 1}-MM-DD`)
      } else if (isLeapYearAnd29Feb(date)) {
        formattedDate = moment(`${currentYear}-03-01`)
      } else {
        formattedDate = moment(date).format(`${currentYear}-MM-DD`)
      }

      if (formattedDate === currentDate) {
        return "today"
      } else {
        return moment(formattedDate).from(currentDate)
      }
    } else {
      return ""
    }
  }

  const renderLastContact = (person) => {
    let lastContact = "Never"
    if (person.last_sent) {
      lastContact = moment(person.last_sent)
        .tz(user.time_zone)
        .format("MMM Do, YYYY")
    } else if (person.last_received) {
      lastContact = moment(person.last_received)
        .tz(user.time_zone)
        .format("MMM Do, YYYY")
    }
    return lastContact
  }

  const printDetails = () => {
    switch (item.type) {
      case "special_date":
        return (
          <span>
            {`${item?.name}, ${
              isLeapYearAnd29Feb(item?.date)
                ? "March 1st"
                : moment(item?.date).format("MMMM Do")
            }
              (${timeFromNow(item?.date)})`}
          </span>
        )
      case "birthday":
        return (
          <span>
            {`Birthday, ${
              isLeapYearAnd29Feb(item?.date)
                ? "March 1st"
                : moment(item?.date).format("MMMM Do")
            }
              (${timeFromNow(item?.date)})`}
          </span>
        )
      case "trigger":
        return (
          <>
            <span>
              Last contact:{" "}
              <Tooltip title="Have you spoken more recently? Click to update the date and dismiss this">
                <span
                  className="EventCard_Details--LastContact"
                  onClick={() => setSettingRecency(!settingRecency)}
                >
                  {renderLastContact(item.person)}
                </span>
              </Tooltip>
            </span>
            <RecencySelector
              id={item.person.id}
              opened={settingRecency}
              onClose={() => setSettingRecency(false)}
              onSubmit={(date) => {
                if (dateWithinInterval(date, item.rule.interval)) {
                  dispatch(updateTrigger(item.id, "date_updated"))
                } else {
                  setTimeout(() => handleReloadEvents(), 500)
                }
                setSettingRecency(false)
              }}
            />
          </>
        )
      case "job_change":
        return <span>{item.description}</span>
      default:
        return null
    }
  }

  const eventTag = () => {
    switch (item.type) {
      case "special_date":
        return "Special Date"
      case "birthday":
        return "Birthday"
      case "trigger":
        return "Fading Relationship"
      case "job_change":
        return "Job Change"
      default:
        return null
    }
  }

  const handleIgnoreTrigger = () => {
    dispatch(ignoreTrigger(item.id))
  }

  const snoozeOptions = (
    <Menu>
      <Menu.Item
        onClick={(e) => {
          handleIgnoreTrigger()
        }}
        key={1}
      >
        Snooze for later
      </Menu.Item>
      {item.type === "trigger" && (
        <Menu.Item
          onClick={(e) => {
            const newSpheres = item.person.spheres.filter(
              (s) => s.id !== ruleSphere?.id
            )
            dispatch(
              editContact(item.person.id, {
                spheres: [...newSpheres],
              })
            )
            handleIgnoreTrigger()
            if (newSpheres.length === 0) {
              dispatch(deleteContact(item.person.id))
            }
          }}
          key={2}
        >
          Remove from <strong>{ruleSphere?.title}</strong>
        </Menu.Item>
      )}

      <Menu.Item
        onClick={(e) => {
          setSettingRecency(true)
        }}
        key={3}
      >
        I just spoke with them
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          dispatch(deleteContact(item.person.id))
          handleIgnoreTrigger()
        }}
        key={4}
      >
        <span className={"TriggerCard_Archive"}>Archive them</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <List.Item
      className={`EventCard EventCard--type-${item.type}`}
      actions={
        item.type === "empty"
          ? null
          : ["trigger", "job_change"].includes(item.type)
          ? [
              <Tooltip title="We’ll add them to your list of actions below">
                <RemindMeButton id={item.id} />
              </Tooltip>,
              <Dropdown overlay={snoozeOptions} trigger={["hover"]}>
                <button
                  className="EventCard_DismissButton"
                  onClick={handleIgnoreTrigger}
                >
                  <Icon component={CancelIcon} />
                  <span>Not Now</span>
                </button>
              </Dropdown>,
            ]
          : [
              <Tooltip title="See their contact information and reach out right away">
                <button
                  className="EventCard_ApproveButton"
                  onClick={handleClickViewPerson}
                >
                  Open Profile
                </button>
              </Tooltip>,
              <button
                onClick={() =>
                  handleClickIgnoreEvent(item.id, item.person.id, item.type)
                }
                className="EventCard_DismissButton"
              >
                <Icon component={CancelIcon} />
                <span>
                  {["special_date", "birthday"].includes(item.type)
                    ? "Dismiss"
                    : "Not Now"}
                </span>
              </button>,
            ]
      }
    >
      <Skeleton avatar title={false} loading={item.loading} active>
        <div
          className={`EventCard_BackgroundAvatar EventCard_BackgroundAvatar--color${
            item?.person.id % 5
          }`}
          style={{
            backgroundImage: `url(${
              item?.person.avatar
                ? item.person.avatar
                : `/avatars/${item?.person.id % 20}.png`
            })`,
          }}
        />
        <List.Item.Meta
          avatar={
            <Avatar
              avatar={item?.person.avatar}
              firstName={item?.person.full_name?.split(" ")[0]}
              lastName={item?.person.full_name?.split(" ")[1]}
              colorNumber={item?.person.id}
              size={45}
            />
          }
          title={
            <span className="EventCard_Title" onClick={handleClickViewPerson}>
              {item?.person.full_name}
            </span>
          }
          description={
            <div className="EventCard_Spheres">
              {item.person.spheres.slice(0, 1).map((sphere) => (
                <span key={sphere.id}>{sphere.title}</span>
              ))}
              {item.person.spheres.length > 1 && (
                <span>+{item.person.spheres.length - 1}</span>
              )}
            </div>
          }
        />
        <div className="EventCard_Details">
          <span
            className={`EventCard_Details_Tag EventCard_Details_Tag--${item.type}`}
          >
            {eventTag()}
          </span>
          {printDetails()}
        </div>
      </Skeleton>
    </List.Item>
  )
}

EventCard.propTypes = {
  i: PropTypes.number,
}

EventCard.defaultProps = {}

export default EventCard
