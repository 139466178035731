import React from "react"
import GridLoader from "react-spinners/GridLoader"

const CelebrationScene = ({ goNext }) => (
  <div className="Scene CelebrationScene">
    <h1>Nice Work!</h1>
    <h3 style={{ marginBottom: "40px" }}>
      Automagically figuring out your next move...
    </h3>
    <GridLoader color="#36d7b7" size={40} speedMultiplier={0.8} />
    <button style={{ marginTop: "48px" }} onClick={goNext} className="primary">
      🎉 I’m ready now - let’s do it!
    </button>
  </div>
)

export default CelebrationScene
