import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../Partner.styles.scss"
import * as yup from "yup"
import { useFormik } from "formik"
import { Form, Input } from "antd"
import { updatePartner, updatePartnerLogo } from "../../../redux/User/User.actions"
import RelatableLoader from "../../../components/UI/RelatableLoader/RelatableLoader.component"
import { useDropzone } from "react-dropzone"
import Icon, { PlusOutlined } from "@ant-design/icons"
import XCircleIcon from "../../../components/Icons/XCircle.icons"
import PlusCircleIcon from "../../../components/Icons/PlusCircle.icons"
import { apiHost } from "../../../config/serverConf"

const PartnerDetailsSection = () => {
  const partner = useSelector((state) => state.UserState.partner)
  const updating = useSelector((state) => state.UserState.updating)
  const dispatch = useDispatch()

  const onDrop = useCallback((files) => {
    if (files && files.length > 0) {
      dispatch(updatePartnerLogo(partner.id, ...files))
    }
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  })

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    keyword: yup.string().required("Keyword is required"),
    welcome_videos: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .min(4, "Title must be at least 4 characters")
          .required("Title is required"),
        description: yup
          .string()
          .min(12, "Description must be at least 12 characters")
          .required("Description is required"),
        thumbnail_url: yup
          .string()
          .min(8, "Thumbnail URL must be at least 8 characters")
          .required("Thumbnail URL is required"),
        vimeo_url: yup
          .string()
          .min(8, "Vimeo URL must be at least 8 characters")
          .required("Vimeo URL is required"),
      })
    ),
  })

  const formik = useFormik({
    initialValues: {
      name: partner.name || "",
      keyword: partner.keyword || "",
      welcome_videos: partner.welcome_videos || [],
    },
    isInitialValid: false,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(updatePartner(partner.id, values))
    },
  })

  return (
    <Form className="PartnerForm Section">
      <h2>My partner details</h2>
      <Form.Item
        label="Name"
        className="PartnerForm_Item"
        validateStatus={
          formik.touched.name && formik.errors.name ? "error" : "success"
        }
        help={formik.touched.name && formik.errors.name ? formik.errors.name : []}
      >
        <Input
          placeholder="Name"
          key="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="Keyword"
        className="PartnerForm_Item"
        validateStatus={
          formik.touched.keyword && formik.errors.keyword ? "error" : "success"
        }
        help={
          formik.touched.keyword && formik.errors.keyword
            ? formik.errors.keyword
            : []
        }
      >
        <Input
          placeholder="Keyword"
          key="keyword"
          name="keyword"
          value={formik.values.keyword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item label="Your Logo (178px x 42px)" className="PartnerForm_Item">
        <div className="PartnerForm_Logo" {...getRootProps()}>
          {updating && <RelatableLoader size={"large"} />}
          <input {...getInputProps()} />
          {partner?.logo?.length > 5 ? (
            <img
              width={178}
              style={{ maxHeight: "42px", cursor: "pointer" }}
              src={apiHost() + partner.logo}
              alt="Your logo"
            />
          ) : (
            <div className="PartnerForm_UploadButton">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </div>
      </Form.Item>
      <h4>Welcome videos:</h4>
      {formik.values.welcome_videos?.length > 0 &&
        formik.values.welcome_videos.map((video, i) => (
          <div className="PartnerForm_Video" key={i}>
            <Form.Item
              validateStatus={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.title &&
                formik.errors.welcome_videos[i]?.title
                  ? "error"
                  : "success"
              }
              help={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.title &&
                formik.errors.welcome_videos[i]?.title
              }
            >
              <Input
                placeholder="Title"
                key={`welcome_videos[${i}].title`}
                name={`welcome_videos[${i}].title`}
                value={formik.values.welcome_videos[i].title}
                onChange={(e) => {
                  formik.setFieldValue(`welcome_videos[${i}].title`, e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item
              validateStatus={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.description &&
                formik.errors.welcome_videos[i]?.description
                  ? "error"
                  : "success"
              }
              help={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.description &&
                formik.errors.welcome_videos[i]?.description
              }
            >
              <Input
                placeholder="Description"
                key={`welcome_videos[${i}].description`}
                name={`welcome_videos[${i}].description`}
                value={formik.values.welcome_videos[i].description}
                onChange={(e) => {
                  formik.setFieldValue(
                    `welcome_videos[${i}].description`,
                    e.target.value
                  )
                }}
              />
            </Form.Item>
            <Form.Item
              validateStatus={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.thumbnail_url &&
                formik.errors.welcome_videos[i]?.thumbnail_url
                  ? "error"
                  : "success"
              }
              help={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.thumbnail_url &&
                formik.errors.welcome_videos[i]?.thumbnail_url
              }
            >
              <Input
                placeholder="Thumbnail URL"
                key={`welcome_videos[${i}].thumbnail_url`}
                name={`welcome_videos[${i}].thumbnail_url`}
                value={formik.values.welcome_videos[i].thumbnail_url}
                onChange={(e) => {
                  formik.setFieldValue(
                    `welcome_videos[${i}].thumbnail_url`,
                    e.target.value
                  )
                }}
              />
            </Form.Item>
            <Form.Item
              validateStatus={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.vimeo_url &&
                formik.errors.welcome_videos[i]?.vimeo_url
                  ? "error"
                  : "success"
              }
              help={
                formik.touched.welcome_videos &&
                formik.errors.welcome_videos &&
                formik.touched.welcome_videos[i]?.vimeo_url &&
                formik.errors.welcome_videos[i]?.vimeo_url
              }
            >
              <Input
                placeholder="Vimeo URL"
                key={`welcome_videos[${i}].vimeo_url`}
                name={`welcome_videos[${i}].vimeo_url`}
                value={formik.values.welcome_videos[i].vimeo_url}
                onChange={(e) => {
                  formik.setFieldValue(
                    `welcome_videos[${i}].vimeo_url`,
                    e.target.value
                  )
                }}
              />
            </Form.Item>
            <div
              className="PartnerForm_Video_DeleteButton"
              onClick={() =>
                formik.setFieldValue(
                  "videos",
                  formik.values.welcome_videos.splice(i, 1)
                )
              }
            >
              <Icon component={XCircleIcon} />
            </div>
          </div>
        ))}
      {formik.values.welcome_videos?.length < 3 && (
        <div
          className="PartnerForm_Video_AddNewButton"
          onClick={() =>
            formik.setFieldValue(
              "welcome_videos",
              formik.values.welcome_videos.concat({
                title: "",
                description: "",
                thumbnail_url: "",
                vimeo_url: "",
              })
            )
          }
        >
          <Icon component={PlusCircleIcon} />
          Add new video
        </div>
      )}
      <button
        className={updating ? "SubmitButton SubmitButton-disabled" : "SubmitButton"}
        onClick={formik.submitForm}
        disabled={updating}
      >
        Save changes
      </button>
    </Form>
  )
}

export default PartnerDetailsSection
