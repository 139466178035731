import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import LoginForm from "../../components/Login/Forms/LoginForm.component"
import { notification } from "antd"
import LoginPageBackground from "./../../images/LoginPageBackground.svg"
import PageTemplate from "../../components/Login/LoginPageTemplate/LoginPageTemplate.component"

const LoginPage = (props) => {
  const history = useHistory()

  useEffect(() => {
    if (history.location.state && history.location.state.expired) {
      notification.error({
        message: "Login",
        description: "Your session has expired. Please login again.",
        className: "Notification-error",
      })
      history.location.state.expired = false
    }
    if (props.isAuthorized && !props.loggingOut) {
      history.push("/dashboard")
    }
    // eslint-disable-next-line
  }, [history])

  return (
    <PageTemplate backgroundImage={LoginPageBackground}>
      <LoginForm />
    </PageTemplate>
  )
}

export default LoginPage
