import React from "react"
import { List, Spin, Tooltip } from "antd"
import Icon, { LoadingOutlined } from "@ant-design/icons"
import moment from "moment"
import Avatar from "../../../../../components/UI/Avatar/Avatar.component"
import EyeSlashIcon from "../../../../../components/Icons/EyeSlash.icons"
import EyeIcon from "../../../../../components/Icons/Eye.icons"
import HourglassIcon from "../../../../../components/Icons/Hourglass.icons"
import SendingIcon from "../../../../../components/Icons/Sending.icons"
import TrashIcon from "../../../../../components/Icons/Trash.icons"
import { useSelector } from "react-redux"

const renderStatusIcon = (item, scheduledSendDate, time_zone) => {
  const antLoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />

  switch (item.status) {
    case "sent":
      if (item.tracking === "open") {
        return (
          <div className="MessagesTab_Item_TrackingIcon">
            {item.last_opened ? (
              <Tooltip
                placement="topRight"
                title={`Last opened ${moment(item.last_opened)
                  .tz(time_zone)
                  .format("LLL")}`}
              >
                <Icon component={EyeIcon} className="blue" />
              </Tooltip>
            ) : (
              <Icon component={EyeSlashIcon} className="gray" />
            )}
          </div>
        )
      } else return
    case "scheduled":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip
            placement="topRight"
            title={`The message is scheduled for ${moment(scheduledSendDate)
              .tz(time_zone)
              .format("LLL")}`}
          >
            <Icon component={HourglassIcon} className="green" />
          </Tooltip>
        </div>
      )
    case "scheduling_error__three":
    case "scheduling_error__two":
    case "scheduling_error__one":
    case "scheduling_error":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip
            placement="topRight"
            title="Something went wrong when scheduling message..."
          >
            <Icon component={HourglassIcon} className="red" />
          </Tooltip>
        </div>
      )
    case "sending_error__five":
    case "sending_error__four":
    case "sending_error__three":
    case "sending_error__two":
    case "sending_error__one":
    case "split_sending_error":
    case "sending_error":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip
            placement="topRight"
            title="Something went wrong when sending message..."
          >
            <Icon component={SendingIcon} className="red" />
          </Tooltip>
        </div>
      )
    case "invalid_email_error":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip
            placement="topRight"
            title="Email address is invalid. Please check the email address and try again."
          >
            <Icon component={SendingIcon} className="yellow" />
          </Tooltip>
        </div>
      )
    case "deleting_error":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip
            placement="topRight"
            title="Something went wrong when deleting scheduled message..."
          >
            <Icon component={TrashIcon} className="red" />
          </Tooltip>
        </div>
      )
    case "sending__one":
    case "split_sending":
    case "resending":
    case "sending":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip placement="topRight" title="Sending message...">
            <Spin indicator={antLoadingOutlinedIcon} />
          </Tooltip>
        </div>
      )
    case "scheduling":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip placement="topRight" title="Scheduling message...">
            <Spin indicator={antLoadingOutlinedIcon} />
          </Tooltip>
        </div>
      )
    case "deleting":
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip placement="topRight" title="Deleting message...">
            <Spin indicator={antLoadingOutlinedIcon} />
          </Tooltip>
        </div>
      )
    case null:
      return (
        <div className="MessagesTab_Item_TrackingIcon">
          <Tooltip placement="topRight" title="The message was not sent...">
            <Icon component={SendingIcon} className="red" />
          </Tooltip>
        </div>
      )
    case "unknown":
      return
    default:
      return
  }
}

const SimpleMessage = ({ message, scheduledSendDate, small = false }) => {
  const user = useSelector((state) => state.UserState)

  return (
    <List.Item
      className={`MessagesTab_Item ${small ? "MessagesTab_Item-small" : ""}`}
      key={message.id}
    >
      <List.Item.Meta
        avatar={
          <Avatar
            avatar={message.person.avatar}
            firstName={message.person.full_name?.split(" ")[0]}
            lastName={message.person.full_name?.split(" ")[1]}
            colorNumber={message.person.id}
            size={small ? 24 : 33}
          />
        }
        title={message.person.full_name}
        description={message.subject || " "}
      />
      <div>{renderStatusIcon(message, scheduledSendDate, user.time_zone)}</div>
    </List.Item>
  )
}

export default SimpleMessage
