import React, { useEffect } from "react"
import "./WelcomeChecklist.styles.scss"
import WhiteCheckboxIcon from "../../../../components/Icons/WhiteCheckbox.icons"
import BlueCheckboxIcon from "../../../../components/Icons/BlueCheckbox.icons"
import { useDispatch, useSelector } from "react-redux"
import { getUserFinishedTasks } from "../../../../redux/User/User.actions"
import { Spin } from "antd"
import RelatableLoader from "../../../../components/UI/RelatableLoader/RelatableLoader.component"

const WelcomeChecklist = () => {
  const finishedTasks = useSelector((state) => state.UserState.finished_tasks)
  const loading = useSelector((state) => state.UserState.finished_tasks_loading)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserFinishedTasks())
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {finishedTasks.length === 5 ? null : (
        <div className="WelcomeChecklist">
          <h1>Get Started with Relatable</h1>
          <RelatableLoader loading={loading}>
            <p>
              {finishedTasks.includes("first_email_connected") ? (
                <BlueCheckboxIcon />
              ) : (
                <WhiteCheckboxIcon />
              )}
              <span>
                <a href="account/settings">Connect</a> an email account
              </span>
            </p>
            <p>
              {finishedTasks.includes("first_sphere_created") ? (
                <BlueCheckboxIcon />
              ) : (
                <WhiteCheckboxIcon />
              )}
              <span>
                <a href="/database">Create</a> your spheres
              </span>
            </p>
            <p>
              {finishedTasks.includes("first_sphere_created") ? (
                <BlueCheckboxIcon />
              ) : (
                <WhiteCheckboxIcon />
              )}
              <span>
                Request{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://form.typeform.com/to/wQSqYF5M"
                >
                  Concierge Onboarding{" "}
                </a>
                (optional)
              </span>
            </p>
            <p>
              {finishedTasks.includes("added_25_people") ? (
                <BlueCheckboxIcon />
              ) : (
                <WhiteCheckboxIcon />
              )}
              <span>
                <a href="/uncategorized">Add</a> people to your database
              </span>
            </p>
            <p>
              {finishedTasks.includes("registered_to_mobile_app") ? (
                <BlueCheckboxIcon />
              ) : (
                <WhiteCheckboxIcon />
              )}
              <span>
                Grab the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/us/app/relatable-crm/id1668247614"
                >
                  iPhone
                </a>{" "}
                or{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.relatable.crm"
                >
                  Android
                </a>{" "}
                app
              </span>
            </p>
            <p>
              {finishedTasks.includes("first_message_sent") ? (
                <BlueCheckboxIcon />
              ) : (
                <WhiteCheckboxIcon />
              )}
              <span>
                Send one <a href="/database">message</a>
              </span>
            </p>
          </RelatableLoader>
        </div>
      )}
    </>
  )
}

export default WelcomeChecklist
