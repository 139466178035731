import React from "react"

const EyeSlashIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.30859 3.3125L15.5586 15.6875"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8247 11.5814C11.3086 12.0543 10.6333 12.3154 9.93329 12.3127C9.36564 12.3126 8.81128 12.1408 8.34311 11.8198C7.87494 11.4988 7.51486 11.0436 7.31021 10.5141C7.10557 9.98463 7.06594 9.40561 7.19653 8.85318C7.32712 8.30074 7.62182 7.80076 8.04189 7.41895"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.13672 5.32324C3.26797 6.77168 2.05859 9.4998 2.05859 9.4998C2.05859 9.4998 4.30859 14.5623 9.93359 14.5623C11.2517 14.573 12.5533 14.2693 13.7305 13.6764"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6031 12.3899C17.1359 11.0188 17.8109 9.50002 17.8109 9.50002C17.8109 9.50002 15.5609 4.43752 9.93594 4.43752C9.44829 4.43655 8.96142 4.47654 8.48047 4.55705"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4609 6.7373C11.059 6.85063 11.6041 7.15543 12.0138 7.6057C12.4234 8.05597 12.6755 8.62727 12.732 9.2334"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EyeSlashIcon
