import { Input, Table } from "antd"
import axios from "axios"
import React, { useEffect, useState } from "react"
import Player from "./Player.component"

const PromptsComponent = () => {
  const { TextArea } = Input
  const [prompts, setPrompts] = useState([])

  const handleGetPrompts = async () => {
    await axios
      .request({
        url: "/v1/admin/prompts",
        method: "GET",
      })
      .then((r) => {
        setPrompts(r.data)
      })
  }

  useEffect(() => {
    handleGetPrompts()
  }, [])

  return (
    <div>
      <h2>Prompts:</h2>

      <div>
        <Table
          scroll={{ x: 1500 }}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
            },
            {
              title: "Content",
              dataIndex: "content",
              key: "content",
              render: (text, record) => (
                <div>
                  {record.recording_link ? (
                    <Player url={record.recording_link} />
                  ) : (
                    record.content
                  )}
                </div>
              ),
            },
            {
              title: "User ID",
              dataIndex: "user_id",
              key: "user_id",
            },
            {
              title: "User Email",
              dataIndex: "user_email",
              key: "user_email",
            },
            {
              title: "Structured Content",
              dataIndex: "structured_content",
              key: "structured_content",
              width: "20%",
              render: (text, record) => (
                <div>{JSON.stringify(record.structured_content)}</div>
              ),
            },
            {
              title: "Created At",
              dataIndex: "created_at",
              key: "created_at",
            },
            {
              title: "Review Data",
              dataIndex: "review_data",
              key: "review_data",
              width: "20%",
              render: (text, record) => (
                <div>{JSON.stringify(record.review_data)}</div>
              ),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
            },
            {
              title: "Content Type",
              dataIndex: "content_type",
              key: "content_type",
            },
          ]}
          dataSource={prompts}
          rowKey="id"
        />
      </div>
    </div>
  )
}

export default PromptsComponent
