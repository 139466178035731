import React, { useEffect, useState } from "react"
import { Select, Tooltip } from "antd"
import { useSelector } from "react-redux"
import UpRightSquare from "../Icons/UpRightSquare.icons"
import Icon from "@ant-design/icons"
import { Link } from "react-router-dom"

const SendStep = ({ step, accounts, onUpdate }) => {
  const templates = useSelector((state) => state.Messages.templates)

  const [template, setTemplate] = useState(null)
  const [email, setEmail] = useState(
    accounts.find((a) => a.default)?.email || accounts[0]?.email
  )

  useEffect(() => {
    if (step?.configuration) {
      setTemplate(step.configuration.template)
      setEmail(step.configuration.email)
    }
  }, [step])

  useEffect(() => {
    onUpdate({ template, email })
    // eslint-disable-next-line
  }, [template, email])

  const onChangeTemplate = (val) => {
    setTemplate(val)
  }

  const onChangeEmail = (val) => {
    setEmail(val)
  }

  return (
    <div className={"FlowSphere_StepContent"}>
      <span>Send</span>
      <Select
        size={"large"}
        placeholder={"selected template"}
        onChange={(v) => onChangeTemplate(v)}
        value={template}
      >
        {templates?.map(({ id, subject }, idx) => {
          return (
            <Select.Option key={idx} value={id}>
              {subject}
            </Select.Option>
          )
        })}
      </Select>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        to={`/account/settings?section=Templates&template_id=${template}`}
      >
        <Tooltip title={"View and edit template"}>
          <Icon style={{ width: "12px" }} component={UpRightSquare} />
        </Tooltip>
      </Link>
      <span>via</span>
      <Select size={"large"} value={email} onChange={(v) => onChangeEmail(v)}>
        {accounts.map(({ email }, idx) => {
          return (
            <Select.Option key={idx} value={email}>
              {email}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export default SendStep
