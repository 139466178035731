import React from "react"
import { useDispatch } from "react-redux"
import { Button, Card, Tabs } from "antd"
import "./Admin.styles.scss"
import UsersTable from "../../components/Admin/UsersTable/UsersTable.component"
import {
  clearReduxAdminState,
  setInitialReduxAdminState,
} from "../../redux/Admin/Admin.actions"
import ReduxStateCard from "../../components/Admin/ReduxStateCard/ReduxStateCard.component"
import {
  clearReduxAccountsState,
  setInitialReduxAccountsState,
} from "../../redux/Accounts/Accounts.actions"
import {
  clearReduxActivitiesState,
  setInitialReduxActivitiesState,
} from "../../redux/Activities/Activities.actions"
import {
  clearReduxAppState,
  setInitialReduxAppState,
} from "../../redux/App/App.actions"
import {
  clearReduxCollectionsState,
  setInitialReduxCollectionsState,
} from "../../redux/Collections/Collections.actions"
import {
  clearReduxInteractionsState,
  setInitialReduxInteractionsState,
} from "../../redux/Interactions/Interactions.actions"
import {
  clearReduxIntroductionsState,
  setInitialReduxIntroductionsState,
} from "../../redux/Introductions/Introductions.actions"
import {
  clearReduxMessagesState,
  setInitialReduxMessagesState,
} from "../../redux/Messages/Messages.actions"
import {
  clearReduxParticipantsState,
  setInitialReduxParticipantsState,
} from "../../redux/Participants/Participants.actions"
import {
  clearReduxPeopleState,
  setInitialReduxPeopleState,
} from "../../redux/People/People.actions"
import {
  clearReduxRemindersState,
  setInitialReduxRemindersState,
} from "../../redux/Reminders/Reminders.actions"
import {
  clearReduxTriggersState,
  setInitialReduxTriggersState,
} from "../../redux/Triggers/Triggers.actions"
import {
  clearReduxUserState,
  setInitialReduxUserState,
} from "../../redux/User/User.actions"
import ChallengesTable from "../../components/Admin/ChallengesTable/ChallengesTable.component"
import PresetTemplates from "../../components/Admin/Suggestions/PresetTemplates.component"
import PromptsComponent from "../../components/Admin/PromptsComponent/PromptConfig.component"

const AdminPage = () => {
  const dispatch = useDispatch()

  const handleClickSetInitialAllReduxStore = () => {
    dispatch(setInitialReduxAccountsState())
    dispatch(setInitialReduxActivitiesState())
    dispatch(setInitialReduxAdminState())
    dispatch(setInitialReduxAppState())
    dispatch(setInitialReduxCollectionsState())
    dispatch(setInitialReduxInteractionsState())
    dispatch(setInitialReduxIntroductionsState())
    dispatch(setInitialReduxMessagesState())
    dispatch(setInitialReduxParticipantsState())
    dispatch(setInitialReduxPeopleState())
    dispatch(setInitialReduxRemindersState())
    dispatch(setInitialReduxTriggersState())
    dispatch(setInitialReduxUserState())
  }

  return (
    <div className="AdminPage">
      <h1>Admin Panel</h1>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="User accounts" key="1">
          <UsersTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Challenges" key="2">
          <ChallengesTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Suggestions" key="3">
          <PresetTemplates />
        </Tabs.TabPane>
        {/*<Tabs.TabPane tab="Magic Messages Prompt" key="3">*/}
        {/*  <PromptConfigComponent />*/}
        {/*</Tabs.TabPane>*/}
        <Tabs.TabPane tab="Redux State" key="4">
          <Card
            className="AdminPage_Cards"
            title="Redux State"
            extra={
              <div>
                <Button onClick={handleClickSetInitialAllReduxStore}>
                  Set initial all
                </Button>
              </div>
            }
          >
            <ReduxStateCard
              title="Accounts"
              clearState={() => dispatch(clearReduxAccountsState())}
              setInitialState={() => dispatch(setInitialReduxAccountsState())}
            />
            <ReduxStateCard
              title="Activities"
              clearState={() => dispatch(clearReduxActivitiesState())}
              setInitialState={() => dispatch(setInitialReduxActivitiesState())}
            />
            <ReduxStateCard
              title="Admin"
              clearState={() => dispatch(clearReduxAdminState())}
              setInitialState={() => dispatch(setInitialReduxAdminState())}
            />
            <ReduxStateCard
              title="App"
              clearState={() => dispatch(clearReduxAppState())}
              setInitialState={() => dispatch(setInitialReduxAppState())}
            />
            <ReduxStateCard
              title="Collections"
              clearState={() => dispatch(clearReduxCollectionsState())}
              setInitialState={() => dispatch(setInitialReduxCollectionsState())}
            />
            <ReduxStateCard
              title="Interactions"
              clearState={() => dispatch(clearReduxInteractionsState())}
              setInitialState={() => dispatch(setInitialReduxInteractionsState())}
            />
            <ReduxStateCard
              title="Introductions"
              clearState={() => dispatch(clearReduxIntroductionsState())}
              setInitialState={() => dispatch(setInitialReduxIntroductionsState())}
            />
            <ReduxStateCard
              title="Messages"
              clearState={() => dispatch(clearReduxMessagesState())}
              setInitialState={() => dispatch(setInitialReduxMessagesState())}
            />
            <ReduxStateCard
              title="Participants"
              clearState={() => dispatch(clearReduxParticipantsState())}
              setInitialState={() => dispatch(setInitialReduxParticipantsState())}
            />
            <ReduxStateCard
              title="People"
              clearState={() => dispatch(clearReduxPeopleState())}
              setInitialState={() => dispatch(setInitialReduxPeopleState())}
            />
            <ReduxStateCard
              title="Reminders"
              clearState={() => dispatch(clearReduxRemindersState())}
              setInitialState={() => dispatch(setInitialReduxRemindersState())}
            />
            <ReduxStateCard
              title="Triggers"
              clearState={() => dispatch(clearReduxTriggersState())}
              setInitialState={() => dispatch(setInitialReduxTriggersState())}
            />
            <ReduxStateCard
              title="User"
              clearState={() => dispatch(clearReduxUserState())}
              setInitialState={() => dispatch(setInitialReduxUserState())}
            />
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Prompts" key="5">
          <PromptsComponent />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default AdminPage
