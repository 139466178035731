import { all, call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { APPROVE_TRIGGER, IGNORE_TRIGGER, UPDATE_TRIGGER } from "./Triggers.types"
import {
  approveTriggerFail,
  approveTriggerSuccess,
  ignoreTriggerFail,
  ignoreTriggerSuccess,
  updateTriggerFail,
  updateTriggerSuccess,
} from "./Triggers.actions"
import { fetchEvents, fetchReminders } from "../Reminders/Reminders.actions"
import { fetchActivities, fetchPoints } from "../Activities/Activities.actions"

export function* watchTriggersSaga() {
  yield takeLatest(APPROVE_TRIGGER, approve)
  yield takeLatest(IGNORE_TRIGGER, ignore)
  yield takeLatest(UPDATE_TRIGGER, update)
}

function* approve(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/triggers/${action.id}/approve`,
        method: "POST",
      })
    )
    if (action.callback) {
      yield action.callback()
    }
    yield all([
      put(approveTriggerSuccess(data.trigger)),
      put(fetchReminders()),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
      put(fetchEvents(1, 3)),
    ])
  } catch (error) {
    yield put(approveTriggerFail(error))
  }
}

function* ignore(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/triggers/${action.id}/ignore`,
        method: "POST",
      })
    )
    if (action.callback) {
      yield action.callback()
    }
    yield put(ignoreTriggerSuccess(data.trigger))
    yield put(fetchEvents(1, 3))
  } catch (error) {
    yield put(ignoreTriggerFail(error))
  }
}

function* update(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/triggers/${action.id}`,
        data: { trigger: { status: action.status } },
        method: "PATCH",
      })
    )
    if (action.callback) {
      yield action.callback()
    }
    yield put(updateTriggerSuccess(data.trigger))
    yield put(fetchEvents(1, 3))
  } catch (error) {
    yield put(updateTriggerFail(error))
  }
}
