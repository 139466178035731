import React from "react"

const AtIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.66667C5.40001 1.66667 1.66667 5.40001 1.66667 10C1.66667 14.6 5.40001 18.3333 10 18.3333H14.1667V16.6667H10C6.38334 16.6667 3.33334 13.6167 3.33334 10C3.33334 6.38334 6.38334 3.33334 10 3.33334C13.6167 3.33334 16.6667 6.38334 16.6667 10V11.1917C16.6667 11.85 16.075 12.5 15.4167 12.5C14.7583 12.5 14.1667 11.85 14.1667 11.1917V10C14.1667 7.70001 12.3 5.83334 10 5.83334C7.70001 5.83334 5.83334 7.70001 5.83334 10C5.83334 12.3 7.70001 14.1667 10 14.1667C11.15 14.1667 12.2 13.7 12.95 12.9417C13.4917 13.6833 14.425 14.1667 15.4167 14.1667C17.0583 14.1667 18.3333 12.8333 18.3333 11.1917V10C18.3333 5.40001 14.6 1.66667 10 1.66667ZM10 12.5C8.61667 12.5 7.50001 11.3833 7.50001 10C7.50001 8.61667 8.61667 7.50001 10 7.50001C11.3833 7.50001 12.5 8.61667 12.5 10C12.5 11.3833 11.3833 12.5 10 12.5Z"
      fill="currentColor"
    />
  </svg>
)

export default AtIcon
