import React from "react"
import PageTemplate from "../../components/Login/LoginPageTemplate/LoginPageTemplate.component"
import ResetPasswordForm from "../../components/Login/Forms/ResetPasswordForm.component"
import SignUpPageBackground from "../../images/SignUpPageBackground.svg"

const ResetPasswordPage = () => {
  return (
    <PageTemplate
      backgroundImage={SignUpPageBackground}
      subtitle="Reset your password"
    >
      <ResetPasswordForm />
    </PageTemplate>
  )
}

export default ResetPasswordPage
