import React from "react"
import { useReward } from "react-rewards"
import { Checkbox } from "antd"

const RewardsCheckbox = ({ reminderCompleted, id, handleCompleteReminder }) => {
  const { reward, isAnimating } = useReward(`rewardId_checkbox_${id}`, "confetti", {
    position: "absolute",
    lifetime: 40,
  })
  return (
    <Checkbox
      style={{ position: "relative" }}
      onClick={reward}
      checked={reminderCompleted}
      disabled={reminderCompleted || isAnimating}
      onChange={(e) => {
        setTimeout(() => {
          if (e.target.checked) {
            handleCompleteReminder()
          }
        }, 800)
      }}
    >
      <div
        id={`rewardId_checkbox_${id}`}
        style={{
          backgroundColor: "red",
          position: "absolute",
          top: "11px",
          left: "9px",
        }}
      />
    </Checkbox>
  )
}
export default RewardsCheckbox
