import React, { useEffect, useState } from "react"
import { DatePicker } from "antd"
import PropTypes from "prop-types"
import moment from "moment/moment"
import "./RangeDatePicker.styles.scss"
import { useSelector } from "react-redux"

const RangeDatePicker = ({ onApplyRangeFilter, filters }) => {
  const user = useSelector((state) => state.UserState)
  const [after, setAfter] = useState(null)
  const [before, setBefore] = useState(null)

  useEffect(() => {
    const lastSent = filters.find((f) => f.field === "last_sent")

    let lt = lastSent?.values.find((v) => v.match(/lt:.*/)?.toString())
    let gt = lastSent?.values.find((v) => v.match(/gt:.*/)?.toString())

    setAfter(lt ? moment(lt.slice(4)).tz(user.time_zone) : null)
    setBefore(gt ? moment(gt.slice(4)).tz(user.time_zone) : null)
    // eslint-disable-next-line
  }, [filters])

  return (
    <div className="RangeDatePicker">
      <div className="RangeDatePicker_Before">
        <p>Before:</p>
        <DatePicker
          className="RangeDatePicker_Before_DatePicker"
          value={after}
          getCalendarContainer={(trigger) => trigger.parentNode}
          onChange={(e) => {
            if (e) {
              onApplyRangeFilter(true, {
                field: "last_sent",
                value: `lt: ${moment(e).tz(user.time_zone).format()}`,
              })
            } else {
              onApplyRangeFilter(false, {
                field: "last_sent",
                value: `lt: ${moment(e).tz(user.time_zone).format()}`,
              })
            }
          }}
          suffixIcon={null}
          placeholder="Select a date"
          bordered={false}
        />
      </div>
      <div className="RangeDatePicker_After">
        <p>After:</p>
        <DatePicker
          className="RangeDatePicker_After_DatePicker"
          value={before}
          getCalendarContainer={(trigger) => trigger.parentNode}
          onChange={(e) => {
            if (e) {
              onApplyRangeFilter(true, {
                field: "last_sent",
                value: `gt: ${moment(e).tz(user.time_zone).format()}`,
              })
            } else {
              onApplyRangeFilter(false, {
                field: "last_sent",
                value: `gt: ${moment(e).tz(user.time_zone).format()}`,
              })
            }
          }}
          suffixIcon={null}
          placeholder="Select a date"
          bordered={false}
        />
      </div>
    </div>
  )
}

RangeDatePicker.propTypes = {
  onApplyRangeFilter: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
}

export default RangeDatePicker
