import { all, call, delay, put, takeLatest } from "redux-saga/effects"
import {
  ADD_INTRODUCTION,
  APPROVE_INTRODUCTION,
  DELETE_INTRODUCTION,
  FETCH_INTRODUCTIONS,
} from "./Introductions.types"
import axios from "axios"
import {
  addIntroductionFail,
  addIntroductionSuccess,
  approveIntroductionFail,
  approveIntroductionSuccess,
  deleteIntroductionFail,
  deleteIntroductionSuccess,
  fetchIntroductionsFail,
  fetchIntroductionsSuccess,
} from "./Introductions.actions"
import { fetchActivities, fetchPoints } from "../Activities/Activities.actions"
import {
  setContactDrawerVisible,
  setRightPanelTab,
  setVisibleContactID,
} from "../App/App.actions"

const INTRODUCTIONS_PER_PAGE = 9

export function* watchIntroductionsSaga() {
  yield takeLatest(FETCH_INTRODUCTIONS, fetchIntroductions)
  yield takeLatest(ADD_INTRODUCTION, addIntroduction)
  yield takeLatest(APPROVE_INTRODUCTION, approveIntroduction)
  yield takeLatest(DELETE_INTRODUCTION, deleteIntroduction)
}

const getParams = (action) => {
  if (action.category === "awaiting")
    return {
      awaiting_page: action.page,
      per_page: INTRODUCTIONS_PER_PAGE,
    }
  else {
    return {
      completed_page: action.page,
      per_page: INTRODUCTIONS_PER_PAGE,
    }
  }
}

function* fetchIntroductions(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/introductions.json",
        params: getParams(action),
      })
    )
    yield put(
      fetchIntroductionsSuccess(
        data.introductions.awaiting,
        data.introductions.awaiting_total_entries,
        data.introductions.approved,
        data.introductions.approved_total_entries
      )
    )
  } catch (error) {
    yield put(fetchIntroductionsFail(error))
  }
}

function* addIntroduction(action) {
  const intro = action.introduction
  const formData = new FormData()
  yield call(async () => {
    formData.append("note", intro.note)
    formData.append("mail_from", intro.mail_from)
    formData.append("subject", intro.subject)
    formData.append("cc", intro.cc)
    formData.append("body", intro.body)
    intro.files.forEach((file) => {
      formData.append("attachments[]", file, file.name)
    })
    intro.participant_1_files.forEach((file) => {
      formData.append("participant_1_attachments[]", file, file.name)
    })
    intro.participant_2_files.forEach((file) => {
      formData.append("participant_2_attachments[]", file, file.name)
    })
    formData.append(
      "introduction_participants",
      JSON.stringify(intro.introduction_participants_attributes)
    )
  })

  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/introductions.json",
        data: formData,
        method: "POST",
      })
    )
    yield all([
      put(addIntroductionSuccess(data.introduction)),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
      put(setVisibleContactID(null)),
      put(setContactDrawerVisible(false)),
      put(setRightPanelTab("intros")),
    ])
  } catch (error) {
    yield put(addIntroductionFail(error))
  }
}

function* approveIntroduction(action) {
  const formData = new FormData()

  yield call(async () => {
    if (action.config) {
      formData.append("subject", action.config.subject)
      formData.append("body", action.config.body)
      formData.append("mail_from", action.config.mail_from)
      formData.append("mail_to", action.config.mail_to)
      formData.append("cc", action.config.cc)
      action.config.files.forEach((file) => {
        formData.append("attachments[]", file, file.name)
      })
    }
  })

  try {
    if (action.config) {
      yield call(() =>
        axios.request({
          url: `/v1/introductions/${action.id}`,
          data: formData,
          method: "PUT",
        })
      )
    }
    yield delay(1000)
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/introductions/${action.id}/approve.json`,
        params: {
          person_id: action.participant_id,
        },
        method: "POST",
      })
    )
    yield all([
      put(approveIntroductionSuccess(data.introduction)),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
    ])
  } catch (error) {
    yield put(approveIntroductionFail(error))
  }
}

function* deleteIntroduction(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/introductions/${action.id}.json`,
        method: "DELETE",
      })
    )
    yield put(deleteIntroductionSuccess(data.introduction))
  } catch (error) {
    yield put(deleteIntroductionFail(error))
  }
}
