import axios from "axios"

export const checkPublicUsername = async (public_username, callback) => {
  const { data } = await axios.request({
    url: "v1/users/check_public_username",
    params: {
      public_username: public_username,
    },
    method: "GET",
  })

  callback(data)
}

export const connectLinkedin = async (callback) => {
  const { data } = await axios.request({
    url: "v1/linkedin/connect",
    method: "GET",
  })

  callback(data)
}

export const getLinkedinStatus = async (callback) => {
  const { data } = await axios.request({
    url: "v1/linkedin/status",
    method: "GET",
  })

  callback(data)
}

export const syncLinkedin = async (token, status, cookie, callback) => {
  const { data } = await axios.request({
    url: "v1/linkedin/sync",
    params: {
      account_state: status,
      token: token,
      cookie: cookie,
    },
    method: "GET",
  })

  callback(data)
}

export const disconnectLinkedin = async (callback) => {
  const { data } = await axios.request({
    url: "v1/linkedin/disconnect",
    method: "GET",
  })

  callback(data)
}

export const saveSynced = async (sync, callback) => {
  const { data } = await axios.request({
    url: "v1/linkedin/update_sync_status",
    data: {
      synced: sync,
    },
    method: "POST",
  })

  callback(data)
}
